export class CreditiDistributoriItem {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.distributoreID = 0;
		this.importo = null;
		this.istanteInserimento = null;
		this.istanteModifica = null;
		this.istanteCancellazione = null;
		this.note = null;
		this.userIdCreazione = null;
		this.userIdModifica = null;
		this.userIdCancellazione = null;
		this.utenteCreazione = null;
		this.utenteModifica = null;
		this.utenteCancellazione = null;
		this.tipoUso = null;
		this.deleted = null;
	}

	itemID: number;
	distributoreID: number | null;
	importo: number | null;
	istanteInserimento: Date | string | null;
	istanteModifica: Date | string | null;
	istanteCancellazione: Date | string | null;
	note: string;
	userIdCreazione: string;
	userIdModifica: string;
	userIdCancellazione: string;
	utenteCreazione: string;
	utenteModifica: string;
	utenteCancellazione: string;
	tipoUso: number | null;
	deleted: boolean | null;
}