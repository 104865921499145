import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { StatiItem } from "@/models/amministrazione/stati/statiItem";
import permessi from "@/config/permessi";

const _ENDPONT_ASSOCIATI = api.endpoint.AMMINISTRAZIONE.GRUPPI_STATI_ASSOCIATI;
const _ENDPONT_DA_ASSOCIARE = api.endpoint.AMMINISTRAZIONE.GRUPPI_STATI_DA_ASSOCIARE;
@Component({})
export default class AssociaStati extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.STATI];
	}
	@Prop({ default: () => null }) relationItemId: number;
	@Prop({ default: () => null }) escludi: boolean;
	

	kendop: any = kendo;


	created() {

	}

	onReloadAssociazioneStati() {
		this.$emit("reload-associa-stati");
	}
}
