export class PacchettiCategorieItem {
	constructor() {
		this.itemID = null;
		this.categoria = null;
		this.ordine = null;
		this.backgroundColorFrontCard = null;
		this.colorFrontCard = null;
		this.backgroundColorBackCard = null;
		this.backgroundColorDettaglioCategoria = null;
		this.colorBackCard = null;
		this.backgroundColorButton = null;
		this.colorButton = null;
		this.image = null;
		this.image_file = null;
	}
	itemID: number;
	categoria: string;
	ordine: number | null;
	backgroundColorFrontCard: string;
	colorFrontCard: string;
	backgroundColorBackCard: string;
	backgroundColorDettaglioCategoria: string;
	colorBackCard: string;
	backgroundColorButton: string;
	colorButton: string;
	image: string;
	image_file: string;
}