export class CarrelloInfoProdotto {
	pacchettoID: number;
	contrattoNumero: string;
	fornitore: string;
	offsetDataMinima: number;
	pacchetto: string;
	descrizioneBreve: string;
	descrizioneEstesa: string;
	descrizioneSintetica: string;
	categoria: string;
	logoImage: string;
	logoImageThumb: string;
	immagineRicerca: string;
	immagineComplementare: string;
	pdfTerminiECondizioni: string;
	tipologiaProdotto: string;
	descrizioneTipologia: string;
	prodottoID: number;
	nomeProdotto: string;
	categoriaID: number;
	tipo: number;
	caricaImmagine: boolean | null;
	controlloIddEscluso: number | null;
	destinazioni: CarrelloDestinazione[];
	nazionalita: CarrelloNazionalita[];
	extraInfo: CarrelloExtraInfo[];
	parametroViaggio: CarrelloParametroViaggio[];
	testiCarrello: CarrelloTesto[];
	ulterioriDati: CarrelloUlterioriDati[];
}

export class CarrelloDestinazione {
	alphaCode3: string;
	code: number;
	nazione: string;
}

export class CarrelloNazionalita {
	codice: number;
	nazione: string;
}

export class CarrelloExtraInfo {
	itemID: number;
	prodottoID: number;
	extraInfoLabel: string;
	extraInfoApplication: number;
	extraInfoType: string;
	itemGUID: string;
}

export class CarrelloParametroViaggio {
	parametro: string;
	valore: string;
}

export class CarrelloTesto {
	itemID: number;
	prodottoID: number;
	tipoID: number;
	testo: string;
}

export class CarrelloUlterioriDati {
	itemID: number;
	prodottoID: number | null;
	pacchettoID: number | null;
	descrizione: string;
	note: string;
	tipo: string;
	obbligatorio: number | null;
	ordine: number | null;
}