//
    // Riepilogo:
    //     Specifies the day of the week.
    export enum DayOfWeek {
        // Riepilogo:
        //     Indicates Monday.
        monday = "monday",
        //
        // Riepilogo:
        //     Indicates Tuesday.
        tuesday = "tuesday",
        //
        // Riepilogo:
        //     Indicates Wednesday.
        wednesday = "wednesday",
        //
        // Riepilogo:
        //     Indicates Thursday.
        thursday = "thursday",
        //
        // Riepilogo:
        //     Indicates Friday.
        friday = "friday",
        //
        // Riepilogo:
        //     Indicates Saturday.
		saturday = "saturday",
		//
        // Riepilogo:
        //     Indicates Sunday.
        sunday = "sunday",
        //
    }