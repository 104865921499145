"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FooterRow = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");

var utils_1 = require("./../utils");

var FooterRow = {
  name: 'FooterRow',
  props: {
    columns: Array
  },
  methods: {
    columnStyles: function columnStyles(column) {
      return column.left !== undefined ? {
        position: 'sticky',
        left: column.left + 'px',
        right: column.right + 'px',
        zIndex: 1,
        background: '#f6f6f6',
        borderRightWidth: column.rightBorder ? '1px' : undefined
      } : undefined;
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;

    var renderCell = function renderCell(column, index) {
      var footerCellRendering = null;
      footerCellRendering = kendo_vue_common_1.getTemplate.call(this, {
        h: h,
        template: column.footerCell,
        defaultRendering: null,
        additionalProps: {
          field: column.field,
          colSpan: column.colSpan !== 1 ? column.colSpan : undefined,
          defaultStyle: this.columnStyles(column)
        }
      });
      return h("td", {
        key: index,
        field: column.field,
        attrs: this.v3 ? undefined : {
          field: column.field,
          colSpan: column.colSpan !== 1 ? column.colSpan : undefined
        },
        colSpan: column.colSpan !== 1 ? column.colSpan : undefined,
        style: this.columnStyles(column),
        "class": column.footerClassName
      }, [footerCellRendering]);
    };

    return h("tr", [utils_1.footerColumns(this.$props.columns).map(renderCell, this)]);
  }
};
exports.FooterRow = FooterRow;