import { datiViaggio } from "@/models/carrello/datiViaggio";
import { parametriDichiarazioni } from "@/models/carrello/parametriDichiarazioni";
import { Component } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseUserContextComponent from "../baseUserContextComponent";

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio
		})
	},

	methods: {
		...mapMutations(['setDatiViaggio'])
	}
})


export default class dichiarazioni extends baseUserContextComponent {

	adeguatezza: boolean = false;
	checkPolizza: boolean = false;
	checkPrivacy: boolean = false;

	datiViaggio!: datiViaggio;
	setDatiViaggio!: (setDatiViaggio: datiViaggio) => void;

	created() {
		this.initDichiarazioni();
	}

	initDichiarazioni(){
		if( this.datiViaggio && this.datiViaggio.parametriDichiarazione ){
			this.adeguatezza =  this.datiViaggio.parametriDichiarazione.adeguatezza;
			this.checkPolizza =  this.datiViaggio.parametriDichiarazione.checkPolizza;
			this.checkPrivacy =  this.datiViaggio.parametriDichiarazione.checkPrivacy;
		}
	}

	beforeDestroy() {
		this.$root.$off("updateDichiarazioni");
	}
	mounted() {
		var self = this;
		this.$root.$on("updateDichiarazioni", () => {
			self.initDichiarazioni();
		});
	}
	
	onChangeParametri(){
		var obj = {
			adeguatezza: this.adeguatezza,
			checkPolizza: this.checkPolizza,
			checkPrivacy: this.checkPrivacy
		}
		this.datiViaggio.parametriDichiarazione = obj;
		this.setDatiViaggio(this.datiViaggio);
	}
}