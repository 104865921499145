"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PagerInput = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var messages_1 = require("./../messages");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");
/**
 * Represents the default `PagerInput` component.
 */


var PagerInput = {
  props: {
    totalPages: Number,
    currentPage: Number,
    pageChange: Function
  },
  inject: {
    kendoLocalizationService: {
      default: null
    }
  },
  methods: {
    changeHangler: function changeHangler(e) {
      var text = this._text = e.target.value.replace(/\D/g, '');
      this.$forceUpdate();

      if (text) {
        this.$emit('pageChange', parseInt(text, 10), e);
      }
    },
    blurHandler: function blurHandler() {
      this.$forceUpdate();
    },
    value: function value() {
      var value = this._text === undefined ? this.$props.currentPage.toString() : this._text;
      this._text = undefined;
      return value;
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoLocalizationService = inject('kendoLocalizationService', {});
    return {
      v3: v3,
      kendoLocalizationService: kendoLocalizationService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var localizationService = kendo_vue_intl_1.provideLocalizationService(this);
    return h("span", {
      "class": "k-pager-input k-label"
    }, [localizationService.toLanguageString(messages_1.pagerPage, messages_1.messages[messages_1.pagerPage]), " ", h("input", {
      "class": "k-textbox",
      value: this.v3 ? this.value() : null,
      domProps: this.v3 ? undefined : {
        "value": this.value()
      },
      onBlur: this.blurHandler,
      on: this.v3 ? undefined : {
        "blur": this.blurHandler,
        "change": this.changeHangler
      },
      onChange: this.changeHangler
    }), " ", localizationService.toLanguageString(messages_1.pagerOf, messages_1.messages[messages_1.pagerOf]), " ", this.$props.totalPages]);
  }
};
exports.PagerInput = PagerInput;