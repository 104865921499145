import { Component } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { UserLoggedInfoModel } from "@/models/userModels";
import baseContextComponent from "./baseContextComponent";
import { Azioni } from "@/models/ruolo";
import { appRoute } from "@/models/appRoute";

@Component({
	computed: {
		...mapGetters([
			"getUser",
			"getIsAuthenticated"
		])
	}
})
export default class baseUserContextComponent extends baseContextComponent {
	get PERMESSI(): string[] { return null; }
	get canAdd(): boolean {
		return this.hasPermessi(this.PERMESSI, [Azioni.C]);
	}
	get canEdit(): boolean {
		return this.hasPermessi(this.PERMESSI, [Azioni.U]);
	}
	get canDelete(): boolean {
		return this.hasPermessi(this.PERMESSI, [Azioni.D]);
	}
	getUser!: UserLoggedInfoModel;
	getIsAuthenticated: boolean;

	get userFirstName(): string {
		return this.getUser != null && this.getUser.firstName != null ? this.getUser.firstName : "";
	}
	get userLastName(): string {
		return this.getUser != null && this.getUser.lastName != null ? this.getUser.lastName : "";
	}
	get userName(): string {
		return this.getUser != null && this.getUser.userName != null ? this.getUser.userName : "";
	}
	get userFullName(): string {
		return `${this.userFirstName} ${this.userLastName}`;
	}
	get hasImpersonateUser(): boolean {
		return this.getUser != null && this.getUser.hasImpersonateUser;
	}
	get userImpersonatorFullName(): string {
		return this.getUser != null && this.getUser.impersonatorFullName != null ? this.getUser.impersonatorFullName : "";
	}
	get userDescrizione(): string {
		var u = this.getUser;
		if (u == null)
			return '';
		if (u.distributoreID != null)
			return u.distributore;
		if (u.fornitoreID != null)
			return u.fornitore;
		return '';
	}
	get userIsWeb(): boolean {
		return this.getUser != null ? this.getUser.web : false;
	}
	// get getUserRole(): string {
	// 	if (this.getUser == null || this.getUser.roles == null) return "";
	// 	return this.getUser.roles[0];
	// }
	get getUserId(): string | null {
		return this.getUser != null ? this.getUser.id : null;
	}
	hasPermessi(permessi: string[], azioni: Azioni[] = null, all: boolean = false): boolean {

		if (permessi == null || permessi.length == 0)
			return true;

		var u = this.getUser;
		if (u == null)
			return false;
		if (u.permessi.find(x => x == '*'))
			return true;
		let per = [];
		if (azioni == null || azioni.length == 0) {
			per = permessi.map(p => p + '|');
		}
		else {
			azioni.forEach(a => per.push(permessi.map(p => p + '|' + a)));
		}
		if (all)
			return per.every(p => u.permessi.some(up => up.startsWith(p)));
		return per.some(p => u.permessi.some(up => up.startsWith(p)));
	}
	hasPermesso(permesso: string) {
		return this.hasPermessi(permesso == null ? null : [permesso]);
	}

	getRouteAllowedChildren(r: appRoute): appRoute[] {
		if (r == null)
			return null;
		var self = this;
		let name = r.name;
		let children = self.routes
			.filter(x => x.parent != null && x.parent.name == name && x.meta != null && x.meta.topSubNav != null && self.hasPermessi(x.meta.permissions))
			.sort((a, b) =>
				a.meta.topSubNav.displayOrder > b.meta.topSubNav.displayOrder
					? 1
					: -1
			);
		return children || [];
	}
}
