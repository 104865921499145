import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Prop } from "vue-property-decorator";
import { GruppiStatoPerProdotto } from "../../../models/anafrafica/gruppiStatiPerProdotto";

const _ENDPONT = api.endpoint.POLIZZE.GRUPPI_STATI;
@Component
export default class GruppiStatoPolize extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Prodotti.POLIZZE]; }
	@Prop({ default: () => null }) prodottoID: number;
	@Prop({ default: () => null }) fornitoreID: number;

	gruppiStati: TextValueItem[] = [];

	editItem: GruppiStatoPerProdotto = new GruppiStatoPerProdotto();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	keyIndex: number = 0;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },

			{
				field: "gruppoStatiID",
				title: this.$i18n.t("generico.gruppostato").toString(),
				sortBy: "gruppoStato",
				headerCell: "myHeaderCellTemplate",
				editSourceValue: "value",
				values: this.gruppiStati,
				filterable: true,
				itemsForFilter: this.gruppiStati,
				filterType: "select",
				editType: "select",
				editSource: this.gruppiStati,
				hidden: true,
			},
			{
				field: "gruppoStatoA3",
				title: "AlphaCode3",
				editHide: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
			},
			{ field: "gruppoStato", title: this.$i18n.t("generico.gruppostato").toString(), editHide: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "codiceRiepilogo", 
			editHide: true, title: this.$i18n.t("generico.codiceRiepilogo").toString(), headerCell: "myHeaderCellTemplate", width: 150, filterable: true },
			{ field: "descrizioneRiepilogo",
			editHide: true, title: this.$i18n.t("generico.descrizioneRiepilogo").toString(), headerCell: "myHeaderCellTemplate", width: 380, filterable: true },

			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		Promise.all([api.getDatiDropDown(`${api.endpoint.DROPDOWN.GRUPPISTATI_FORNITORE}/${this.fornitoreID}`).then(res => self.gruppiStati = res)])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		var self = this;
		self.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	addNewRow() {
		let newItem = new GruppiStatoPerProdotto();
		newItem.prodottoID = this.prodottoID;

		this.onEdit(newItem);
	}

	onEdit(item: GruppiStatoPerProdotto) {
		var self = this;
		self.editItem = Object.assign(new GruppiStatoPerProdotto(), item);
		self.editSubTitle = "";
		self.editTitle = `${item.gruppoStatoA3} - ${item.gruppoStato}`;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.gruppostato") }).toString();
		self.setGridRow<GruppiStatoPerProdotto>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	onDelete(item: GruppiStatoPerProdotto) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.gruppoStatoA3} - ${item.gruppoStato}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.gruppostato")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	onReloadAssociaStati() {
		this.keyIndex++;
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
