export class AgenziaAssociazioniItem {
	constructor() {
		this.itemID = null;
		this.network = null;
		this.distributorePadreID = null;
		this.tipoDistributore = null;
		this.pathLogo = null;
		this.pathLogo_file = null;
		this.pathImmagine1 = null;
	}
	itemID: number;
	network: string;
	distributorePadreID: number | null;
	tipoDistributore: number | null;
	pathLogo: string | null;
	pathLogo_file: string | null;
	pathImmagine1: string | null;
}