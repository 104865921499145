"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnResize = void 0;
/**
 * @hidden
 */
var ColumnResize = /** @class */ (function () {
    function ColumnResize(triggerResize) {
        var _this = this;
        /**
         * The settings for resizing the Grid.
         */
        this.resizable = false;
        this.isRtl = false;
        this.setIsRtl = function (isRtl) {
            _this.isRtl = isRtl;
        };
        this.onResize = triggerResize;
        this.dragHandler = this.dragHandler.bind(this);
    }
    ColumnResize.prototype.dragHandler = function (event, column, dragCue, end) {
        var e = event.originalEvent;
        if (!end) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }
        var tdElement = dragCue.parentElement;
        if (!tdElement || !tdElement.parentElement) {
            return;
        }
        var oldWidth = tdElement.clientWidth;
        var newWidth;
        if (this.isRtl) {
            newWidth = (dragCue.getBoundingClientRect().right - (dragCue.offsetWidth / 2)) - event.clientX;
            newWidth += oldWidth;
        }
        else {
            newWidth = oldWidth + event.clientX - dragCue.getBoundingClientRect().left - (dragCue.offsetWidth / 2);
        }
        if (!end && Math.abs(newWidth - oldWidth) < 1) {
            return;
        }
        this.fixateInitialWidths(tdElement.parentElement.clientWidth);
        this.setWidths(column, Math.floor(newWidth) / oldWidth);
        var index = this.columns.filter(function (c) { return !c.children.length; }).indexOf(column);
        this.onResize(index, oldWidth, newWidth, e, end);
    };
    ColumnResize.prototype.fixateInitialWidths = function (width) {
        var columns = this.columns.filter(function (c) { return !c.children.length; });
        var remainingCount = 0;
        var cols = this.colGroupMain ? this.colGroupMain.children : [];
        for (var i = 0; i < cols.length; i++) {
            if (cols[i].width) {
                width -= parseFloat(cols[i].width);
            }
            else {
                remainingCount++;
            }
        }
        if (remainingCount === 0) {
            return;
        }
        var perCol = Math.floor(width / remainingCount);
        for (var i = 0; i < cols.length; i++) {
            var col = cols[i];
            if (!col.width) {
                col.width = perCol;
                columns[i].width = perCol.toString();
                if (this.colGroupHeader) {
                    this.colGroupHeader.children[i].width = perCol;
                }
                if (this.colGroupFooter) {
                    this.colGroupFooter.children[i].width = perCol;
                }
            }
        }
    };
    ColumnResize.prototype.setWidths = function (column, coef) {
        var indexInOriginal = this.columns.indexOf(column);
        var toAdjust = [];
        var more = column.children.length;
        for (var i = indexInOriginal + 1; more > 0 && i < this.columns.length; i++, more--) {
            var cc = this.columns[i];
            if (!cc.children.length) {
                toAdjust.push(cc);
            }
            else {
                more += cc.children.length;
            }
        }
        if (toAdjust.length === 0) {
            toAdjust.push(column);
        }
        toAdjust.forEach(function (colToAdjust) {
            var targetWidth = colToAdjust.width ? parseFloat(colToAdjust.width.toString()) * coef : 0;
            var min = colToAdjust.minResizableWidth === undefined ? 10 : colToAdjust.minResizableWidth;
            if (targetWidth < min) {
                targetWidth = min;
            }
            colToAdjust.width = targetWidth;
        });
        this.updateColElements(toAdjust);
    };
    ColumnResize.prototype.updateColElements = function (affectedColumns) {
        var columns = this.columns.filter(function (c) { return !c.children.length; });
        var difference = 1e-10;
        for (var i = 0; i < affectedColumns.length; i++) {
            var colIndex = columns.indexOf(affectedColumns[i]);
            var currentColumnFloatWidth = parseFloat((affectedColumns[i].width || 0).toString());
            difference += currentColumnFloatWidth - Math.floor(currentColumnFloatWidth);
            var currentWidth = Math.floor(currentColumnFloatWidth) + Math.floor(difference);
            difference -= Math.floor(difference);
            if (this.colGroupMain) {
                this.colGroupMain.children[colIndex].width = currentWidth + 'px';
            }
            if (this.colGroupHeader) {
                // static headers
                this.colGroupHeader.children[colIndex].width = currentWidth + 'px';
            }
            if (this.colGroupFooter && this.colGroupFooter.children[colIndex]) {
                // static footers
                this.colGroupFooter.children[colIndex].width = currentWidth + 'px';
            }
        }
    };
    return ColumnResize;
}());
exports.ColumnResize = ColumnResize;
