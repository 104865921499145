import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueExtended, TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { PacchettiCategorieItem } from "@/models/amministrazione/pacchettiCategorie/pacchettiCategorieItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.PACCHETTI_CATEGORIE;
@Component({})
export default class iPacchettiCategorie extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.PACCHETTI_CATEGORIE];
	}
	editItem: PacchettiCategorieItem = new PacchettiCategorieItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "categoria", title: this.$i18n.t("generico.categoria").toString(), headerCell: "myHeaderCellTemplate", width: this.setColPercent(15) },
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(), headerCell: "myHeaderCellTemplate", width: this.setColPercent(20) },
			{
				field: "ordine",
				title: this.$i18n.t("generico.ordine").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "numeric-int",
				filterType: "numeric-int"
			},
			{
				field: "escludiImportoViaggio",
				title: this.$i18n.t("generico.escludiImportoViaggio").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean"
			},
			{ field: "backgroundColorFrontCard", title: this.$i18n.t("generico.backgroundFrontCard").toString(), headerCell: "myHeaderCellTemplate",type:"app-color" },
			{ field: "colorFrontCard", title: this.$i18n.t("generico.coloreFrontcard").toString(), headerCell: "myHeaderCellTemplate",type:"app-color" },
			{ field: "backgroundColorBackCard", title: this.$i18n.t("generico.backgroundBackCard").toString(), headerCell: "myHeaderCellTemplate",type:"app-color" },
			{ field: "backgroundColorDettaglioCategoria", title: this.$i18n.t("generico.backgroundColorDettaglioCategoria").toString(), headerCell: "myHeaderCellTemplate",type:"app-color" },
			{ field: "colorBackCard", title: this.$i18n.t("generico.coloreBackcard").toString(), headerCell: "myHeaderCellTemplate",type:"app-color" },
			{ field: "backgroundColorButton", title: this.$i18n.t("generico.backgroundBottone").toString(), headerCell: "myHeaderCellTemplate",type:"app-color" },
			{ field: "colorButton", title: this.$i18n.t("generico.coloreBottone").toString(), headerCell: "myHeaderCellTemplate",type:"app-color"},
			//{ field: "image", title: this.$i18n.t("generico.immagine").toString(), headerCell: "myHeaderCellTemplate", type: "link", },
			{
				field: "image",
				title: this.$i18n.t("generico.immagine").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\CategoriePacchetti",
				editField: "image_file",
				export: true,
				width: 150
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.pacchettiCategorie")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new PacchettiCategorieItem();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new PacchettiCategorieItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.pacchettiCategorie") }).toString();
		
		var formData = new FormData();
		var ojb = Object.assign({}, self.editItem);
		formData.append("image", self.editItem.image_file);
		delete ojb.image_file;
		let json = JSON.stringify(ojb);
		formData.append("data", json);

		
		var methodType = self.editItem.itemID > 0 ? 'put' : 'post';

		api.postFormData(_ENDPONT, formData, methodType).then((res) =>{
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});

			self.showEditModal = false;
			self.getData();
		});
		/*
		self.setGridRow<PacchettiCategorieItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
		 	self.showEditModal = false;
		 });*/
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
