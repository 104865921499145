import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { CommissioneBeneficiariItem } from "@/models/amministrazione/commissioneBeneficiari/commissioniBeneficiari";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.COMMISSIONI_BENEFICIARI;
@Component({})
export default class CommissioneBeneficiari extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.COMMISIONI_BENEFICARI];
	}
	editItem: CommissioneBeneficiariItem = new CommissioneBeneficiariItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	tipiDistributore: TextValueItem[] = [];

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "beneficiario",
				title: this.$i18n.t("generico.beneficiario").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(30)
			},

			{
				field: "tipoDistributoreID",
				title: this.$i18n.t("generico.tipoDistributore").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				values: this.tipiDistributore,
				sortBy: "tipoDistributore",
				itemsForFilter: this.tipiDistributore,
				filterType: "select",
				editType: "select",
				editSource: this.tipiDistributore,
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		//this.take = 60;
		//this.pageable.pageSize = 60;
		//this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;
		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.TIPO_DISTRIBUTORE).then(res => (self.tipiDistributore = res))])
		.then(responses => {
			self.getData();
		})
		.catch(err => {
			console.log(err);
		});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.commissioneBeneficiari")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new CommissioneBeneficiariItem();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new CommissioneBeneficiariItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.commissioneBeneficiari") }).toString();
		self.setGridRow<CommissioneBeneficiariItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
