var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('grid',{attrs:{"cell-render":'defaultCellTemplate',"data-items":_vm.gridData,"pageable":_vm.pageable,"filter":_vm.filter,"filterable":false,"resizable":true,"reorderable":true,"sortable":true,"sort":_vm.sort,"skip":_vm.skip,"take":_vm.take,"total":_vm.total,"columns":_vm.columns,"page-size":_vm.pageSize,"selectable":true,"selected-field":_vm.selectedRowField,"group":_vm.group,"expand-field":_vm.expandedField},on:{"pagechange":_vm.pageChangeHandler,"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange},scopedSlots:_vm._u([{key:"myHeaderCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('header-cell-template',_vm._b({attrs:{"tableName":'informazioni',"sort":_vm.sort},on:{"sort-changed":_vm.onSortChanged,"filter-apply":_vm.onFilterApply,"filter-remove":_vm.onFilterRemove}},'header-cell-template',props,false))]}},{key:"myFooterCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.gridData && _vm.gridData.data && _vm.gridData.data.length > 0 && _vm.gridData.data[0].items),expression:"gridData && gridData.data && gridData.data.length > 0 && gridData.data[0].items"}],staticClass:"text-right",attrs:{"prova":props.id}},[_vm._v(" "+_vm._s(_vm.$t("grid.groupfootersum"))+" "+_vm._s(_vm.kendop.toString(_vm.getImportoTotale, "n2"))+" ")])])]}},{key:"defaultCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('cell-template',_vm._b({attrs:{"canEdit":_vm.canEdit,"showDelete":false},on:{"delete":_vm.onDelete,"edit":_vm.onEdit,"expand":_vm.expandChange,"customSelect":_vm.customSelectionChange,"cell-click":function($event){return _vm.clickHandler(props.dataItem)}}},'cell-template',props,false))]}},{key:"myTextAreaTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('div',{staticStyle:{"white-space":"nowrap","width":"100%","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.getNestedValue(props.field, props.dataItem))+" ")])])]}}])}),_c('transition',{attrs:{"name":"full-slide-from-right"}},[(_vm.showEditModal)?_c('k-dialog',{staticClass:"app-dialog app-dialog-slim-scroll dialog-full-screen dialog-right",attrs:{"titleRender":'modal-title'},on:{"close":function($event){_vm.showEditModal = !_vm.showEditModal}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"modal-title h5"},[_c('div',{staticClass:"h5 text-capitalize"},[_vm._v(_vm._s(_vm.isNewItem ? _vm.$t("titolo.crea", [_vm.$t("generico.informazioni")]) : _vm.$t("titolo.modifica", [_vm.$t("generico.informazioni")])))]),(_vm.isNewItem == false)?_c('div',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.editTitle))]):_vm._e(),(_vm.isNewItem == false)?_c('div',{},[_vm._v(" "+_vm._s(_vm.editSubTitle)+" ")]):_vm._e()])]},proxy:true}],null,false,1767539063)},[_c('div',{staticClass:"dialog-body p-3"},[_c('edit-form',{attrs:{"canEdit":_vm.canEdit,"columns":_vm.columns},model:{value:(_vm.editItem),callback:function ($$v) {_vm.editItem=$$v},expression:"editItem"}})],1),_c('dialog-actions-bar',{staticClass:"p-2"},[(_vm.canEdit)?_c('div',{staticClass:"flex-fill p-2"},[_c('b-button',{staticClass:"text-capitalize",attrs:{"block":"","variant":"theme"},on:{"click":function($event){return _vm.onSaveEdit()}}},[_vm._v(" "+_vm._s(_vm.$t("btn.salva"))+" ")])],1):_vm._e(),_c('div',{staticClass:"flex-fill p-2"},[_c('b-button',{staticClass:"text-capitalize",attrs:{"block":""},on:{"click":function($event){_vm.showEditModal = !_vm.showEditModal}}},[_vm._v(" "+_vm._s(_vm.canEdit ? _vm.$t("btn.annulla") : _vm.$t("btn.chiudi"))+" ")])],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }