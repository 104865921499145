import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { ProdottiPolize } from "@/models/prodotti/prodottiPolize";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.POLIZZE.PRODOTTI_POLIZZE;
@Component({})
export default class ParametriPolize extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	@Prop({ default: () => null }) poliza: string;
	editItem: ProdottiPolize = new ProdottiPolize();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	tipologieProdotti: TextValueItem[] = [];
	fornitori: TextValueItem[] = [];
	categorie: TextValueItem[] = [];
	prodotto: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "pacchettoID", title: "Prodotto ID", filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "pacchetto", title: "Prodotto", groupable: true, headerCell: "myHeaderCellTemplate", width: 300 },
			{
				field: "prodottoID",
				title: "Polizza",
				width: 10,
				filterable: false,
				hidden: true,
				values: this.prodotto,
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				editType: "select",
				editSource: this.prodotto
			},
			{
				field: "tipologiaID",
				title: "Tipologia",
				width: 100,
				values: this.tipologieProdotti,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				hidden: true,
				editType: "select",
				editSource: this.tipologieProdotti
			},
			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				width: 100,
				values: this.fornitori,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				hidden: true,
				editType: "select",
				editSource: this.fornitori
			},
			{
				field: "categoriaID",
				title: "Categoria",
				groupable: true,
				values: this.categorie,
				headerCell: "myHeaderCellTemplate",
				width: 300,
				sortBy: "categoria",
				itemsForFilter: this.categorie,
				filterType: "select",
				editType: "select",
				editSource: this.categorie
			},
			{ field: "codiceAcquistoAutomatico", title: "Cod Automatico", width: 200, headerCell: "myHeaderCellTemplate" },
			{
				field: "logoImageThumb",
				title: "Logo Piccolo",
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\Loghi\\Thumb",
				editField: "logoImageThumb_file"
				// template: function(dataItem) {
				// 	if (dataItem.LogoImageThumb) return "<img src='~/App_Themes/Webins/Immagini/Loghi/Thumb/" + dataItem.LogoImageThumb + "'></img>";
				// 	else return "";
				// }
			},
			{
				field: "logoImage",
				title: "Logo Grande",
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\Loghi",
				editField: "logoImage_file",
				hidden: true
				// template: function(dataItem) {
				// 	if (dataItem.LogoImageThumb) return "<img src='~/App_Themes/Webins/Immagini/Loghi/Thumb/" + dataItem.LogoImageThumb + "'></img>";
				// 	else return "";
				// }
			},
			{
				field: "immagineRicerca",
				title: "Immagine Ricerca",
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\CarrelloRicerca",
				editField: "immagineRicerca_file",
				hidden: true
				// template: function(dataItem) {
				// 	if (dataItem.LogoImageThumb) return "<img src='~/App_Themes/Webins/Immagini/Loghi/Thumb/" + dataItem.LogoImageThumb + "'></img>";
				// 	else return "";
				// }
			},
			{
				field: "immagineComplementare",
				title: "immagine su Complementare",
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\CarrelloComplementari",
				editField: "immagineComplementare_file",
				hidden: true
			},
			{
				field: "vendibile",
				title: "Vendibile",
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{
				field: "CaricaImmagine",
				title: "Carica Immagine",
				width: 75,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				hidden: true
			},
			{
				field: "_actions",
				title: " ",
				width: (this.canEdit ? 50 : 0) + (this.canDelete ? 50 : 0),
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				hidden: (this.canEdit == false && this.canDelete == false)
			}
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.CATEGORIE).then(res => (self.categorie = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPOLOGIE_PRODOTTI).then(res => (self.tipologieProdotti = res));
		var p3 = api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res));
		var p4 = api.getDatiDropDown(api.endpoint.DROPDOWN.PRODOTTI).then(res => (self.prodotto = res));

		Promise.all([p, p2, p3, p4])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${self.poliza}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.prodotti")]).toString();
		self.deleteGridRow(_ENDPONT, item.pacchettoID, title, [message]);
	}

	addNewRow() {
		let newItem = new ProdottiPolize();
		newItem.prodottoID = this.prodottoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new ProdottiPolize(), item);
		self.editSubTitle = "";
		self.editTitle = item.pacchettoID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;

		api.setProdottoPoliza(self.editItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.prodotti") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
