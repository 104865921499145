export class ProdottiCorrelatiItem {
	constructor() {
		this.itemID = null;
		this.pacchettoID = null;
		this.pacchettoComplementareID = null;
		this.pacchettoComplementare = null;
		this.regole = null;
		this.tipo = null;
	}
	itemID: number;
	pacchettoID: number | null;
	pacchettoComplementareID: number | null;
	pacchettoComplementare: string;
	regole: string;
	tipo: number | null;
}