//
// Summary:
//     Types of the service messages
export enum MessageType {
	//
	// Summary:
	//     Generic info message
	info = "info",
	//
	// Summary:
	//     Warning message
	warning = "warning",
	//
	// Summary:
	//     Error message
	error = "error"
  }
  
  export interface responseMessage {
	//
	// Summary:
	//     Property originating the message
	propertyName: string;
	//
	// Summary:
	//     Code of the message
	code: string;
	//
	// Summary:
	//     Content of the message
	text: string;
	//
	// Summary:
	//     Type of the message
	type: MessageType;
  }
  