export class TipiValoreSchedaParametri {
	constructor() {
		this.itemID = 0;
		this.overIns_TipoSchedaParametri_TypeId = null;
		this.valore = null;
		this.descrizione = null;
	}
	itemID: number;
	overIns_TipoSchedaParametri_TypeId: number;
	valore: number | null;
	descrizione: string | null;
}