"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridColumnMenuFilterCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_dropdowns_1 = require("@progress/kendo-vue-dropdowns");

var kendo_vue_inputs_1 = require("@progress/kendo-vue-inputs");

var kendo_vue_dateinputs_1 = require("@progress/kendo-vue-dateinputs");

var filterCommon_1 = require("../filterCommon");
/**
 * Represents the default `GridColumnMenuFilterCell` component.
 */


var GridColumnMenuFilterCell = {
  props: {
    field: String,
    filterType: String,
    value: [Object, String, Number, Date, Boolean],
    operator: String,
    operators: Array
  },
  methods: {
    handleFocus: function handleFocus(e) {
      this.$emit('filtercellfocus', e);
    },
    triggerChange: function triggerChange(filter) {
      this.$emit('change', filter);
    },
    inputChange: function inputChange(value, e) {
      var filter = filterCommon_1.cellInputChange(value, e, this.$props);
      this.triggerChange(filter);
    },
    operatorChange: function operatorChange(operatorValue, e) {
      var filter = filterCommon_1.cellOperatorChange(operatorValue.value.operator, e, this.$props.value);
      this.triggerChange(filter);
    },
    boolDropdownChange: function boolDropdownChange(value, e) {
      var filter = filterCommon_1.cellBoolDropdownChange(value.value.operator, e);
      this.triggerChange(filter);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var ddlValue = this.$props.operators.find(function (item) {
      return item.operator === _this.$props.operator;
    }) || null;

    var filterComponent = function filterComponent(filterType, value, operators) {
      var _this = this;

      switch (filterType) {
        case 'numeric':
          return (// @ts-ignore
            h(kendo_vue_inputs_1.NumericTextBox, {
              value: value,
              attrs: this.v3 ? undefined : {
                value: value
              },
              onChange: function onChange(e) {
                _this.inputChange(e.value, e.event);
              },
              on: this.v3 ? undefined : {
                "change": function onChange(e) {
                  _this.inputChange(e.value, e.event);
                }
              }
            })
          );

        case 'date':
          return (// @ts-ignore
            h(kendo_vue_dateinputs_1.DatePicker, {
              value: value,
              attrs: this.v3 ? undefined : {
                value: value
              },
              onFocus: this.handleFocus,
              on: this.v3 ? undefined : {
                "focus": this.handleFocus,
                "change": function change(e) {
                  _this.inputChange(e.value, e.event);
                }
              },
              onChange: function change(e) {
                _this.inputChange(e.value, e.event);
              }
            })
          );

        case 'boolean':
          var noFilterSet_1 = function noFilterSet_1(filter) {
            return filter === null || filter === undefined;
          };

          return (// @ts-ignore
            h(kendo_vue_dropdowns_1.DropDownList, {
              onChange: this.boolDropdownChange,
              on: this.v3 ? undefined : {
                "change": this.boolDropdownChange
              },
              value: operators.find(function (item) {
                return item.operator === (noFilterSet_1(value) ? '' : value);
              }),
              attrs: this.v3 ? undefined : {
                value: operators.find(function (item) {
                  return item.operator === (noFilterSet_1(value) ? '' : value);
                }),
                "data-items": operators,
                textField: "text"
              },
              "data-items": operators,
              textField: "text"
            })
          );

        default:
          return h("input", {
            "class": "k-textbox",
            value: this.v3 ? value || '' : null,
            domProps: this.v3 ? undefined : {
              "value": value || ''
            },
            onInput: function onInput(e) {
              _this.inputChange(e.target.value, e);
            },
            on: this.v3 ? undefined : {
              "input": function onInput(e) {
                _this.inputChange(e.target.value, e);
              }
            }
          });
      }
    };

    return h("div", [this.$props.filterType !== 'boolean' && // @ts-ignore
    h(kendo_vue_dropdowns_1.DropDownList, {
      onChange: this.operatorChange,
      on: this.v3 ? undefined : {
        "change": this.operatorChange
      },
      value: ddlValue,
      attrs: this.v3 ? undefined : {
        value: ddlValue,
        "data-items": this.$props.operators,
        textField: "text"
      },
      "data-items": this.$props.operators,
      textField: "text"
    }), filterComponent.call(this, this.$props.filterType, this.$props.value, this.$props.operators)]);
  }
};
exports.GridColumnMenuFilterCell = GridColumnMenuFilterCell;