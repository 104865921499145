import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import { BIconShieldLockFill } from "bootstrap-vue";
import { NazionalitaItem } from "@/models/amministrazione/nazionalita/nazionalitaItem";

const _ENDPONT_ASSOCIATI = api.endpoint.AMMINISTRAZIONE.GRUPPI_NAZIONALITA_ASSOCIATI;
const _ENDPONT_DA_ASSOCIARE = api.endpoint.AMMINISTRAZIONE.GRUPPI_NAZIONALITA_DA_ASSOCIARE;
@Component({})
export default class AssociazioneStati extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.NAZIONALITA];
	}
	@Prop({ default: () => null }) relationItemId: number;
	@Prop({ default: () => false }) associa: boolean;
	
	@Prop({ default: () => false }) escludi: boolean;

	editItem: NazionalitaItem = new NazionalitaItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	showResultModal: boolean = false;

	fornitoriAggiornati: TextValueItem[] = [];
	polizzeAggiornate: TextValueItem[] = [];
	gruppoStatoChanged: string = "";

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		var nationTitle = !this.associa && this.escludi ? "generico.nazioneEscluse" : "generico.nazione";

		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "nazionalita", title: this.$i18n.t("generico.nazionalita").toString(),  headerCell: "myHeaderCellTemplate", width: this.setColPercent(30)},
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(),  headerCell: "myHeaderCellTemplate"},
			
			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	sort: any = [{ field: "nazionalita", dir: "asc" }];

	created() {
		var self = this;
		this.take = 20;
		this.pageable.pageSize = 20;
		this.pageSize = 20;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		if (this.associa) {
			this.getGridData(`${_ENDPONT_DA_ASSOCIARE}/${this.relationItemId}`);
		} else {
			this.getGridData(`${_ENDPONT_ASSOCIATI}/${this.relationItemId}`);
		}
	}

	associaNazionalita() {
		var self = this;
		if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
			let stati = this.gridData.data
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.itemID;
				});
			if (stati.length) {
				let data = {
					associa: this.associa,
					statiIds: stati
				};
				debugger
				api.associaNazionalita(this.relationItemId, data)
					.then(res => {
						// self.$bvToast.toast(self.$i18n.t("generico.cmdeseguito").toString(), {
						// 	variant: "success",
						// 	title: self.$i18n.t("generico.azioneeseguita").toString(),
						// 	solid: true
						// });						
						if (res.data && ((res.data.polizzeModificate && res.data.polizzeModificate.length) || (res.data.fornitoriModificati && res.data.fornitoriModificati.length))) {
							self.fornitoriAggiornati = res.data.fornitoriModificati;
							self.polizzeAggiornate = res.data.polizzeModificate;
							self.gruppoNazionalitaChanged = res.data.gruppoNazionalitaModificato;

							self.showResultModal = true;
						}
						else {
							self.$emit("reload-associazione-nazionalita");
						}
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.noElementsSelected").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	disassociaNazionalita() {
		var self = this;
		if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
			let stati = this.gridData.data
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.itemID;
				});
			if (stati.length) {
				let data = {
					associa: this.associa,
					statiIds: stati
				};
				api.associaNazionalita(this.relationItemId, data)
					.then(res => {
						// self.$bvToast.toast(self.$i18n.t("generico.cmdeseguito").toString(), {
						// 	variant: "success",
						// 	title: self.$i18n.t("generico.azioneeseguita").toString(),
						// 	solid: true
						// });
						if (res.data && ((res.data.polizzeModificate && res.data.polizzeModificate.length) || (res.data.fornitoriModificati && res.data.fornitoriModificati.length))) {
							self.fornitoriAggiornati = res.data.fornitoriModificati;
							self.polizzeAggiornate = res.data.polizzeModificate;
							self.gruppoNazionalitaChanged = res.data.gruppoNazionalitaModificato;

							self.showResultModal = true;
						}
						else {
							self.$emit("reload-associazione-nazionalita");
						}
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.noElementsSelected").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	hideResultModal() {
		this.showResultModal = false;
		this.$emit("reload-associazione-nazionalita");
	}

	copyResultOnClipboard() {
		try {
			var tempInput = document.createElement("input") as any;
			tempInput.style = "position: absolute; left: -1000px; top: -1000px";
			tempInput.value = this.textForClipBoard;
			document.body.appendChild(tempInput);
			tempInput.select();
			document.execCommand("copy");
			document.body.removeChild(tempInput);

			this.$bvToast.toast(this.$i18n.t("generico.copiaEseguita").toString(), {
				variant: "success",
				title: this.$i18n.t("generico.cmdeseguito").toString(),
				solid: true
			});
		} catch (ex) {
			this.$bvToast.toast(this.$i18n.t("msg.erroreGenerico").toString(), {
				variant: "error",
				title: this.$i18n.t("generico.error").toString(),
				solid: true
			});
		}
	}

	get textForClipBoard(): string {
		var textForClipBoard = "";
		if (this.fornitoriAggiornati.length) {
			textForClipBoard += `${this.$i18n.t("messaggio.fornitoriAggiornati").toString()} ${this.fornitoriAggiornati
				.map(function(x) {
					return x.value + " - " + x.text;
				})
				.join(", ")}` + "\r\n";
		}

		if (this.polizzeAggiornate.length) {
			textForClipBoard += `${this.$i18n.t("messaggio.polizzeAggiornate").toString()} ${this.polizzeAggiornate
				.map(function(x) {
					return x.value + " - " + x.text;
				})
				.join(", ")}`;
		}

		return textForClipBoard;
	}
}
