import Vue from "vue";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router/router";
import appConfig from "./config/appConfig";
// import "./filters";
//import Transitions from 'vue2-transitions'
import VuePageTransition from "vue-page-transition";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import main_registrations from "./main_registrations";

// import api from "./services/api";

import '@progress/kendo-ui';
// import VueI18n from 'vue-i18n'

// Vue.use(VueI18n)
import { i18n } from './i18n/i18n';


import "./assets/css/app-css-registration";
import moment from "moment";

// Vue.use(VueI18n)

Date.prototype.toJSON = function () { return moment(this).format(); }

Vue.config.productionTip = false;

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Vue.use(Transitions)
Vue.use(VuePageTransition);
Vue.use(appConfig);

// i18n.locale = currentCulture;
// kendo.culture(currentCulture);
kendo.culture().calendar.firstDay = 1;

import '@progress/kendo-ui/js/cultures/kendo.culture.it-IT.js';
kendo.culture("it-IT");

moment.locale("it");

main_registrations.init();
// router.beforeEach((to, from, next) => {
// 	const isAuthenticated = store.getters.getIsAuthenticated;
// 	const allowanonymous = to.meta.allowanonymous;
// 	if (allowanonymous || isAuthenticated) {
// 		next();
// 	} else {
// 		const loginpath = to.fullPath;
// 		next({ name: "login", query: { returnUrl: loginpath } });
// 	}
// });

Vue.filter("dateToString", function(value) {
	if (!value) return "";
	return moment(value, [moment.ISO_8601, "DD/MM/YYYY"]).format("DD/MM/YYYY");
});

Vue.filter("datetimeToString", function(value) {
	if (!value) return "";
	return moment(value, [moment.ISO_8601, "DD/MM/YYYY"]).format("DD/MM/YYYY HH:mm");
});

Vue.filter("dateToMonthString", function (value) {
	if (!value) return "";
	return moment(value, [moment.ISO_8601, "DD/MM/YYYY"]).format("DD MMMM YYYY");
});

Vue.filter("timeStringToStringHHmm", function(value) {
	if (!value) return "";
	var tt = value.split(":");
	if (tt.length < 1) return value;
	return `${tt[0]}:${tt[1]}`;
});

Vue.filter("numberToCurrency", function (value, decimalPlaces = 2) {
	return kendo.toString(parseFloat(value || 0), `c${decimalPlaces}`);
});
Vue.filter("numberToInteger", function (value) {
	return kendo.toString(parseFloat(value || 0), "##,#");
});
Vue.filter("numberToDecimal", function (value, decimalPlaces = 2) {
	return kendo.toString(parseFloat(value || 0), `n${decimalPlaces}`);
});
Vue.filter("numberToPercentage", function (value, decimalPlaces = 0) {
	return kendo.toString(parseFloat(value || 0), `p${decimalPlaces}`);
});

export const bus = new Vue();

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount("#app");