import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Prop } from "vue-property-decorator";
import { GruppiStatoPerFornitore } from "../../../models/anafrafica/gruppiStatiPerFornitore";

const _ENDPONT = api.endpoint.FORNITORI_GRUPPISTATOFORNITORE;
@Component
export default class GruppiStato extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Anagrafica.FORNITORI]; }
	@Prop({ default: () => null }) fornitoreID: number;
	@Prop({ default: () => null }) prodottoID: number;
	@Prop({ default: () => false }) readonly: boolean;

	gruppiStati: TextValueItem[] = [];

	editItem: GruppiStatoPerFornitore = new GruppiStatoPerFornitore();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";

	keyIndex:number = 0;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },

			//{ field: "fornitoreID", title: "FornitoreID", hidden: true, values: this.fornitoreID, editHide: true },
			{
				field: "gruppoStatiID",
				title: this.prodottoID ? this.$i18n.t("generico.gruppoStatoFornitore").toString() : this.$i18n.t("generico.gruppostato").toString(),
				sortBy: "gruppoStato",
				headerCell: "myHeaderCellTemplate",
				editSourceValue: "value",
				values: this.gruppiStati,
				filterable: true,
				itemsForFilter: this.gruppiStati,
				filterType: "select",
				editType: "select",
				editSource: this.gruppiStati,
				hidden:true
			},
			{
				field: "gruppoStatoA3",
				title: "AlphaCode3",
				editHide: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
			},
			{ field: "gruppoStato", title: this.prodottoID ? this.$i18n.t("generico.gruppoStatoFornitore").toString() : this.$i18n.t("generico.gruppostato").toString(), width: this.setColPercent(20), editHide: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "codiceRiepilogo", title: this.$i18n.t("generico.codiceRiepilogo").toString(), headerCell: "myHeaderCellTemplate", width: this.setColPercent(20), filterable: true },
			{ field: "descrizioneRiepilogo", title: this.$i18n.t("generico.descrizioneRiepilogo").toString(), headerCell: "myHeaderCellTemplate", width: this.setColPercent(25), filterable: true },
			{
				field: "comprendeTuttiGliStati",
				title: this.$i18n.t("generico.tuttiGliStati").toString(),
				headerCell: "myHeaderCellTemplate",
				export: true,
				type: "boolean",
				filterType: "boolean",
				width: 200,
				disabled: true,
				editHide:true
			},
			
			{ field: "_actions", title: " ", hidden: this.prodottoID ? true: false, width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.GRUPPISTATI).then(res => self.gruppiStati = res)])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		var self = this;
		self.getGridData(`${_ENDPONT}/${this.fornitoreID}`);
	}

	onDelete(item: GruppiStatoPerFornitore) {
		var self = this;
		var itemToDelete = item.gruppoStatoA3 + " - " + item.gruppoStato + " - " + item.descrizioneRiepilogo;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${itemToDelete}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.gruppostato")]).toString();
		self.deleteGridRow(_ENDPONT.replace("/{id}", ""), item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new GruppiStatoPerFornitore();
		newItem.fornitoreID = this.fornitoreID;
		if (this.gruppiStati && this.gruppiStati.length > 0) {
			newItem.gruppoStatiID = this.gruppiStati[0].value as number;
		}
		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new GruppiStatoPerFornitore(), item);
		self.editSubTitle = item.gruppoStato;
		// self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.gruppostato") }).toString();
		self.setGridRow<GruppiStatoPerFornitore>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false
		});
	}

	
	onReloadAssociaStati() {
		this.keyIndex++;
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
