"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupPanel = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var GroupingIndicator_1 = require("../drag/GroupingIndicator");

var messages_1 = require("./../messages");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");
/**
 * Represents the default `GroupPanel` component.
 */


var GroupPanel = {
  props: {
    group: Array,
    resolveTitle: Function
  },
  inject: {
    kendoLocalizationService: {
      default: null
    }
  },
  methods: {
    pressHandler: function pressHandler(event, element) {
      this.$emit('pressHandler', event, element);
    },
    dragHandler: function dragHandler(event, element) {
      this.$emit('dragHandler', event, element);
    },
    releaseHandler: function releaseHandler(event) {
      this.$emit('releaseHandler', event);
    },
    onGroupRemove: function onGroupRemove(event, index) {
      var newGroups = this.$props.group.slice();
      newGroups.splice(index, 1);
      this.$emit('groupChange', newGroups, event);
    },
    onGroupSortChange: function onGroupSortChange(event, index, groupDesc, dir) {
      var group = Object.assign({}, groupDesc, {
        dir: dir
      });
      var newGroups = this.$props.group.slice();
      newGroups.splice(index, 1, group);
      this.$emit('groupChange', newGroups, event);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoLocalizationService = inject('kendoLocalizationService', {});
    return {
      v3: v3,
      kendoLocalizationService: kendoLocalizationService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var groupsProp = this.$props.group || [];
    var groups = groupsProp.map(function (groupDesc, index) {
      var _this = this;

      return (// @ts-ignore
        h(GroupingIndicator_1.GroupingIndicator, {
          key: index,
          dir: groupDesc.dir || 'asc',
          attrs: this.v3 ? undefined : {
            dir: groupDesc.dir || 'asc',
            title: this.$props.resolveTitle(groupDesc.field)
          },
          title: this.$props.resolveTitle(groupDesc.field),
          onRemove: function onRemove(event) {
            return _this.onGroupRemove(event, index);
          },
          on: this.v3 ? undefined : {
            "remove": function onRemove(event) {
              return _this.onGroupRemove(event, index);
            },
            "sortChange": function sortChange(event, dir) {
              _this.onGroupSortChange(event, index, groupDesc, dir);
            },
            "press": this.pressHandler,
            "drag": this.dragHandler,
            "release": this.releaseHandler
          },
          onSortChange: function sortChange(event, dir) {
            _this.onGroupSortChange(event, index, groupDesc, dir);
          },
          onPress: this.pressHandler,
          onDrag: this.dragHandler,
          onRelease: this.releaseHandler
        })
      );
    }, this);
    return h("div", {
      "class": "k-grouping-header k-grouping-header-flex"
    }, [groups, h("div", {
      "class": "k-indicator-container"
    }, [!groups.length && kendo_vue_intl_1.provideLocalizationService(this).toLanguageString(messages_1.groupPanelEmpty, messages_1.messages[messages_1.groupPanelEmpty])])]);
  }
};
exports.GroupPanel = GroupPanel;