import { render, staticRenderFns } from "./commissioniProdottiDistributori.vue?vue&type=template&id=abe021cc&"
import script from "./commissioniProdottiDistributori.vue.ts?vue&type=script&lang=ts&"
export * from "./commissioniProdottiDistributori.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports