export class EtaItem {
	constructor() {
		this.itemID = null;
		this.descrizione = null;
		this.etaMinima = null;
		this.etaMassima = null;
	}
	itemID: number | null;
	descrizione: string;
	etaMinima: number | null;
	etaMassima: number | null;
}
