"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridSelectionCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var utils_1 = require("./../utils");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridSelectionCell` component.
 */


var GridSelectionCell = {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    colSpan: Number,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    render: [String, Function, Object],
    editor: String
  },
  data: function data() {
    return {
      inputId: kendo_vue_common_1.guid()
    };
  },
  methods: {
    triggerKeydown: function triggerKeydown(e) {
      this.$emit('cellkeydown', {
        event: e,
        dataItem: this.$props.dataItem,
        field: this.$props.field
      });
    },
    handleOnChange: function handleOnChange(event) {
      this.$emit('selectionchange', {
        event: event,
        dataItem: this.$props.dataItem
      });
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var data = utils_1.getNestedValue(this.$props.field, this.$props.dataItem);
    var renderTemplate = this.$props.render;
    var defaultRendering = this.$props.rowType !== 'groupHeader' ? h("td", {
      onKeydown: this.triggerKeydown,
      on: this.v3 ? undefined : {
        "keydown": this.triggerKeydown
      },
      colSpan: this.$props.colSpan,
      attrs: this.v3 ? undefined : {
        colSpan: this.$props.colSpan
      },
      "class": this.$props.className
    }, [h("input", {
      checked: this.v3 ? data : null,
      domProps: this.v3 ? undefined : {
        "checked": data
      },
      id: this.inputId,
      attrs: this.v3 ? undefined : {
        id: this.inputId,
        type: "checkbox"
      },
      type: "checkbox",
      "class": "k-checkbox",
      onChange: this.handleOnChange,
      on: this.v3 ? undefined : {
        "change": this.handleOnChange
      }
    }), h("label", {
      "class": "k-checkbox-label",
      "for": this.inputId,
      attrs: this.v3 ? undefined : {
        "for": this.inputId
      }
    })]) : null;
    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: renderTemplate,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        keydown: this.triggerKeydown,
        change: this.handleOnChange
      }
    });
  }
};
exports.GridSelectionCell = GridSelectionCell;