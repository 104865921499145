import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { NazionalitaItem } from "@/models/amministrazione/nazionalita/nazionalitaItem";

const _ENDPONT_ASSOCIATI = api.endpoint.FORNITORI_NAZIONALITA_ASSOCIATE;
const _ENDPONT_DA_ASSOCIARE = api.endpoint.FORNITORI_NAZIONALITA_DA_ASSOCIARE;
@Component({})
export default class AssociazioneNazionalitaFornitore extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.NAZIONALITA];
	}
	@Prop({ default: () => null }) fornitoreItemId: number;
	@Prop({ default: () => null }) gruppoNazionalitaItemId: number;
	@Prop({ default: () => false }) associa: boolean;
	@Prop({ default: () => false }) readonly: boolean;
	@Prop({ default: () => false }) escludi: boolean;

	editItem: NazionalitaItem = new NazionalitaItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		var nationTitle = !this.associa && this.escludi ? "generico.nazioneEscluse" : "generico.nazione";

		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "nazionalita", title: this.$i18n.t("generico.nazionalita").toString(), headerCell: "myHeaderCellTemplate", width: 200 },
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(), groupable: true, headerCell: "myHeaderCellTemplate", export: true },
			
			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean", hidden: this.readonly }
		];
	}

	sort: any = [{ field: "nazionalita", dir: "asc" }];

	created() {
		var self = this;
		this.take = 20;
		this.pageable.pageSize = 20;
		this.pageSize = 20;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		if (this.associa) {
			this.getGridData(`${_ENDPONT_DA_ASSOCIARE}/${this.fornitoreItemId}/${this.gruppoNazionalitaItemId}`);
		} else {
			this.getGridData(`${_ENDPONT_ASSOCIATI}/${this.fornitoreItemId}/${this.gruppoNazionalitaItemId}`);
		}
	}

	associaNazionalita() {
		var self = this;
		if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
			let stati = this.gridData.data
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.itemID;
				});
			if (stati.length) {
				let data = {
					associa: this.associa,
					statiIds: stati
				};
				api.associaFornitoriGruppoNazionalita_Nazionalita(this.fornitoreItemId, this.gruppoNazionalitaItemId, data)
					.then(res => {
						self.$bvToast.toast(self.$i18n.t("generico.cmdeseguito").toString(), {
							variant: "success",
							title: self.$i18n.t("generico.azioneeseguita").toString(),
							solid: true
						});

						self.$emit("reload-associazione-nazionalita");
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				self.$bvToast.toast(self.$i18n.t("generico.noElementsSelected").toString(), {
					variant: "warning",
					title: self.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	disassociaNazionalita() {
		var self = this;
		if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
			let stati = this.gridData.data
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.itemID;
				});
			if (stati.length) {
				let data = {
					associa: this.associa,
					statiIds: stati
				};
				api.associaFornitoriGruppoNazionalita_Nazionalita(this.fornitoreItemId, this.gruppoNazionalitaItemId, data)
					.then(res => {
						self.$bvToast.toast(self.$i18n.t("generico.cmdeseguito").toString(), {
							variant: "success",
							title: self.$i18n.t("generico.azioneeseguita").toString(),
							solid: true
						});
						self.$emit("reload-associazione-nazionalita");
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.noElementsSelected").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	resetNazionalitaDaDefinizione() {
		var self = this;
		api.resetFornitoriNazionalitaByGruppoNazionalita(this.fornitoreItemId, this.gruppoNazionalitaItemId)
			.then(res => {
				self.$bvToast.toast(self.$i18n.t("generico.cmdeseguito").toString(), {
					variant: "success",
					title: self.$i18n.t("generico.azioneeseguita").toString(),
					solid: true
				});
				self.$emit("reload-associazione-nazionalita");
			})
			.catch(err => {
				console.log(err);
			});
	}
}
