import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { InformazioniGenericheItem } from "@/models/amministrazione/informazioniGeneriche/informazioniGenericheItem";
import { process } from "@progress/kendo-data-query";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.INFORMAZIONI_GNERICHE;
@Component({})
export default class InformazioniGeneriche extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Amministrazione.STATI]; }
	editItem: InformazioniGenericheItem = new InformazioniGenericheItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;


	get columns(): Array<any> {
		var cols = [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "codice", title: this.$i18n.t("generico.codice").toString(),  headerCell: "myHeaderCellTemplate", export: true, width: 250, editDisabled: true},
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(), headerCell: "myHeaderCellTemplate", export: true, width: 350 },
			
			{ field: "testo_it-IT", title: this.$i18n.t("generico.testo_it-IT").toString(), headerCell: "myHeaderCellTemplate", cell: "myTextAreaTemplate", export: true, editType: "textarea", sortable: false },
			{ field: "testo_en-US", title: this.$i18n.t("generico.testo_en-US").toString(), headerCell: "myHeaderCellTemplate", cell: "myTextAreaTemplate", export: true, editType: "textarea", sortable: false },
		
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];

		return cols;
	}

	sort: any = [{ field: "codice", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		self.mapDataCallback = function (items: any[]) {
			self.itemsGrid = self.itemsGrid.map( (x: any) => { 
				var newItem = {
					itemID: x.itemID,
					codice: x.codice,
					descrizione: x.descrizione,
					testiInformazioni: x.testiInformazioni,
					codiceInformazione: x.codiceInformazione
				};

				for (var i = 0; i < x.testiInformazioni.length; i++ ) {
					var infoText = x.testiInformazioni[i];
					newItem[`testo_${infoText.key}`] = infoText.value;
				}

				return newItem;
			});

			self.gridData = process(self.itemsGrid, { take: self.take, skip: 0, group: self.group });
		}

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.informazioni")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	// addNewRow() {
	// 	let newItem = new StatiItem();

	// 	this.onEdit(newItem);
	// }

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new InformazioniGenericheItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.stati") }).toString();

		//normalize editItem;
		for (var i = 0; i < this.editItem.testiInformazioni.length; i++) {
			var ei = this.editItem.testiInformazioni[i];

			ei.value = this.editItem[`testo_${ei.key}`];
		}

		self.setGridRow<InformazioniGenericheItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	getNestedValue(fieldName, dataItem) {
		const path = fieldName.split('.');
		let data = dataItem;
		path.forEach((p) => {
			data = data ? data[p] : undefined;
		});

		return data;
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	
}
