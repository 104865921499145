"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DragClue = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
/**
 * Represents the default `DragClue` component.
 */

var DragClue = {
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      innerText: '',
      status: 'k-i-cancel'
    };
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    return this.visible && h("div", {
      "class": "k-header k-drag-clue",
      style: {
        display: 'block',
        position: 'absolute',
        zIndex: 20000,
        //padding: '8px 12px',
        top: this.top + 'px',
        left: this.left + 'px'
      }
    }, [h("span", {
      "class": 'k-icon k-drag-status ' + this.status + ' k-icon-with-modifier'
    }, [h("span", {
      "class": "k-icon k-icon-modifier"
    })]), this.innerText]);
  }
};
exports.DragClue = DragClue;