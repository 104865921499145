import appConfigDefault from "../assets/app_customization/default/config/appConfig";
const appConfigCustomModule = require(`../assets/app_customization/${process.env.VUE_APP_CUSTOMIZATION_FOLDER}/config/appConfig`);
import Vue from "vue";
import { validatePackage } from "@progress/kendo-licensing";

// 2. Specify a file with the types you want to augment
//    Vue has the constructor type in types/vue.d.ts
declare module 'vue/types/vue' {
	// 3. Declare augmentation for Vue
	interface Vue {
		$appConfig: appConfig
	}
}

class appConfig {
	constructor() {
		// inizio - non adrebbero sovrascritte
		this.customizationFolder = process.env.VUE_APP_CUSTOMIZATION_FOLDER || 'default';
		this.societa = process.env.VUE_APP_CUSTOMIZATION_FOLDER || 'default';
		this.baseUrl = process.env.BASE_URL;
		this.baseApiUrl = process.env.VUE_APP_BASE_URL;
		this.carrelloUrl = process.env.VUE_APP_CARRELLO_URL;
		// fine - non andrebbero sovrascritte

		this.defaultCulture = "it-IT";
		this.report = new appConfigReport();
		this.footer = new appConfigFooter();
		this.loginPage = new appConfigLoginPage();
	}
	// inizio - non adrebbero sovrascritte
	public societa: string;
	public customizationFolder: string;
	public baseUrl: string;
	public baseApiUrl: string;
	public carrelloUrl: string;
	// fine - non andrebbero sovrascritte

	public defaultCulture: string;
	public report: appConfigReport;
	public footer: appConfigFooter;
	public loginPage: appConfigLoginPage;
}
class appConfigReport {
	constructor() {
		this.reportUrl = "";
	}
	public reportUrl: string;
}
class appConfigFooter {
	constructor() {

		this.htmlText = "develop mode";
		this.cssClass = "";
	}
	public htmlText: string;
	public cssClass: string;
}
class appConfigLoginPage {
	constructor() {

		this.htmlText = "Accedi al servizio";
		this.headerText = "OverIns";
		this.cssClass = "";
	}
	public htmlText: string;
	public headerText: string;
	public cssClass: string;
}

let appBaseConfig = new appConfig();
let appConfigCustom = appConfigCustomModule.default || {};
const AppConfigReport: appConfigReport = Object.assign(appBaseConfig.report || {}, { reportUrl: process.env.VUE_APP_REPORT_URL });

const AppConfigFooter: appConfigFooter = Object.assign(appBaseConfig.footer || {}, (appConfigDefault as any).footer || {}, appConfigCustom.footer || {});
const AppConfigLoginPage: appConfigLoginPage = Object.assign(appBaseConfig.loginPage || {}, (appConfigDefault as any).loginPage || {}, appConfigCustom.loginPage || {});
const AppConfig: appConfig = Object.assign(appBaseConfig, appConfigDefault, appConfigCustom.default || {});

AppConfig.report = AppConfigReport;
AppConfig.footer = AppConfigFooter;
AppConfig.loginPage = AppConfigLoginPage;
// export default AppConfig
export default {
	install(_Vue) {
		_Vue._appConfig = AppConfig;
		_Vue.prototype.$appConfig = AppConfig;
	}
}

