
export class column {
	constructor() {
		this.field = '';
		this.title = '';
		this.type = null;
		this.format = '';
		this.width = null;
		this.hidden = false;
		this.filterable = true;
		this.groupable = false;
		this.filtrable = false;
		this.cell = null;
		this.headerCell = null;
		this.editType = "string"; //forse enum
		this.editSource = [];
		this.editSourceText = "label";
		this.editSourceValue = "value";
		this.editHide = false;
		this.editDisabled = false;
		this.editOrder = null;
		this.editSelectionCallBack = Function;
		this.pathFallback = null;
		this.customButtons = [];
		this.customButtons = [];
		this.label = null;
		this.downloadFileUrlType = null;
		this.hint = '';
		this.multiselectSplitChar = '';
		this.selectValueType = '';
		this.validation = null;
		this.acceptFile = ".pdf";
	}
	field: string | null;
	title: string | null;
	type: string | null;
	format: string | null;
	width: number | string;
	hidden: boolean | null;
	filterable: boolean | null;
	groupable: boolean | null;
	filtrable: boolean | null;
	cell: string | null;
	headerCell: string | null;
	editType: string | null; //forse enum
	editSource: Array<any> | null;
	editSourceText: string | null;
	editSourceValue: string | null;
	editHide: boolean | null;
	editDisabled: boolean | null;
	editOrder: number | null;
	editField: string | null;
	editSelectionCallBack: Function | null;
	editChangeCallback: Function | null;
	pathFallback: string | null;
	label: string | null;
	downloadFileUrlType: string | null;
	customButtons: customButtons[] | null;
	hint: string | null;
	multiselectSplitChar: string | null;
	selectValueType: string | null;
	validation: Object | null;
	acceptFile: string | null;
}

export class customEventItem {
	constructor() {
		this.command = null;
		this.item = null;
	}
	command: string;
	item: any;
}
export class customButtons {
	constructor() {
		this.command = null;
		this.label = null;
		this.icon = null;
		this.variant = null;
		this.visible = true;
		this.disabled = false;
		this.tooltip = null;
	}
	command: string;
	label: string;
	icon: string;
	variant: string;
	tooltip: string;
	visible: boolean | Function;
	disabled: boolean | Function;
}
