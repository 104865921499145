import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { AgenziaAssociazioniItem } from "@/models/amministrazione/agenzie-associazioni/agenzieAssociazioni";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.AGENZIE_ASSOCIAZIONI;
@Component({})
export default class GridAgenzieAssociazioni extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.AGENZIE];
	}
	distributori: Array<TextValueItem> = [];
	editItem: AgenziaAssociazioniItem = new AgenziaAssociazioniItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 130, filterable: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "network",
				title: this.$i18n.t("generico.network").toString(),
				width:  this.setColPercent(15),
				headerCell: "myHeaderCellTemplate"
			},

			{
				field: "distributorePadreID",
				title: "Distributore Padre",
				headerCell: "myHeaderCellTemplate",
				width:  this.setColPercent(15),
				values: this.distributori,
				sortBy: "distributore",
				itemsForFilter: this.distributori,
				filterType: "select",
				editType: "select",
				editSource: this.distributori,
			},


			{
				field: "pathLogo",
				title: "Logo",
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\AgenzieAssociazioni",
				editField: "pathLogo_file",
				//width: this.setColPercent(20)
			},

			// {
			// 	field: "tipoDistributore",
			// 	title: "Tipo Distributore",
			// 	headerCell: "myHeaderCellTemplate",
			// 	width:  this.setColPercent(10),
			// 	filterType: "numeric-int",
			// 	editType: "numeric-int",
			// },
		

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		var p = [
			api.getDatiDropDown(api.endpoint.DROPDOWN.ALL_DISTRIBUTORI).then(res => (self.distributori = res))
		];

		Promise.all(p)
		.then(responses => {
			self.refreshGrid();
			self.loading = false;
		})
		.catch(err => {
			console.log(err);
		});
	}

	refreshGrid(){
		var self = this;
		Promise.all([])
		.then(responses => {
			self.getData();
		})
		.catch(err => {
			console.log(err);
		});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.network}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.associazione")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new AgenziaAssociazioniItem();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new AgenziaAssociazioniItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.associazione") }).toString();
		
		var formData = new FormData();
		var ojb = Object.assign({}, self.editItem);
		formData.append("pathLogo", self.editItem.pathLogo_file);
		delete ojb.pathLogo_file;
		let json = JSON.stringify(ojb);
		formData.append("data", json);

		var methodType = self.editItem.itemID > 0 ? 'put' : 'post';

		api.postFormData(_ENDPONT, formData, methodType).then((res) => {
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});

			self.showEditModal = false;
			self.getData();
		});
	}



	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
