export class CommissioniPolizeItem {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.beneficiarioID = null;
		this.tipoValoreID = null;
		this.valore = null;
		this.startDate = null;
		this.endDate = null;
		this.parametroID = null;
	}
	itemID: number;
	prodottoID: number;
	beneficiarioID: number | null;
	tipoValoreID: number | null;
	valore: number | null;
	startDate: string | null;
	endDate: string | null;
	parametroID: number | null;
}