import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import api from "@/services/api";
import { Component, Prop } from "vue-property-decorator";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.PREVIEW_TEMPLATE_PRATICHE_MULTIPLE;

@Component({})
export default class PreviewPratica extends gridComponentBase {
	@Prop({ default: () => null }) itemId: number;

	mapping: any[] = [];

	get columns(): Array<any> {
		return [
			{
				field: "pacchettoID",
				title: this.$i18n.t("generico.idPacchetto").toString() + this.getColonna("ColonnaPacchettoID"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "codicePromo",
				title: this.$i18n.t("generico.codicePromo").toString() + this.getColonna("ColonnaCodicePromo"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "riferimentoPratica",
				title: this.$i18n.t("generico.riferimento").toString() + this.getColonna("ColonnaRiferimentoPratica"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "destinazioneID",
				title: this.$i18n.t("generico.destinazione").toString() + this.getColonna("ColonnaDestinazioneID"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "nazionalitaID",
				title: this.$i18n.t("generico.nazionalita").toString() + this.getColonna("ColonnaNazionalitaID"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "inizioViaggio",
				title: this.$i18n.t("generico.inizioViaggio").toString() + this.getColonna("ColonnaInizioViaggio"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "fineViaggio",
				title: this.$i18n.t("generico.fineViaggio").toString() + this.getColonna("ColonnaFineViaggio"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "numeroPartecipanti",
				title: this.$i18n.t("generico.numeroPax").toString() + this.getColonna("ColonnaNumeroPartecipanti"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "importoViaggio",
				title: this.$i18n.t("generico.importo").toString() + this.getColonna("ColonnaImportoViaggio"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "nome",
				title: this.$i18n.t("generico.nome").toString() + this.getColonna("ColonnaNome") + this.getColonna("ColonnaPartecipanti"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "cognome",
				title: this.$i18n.t("generico.cognome").toString() + this.getColonna("ColonnaCognome") + this.getColonna("ColonnaPartecipanti"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "dataNascita",
				title: this.$i18n.t("generico.dataDiNascita").toString() + this.getColonna("ColonnaDataNascita") + this.getColonna("ColonnaPartecipanti"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "codiceFiscale",
				title: this.$i18n.t("generico.codiceFiscale").toString() + this.getColonna("ColonnaCodiceFiscale") + this.getColonna("ColonnaPartecipanti"),
				filterable: false,
				sortable: false,
				hidden: false,
				headerCell: "myHeaderCellTemplate"
			},
			{ field: "note", title: this.$i18n.t("generico.note").toString() + this.getColonna("ColonnaNote"), filterable: false, hidden: false, headerCell: "myHeaderCellTemplate" }

			// { field: "itemID", title: "ID", filterable: false, hidden: false, headerCell: "myHeaderCellTemplate", editHide: true, width: 50 },
			// { field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(),  headerCell: "myHeaderCellTemplate"},
			// { field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	getColonna(nomeColonna) {
		if (this.mapping && (this.mapping as any).Schema) {
			var ncol = (this.mapping as any).Schema.Colonne[nomeColonna];
			return ncol ? " (" + (this.mapping as any).Schema.Colonne[nomeColonna] + ")" : "";
		}

		return "";
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.itemId}`);
	}
}
