import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { FattoriPolizzeItem } from "@/models/prodotti/fattoriPolizzeItem";
import permessi from "@/config/permessi";
import { Text } from "@progress/kendo-drawing";

const _ENDPONT = api.endpoint.POLIZZE.FATTORI_POLIZZE;
@Component({})
export default class FattoriPolizze extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	editItem: FattoriPolizzeItem = new FattoriPolizzeItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	hint: string = "";
	valoreDisabled: boolean = false;
	tipoValore: string = "string";

	tipiFattori : TextValueItem[] = [{value: 1, text: "Parametro viaggio"}, {value: 2, text: "Valore viaggio"}, {value: 3, text: "Valore Fisso"}];


	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "itemID", filterable: false, headerCell: "myHeaderCellTemplate", hidden: true, editHide: true },
			{ field: "chiave", title: "Chiave", headerCell: "myHeaderCellTemplate" },
			{
				field: "tipo",
				title: "Tipo",
				width: 250,
				values: this.tipiFattori,
				filterType: "select",
				itemsForFilter: this.tipiFattori,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipiFattori,
				export: true,
				editSelectionCallBack: this.onChangeTipo
			},
			{ field: "valore", title: "Valore", headerCell: "myHeaderCellTemplate", width: 250, editDisabled: this.valoreDisabled },
			
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	onChangeTipo(val, obj) {
		this.valoreDisabled = val !== 3;
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		self.getData();
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.extraInfo")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new FattoriPolizzeItem();
		newItem.prodottoID = this.prodottoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new FattoriPolizzeItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		debugger;
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.fattore") }).toString();
		self.setGridRow<FattoriPolizzeItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
