
export class utenteEdit {
	constructor() {
		this.itemID = null;
		this.username = null;
		this.ruoliID = [];
		this.attivo = null;
		this.distributoreID = null;
		this.fornitoreID = null;
		this.commercialeID = null;
		this.nome = null;
		this.cognome = null;
		this.email = null;
		this.emailAzienda = null;
		this.telefono = null;
		this.fax = null;
		this.indirizzo = null;
		this.localita = null;
		this.codiceProvincia = null;
		this.cAP = null;
		this.sesso = null;
		this.commissioni = null;
		this.riceviNewsletter = null;
		this.note = null;
		this.usaCarrelloAvanzato = null;
	}
	itemID: number | null;
	username: string;
	ruoliID: number[];
	attivo: boolean | null;
	distributoreID: number | null;
	fornitoreID: number | null;
	commercialeID: number | null;
	nome: string;
	cognome: string;
	email: string;
	emailAzienda: string;
	telefono: string;
	fax: string;
	indirizzo: string;
	localita: string;
	codiceProvincia: string;
	cAP: string;
	sesso: string;
	commissioni: number | null;
	riceviNewsletter: boolean | null;
	note: string;
	usaCarrelloAvanzato: boolean | null;
}
