"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnMenu = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");

var kendo_vue_popup_1 = require("@progress/kendo-vue-popup");

var ColumnMenuContent_1 = require("./ColumnMenuContent");
/**
 * Represents the default `ColumnMenu` component.
 */


var ColumnMenu = {
  props: {
    column: Object,
    sortable: [Boolean, Object],
    sort: {
      type: Array
    },
    filter: Object,
    filterable: Boolean,
    render: [Boolean, String, Function, Object]
  },
  data: function data() {
    return {
      show: false,
      blurTimeout: undefined
    };
  },
  created: function created() {
    this._anchor = kendo_vue_common_1.guid();
  },
  methods: {
    blur: function blur() {
      var _this = this;

      clearTimeout(this.blurTimeout);
      this.blurTimeout = setTimeout(function () {
        _this.closeMenu();
      }, 200);
    },
    focus: function focus() {
      clearTimeout(this.blurTimeout);
    },
    handleFocus: function handleFocus(_) {
      clearTimeout(this.blurTimeout);
    },
    anchorClick: function anchorClick() {
      var that = this;
      this.show = !this.show;
      this.$nextTick(function () {
        that._content = that.$refs.content;

        if (that.show && that._content) {
          that._content.focus();
        }
      });
    },
    closeMenu: function closeMenu() {
      this.show = false;
    },
    sortChange: function sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e);
    },
    filterChange: function filterChange(newDescriptor, e) {
      this.$emit('filterchange', newDescriptor, e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this2 = this;

    var _this = this;

    var h = gh || createElement;
    var renderTemplate = this.$props.render;
    return h("div", {
      style: {
        display: 'inline'
      }
    }, [h("div", {
      style: {
        display: 'inline',
        position: 'absolute',
        cursor: 'pointer',
        right: '5px'
      },
      ref: this.v3 ? function (el) {
        _this.kendoAnchorRef = el;
      } : this._anchor,
      onClick: this.anchorClick,
      on: this.v3 ? undefined : {
        "click": this.anchorClick
      }
    }, [h("span", {
      "class": 'k-icon k-i-more-vertical'
    })]), // @ts-ignore function children
    h(kendo_vue_popup_1.Popup, {
      anchor: this._anchor,
      attrs: this.v3 ? undefined : {
        anchor: this._anchor,
        show: this.show
      },
      show: this.show
    }, this.v3 ? function () {
      return [h("div", {
        ref: 'content',
        "class": 'k-grid-columnmenu-popup',
        tabIndex: 0,
        attrs: _this2.v3 ? undefined : {
          tabIndex: 0
        },
        onFocusout: _this2.blur,
        on: _this2.v3 ? undefined : {
          "focusout": _this2.blur,
          "focusin": _this2.focus
        },
        onFocusin: _this2.focus,
        style: {
          outline: 'none'
        }
      }, [// @ts-ignore
      h(ColumnMenuContent_1.ColumnMenuContent, {
        column: _this2.$props.column,
        attrs: _this2.v3 ? undefined : {
          column: _this2.$props.column,
          sortable: _this2.$props.sortable,
          sort: _this2.$props.sort,
          filter: _this2.$props.filter,
          filterable: _this2.$props.filterable,
          render: renderTemplate
        },
        sortable: _this2.$props.sortable,
        sort: _this2.$props.sort,
        filter: _this2.$props.filter,
        filterable: _this2.$props.filterable,
        render: renderTemplate,
        onContentfocus: _this2.handleFocus,
        on: _this2.v3 ? undefined : {
          "contentfocus": _this2.handleFocus,
          "closemenu": _this2.closeMenu,
          "sortchange": _this2.sortChange,
          "filterchange": _this2.filterChange
        },
        onClosemenu: _this2.closeMenu,
        onSortchange: _this2.sortChange,
        onFilterchange: _this2.filterChange
      })])];
    } : [h("div", {
      ref: 'content',
      "class": 'k-grid-columnmenu-popup',
      tabIndex: 0,
      attrs: _this2.v3 ? undefined : {
        tabIndex: 0
      },
      onFocusout: _this2.blur,
      on: _this2.v3 ? undefined : {
        "focusout": _this2.blur,
        "focusin": _this2.focus
      },
      onFocusin: _this2.focus,
      style: {
        outline: 'none'
      }
    }, [h(ColumnMenuContent_1.ColumnMenuContent, {
      column: _this2.$props.column,
      attrs: _this2.v3 ? undefined : {
        column: _this2.$props.column,
        sortable: _this2.$props.sortable,
        sort: _this2.$props.sort,
        filter: _this2.$props.filter,
        filterable: _this2.$props.filterable,
        render: renderTemplate
      },
      sortable: _this2.$props.sortable,
      sort: _this2.$props.sort,
      filter: _this2.$props.filter,
      filterable: _this2.$props.filterable,
      render: renderTemplate,
      onContentfocus: _this2.handleFocus,
      on: _this2.v3 ? undefined : {
        "contentfocus": _this2.handleFocus,
        "closemenu": _this2.closeMenu,
        "sortchange": _this2.sortChange,
        "filterchange": _this2.filterChange
      },
      onClosemenu: _this2.closeMenu,
      onSortchange: _this2.sortChange,
      onFilterchange: _this2.filterChange
    })])])]);
  }
};
exports.ColumnMenu = ColumnMenu;