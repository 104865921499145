"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.messages = exports.sortDescending = exports.sortAscending = exports.filterTitle = exports.filterSubmitButton = exports.filterClearButton = exports.groupPanelEmpty = exports.pagerOf = exports.pagerPage = exports.pagerItemPerPage = exports.pagerLastPage = exports.pagerNextPage = exports.pagerPreviousPage = exports.pagerFirstPage = exports.pagerInfo = exports.noRecords = void 0;
/**
 * @hidden
 */
exports.noRecords = 'grid.noRecords';
/**
 * @hidden
 */
exports.pagerInfo = 'grid.pagerInfo';
/**
 * @hidden
 */
exports.pagerFirstPage = 'grid.pagerFirstPage';
/**
 * @hidden
 */
exports.pagerPreviousPage = 'grid.pagerPreviousPage';
/**
 * @hidden
 */
exports.pagerNextPage = 'grid.pagerNextPage';
/**
 * @hidden
 */
exports.pagerLastPage = 'grid.pagerLastPage';
/**
 * @hidden
 */
exports.pagerItemPerPage = 'grid.pagerItemsPerPage';
/**
 * @hidden
 */
exports.pagerPage = 'grid.pagerPage';
/**
 * @hidden
 */
exports.pagerOf = 'grid.pagerOf';
/**
 * @hidden
 */
exports.groupPanelEmpty = 'grid.groupPanelEmpty';
/**
 * @hidden
 */
exports.filterClearButton = 'grid.filterClearButton';
/**
 * @hidden
 */
exports.filterSubmitButton = 'grid.filterSubmitButton';
/**
 * @hidden
 */
exports.filterTitle = 'grid.filterTitle';
/**
 * @hidden
 */
exports.sortAscending = 'grid.sortAscending';
/**
 * @hidden
 */
exports.sortDescending = 'grid.sortDescending';
/**
 * @hidden
 */
exports.messages = (_a = {},
    _a[exports.noRecords] = 'Nessun dato presente',
    _a[exports.groupPanelEmpty] = 'Trascina una colonna qui per raggruppare',
    _a[exports.filterClearButton] = 'Clear',
    _a[exports.filterSubmitButton] = 'Filter',
    _a[exports.filterTitle] = 'Filter',
    _a[exports.sortAscending] = 'Sort Ascending',
    _a[exports.sortDescending] = 'Sort Descending',
    _a[exports.pagerItemPerPage] = 'elementi per pagina',
    _a[exports.pagerInfo] = '{0} - {1} of {2} elementi',
    _a[exports.pagerFirstPage] = 'Vai alla prima pagina',
    _a[exports.pagerPreviousPage] = 'Pagina precedente',
    _a[exports.pagerNextPage] = 'Pagina successiva',
    _a[exports.pagerLastPage] = 'Vai all\'ultima pagina',
    _a[exports.pagerPage] = 'Pagina',
    _a[exports.pagerOf] = 'of',
    _a['grid.filterEqOperator'] = 'Is equal to',
    _a['grid.filterNotEqOperator'] = 'Is not equal to',
    _a['grid.filterIsNullOperator'] = 'Is null',
    _a['grid.filterIsNotNullOperator'] = 'Is not null',
    _a['grid.filterIsEmptyOperator'] = 'Is empty',
    _a['grid.filterIsNotEmptyOperator'] = 'Is not empty',
    _a['grid.filterStartsWithOperator'] = 'Starts with',
    _a['grid.filterContainsOperator'] = 'Contains',
    _a['grid.filterNotContainsOperator'] = 'Does not contain',
    _a['grid.filterEndsWithOperator'] = 'Ends with',
    _a['grid.filterGteOperator'] = 'Is greater than or equal to',
    _a['grid.filterGtOperator'] = 'Is greater than',
    _a['grid.filterLteOperator'] = 'Is less than or equal to',
    _a['grid.filterLtOperator'] = 'Is less than',
    _a['grid.filterIsTrue'] = 'Is true',
    _a['grid.filterIsFalse'] = 'Is false',
    _a['grid.filterBooleanAll'] = '(All)',
    _a['grid.filterAfterOrEqualOperator'] = 'Is after or equal to',
    _a['grid.filterAfterOperator'] = 'Is after',
    _a['grid.filterBeforeOperator'] = 'Is before',
    _a['grid.filterBeforeOrEqualOperator'] = 'Is before or equal to',
    _a['grid.filterAndLogic'] = 'And',
    _a['grid.filterOrLogic'] = 'Or',
    _a);
