export class ProdottiUpgradeItem {
	constructor() {
		this.itemID = null;
		this.pacchettoID = null;
		this.pacchettoUpgradeID = null;
		this.pacchettoUpgrade = null;
		this.regole = null;
		this.tipo = null;
	}
	itemID: number;
	pacchettoID: number | null;
	pacchettoUpgradeID: number | null;
	pacchettoUpgrade: string;
	regole: string;
	tipo: number | null;
}