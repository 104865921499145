import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";
import { SchedaProdottoItem } from "@/models/prodotti/schedaProdottoItem";
import { SchedaPolizzaItem } from "@/models/prodotti/schedaPolizzaItem";
import helpers from "@/helpers";

const _ENDPONT = api.endpoint.POLIZZE.SCHEDA_POLIZZE;
@Component({})
export default class SchedaPolizza extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	editItem: SchedaPolizzaItem = new SchedaPolizzaItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	typeValore: string = "";
	editTypeValore: string = "";

	get columns(): Array<any> {
		return [
			{ field: "prodottoID", title: "ID", width: 80, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "schedaParametroID", title: this.$i18n.t("generico.parametro").toString(), hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true },
			{ field: "nomeXML", title: this.$i18n.t("generico.nomeXML").toString(), headerCell: "myHeaderCellTemplate", hidden: true, editHide: true },
			{ field: "descrizione", title: this.$i18n.t("generico.nome").toString(), headerCell: "myHeaderCellTemplate", editDisabled: true },
			{
				field: "valore",
				hidden: true,
				disabled: true,
				editType: this.editTypeValore,
				title: this.$i18n.t("generico.valorePredefinito").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "renderfunction",
				renderFunction: this.templateValore
			},
			{
				field: "valoreTesto",
				disabled: true,
				editType: this.editTypeValore,
				title: this.$i18n.t("generico.valorePredefinito").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "renderfunction",
				renderFunction: this.templateValore
			},
			// { field: "note", title: this.$i18n.t("generico.note").toString(), headerCell: "myHeaderCellTemplate", editDisabled: true },
			{ field: "categoria", title: this.$i18n.t("generico.categoria").toString(), headerCell: "myHeaderCellTemplate", editDisabled: true },
			{ field: "etichetta", title: this.$i18n.t("generico.etichetta").toString(), headerCell: "myHeaderCellTemplate" },
			{
				field: "attivo",
				title: this.$i18n.t("generico.attivo").toString(),
				headerCell: "myHeaderCellTemplate",
				disabled: false,
				type: "boolean",
				filterType: "boolean",
				width: 130
			},
			{
				field: "Valore",
				title: "",
				headerCell: "myHeaderCellTemplate",
				disabled: false,
				type: "ternaryBoolean",
				filterType: "boolean",
				filterable: false,
				width: 130
			},
			{ field: "_actions", title: " ", width: 50, cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;
		this.typeValore = "boolean";

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	// onDelete(item: any) {
	// 	var self = this;
	// 	var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
	// 	var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributoreAssociato")]).toString();
	// 	self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	// }

	eseguiSincronizzaPolizza() {
		var self = this;
		let cmd = "CreaSchedaPolizza";
		let data: EseguiComandoRequest = {
			comando: cmd,
			elenco: [],
			parametri: [this.prodottoID]
		};
		api.eseguiComando(data)
			.then(res => {
				self.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
					variant: "success",
					title: this.$i18n.t("generico.azioneeseguita").toString(),
					solid: true
				});
				self.getData();
			})
			.catch(err => {
				// self.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
				// 	variant: "error",
				// 	title: this.$i18n.t("generico.errore").toString(),
				// 	solid: true
				// });
			});
	}

	// addNewRow() {
	// 	let newItem = new DistributoriAssociatiProdottiItem();
	// 	newItem.pacchettoID = this.pacchettoID;
	// 	//var newItem = this.gridData.data[0];

	// 	this.onEdit(newItem);
	// }

	onEdit(item: SchedaPolizzaItem) {
		var self = this;
		self.editItem = Object.assign(new SchedaPolizzaItem(), item);
		self.editSubTitle = `${item.schedaParametroID} - ${item.nomeParametro}`;
		self.editTitle = item.categoria;

		//self.editItem.fieldType = helpers.GetTipologiaFromItem(self.editItem);

		api.getSchedaPolizzaValori(item.prodottoID, item.schedaParametroID).then(data => {
			self.editItem.valori = data;
			self.showEditModal = true;
		});
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.parametro") }).toString();
		// if (self.editItem.tipo == 1) {
		// 	if (self.editItem.valore) self.editItem.valore = "1";
		// }
		self.setGridRow<SchedaPolizzaItem>(_ENDPONT, self.editItem, title, message, false).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return false;
	}

	templateValore(data, dataItem) {
		if (dataItem.isBoolean) {
			var css = "secondary";
			if (dataItem.valore == "1") css = "success";
			else if (dataItem.valore == "0") css = "danger";
			// return '<label><div class="SiNo custom-control custom-checkbox"><input type="checkbox" disabled="disabled" ' + value + ' class="custom-control-input"><label class="custom-control-label"></label></div></label>';
			return `<div class="text-center text-${css}">${data}</div>`;
		} else if (dataItem.isDecimal) {
			if (dataItem.valore == 0 || dataItem.valore) {
				var result = `<div class="text-center">`;
				if (dataItem.isCurrency) {
					result += this.$options.filters.numberToCurrency(dataItem.valore, dataItem.decimalSlot);
				} else if (dataItem.isPercentage) {
					result += this.$options.filters.numberToPercentage(dataItem.valore, dataItem.decimalSlot);
				} else {
					result += this.$options.filters.numberToDecimal(dataItem.valore, dataItem.decimalSlot);
				}
				return result + "</div>";
			}

			return "";
		} else if (dataItem.isInt) {
			if (dataItem.valore == 0 || dataItem.valore) {
				var result = `<div class="text-center">`;
				if (dataItem.isPercentage) {
					result += this.$options.filters.numberToPercentage(dataItem.valore, 0);
				} else {
					result += dataItem.valore;
				}

				return result + "</div>";
			}

			return "";
		} else return `<div class="text-center">${data}</div>`;
	}

	onCellItemChange(e: any) {
		var dataItem = e.dataItem as SchedaPolizzaItem;
		var self = this;

		api.getSchedaPolizzaValori(dataItem.prodottoID, dataItem.schedaParametroID).then(data => {
			dataItem.valori = data;

			var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
			var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.parametro") }).toString();

			self.setGridRow<SchedaPolizzaItem>(_ENDPONT, dataItem, title, message, false).then(res => {
				
			});
		});
	}
}
