import { filterBy } from "@progress/kendo-data-query";
import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";

@Component({})

export default class CarrelloDropdown extends baseUserContextComponent {
	@Prop({ default: () => null }) items: [];
	@Prop({ default: () => null }) textField: string;
	@Prop({ default: () => null }) valueField: string;
	@Prop({ default: () => null }) type: string;
	@Prop({ default: () => null }) value: any;
	@Prop({ default: () => null }) selectionCallBack: any;
	@Prop({ default: () => null }) placeholder: string;

	content: any = null;

	ddlFilter: any = null;

	beforeDestroy() { }
	beforeMount() { }
	mounted() { }
	beforeCreate() { }
	created() { }

	@Watch('value', { immediate: true })
	onValuePropChange(newValue): void {
		this.content = newValue;
	}

	get dropdownSource() {
		var self = this;
		const data = self.items.slice();
		if (self.ddlFilter == null) return data;
		var filtered = filterBy(data, this.ddlFilter);
		return filtered;
	}
	onFilterSelectChange(event) {
		this.ddlFilter = event.filter;
	}
	onChangeDropdownValue(obj: any) {
		var value = ((obj || {}).target || {}).value;

		this.$emit('input', value);

		if (this.selectionCallBack) {
			this.selectionCallBack(value);
		}
	}
}