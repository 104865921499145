import { Component } from "vue-property-decorator";
import baseContextComponent from "./baseContextComponent";

@Component({
})
export default class footerBottom extends baseContextComponent {
	get text(): string {
		this.$appConfig
		return this.$appConfig.footer.htmlText;
	}
	get cssClass(): string {
		this.$appConfig
		return this.$appConfig.footer.cssClass;
	}
}
