import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { PopupCarrelloItem } from "@/models/amministrazione/popupCarrello/popupCarrello";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.POPUP_CARRELLO;
@Component({})
export default class Impostazioni extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.POPUP_CARRELLO];
	}
	editItem: PopupCarrelloItem = new PopupCarrelloItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	prodotti: TextValueItem[] = [];
	pacchetti: TextValueItem[] = [];
	distributori: TextValueItem[] = [];
	popupPosizioniApertura: TextValueItem[] = [];
	popupPosizioniTipiVisualizzazione: TextValueItem[] = [];
	popupPosizioniModalitaVisualizzazione: TextValueItem[] = [];

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		return [
			// { field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "itemID", title: "ID",
				filterType: "numeric-int", width: 80, filterable: true, hidden: false, headerCell: "myHeaderCellTemplate", editHide: true
			},
			{
				field: "idPolizza",
				title: this.$i18n.t("generico.polizza").toString(),
				headerCell: "myHeaderCellTemplate",
				width: this.setColPercent(10),
				values: this.prodotti,
				itemsForFilter: this.prodotti,
				filterType: "select",
				editType: "select",
				editSource: this.prodotti,
			},
			{
				field: "idProdotto",
				title: this.$i18n.t("generico.prodotto").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(10),
				values: this.pacchetti,
				itemsForFilter: this.pacchetti,
				filterType: "select",
				editType: "select",
				editSource: this.pacchetti,
			},

			{
				field: "idDistributore",
				title: this.$i18n.t("generico.distributore").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(10),
				values: this.distributori,
				itemsForFilter: this.distributori,
				filterType: "select",
				editType: "select",
				editSource: this.distributori,
			},
			/*{
				field: "pathFile",
				title: this.$i18n.t("generico.pathFile").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(20),
				type: "link",
			},*/
			{
				field: "pathFile",
				title: this.$i18n.t("generico.pathFile").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\PopupCarrello",
				editField: "pathFile_file",
				export: true,
				width: this.setColPercent(20)
			},

			{
				field: "dallaData",
				title: this.$i18n.t("generico.dallaData").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(7),
				type: "datetime",
				headerType: "daterange"
			},
			{
				field: "allaData",
				title: this.$i18n.t("generico.allaData").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(7),
				type: "datetime",
				headerType: "daterange"
			},
			{
				field: "attivo",
				title: this.$i18n.t("generico.attivo").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(4),
				filterType: "boolean",
				editType: "boolean",
				type: "boolean",
				disabled: true,
			},
			{
				field: "titolo",
				title: this.$i18n.t("generico.titolo").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
			},
			{
				field: "descrizione",
				title: this.$i18n.t("generico.descrizione").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,

				encoded: false,
				type: "renderfunction",
				editType: "editor"
			},
			{
				field: "link",
				title: this.$i18n.t("generico.link").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(10)
			},
			{
				field: "posizioneApertura",
				title: this.$i18n.t("generico.posizioneApertura").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(10),
				values: this.popupPosizioniApertura,
				itemsForFilter: this.popupPosizioniApertura,
				filterType: "select",
				editType: "select",
				editSource: this.popupPosizioniApertura,
			},
			{
				field: "tipoVisualizzazione",
				title: this.$i18n.t("generico.tipoVisualizzazione").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(10),
				values: this.popupPosizioniTipiVisualizzazione,
				itemsForFilter: this.popupPosizioniTipiVisualizzazione,
				filterType: "select",
				editType: "select",
				editSource: this.popupPosizioniTipiVisualizzazione,
			},
			{
				field: "modalitaVisualizzazione",
				title: this.$i18n.t("generico.modalitaVisualizzazione").toString(),
				hidden: false,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(10),
				values: this.popupPosizioniModalitaVisualizzazione,
				itemsForFilter: this.popupPosizioniModalitaVisualizzazione,
				filterType: "select",
				editType: "select",
				editSource: this.popupPosizioniModalitaVisualizzazione,
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		//this.take = 60;
		//this.pageable.pageSize = 60;
		//this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		this.refreshGrid();
	}

	refreshGrid() {
		var self = this;
		var p = [];
		p.push(api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)));
		p.push(api.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI).then(res => (self.pacchetti = res)));
		p.push(api.getDatiDropDown(api.endpoint.DROPDOWN.PRODOTTI).then(res => (self.prodotti = res)));
		p.push(api.getDatiDropDown(api.endpoint.DROPDOWN.POPUP_POSIZIONI_APERTURA).then(res => (self.popupPosizioniApertura = res)));
		p.push(api.getDatiDropDown(api.endpoint.DROPDOWN.POPUP_POSIZIONI_TIPI_VISUALIZZAZIONI).then(res => (self.popupPosizioniTipiVisualizzazione = res)));
		p.push(api.getDatiDropDown(api.endpoint.DROPDOWN.POPUP_POSIZIONI_MODALITA_VISUALIZZAZIONE).then(res => (self.popupPosizioniModalitaVisualizzazione = res)));
		Promise.all(p)
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.popup")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new PopupCarrelloItem();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new PopupCarrelloItem(), item);
		self.editItem.modalitaVisualizzazione = 1;
		self.editItem.tipoVisualizzazione = self.editItem.tipoVisualizzazione == null || self.editItem.tipoVisualizzazione == 0 ? 1 : self.editItem.tipoVisualizzazione;
		self.editSubTitle = "";
		self.editTitle = `ID ${item.itemID} - ${self.$i18n.t("generico.versione").toString()} ${self.editItem.versione == null || self.editItem.versione == 0 ? 1 : self.editItem.versione}`;
		self.showEditModal = true;
	}

	/*onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.popup") }).toString();
		self.setGridRow<PopupCarrelloItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}*/
	onSaveAndIncreseVersionEdit() {
		var self = this;
		self.editItem.versione = (this.editItem.versione || 0) + 1;
		self.onSaveEdit();
	}
	onSaveEdit() {
		var self = this;
		self.editItem.versione = (self.editItem.versione || 1);
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.popup") }).toString();

		var formData = new FormData();
		var ojb = Object.assign({}, self.editItem);
		formData.append("pathFile", self.editItem.pathFile_file);
		delete ojb.pathFile_file;
		let json = JSON.stringify(ojb);
		formData.append("data", json);

		var methodType = self.editItem.itemID > 0 ? 'put' : 'post';

		api.postFormData(_ENDPONT, formData, methodType).then((res) => {
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});

			self.showEditModal = false;
			self.getData();
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	modalShown: boolean = false;
	onModalShown() {
		this.modalShown = true;
	}

	onModalHidden() {
		this.modalShown = false;
	}
	get descrizioneColum() {
		return this.getColumsnDefinition('descrizione');
	}
	get arrayColonnaDescrizione() {
		return this.columns.filter(x => x.field == "descrizione");
	}
	isPosizioneModale(posizione: string) {
		var self = this;
		var pos = '';
		if (self.editItem.posizioneApertura == 1)
			pos = 'login';
		if (self.editItem.posizioneApertura == 2)
			pos = 'carrello';
		if (self.editItem.posizioneApertura == 3)
			pos = 'sceltaProdotto';
		if (self.editItem.posizioneApertura == 4)
			pos = 'myWebins';
		return pos == posizione;
	}
}
