export class DescrizioniPolizeItem {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.descrizione = null;
		this.tipoDescrizioneID = null;
		this.tipoDescrizione = null;
		this.deleted = null;
	}
	itemID: number | null;
	prodottoID: number | null;
	descrizione: string;
	tipoDescrizioneID: number | null;
	tipoDescrizione: string;
	deleted: boolean | null;
}