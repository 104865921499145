import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { StatiItem } from "@/models/amministrazione/stati/statiItem";
import permessi from "@/config/permessi";

@Component({})
export default class AssociaStatiPolizza extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	@Prop({ default: () => null }) gruppoStatoItemId: number;
	

	kendop: any = kendo;


	created() {

	}

	onReloadAssociazioneStati() {
		this.$emit("reload-associa-stati");
	}
}
