import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { ParametriPolizeItem } from "@/models/prodotti/parametriPolizeItem";
import { ParametriProdottiItem } from "@/models/prodotti/parametriProdottiItem";
import permessi from "@/config/permessi";
import { DistributoriAssociatiProdottiItem } from "@/models/prodotti/distributoriAssociatiProdottiItem";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";
import { setFlagsFromString } from "v8";
import { filterBy } from "@progress/kendo-data-query";

const _ENDPONT = api.endpoint.PRODOTTI.SELEZIONA_DISTRIBUTORI_PRODOTTI;
const _ENDPONT_COPIA_DISTRIBUTORI = api.endpoint.PRODOTTI.COPIA_DISTRIBUTORI_PRODOTTI;
@Component({})
export default class SelezionaDistributori extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.PRODOTTI];
	}
	@Prop({ default: () => null }) pacchettoID: number;
	editItem: DistributoriAssociatiProdottiItem = new DistributoriAssociatiProdottiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	productFilter: any = null;

	showOnlyActive: boolean = false;
	showCopiaDistributoriDa: boolean = false;

	codiciPagamento: TextValueItem[] = [];
	province: TextValueItem[] = [];
	lingue: TextValueItem[] = [];
	distributori: TextValueItem[] = [];
	tipiDistributore: TextValueItem[] = [];
	pacchetti: TextValueItem[] = [];
	pacchettiFiltered: TextValueItem[] = [];

	prodottoPerCopia: TextValueItem = null;

	get columns(): Array<any> {
		return [
			{ field: "ItemID", title: "ID", width: 20, filterable: false, hidden: true },
			{
				field: "ragioneSociale",
				title: this.$i18n.t("generico.nominativo").toString(),
				headerCell: "myHeaderCellTemplate"
			},
			{ field: "tipoDistributoreID", title: this.$i18n.t("generico.tipo").toString(), width: 100, values: this.tipiDistributore, groupable: true, headerCell: "myHeaderCellTemplate" },
			{ field: "email", title: this.$i18n.t("generico.email").toString(), width: 150, headerCell: "myHeaderCellTemplate" },
			{ field: "partitaIva", title: this.$i18n.t("generico.partitaIva").toString(), width: 100, headerCell: "myHeaderCellTemplate" },
			{ field: "codiceFiscale", title: this.$i18n.t("generico.codiceFiscale").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "telefono", title: this.$i18n.t("generico.telefono").toString(), width: 100, headerCell: "myHeaderCellTemplate" },
			{ field: "fax", title: this.$i18n.t("generico.fax").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "distributorePadreID", title: this.$i18n.t("generico.padre").toString(), width: 100, values: this.distributori, groupable: true, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "codicePagamentoID", title: this.$i18n.t("generico.pagamento").toString(), width: 100, values: this.codiciPagamento, groupable: true, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "indirizzo", title: this.$i18n.t("generico.indirizzo").toString(), width: 300, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "localita", title: this.$i18n.t("generico.localita").toString(), width: 250, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "codiceProvincia", title: this.$i18n.t("generico.provincia").toString(), width: 100, values: this.province, groupable: true, headerCell: "myHeaderCellTemplate" },
			{ field: "regione", title: this.$i18n.t("generico.regione").toString(), width: 100, groupable: true, headerCell: "myHeaderCellTemplate" },
			{ field: "CAP", title: this.$i18n.t("generico.cap").toString(), width: 200, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "commissioni", title: this.$i18n.t("generico.commissioni").toString(), width: 150, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "email2", title: this.$i18n.t("generico.email2").toString(), width: 150, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "email3", title: this.$i18n.t("generico.email3").toString(), width: 150, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "linguaID", title: this.$i18n.t("generico.lingua").toString(), width: 100, values: this.lingue, groupable: true, hidden: true, headerCell: "myHeaderCellTemplate" },
			{
				field: "cancellato",
				title: this.$i18n.t("generico.nonAttivo").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				disabled: true
			},
			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.TIPO_DISTRIBUTORE).then(res => (self.tipiDistributore = res)),
			//api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_SCONTO).then(res => self.codiciPagamento = res),
			api.getDatiDropDown(api.endpoint.DROPDOWN.PROVINCE).then(res => (self.province = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.LINGUE).then(res => (self.lingue = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI).then(res => {
				self.pacchettiFiltered = res;
				self.pacchetti = res;
			}),
		])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "cancellato";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!false!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.pacchettoID}`);
	}

	// onDelete(item: any) {
	// 	var self = this;
	// 	var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
	// 	var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributoreAssociato")]).toString();
	// 	self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	// }

	eseguiSuSelezione() {
		var self = this;
		let cmd = "AssociaDistributori";
		if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
			let elementToDoAction = this.gridData.data
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.itemID;
				});
			if (elementToDoAction.length == 0) {
				return this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}

			let data: EseguiComandoRequest = {
				comando: cmd,
				elenco: elementToDoAction,
				parametri: [this.pacchettoID, true]
			};
			api.eseguiComando(data)
				.then(res => {
					self.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
						variant: "success",
						title: this.$i18n.t("generico.azioneeseguita").toString(),
						solid: true
					});
					self.getData();
				})
				.catch(err => {
					self.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
						variant: "error",
						title: this.$i18n.t("generico.errore").toString(),
						solid: true
					});
				});
		}
	}

	copiaDaProdotto() {
		this.showCopiaDistributoriDa = true;
	}

	copiaAssociazioneDaProdotto() {
		var self = this;

		if (this.prodottoPerCopia) {
			var data = {
				prodottoSorgente: this.prodottoPerCopia.value
			};
			api.associaDistributoriDaProdotto(_ENDPONT_COPIA_DISTRIBUTORI, this.pacchettoID, data)
			.then(res => {
				self.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
					variant: "success",
					title: this.$i18n.t("generico.azioneeseguita").toString(),
					solid: true
				});

				self.prodottoPerCopia = null;
				self.showCopiaDistributoriDa = false;

				self.getData();
			})
			.catch(err => {
				self.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
					variant: "error",
					title: this.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});
		}
		else {
			return this.$bvToast.toast(this.$i18n.t("generico.noelementselected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	onProdottiFilterChange(event: any) {
		if (this.pacchetti && this.pacchetti.length > 0) {
			this.pacchettiFiltered = filterBy(this.pacchetti, event.filter);
		}

		return [];
	}

	// addNewRow() {
	// 	let newItem = new DistributoriAssociatiProdottiItem();
	// 	newItem.pacchettoID = this.pacchettoID;
	// 	//var newItem = this.gridData.data[0];

	// 	this.onEdit(newItem);
	// }

	// onEdit(item: any) {
	// 	var self = this;
	// 	self.editItem = Object.assign(new DistributoriAssociatiProdottiItem(), item);
	// 	self.editSubTitle = "";
	// 	self.editTitle = item.itemID;
	// 	self.showEditModal = true;
	// }

	// onSaveEdit() {
	// 	var self = this;
	// 	var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
	// 	var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.distributoreAssociato") }).toString();
	// 	self.setGridRow<DistributoriAssociatiProdottiItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
	// 		self.showEditModal = false;
	// 	});
	// }

	// get isNewItem(): boolean {
	// 	return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	// }
}
