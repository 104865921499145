"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.PagerPageSizes = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var messages_1 = require("./../messages");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");

const maxPageSizeValue = 2147483647;
/**
 * Represents the default `PagerPageSizes` component.
 */

var PagerPageSizes = {
	props: {
		pageSize: Number,
		pageChange: Function,
		pageSizes: Array
	},
	inject: {
		kendoLocalizationService: {
			default: null
		}
	},
	methods: {
		pageSizeChange: function pageSizeChange(e) {
			this.$emit(
				"pageChange",
				{
					skip: 0,
					take: parseInt(e.target.value, 10)
				},
				e
			);
		}
	},
	// @ts-ignore
	setup: !gh
		? undefined
		: function() {
				var v3 = !!gh;
				var kendoLocalizationService = inject("kendoLocalizationService", {});
				return {
					v3: v3,
					kendoLocalizationService: kendoLocalizationService
				};
		  },
	// @ts-ignore
	render: function render(createElement) {
		var _this = this;

		var h = gh || createElement;
		var sizes = this.$props.pageSizes.slice();

		if (
			sizes.filter(function(s) {
				return s === _this.$props.pageSize;
			}).length === 0
		) {
			//sizes.unshift(this.$props.pageSize); to avoid the render of value not present
		}

		return h(
			"label",
			{
				class: "k-pager-sizes k-label"
			},
			[
				h(
					"select",
					{
						value: this.v3 ? this.$props.pageSize : null,
						domProps: this.v3
							? undefined
							: {
									value: this.$props.pageSize
							  },
						onChange: this.pageSizeChange,
						on: this.v3
							? undefined
							: {
									change: this.pageSizeChange
							  }
					},
					[
						sizes.map(function(value, index) {
							return h(
								"option",
								{
									key: index,
									value: this.v3 ? value : null,
									domProps: this.v3
										? undefined
										: {
												value: value == "all" || value == "tutti" ? maxPageSizeValue : value
										  }
								},
								[" ", value, " "]
							);
						}, this)
					]
				),
				kendo_vue_intl_1.provideLocalizationService(this).toLanguageString(messages_1.pagerItemPerPage, messages_1.messages[messages_1.pagerItemPerPage])
			]
		);
	}
};
exports.PagerPageSizes = PagerPageSizes;
