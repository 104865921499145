export class TipiDistributore {
	constructor(){
		this.itemID = null;
		this.codiceDistributore = null;
		this.tipoDistributore = null;
		this.tipologiaID = null;
		this.tipologia = null;
	}
	itemID: number | null;
	codiceDistributore: string | null;
	tipoDistributore: string | null;
	tipologiaID: number | null;
	tipologia: string | null;
}