import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { CommissioniPolizeItem } from "@/models/prodotti/commissioniPolizeItem";
import { TestiCarrelloPolizeItem } from "@/models/prodotti/testiCarrelloPolizeItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.POLIZZE.TESTI_CARRELLO_POLIZZE;
@Component({})
export default class TestiCarrelloPolize extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	editItem: TestiCarrelloPolizeItem = new TestiCarrelloPolizeItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	tipiTesto: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "itemID", filterable: false, headerCell: "myHeaderCellTemplate", hidden: true, editHide: true },
			{
				field: "tipoID",
				title: this.$i18n.t("generico.tipo").toString(),
				values: this.tipiTesto,
				headerCell: "myHeaderCellTemplate",
				sortBy: "tipo",
				filterType: "select",
				itemsForFilter: this.tipiTesto,
				editType: "select",
				editSource: this.tipiTesto
			},
			{ field: "testo", title: this.$i18n.t("generico.testo").toString(), headerCell: "myHeaderCellTemplate" },
			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPO_TESTI).then(res => (self.tipiTesto = res));

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.testoCarrello")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new TestiCarrelloPolizeItem();
		newItem.prodottoID = this.prodottoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new TestiCarrelloPolizeItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.testoCarrello") }).toString();
		self.setGridRow<TestiCarrelloPolizeItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
