export class ExtraInfoPolizeItem {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.labelID = null;
		this.labelCarrello = null;
		this.tipoCampo = null;
		this.valore = null;
	}

	itemID: number;
	prodottoID: number | null;
	labelID: number | null;
	labelCarrello: string;
	tipoCampo: string;
	valore: string;
}