"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridEditCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var utils_1 = require("./../utils");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");

var kendo_vue_inputs_1 = require("@progress/kendo-vue-inputs");

var kendo_vue_dateinputs_1 = require("@progress/kendo-vue-dateinputs");
/**
 * Represents the default `GridEditCell` component.
 */


var GridEditCell = {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    colSpan: Number,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    render: [String, Function, Object]
  },
  inject: {
    kendoIntlService: {
      default: null
    }
  },
  data: function data() {
    return {
      inputId: kendo_vue_common_1.guid()
    };
  },
  methods: {
    triggerKeydown: function triggerKeydown(e) {
      this.$emit('cellkeydown', {
        event: e,
        dataItem: this.$props.dataItem,
        field: this.$props.field
      });
    },
    triggerEdit: function triggerEdit(dataItem) {
      this.$emit('edit', dataItem);
    },
    triggerAdd: function triggerAdd(dataItem) {
      this.$emit('add', dataItem);
    },
    triggerCancel: function triggerCancel(dataItem) {
      this.$emit('cancel', dataItem);
    },
    triggerSave: function triggerSave(dataItem) {
      this.$emit('save', dataItem);
    },
    triggerRemove: function triggerRemove(dataItem) {
      this.$emit('remove', dataItem);
    },
    changeHandler: function changeHandler(event, value) {
      if (!value) {
        if (event.target.type === 'checkbox') {
          value = event.target.checked;
        } else {
          value = event.target.valueAsDate ? event.target.valueAsDate : event.target.value;
        }
      }

      this.$emit('change', {
        dataItem: this.$props.dataItem,
        field: this.$props.field,
        event: event,
        value: value
      });
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoIntlService = inject('kendoIntlService', {});
    return {
      v3: v3,
      kendoIntlService: kendoIntlService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var data = utils_1.getNestedValue(this.$props.field, this.$props.dataItem);
    var defaultRendering = null;

    switch (this.$props.editor) {
      case 'numeric':
        defaultRendering = h("td", {
          onKeydown: this.triggerKeydown,
          on: this.v3 ? undefined : {
            "keydown": this.triggerKeydown
          },
          colSpan: this.$props.colSpan,
          attrs: this.v3 ? undefined : {
            colSpan: this.$props.colSpan
          },
          "class": this.$props.className
        }, [// @ts-ignore
        h(kendo_vue_inputs_1.NumericTextBox, {
          style: {
            width: '100%'
          },
          value: data === undefined ? null : data,
          attrs: this.v3 ? undefined : {
            value: data === undefined ? null : data
          },
          onChange: this.changeHandler,
          on: this.v3 ? undefined : {
            "change": this.changeHandler
          }
        })]);
        break;

      case 'date':
        defaultRendering = h("td", {
          onKeydown: this.triggerKeydown,
          on: this.v3 ? undefined : {
            "keydown": this.triggerKeydown
          },
          colSpan: this.$props.colSpan,
          attrs: this.v3 ? undefined : {
            colSpan: this.$props.colSpan
          },
          "class": this.$props.className
        }, [// @ts-ignore
        h(kendo_vue_dateinputs_1.DatePicker, {
          style: {
            width: '100%'
          },
          value: data,
          attrs: this.v3 ? undefined : {
            value: data
          },
          onChange: this.changeHandler,
          on: this.v3 ? undefined : {
            "change": this.changeHandler
          }
        })]);
        break;

      case 'boolean':
        defaultRendering = h("td", {
          onKeydown: this.triggerKeydown,
          on: this.v3 ? undefined : {
            "keydown": this.triggerKeydown
          },
          colSpan: this.$props.colSpan,
          attrs: this.v3 ? undefined : {
            colSpan: this.$props.colSpan
          },
          "class": this.$props.className
        }, [h("input", {
          checked: this.v3 ? data || false : null,
          domProps: this.v3 ? undefined : {
            "checked": data || false
          },
          id: this.inputId,
          attrs: this.v3 ? undefined : {
            id: this.inputId,
            type: "checkbox"
          },
          type: "checkbox",
          "class": "k-checkbox",
          onChange: this.changeHandler,
          on: this.v3 ? undefined : {
            "change": this.changeHandler
          }
        }), h("label", {
          "class": "k-checkbox-label",
          "for": this.inputId,
          attrs: this.v3 ? undefined : {
            "for": this.inputId
          }
        })]);
        break;

      default:
        defaultRendering = h("td", {
          onKeydown: this.triggerKeydown,
          on: this.v3 ? undefined : {
            "keydown": this.triggerKeydown
          },
          colSpan: this.$props.colSpan,
          attrs: this.v3 ? undefined : {
            colSpan: this.$props.colSpan
          },
          "class": this.$props.className
        }, [h("input", {
          style: {
            width: '100%'
          },
          "class": "k-textbox",
          value: this.v3 ? data || '' : null,
          domProps: this.v3 ? undefined : {
            "value": data || ''
          },
          onChange: this.changeHandler,
          on: this.v3 ? undefined : {
            "change": this.changeHandler
          }
        })]);
    }

    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: this.$props.render,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        change: this.changeHandler,
        keydown: this.triggerKeydown,
        edit: this.triggerEdit,
        add: this.triggerAdd,
        cancel: this.triggerCancel,
        save: this.triggerSave,
        remove: this.triggerRemove
      }
    });
  }
};
exports.GridEditCell = GridEditCell;