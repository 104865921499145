"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupingIndicator = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var ref = allVue.ref;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GroupingIndicator` component.
 */


var GroupingIndicator = {
  props: {
    title: String,
    dir: String // 'asc' | 'desc';

  },
  mounted: function mounted() {
    this.element = this.v3 ? this.indicatorContainerRef : this.$refs.indicatorContainer;

    if (this.element) {
      this.draggable = this.$refs.draggable;
    }
  },
  methods: {
    press: function press(event) {
      if (this.element) {
        this.$emit('press', event, this.element);
      }
    },
    drag: function drag(event) {
      if (this.element) {
        this.$emit('drag', event, this.element);
      }
    },
    release: function release(event) {
      if (this.element) {
        this.$emit('release', event, this.element);
      }
    },
    sortChange: function sortChange(event) {
      event.preventDefault();
      var sort = this.$props.dir === 'asc' ? 'desc' : 'asc';
      this.$emit('sortChange', event, sort);
    },
    groupRemove: function groupRemove(event) {
      event.preventDefault();
      this.$emit('remove', event);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var indicatorContainerRef = ref(null);
    return {
      v3: v3,
      indicatorContainerRef: indicatorContainerRef
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this2 = this;

    var _this = this;

    var h = gh || createElement;
    return (// @ts-ignore  function children
      h(kendo_vue_common_1.Draggable, {
        onPress: this.press,
        on: this.v3 ? undefined : {
          "press": this.press,
          "drag": this.drag,
          "release": this.release
        },
        onDrag: this.drag,
        onRelease: this.release,
        ref: 'draggable'
      }, this.v3 ? function () {
        return [h("div", {
          "class": "k-indicator-container",
          ref: _this2.v3 ? function (el) {
            _this.indicatorContainerRef = el;
          } : 'indicatorContainer'
        }, [h("div", {
          "class": "k-group-indicator"
        }, [h("a", {
          "class": "k-link",
          href: "#",
          attrs: _this2.v3 ? undefined : {
            href: "#",
            tabIndex: -1
          },
          tabIndex: -1,
          onClick: _this2.sortChange,
          on: _this2.v3 ? undefined : {
            "click": _this2.sortChange
          }
        }, [h("span", {
          "class": 'k-icon k-i-sort-' + _this2.$props.dir + '-sm'
        }), _this2.$props.title]), h("a", {
          "class": "k-button k-button-icon k-bare",
          tabIndex: -1,
          attrs: _this2.v3 ? undefined : {
            tabIndex: -1
          },
          onClick: _this2.groupRemove,
          on: _this2.v3 ? undefined : {
            "click": _this2.groupRemove
          }
        }, [h("span", {
          "class": "k-icon k-i-group-delete"
        })])])])];
      } : [h("div", {
        "class": "k-indicator-container",
        ref: _this2.v3 ? function (el) {
          _this.indicatorContainerRef = el;
        } : 'indicatorContainer'
      }, [h("div", {
        "class": "k-group-indicator"
      }, [h("a", {
        "class": "k-link",
        href: "#",
        attrs: _this2.v3 ? undefined : {
          href: "#",
          tabIndex: -1
        },
        tabIndex: -1,
        onClick: _this2.sortChange,
        on: _this2.v3 ? undefined : {
          "click": _this2.sortChange
        }
      }, [h("span", {
        "class": 'k-icon k-i-sort-' + _this2.$props.dir + '-sm'
      }), _this2.$props.title]), h("a", {
        "class": "k-button k-button-icon k-bare",
        tabIndex: -1,
        attrs: _this2.v3 ? undefined : {
          tabIndex: -1
        },
        onClick: _this2.groupRemove,
        on: _this2.v3 ? undefined : {
          "click": _this2.groupRemove
        }
      }, [h("span", {
        "class": "k-icon k-i-group-delete"
      })])])])])
    );
  }
};
exports.GroupingIndicator = GroupingIndicator;