"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridColumnMenuFilterUI = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var GridColumnMenuFilterCell_1 = require("./GridColumnMenuFilterCell");

var kendo_vue_dropdowns_1 = require("@progress/kendo-vue-dropdowns");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridColumnMenuFilterUI` component.
 */


var GridColumnMenuFilterUI = {
  props: {
    firstFilterProps: Object,
    secondFilterProps: Object,
    logicValue: Object,
    logicData: Array,
    hideSecondFilter: Boolean,
    operators: Array,
    render: [String, Function, Object]
  },
  methods: {
    handleFocus: function handleFocus(e) {
      this.$emit('filteruifocus', e);
    },
    changeHandler: function changeHandler(e, filterIndex) {
      this.$emit('change', e, filterIndex);
    },
    logicChange: function logicChange(e) {
      this.$emit('logicChange', e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var _a = this.$props,
        firstFilterProps = _a.firstFilterProps,
        hideSecondFilter = _a.hideSecondFilter,
        secondFilterProps = _a.secondFilterProps,
        logicData = _a.logicData,
        logicValue = _a.logicValue,
        operators = _a.operators,
        render = _a.render;

    var optionMapper = function optionMapper(items, selected) {
      return items.map(function (item) {
        if (selected && item.operator === selected.operator) {
          return h("option", {
            value: this.v3 ? item.operator : null,
            domProps: this.v3 ? undefined : {
              "value": item.operator
            },
            selected: true,
            attrs: this.v3 ? undefined : {
              selected: true
            }
          }, [item.text]);
        } else {
          return h("option", {
            value: this.v3 ? item.operator : null,
            domProps: this.v3 ? undefined : {
              "value": item.operator
            }
          }, [item.text]);
        }
      });
    };

    var firstField = firstFilterProps.field,
        firstValue = firstFilterProps.value,
        firstOperator = firstFilterProps.operator,
        firstFilterType = firstFilterProps.filterType,
        secondField = secondFilterProps.field,
        secondValue = secondFilterProps.value,
        secondOperator = secondFilterProps.operator,
        secondFilterType = secondFilterProps.filterType;
    var defaultRendering = h("div", [// @ts-ignore
    h(GridColumnMenuFilterCell_1.GridColumnMenuFilterCell, {
      field: firstField,
      attrs: this.v3 ? undefined : {
        field: firstField,
        value: firstValue,
        operator: firstOperator,
        filterType: firstFilterType,
        operators: operators
      },
      value: firstValue,
      operator: firstOperator,
      filterType: firstFilterType,
      onFiltercellfocus: this.handleFocus,
      on: this.v3 ? undefined : {
        "filtercellfocus": this.handleFocus,
        "change": function change(e) {
          _this.changeHandler(e, 0);
        }
      },
      onChange: function change(e) {
        _this.changeHandler(e, 0);
      },
      operators: operators
    }), !hideSecondFilter && h("div", [// @ts-ignore
    h(kendo_vue_dropdowns_1.DropDownList, {
      onChange: this.logicChange,
      on: this.v3 ? undefined : {
        "change": this.logicChange
      },
      "class": "k-filter-and",
      "data-items": logicData,
      attrs: this.v3 ? undefined : {
        "data-items": logicData,
        value: logicValue,
        textField: "text"
      },
      value: logicValue,
      textField: "text"
    }), // @ts-ignore
    h(GridColumnMenuFilterCell_1.GridColumnMenuFilterCell, {
      field: secondField,
      attrs: this.v3 ? undefined : {
        field: secondField,
        value: secondValue,
        operator: secondOperator,
        filterType: secondFilterType,
        operators: operators
      },
      value: secondValue,
      operator: secondOperator,
      filterType: secondFilterType,
      onFiltercellfocus: this.handleFocus,
      on: this.v3 ? undefined : {
        "filtercellfocus": this.handleFocus,
        "change": function change(e) {
          _this.changeHandler(e, 1);
        }
      },
      onChange: function change(e) {
        _this.changeHandler(e, 1);
      },
      operators: operators
    })])]);
    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: render,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        change: this.changeHandler,
        logicchange: this.logicChange
      }
    });
  }
};
exports.GridColumnMenuFilterUI = GridColumnMenuFilterUI;