import { SchedaPolizzaParametriValori, SchedaPolizzaValore } from "./schedaPolizzaItem";

export class SchedaProdottoItem {
	constructor() {
		this.pacchettoID = null;
		this.schedaParametroID = null;
		this.valore = null;
		this.etichetta = null;
		this.etichettaPolizza = null;
		this.nomeParametro = null;
		this.tipo = null;
		this.note = null;
		this.nomeXML = null;
		this.descrizione = null;
		this.categoria = null;
		this.attivo = null;
		this.valori = [];
		this.valoriPolizza = [];
		this.valorePolizza = null;
		this.fieldType = 0;
	}
	pacchettoID: number;
	schedaParametroID: number;
	valore: any;
	etichetta: string;
	etichettaPolizza: string;
	nomeParametro: string;
	tipo: number | null;
	fieldType: number | null;
	note: string;
	nomeXML: string;
	descrizione: string;
	categoria: string;
	attivo: boolean;
	valori: Array<SchedaProdottoParametriValori>;
	valoriPolizza: Array<SchedaPolizzaParametriValori>;
	valorePolizza: SchedaPolizzaValore;
}

export class SchedaProdottoParametriValori {
	constructor() {
		this.itemID = null;
		this.pacchettoID = null;
		this.anagraficaPacchettoParametriID = null;
		this.anagraficaPacchettoParametriCodice = null;
		this.schedaParametroID = null;
		this.valore = null;
		this.attivo = null;
	}
	itemID: number | null;
	pacchettoID: number | null;
	anagraficaPacchettoParametriID: number;
	anagraficaPacchettoParametriCodice: string;
	schedaParametroID: number;
	valore: string;
	attivo: boolean | null;
}