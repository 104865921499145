"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnMenuContent = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var GridColumnMenuSort_1 = require("./GridColumnMenuSort");

var GridColumnMenuFilter_1 = require("./GridColumnMenuFilter");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `ColumnMenuContent` component.
 */


var ColumnMenuContent = {
  props: {
    column: Object,
    sortable: [Boolean, Object],
    sort: {
      type: Array
    },
    filter: Object,
    filterable: Boolean,
    render: [Boolean, String, Function, Object]
  },
  methods: {
    handleFocus: function handleFocus(e) {
      this.$emit('contentfocus', e);
    },
    closeMenu: function closeMenu() {
      this.$emit('closemenu');
    },
    expandChange: function expandChange() {
      this.$emit('expandchange');
    },
    sortChange: function sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e);
    },
    filterChange: function filterChange(newDescriptor, e) {
      this.$emit('filterchange', newDescriptor, e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var renderTemplate = this.$props.render;
    var defaultRendering = h("div", [// @ts-ignore
    h(GridColumnMenuSort_1.GridColumnMenuSort, {
      column: this.$props.column,
      attrs: this.v3 ? undefined : {
        column: this.$props.column,
        sortable: this.$props.sortable,
        sort: this.$props.sort
      },
      sortable: this.$props.sortable,
      sort: this.$props.sort,
      onClosemenu: this.closeMenu,
      on: this.v3 ? undefined : {
        "closemenu": this.closeMenu,
        "sortchange": this.sortChange
      },
      onSortchange: this.sortChange
    }), // @ts-ignore
    h(GridColumnMenuFilter_1.GridColumnMenuFilter, {
      column: this.$props.column,
      attrs: this.v3 ? undefined : {
        column: this.$props.column,
        filterable: this.$props.filterable,
        filter: this.$props.filter
      },
      filterable: this.$props.filterable,
      filter: this.$props.filter,
      onFilterfocus: this.handleFocus,
      on: this.v3 ? undefined : {
        "filterfocus": this.handleFocus,
        "closemenu": this.closeMenu,
        "expandchange": this.expandChange,
        "filterchange": this.filterChange
      },
      onClosemenu: this.closeMenu,
      onExpandchange: this.expandChange,
      onFilterchange: this.filterChange
    })]);
    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: typeof renderTemplate !== 'boolean' && renderTemplate,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        closemenu: this.closeMenu,
        filterchange: this.filterChange,
        sortchange: this.sortChange,
        expandchange: this.expandChange
      }
    });
  }
};
exports.ColumnMenuContent = ColumnMenuContent;