"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridDetailRow = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
/**
 * The detail-row class of the native Vue Grid by Kendo UI. Defines custom
 * details for each row. Can be applied for building the hierarchy. If `expandField` is
 * set, depending on the current data item and its `expandField` value,
 * the details for each row will be visible or hidden.
 */

/**
 * @hidden
 */

var GridDetailRow = {
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    return null;
  }
};
exports.GridDetailRow = GridDetailRow;