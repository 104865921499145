import moment from "moment";
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import { getListeners } from "@progress/kendo-vue-common";
import { isArray } from "lodash";
import { column, customButtons, customEventItem } from "@/models/grid/columnModels";
import apiFile from "@/services/apiFile";
import { bus } from "@/main";

@Component({})
export default class cellTemplate extends Vue {
	@Prop({ default: () => "" }) field: string;
	@Prop({ default: () => { } }) dataItem: any;
	@Prop({ default: () => "" }) format: string;
	@Prop({ default: () => "" }) className: string;
	@Prop({ default: () => "" }) rowType: string;
	@Prop({ default: () => null }) columnIndex: number;
	@Prop({ default: () => null }) level: number;
	@Prop({ default: () => null }) columnsCount: number;
	@Prop({ default: () => null }) expanded: boolean;
	@Prop({ default: () => "" }) editor: string;
	@Prop({ default: () => [] }) values: TextValueItem[];

	//Custom Prop
	@Prop({ default: () => "string" }) type: string;
	@Prop({ default: () => false }) disabled: boolean;
	@Prop({ default: () => "" }) groupTitle: string;

	@Prop({ default: () => "text" }) fieldSourceText: string;
	@Prop({ default: () => "value" }) fieldSourceValue: string;

	// files Porp
	@Prop({ default: () => "" }) pathFallback: string;
	@Prop({ default: () => null }) downloadFileUrlType: string;
	@Prop({ default: () => null }) label: string;

	@Prop({ default: () => false }) showDuplica: boolean;
	@Prop({ default: () => false }) showAdd: boolean;
	@Prop({ default: () => true }) showEdit: boolean;
	@Prop({ default: () => true }) showDelete: boolean;
	@Prop({ default: () => true }) canEdit: boolean;
	@Prop({ default: () => true }) canDelete: boolean;
	@Prop({ default: () => null }) renderFunction: Function;
	@Prop({ default: () => true }) showDetailIcon: boolean;
	@Prop({ default: () => [] }) customButtons: customButtons[];

	show = false;
	readonly = false;
	filterText = "";
	buttoncolorPickerText = {
		apply: "Applica",
		cancel: "Annulla"
	};

	imageUrl = "../../../assets/upload.png";
	imageMapUrl = "../../../assets/pin_standar_map.png";
	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";

	// created() {
	// 	window.moment = moment;
	// }
	getNestedValue(fieldName, dataItem) {
		var self = this;
		//console.log(`${fieldName} - ${dataItem[fieldName]}`);
		const path = fieldName.split(".");
		let data = Object.assign({}, dataItem);
		path.forEach(p => {
			data = data ? data[p] : undefined;
		});

		if (data || data == 0) {
			if (this.values && this.values.length > 0) {
				let val = this.values.find(function (item) {
					return item[self.fieldSourceValue] == data;
				});

				if (val) data = val[self.fieldSourceText];
				else data = "";
			} else if (this.type == "numeric") {
				if (this.format && !isNaN(data)) {
					data = kendo.format(this.format, data);
					//data = data.replace(",", "");
				} else {
					data = kendo.toString(data, "n2");
					//data = data.replace(",", "");
					//data = data.replace(".", ",");
				}
			} else if (this.type == "numeric-int") {
				if (this.format && !isNaN(data)) {
					data = kendo.format(this.format, data);
					//data = data.replace(",", "");
				} else {
					data = kendo.toString(data, "n0");
					//data = data.replace(",", "");
					//data = data.replace(".", ",");
				}
			} else if (this.type == "date") {
				if (this.format && !isNaN(Date.parse(data))) {
					data = kendo.format(this.format, new Date(data));
				} else {
					let result = this.$options.filters.dateToString(data);
					if (result) data = result;
				}
			} else if (this.type == "datetime") {
				if (this.format && this._.isDate(data)) {
					data = kendo.format(this.format, new Date(data));
				} else {
					let result = this.$options.filters.datetimeToString(data);
					if (result) data = result;
				}
			} else if (this.type == "renderfunction") {
				if (this.renderFunction) {
					data = this.renderFunction(data, dataItem);
				}
			}
		}

		return data;
	}
	pickFile() { }

	onFilePicked(e) {
		var self = this;
		const files = e.target.files;
		if (files[0] !== undefined) {
			var imageName = files[0].name;
			if (imageName.lastIndexOf(".") <= 0) {
				return;
			}
			const fr = new FileReader();
			fr.readAsDataURL(files[0]);
			fr.addEventListener("load", () => {
				self.dataItem[self.field as any] = fr.result;
				self.$emit("cell-item-change", self);
				//self.imageFile = files[0]; // this is an image file that can be sent to server...
			});
		}
	}

	clickHandler() {
		if (this.format != "image" && this.format != "booleanSwitch") this.$emit("cell-click", this);
	}

	itemChange(e) {
		if (this.format == "color") {
			// this.dataItem[this.field as any] = e.value;
		}

		this.$emit("cell-item-change", this);
	}

	booleanSwitchChange(e) {
		// this.dataItem[this.field as any] = !this.dataItem[this.field as any];
		this.$emit("boolean-switch-change", this);
	}
	get additionalButtons(): customButtons[] {
		var self = this;
		return (self.customButtons || []).filter(x => self.additionButtonVisible(x));
	}
	additionButtonDisabled(b: customButtons) {
		if (b.disabled == null) {
			return false;
		}
		if (typeof b.disabled === "boolean") {
			return b.disabled;
		}
		if (typeof b.disabled === "function") {
			return b.disabled(this.dataItem);
		}
		return false;
	}
	additionButtonVisible(b: customButtons) {
		if (b.visible == null) {
			return true;
		}
		if (typeof b.visible === "boolean") {
			return b.visible;
		}
		if (typeof b.visible === "function") {
			return b.visible(this.dataItem);
		}
		return true;
	}
	onEmitCustomClick(event: string) {
		var cei = new customEventItem();
		cei.command = event;
		cei.item = this.dataItem;
		this.$emit("customButtonClick", cei);
	}
	onEmitClick(event: string) {
		this.$emit(event, this.dataItem);
	}
	onClick(e) {
		// @ts-ignore
		bus.$emit("onClickRowGroupChange", /*e,*/ this.dataItem, this.expanded);
	}
	onExpand() {
		this.$emit("expand", this.dataItem);
	}
	ckSelectChange() {
		this.$emit("customSelect", this.dataItem);
	}
	getValueInside(): any {
		return this.dataItem.value ? this.dataItem[this.field] : this.$i18n.t("gridvalue.nondefinito").toString();
	}

	get emptyCell() {
		return "";
	}
	get imageToShow() {
		return `${this.$appConfig.baseApiUrl}/file/image/${this.dataItem[this.field]}?fallback=${this.pathFallback}`;
	}
	get imageMapToShow() {
		return this.imageMapUrl;
	}

	get colorToShow() {
		return null;
	}

	get renderCell() {
		let result = this.field !== undefined && this.rowType !== "groupHeader" && this.rowType != "groupFooter";
		return result;
	}

	get groupFooterTemplate() {
		let result = this.field !== undefined && this.rowType == "groupFooter";

		return result;
	}

	get renderAggregation() {
		let result = this.dataItem.aggregates && this.dataItem.aggregates[this.field];

		return result;
	}

	get renderArrow() {
		var returnValue =
			this.columnIndex === undefined ||
			this.level === undefined ||
			this.columnIndex < this.level ||
			this.columnsCount === undefined ||
			(this.rowType !== "groupHeader" && this.rowType !== "groupFooter") ||
			this.dataItem[this.field] === undefined;
		return returnValue && this.dataItem[this.field];
	}

	getDownloadFileUrl(fieldName, dataItem) {
		var self = this;
		var data = self.getNestedValue(fieldName, dataItem);
		if (!data) return "";
		var urlType = (self.downloadFileUrlType || 'file');
		return `${urlType}/${data}`;
	}
	getDownloadFileName(fieldName, dataItem) {
		var self = this;
		if (self.label)
			return self.label;
		var data = self.getNestedValue(fieldName, dataItem) as string;
		if (!data) return "";
		var name = data.split("/").pop();
		return name;
	}
	downloadFile(field, dataItem) {
		var self = this;
		var url = self.getDownloadFileUrl(field, dataItem);
		var nomeFile = self.getDownloadFileName(field, dataItem);
		if (nomeFile.toLocaleLowerCase().endsWith(".pdf") == false)
			nomeFile += ".pdf";
		apiFile.downloadFile(url, nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile;
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t('msg.erroreGenerico').toString(),
					solid: true
				});
			}
			);
	}
}
