import helpers from "@/helpers";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { Component, Prop } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseContextComponent from "../baseContextComponent";

@Component({

})
export default class grigliaUlterioriDati extends baseContextComponent {
	gridData: any[] = [];

	@Prop({ default: () => null }) ulterioriDati: any[];
	
	@Prop({ default: () => new datiViaggio() }) datiViaggio: datiViaggio;
	
	prodottoSelezionato!: CarrelloInfoProdotto;

	updateUlterioriDati() {
		debugger
		this.datiViaggio.ulterioriDati = helpers.cloneObject(this.gridData);
	}

	created() {
		this.initUlterioriDati();
	}

	initUlterioriDati(){
		debugger
		this.gridData = helpers.cloneObject(this.ulterioriDati).map(obj => { obj.valore = null; return obj });

		if(this.datiViaggio && this.datiViaggio.ulterioriDati && this.datiViaggio.ulterioriDati.length){
			for(var i = 0; i < this.datiViaggio.ulterioriDati.length; i++){
				var u = this.gridData.find(obj => obj.itemID === this.datiViaggio.ulterioriDati[i].itemID );
				u && (u.valore = this.datiViaggio.ulterioriDati[i].valore );
			}
		}
		this.updateUlterioriDati();
	}

	beforeDestroy() {
		this.$root.$off("updateUlterioriDati");
	}
	mounted() {
		var self = this;
		this.$root.$on("updateUlterioriDati", () => {
			self.updateUlterioriDati();
		});
	}

	validaDati(){
		var err = [];

		for(var i = 0; i < this.ulterioriDati.length; i++){
			if( this.ulterioriDati[i].obbligatorio){
				var u = this.datiViaggio.ulterioriDati.find( obj => obj.itemID == this.ulterioriDati[i].itemID);
				if( !u || !u.valore )
					err.push("Ulteriore dato obbligatorio: " + this.ulterioriDati[i].descrizione);
			}
		}
		return err;
	}
}
