export class GruppiNazionalitaPerFornitore {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.fornitoreID = 0;
		this.gruppoNazionalitaID = 0;
		this.gruppoNazionalita = "";
		//this.gruppoStatoA3 = "";
		this.descrizione = "";
		this.codiceRiepilogo = "";
		this.descrizioneRiepilogo = "";				
	}

	itemID: number;
	fornitoreID: number | null;
	gruppoNazionalitaID: number | null;
	gruppoNazionalita: string;
	//gruppoStatoA3: string;
	descrizione: string;
	codiceRiepilogo: string;
	descrizioneRiepilogo: string;
}
