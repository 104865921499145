"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridToolbar = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the GridToolbar component.
 *
 * @example
 * ```tsx-no-run
 *   <div id="app">
 *       <Grid
 *           :data-items="products"
 *           :columns="columns">
 *       <grid-toolbar >
 *           <button title="Add new" class="k-button k-primary" @click='insert' >
 *                   Add new
 *           </button>
 *           <button v-if="hasItemsInEdit"
 *               title="Cancel current changes"
 *               class="k-button"
 *               @click="cancelChanges">
 *               Cancel current changes
 *           </button>
 *           </grid-toolbar>
 *       </Grid>
 *   </div>
 * <script>
 *       Vue.component('Grid', Grid);
 *       Vue.component('grid-toolbar', GridToolbar);
 *       new Vue({
 *           el: '#app',
 *           data: function () {
 *               return {
 *                  products: [
 *                    { 'foo': 'A1', 'bar': 'B1' },
 *                       { 'foo': 'A2', 'bar': 'B2' },
 *                       { 'foo': 'A3', 'bar': 'B2' }
 *                   ],
 *                   columns: [
 *                       { field: 'foo'},
 *                       { field: 'bar'}
 *                   ]
 *               };
 *           }
 *       });
 *   </script>
 * ```
 */

/**
 * Represents the default `GridToolbar` component.
 */


var GridToolbar = {
  name: 'GridToolbar',
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
    return h("div", {
      "class": "k-header k-grid-toolbar"
    }, [defaultSlot]);
  }
};
exports.GridToolbar = GridToolbar;