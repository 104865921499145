export class TipoSchedaParametri {
	constructor() {
		this.itemID = 0;
		this.typeId = 0;
		this.name = null;
		this.note = null;
		this.decimalSlot = 2;
		this.dataType = null;
		this.dataTypeId = null;
	}
	itemID: number;
	typeId: number;
	name: string | null;
	note: string | null;
	decimalSlot: number | null;
	dataType: string | null;
	dataTypeId: number | null;
}