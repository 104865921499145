import { responseMessage } from './messageModels';

export enum operationResultStatus {
	ok = "ok",
	ko = "ko",
	warning = "warning"
}
export class operationResult<T>{
	constructor() {
		this.messages = [];
		this.data = null;
		this.status = null;
	}
	data: T;
	messages: responseMessage[];
	status:operationResultStatus;
}
