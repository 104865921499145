import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { StatiItem } from "@/models/amministrazione/stati/statiItem";
import permessi from "@/config/permessi";
import { ModificheAbilitazione } from "@/models/amministrazione/configurazioneModificaPratiche/modificheAbilitazione";
import helpers from "@/helpers";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.MODIFICHE_ABILITAZIONE;
@Component({})
export default class ConfigurazioneModificaPraticheDettaglio extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.STATI];
	}
	@Prop({ default: () => null }) dataItem: ModificheAbilitazione;
	@Prop({ default: () => null }) escludi: boolean;

	categorieSource: TextValueItem[] = [];
	pacchetti: TextValueItem[] = [];
	prodotti: TextValueItem[] = [];
	distributori: TextValueItem[] = [];
	fornitori: TextValueItem[] = [];
	prezzoInvariatoSource: TextValueItem[] = [
		{ value: 1, text: 'Solo Uguale' },
		{ value: 2, text: 'Solo Maggiore o Uguale' },
		{ value: 3, text: 'Solo Minore o Uguale' }
	]

	abilitaDateViaggio: boolean = false;
	
	item: ModificheAbilitazione = new ModificheAbilitazione();

	kendop: any = kendo;


	created() {
		var self = this;


		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.CATEGORIE).then(res => (self.categorieSource = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI).then(res => (self.pacchetti = res));
		var p3 = api.getDatiDropDown(api.endpoint.DROPDOWN.PRODOTTI).then(res => (self.prodotti = res));
		var p4 = api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res));
		var p5 = api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res));

		Promise.all([p, p2, p3, p4, p5])
			.then(responses => {
				
				self.item = helpers.cloneObject(this.dataItem);
				
				if( self.item.fornitoreID ){
					var f = self.fornitori.find(obj => obj.value === self.item.fornitoreID) as any;
					self.item.fornitoreID = f || null;
				}

				if( self.item.categoriePacchetto){
					var c = self.categorieSource.filter(obj => self.item.categoriePacchetto.trim().split(",").indexOf( String(obj.value)) >= 0 ) as any;
					self.item.categoriePacchetto = c || [];
				}

				if( self.item.prodotti){
					var c = self.prodotti.filter(obj => self.item.prodotti.trim().split(",").indexOf( String(obj.value)) >= 0 ) as any;
					self.item.prodotti = c || [];
				}

				if( self.item.pacchetti){
					var c = self.pacchetti.filter(obj => self.item.pacchetti.trim().split(",").indexOf( String(obj.value)) >= 0 ) as any;
					self.item.pacchetti = c || [];
				}

				if( self.item.distributoreInclusoID){
					var c = self.distributori.filter(obj => self.item.distributoreInclusoID.trim().split(",").indexOf( String(obj.value)) >= 0 ) as any;
					self.item.distributoreInclusoID = c || [];
				}

				if( self.item.distributoreEsclusoID){
					var c = self.distributori.filter(obj => self.item.distributoreEsclusoID.trim().split(",").indexOf( String(obj.value)) >= 0 ) as any;
					self.item.distributoreEsclusoID = c || [];
				}
			})
			.catch(err => {
				console.log(err);
			});

	}

	salvaConfigurazione(){
		debugger;
		var self = this;

		var cat = (this.item.categoriePacchetto && this.item.categoriePacchetto.length ? this.item.categoriePacchetto : null ) as any;
		cat && ( cat = (cat as Array<TextValueItem>).map(obj => obj.value).join(",") );

		var prodotti = (this.item.prodotti && this.item.prodotti.length ? this.item.prodotti : null ) as any;
		prodotti && ( prodotti = (prodotti as Array<TextValueItem>).map(obj => obj.value).join(",") );
		
		var pacchetti = (this.item.pacchetti && this.item.pacchetti.length ? this.item.pacchetti : null ) as any;
		pacchetti && ( pacchetti = (pacchetti as Array<TextValueItem>).map(obj => obj.value).join(",") );

		var distrInc = (this.item.distributoreInclusoID && this.item.distributoreInclusoID.length ? this.item.distributoreInclusoID : null ) as any;
		distrInc && ( distrInc = (distrInc as Array<TextValueItem>).map(obj => obj.value).join(",") );

		var distrEsc = (this.item.prodotti && this.item.prodotti.length ? this.item.prodotti : null ) as any;
		distrEsc && ( distrEsc = (distrEsc as Array<TextValueItem>).map(obj => obj.value).join(",") );

		var dati = helpers.cloneObject(this.item) as ModificheAbilitazione;
		dati.fornitoreID = this.item.fornitoreID ? ( this.item.fornitoreID as any ).value || null : null;
		dati.categoriePacchetto = cat;
		dati.prodotti = prodotti;
		dati.pacchetti = pacchetti;
		dati.distributoreInclusoID = distrInc;
		dati.distributoreEsclusoID = distrEsc;
		dati.prezzoInvariato = this.item.prezzoInvariato ? ( this.item.prezzoInvariato as any ).value || null : null;


		//richiama salvataggio e poi fai refresh della griglia
		api.chiamataPut(_ENDPONT, dati)
		.then(res => {
			self.onReloadConfigurazioni();
		})
		.catch(err => {
		});
	}

	onReloadConfigurazioni() {
		this.$emit("reload-configurazioni");
	}
}
