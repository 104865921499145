"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cellOperatorChange = exports.cellBoolDropdownChange = exports.cellInputChange = exports.defaultHideSecondFilter = exports.defaultOperators = exports.operatorMap = exports.IsUnaryFilter = exports.operators = exports.filterLogicList = void 0;
var messages_1 = require("./messages");
/**
 * @hidden
 */
exports.filterLogicList = [
    { text: 'grid.filterAndLogic', value: 'and' },
    { text: 'grid.filterOrLogic', value: 'or' }
];
/**
 * @hidden
 */
exports.operators = {
    'text': [
        { text: 'grid.filterEqOperator', value: 'eq' },
        { text: 'grid.filterNotEqOperator', value: 'neq' },
        { text: 'grid.filterContainsOperator', value: 'contains' },
        { text: 'grid.filterNotContainsOperator', value: 'doesnotcontain' },
        { text: 'grid.filterStartsWithOperator', value: 'startswith' },
        { text: 'grid.filterEndsWithOperator', value: 'endswith' },
        { text: 'grid.filterIsNullOperator', value: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', value: 'isnotnull' },
        { text: 'grid.filterIsEmptyOperator', value: 'isempty' },
        { text: 'grid.filterIsNotEmptyOperator', value: 'isnotempty' }
    ],
    'numeric': [
        { text: 'grid.filterEqOperator', value: 'eq' },
        { text: 'grid.filterNotEqOperator', value: 'neq' },
        { text: 'grid.filterGteOperator', value: 'gte' },
        { text: 'grid.filterGtOperator', value: 'gt' },
        { text: 'grid.filterLteOperator', value: 'lte' },
        { text: 'grid.filterLtOperator', value: 'lt' },
        { text: 'grid.filterIsNullOperator', value: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', value: 'isnotnull' }
    ],
    'date': [
        { text: 'grid.filterEqOperator', value: 'eq' },
        { text: 'grid.filterNotEqOperator', value: 'neq' },
        { text: 'grid.filterAfterOrEqualOperator', value: 'gte' },
        { text: 'grid.filterAfterOperator', value: 'gt' },
        { text: 'grid.filterBeforeOperator', value: 'lt' },
        { text: 'grid.filterBeforeOrEqualOperator', value: 'lte' },
        { text: 'grid.filterIsNullOperator', value: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', value: 'isnotnull' }
    ],
    'boolean': [
        { text: 'grid.filterBooleanAll', value: '' },
        { text: 'grid.filterIsTrue', value: true },
        { text: 'grid.filterIsFalse', value: false }
    ]
};
/**
 * @hidden
 */
exports.IsUnaryFilter = function (operator) {
    return operator === 'isnull' || operator === 'isnotnull' || operator === 'isempty' || operator === 'isnotempty';
};
/**
 * @hidden
 */
exports.operatorMap = function (iterable, service) { return iterable.map(function (operator) { return ({
    // LocalizationService todo
    text: service.toLanguageString(operator.text, messages_1.messages[operator.text]),
    operator: operator.value
}); }); };
/**
 * @hidden
 */
exports.defaultOperators = {
    text: 'contains',
    numeric: 'eq',
    date: 'eq',
    boolean: 'eq'
};
/**
 * @hidden
 */
exports.defaultHideSecondFilter = {
    text: false,
    numeric: false,
    date: false,
    boolean: true
};
/**
 * @hidden
 */
exports.cellInputChange = function (value, e, props) {
    var operator = props.operator;
    switch (props.filterType) {
        case 'numeric':
            if (!operator || exports.IsUnaryFilter(operator)) {
                // change the operator to default
                operator = exports.defaultOperators.numeric;
            }
            if (value === null && operator === exports.defaultOperators.numeric) {
                // clear only the default operator
                operator = '';
            }
            break;
        case 'date':
            if (!operator || exports.IsUnaryFilter(operator)) {
                operator = exports.defaultOperators.date;
            }
            if (value === null && operator === exports.defaultOperators.date) {
                operator = '';
            }
            break;
        case 'text':
            if (!operator || exports.IsUnaryFilter(operator)) {
                operator = exports.defaultOperators.text;
            }
            if (!value && operator === exports.defaultOperators.text) {
                operator = '';
            }
            break;
        default: return;
    }
    return { value: value, operator: operator, event: e };
};
/**
 * @hidden
 */
exports.cellBoolDropdownChange = function (value, e) {
    return {
        value: value,
        operator: value === '' ? '' : exports.defaultOperators.boolean,
        event: e
    };
};
/**
 * @hidden
 */
exports.cellOperatorChange = function (operator, e, value) {
    if (exports.IsUnaryFilter(operator)) {
        // clear the value to avoid confusion on what is filtered
        value = null;
    }
    return { value: value, operator: operator, event: e };
};
