"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Header = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var ref = allVue.ref;

var browser_support_service_1 = require("../utils/browser-support.service");

var utils_1 = require("../utils");
/**
 * Represents the default `Header` component.
 */


var Header = {
  props: {
    staticHeaders: Boolean,
    headerRow: Object,
    columnResize: Object,
    cols: Array
  },
  data: function data() {
    return {
      divStyle: {},
      element: null,
      headerWrap: null,
      table: null
    };
  },
  mounted: function mounted() {
    this.headerWrap = this.v3 ? this.headerWrapRef : this.$refs.headerWrap;
    this.table = this.v3 ? this.tableRef : this.$refs.table;
    this.$props.columnResize.colGroupHeader = this.v3 ? this.colGroupHeaderRef : this.$refs.colGroupHeader;
    var scrollbarWidth = new browser_support_service_1.BrowserSupportService().scrollbarWidth;
    var rtl = utils_1.isRtl(this.$el);

    if (this.$props.columnResize) {
      this.$props.columnResize.setIsRtl(rtl);
    }

    var padding = Math.max(0, scrollbarWidth - 1) + 'px';
    var right = rtl ? 0 : padding;
    var left = rtl ? padding : 0;
    this.divStyle = {
      padding: "0 " + right + " 0 " + left
    };
  },
  methods: {
    setScrollLeft: function setScrollLeft(scrollLeft) {
      if (this.headerWrap) {
        this.headerWrap.scrollLeft = scrollLeft;
      }
    },
    setWidth: function setWidth(width, pxOrPerc) {
      if (this.table) {
        this.table.style.width = width + (!pxOrPerc ? 'px': '%');
      }
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var headerWrapRef = ref(null);
    var tableRef = ref(null);
    var colGroupHeaderRef = ref(null);
    return {
      v3: v3,
      headerWrapRef: headerWrapRef,
      tableRef: tableRef,
      colGroupHeaderRef: colGroupHeaderRef
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;

    if (!this.$props.staticHeaders) {
      return this.$props.headerRow;
    }

    return h("div", {
      "class": "k-grid-header",
      style: this.divStyle
    }, [h("div", {
      ref: this.v3 ? function (el) {
        _this.headerWrapRef = el;
      } : 'headerWrap',
      "class": "k-grid-header-wrap"
    }, [h("table", {
      ref: this.v3 ? function (el) {
        _this.tableRef = el;
      } : 'table'
    }, [h("colgroup", {
      ref: this.v3 ? function (el) {
        _this.colGroupHeaderRef = el;
      } : 'colGroupHeader'
    }, [this.$props.cols]), this.$props.headerRow])])]);
  }
};
exports.Header = Header;