// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class plashScreenPage extends baseContextComponent {
	@Prop({ default: () => true })
	showLoader: boolean
	get logoPath() {
		return `${this.$appConfig.baseUrl}logo.jpg`;
		// return `${process.env.BASE_URL}logo.jpg`;
		//return   `${process.env.VUE_APP_BASE_URL}/logo.jpg`;
	}
}
