var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('grid',{attrs:{"cell-render":'defaultCellTemplate',"data-items":_vm.gridData,"pageable":_vm.pageable,"filter":_vm.filter,"filterable":false,"resizable":true,"reorderable":true,"sortable":true,"sort":_vm.sort,"skip":_vm.skip,"take":_vm.take,"total":_vm.total,"columns":_vm.columns,"page-size":_vm.pageSize,"selectable":true,"selected-field":_vm.selectedRowField,"group":_vm.group,"expand-field":_vm.expandedField},on:{"pagechange":_vm.pageChangeHandler,"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange},scopedSlots:_vm._u([{key:"myHeaderCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('header-cell-template',_vm._b({attrs:{"tableName":'fattoripolizze',"sort":_vm.sort},on:{"sort-changed":_vm.onSortChanged,"filter-apply":_vm.onFilterApply,"filter-remove":_vm.onFilterRemove}},'header-cell-template',props,false))]}},{key:"myFooterCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.gridData && _vm.gridData.data && _vm.gridData.data.length > 0 && _vm.gridData.data[0].items),expression:"gridData && gridData.data && gridData.data.length > 0 && gridData.data[0].items"}],staticClass:"text-right",attrs:{"prova":props.id}},[_vm._v(" "+_vm._s(_vm.$t("grid.groupfootersum"))+" "+_vm._s(_vm.kendop.toString(_vm.getImportoTotale, "n2"))+" ")])])]}},{key:"defaultCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('cell-template',_vm._b({attrs:{"canEdit":_vm.canEdit,"canDelete":_vm.canDelete},on:{"delete":_vm.onDelete,"edit":_vm.onEdit,"expand":_vm.expandChange,"customSelect":_vm.customSelectionChange,"cell-click":function($event){return _vm.clickHandler(props.dataItem)}}},'cell-template',props,false))]}}])},[_c('grid-toolbar',[_c('b-row',{staticClass:"grid-toolbar",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12"}},[(_vm.canAdd)?_c('b-button',{staticClass:"mx-1",attrs:{"title":_vm.$t('generico.aggiunginuovo'),"variant":"primary"},on:{"click":_vm.addNewRow}},[_c('b-icon',{attrs:{"icon":"plus-circle"}}),_vm._v(" "+_vm._s(_vm.$t("generico.aggiunginuovo"))+" ")],1):_vm._e()],1)],1)],1)],1),_c('b-modal',{attrs:{"ignore-enforce-focus-selector":'.k-list-filter input',"modal-class":"modal-full-height modal-slide-from-right modal-right","title-class":" w-100","body-class":"app-slim-scroll p-3"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"h5 text-capitalize"},[_vm._v(_vm._s(_vm.isNewItem ? _vm.$t("titolo.crea", [_vm.$t("generico.fattore")]) : _vm.$t("titolo.modifica", [_vm.$t("generico.fattore")])))]),(_vm.isNewItem == false)?_c('div',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.editTitle))]):_vm._e(),(_vm.isNewItem == false)?_c('div',{},[_vm._v(" "+_vm._s(_vm.editSubTitle)+" ")]):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"flex-fill"},[_c('b-button',{staticClass:"text-capitalize",attrs:{"block":"","variant":"theme"},on:{"click":function($event){return _vm.onSaveEdit()}}},[_vm._v(" "+_vm._s(_vm.$t("btn.salva"))+" ")])],1),_c('div',{staticClass:"flex-fill"},[_c('b-button',{staticClass:"text-capitalize",attrs:{"block":""},on:{"click":function($event){return cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("btn.annulla"))+" ")])],1)]}}]),model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}},[_c('edit-form',{attrs:{"columns":_vm.columns},model:{value:(_vm.editItem),callback:function ($$v) {_vm.editItem=$$v},expression:"editItem"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }