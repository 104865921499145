export class TestiCarrelloPolizeItem {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.tipoID = null;
		this.tipo = null;
		this.testo = null;
	}
	itemID: number;
	prodottoID: number | null;
	tipoID: number | null;
	tipo: string;
	testo: string;
}