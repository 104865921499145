"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridColumnMenuItemContent = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");

var kendo_vue_animation_1 = require("@progress/kendo-vue-animation");
/**
 * Represents the default `GridColumnMenuItemContent` component.
 */


var GridColumnMenuItemContent = {
  props: {
    show: Boolean
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
    return h("div", {
      "class": 'k-columnmenu-item-content'
    }, [// @ts-ignore function children
    h(kendo_vue_animation_1.Reveal, {
      appear: this.$props.show,
      attrs: this.v3 ? undefined : {
        appear: this.$props.show
      },
      style: {
        position: 'relative',
        display: 'block'
      }
    }, this.v3 ? function () {
      return [_this.$props.show ? defaultSlot : null];
    } : [_this.$props.show ? defaultSlot : null])]);
  }
};
exports.GridColumnMenuItemContent = GridColumnMenuItemContent;