export class SendMessageToUserItem {
	
	constructor() {
		this.itemID = 0;
		this.toUser = "";
		this.message = "";

		this.isSingleSend = false;
		this.toUserFullName = "";

		this.notificationFrom = null;

		this.toUserIds = [];
	}

	itemID: number;
	toUser: string;
	message: string;

	isSingleSend: boolean;
	toUserFullName: string;

	notificationFrom: Date | string | null;

	toUserIds: string[];
}
