"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridHeaderSelectionCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridHeaderSelectionCell` component.
 */


var GridHeaderSelectionCell = {
  props: {
    field: String,
    title: String,
    selectionValue: Boolean,
    render: [Object, Function, String]
  },
  data: function data() {
    return {
      inputId: kendo_vue_common_1.guid()
    };
  },
  methods: {
    changeHandle: function changeHandle(e) {
      this.$emit('selectionchange', {
        field: this.$props.field,
        event: e
      });
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var renderTemplate = this.$props.render;
    var defaultRendering = h("span", [[h("input", {
      key: 0,
      checked: this.v3 ? this.$props.selectionValue : null,
      domProps: this.v3 ? undefined : {
        "checked": this.$props.selectionValue
      },
      id: this.inputId,
      attrs: this.v3 ? undefined : {
        id: this.inputId,
        type: "checkbox"
      },
      type: "checkbox",
      "class": "k-checkbox",
      onChange: this.changeHandle,
      on: this.v3 ? undefined : {
        "change": this.changeHandle
      }
    }), h("label", {
      key: 1,
      "class": "k-checkbox-label",
      "for": this.inputId,
      attrs: this.v3 ? undefined : {
        "for": this.inputId
      }
    })]]);
    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: renderTemplate,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        selectionchange: this.changeHandle
      }
    });
  }
};
exports.GridHeaderSelectionCell = GridHeaderSelectionCell;