export class ParametriPolizeItem {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.codice = null;
		this.startDate = null;
		this.endDate = null;
		this.nazionalitaID = null;
		this.destinazioneID = null;
		this.destinazioneA3 = null;
		this.destinazioneStatoID = null;
		this.destinazioneStato = null;
		this.tipoDestinazione = null;
		this.tipoPremioID = null;
		this.premio = null;
		this.durataMinima = null;
		this.durataMassima = null;
		this.tipoApplicazioneID = null;
		this.applicazione = null;
		this.postiMinimi = null;
		this.postiMassimi = null;
		this.imposta = null;
		this.limiteAssicurato = null;
		this.cancellato = null;
		this.limiteMultiploGiorni = null;
		this.limiteMultiploImporto = null;
		this.limiteMultiploPasseggeri = null;
		this.tipoImpostaID = null;
		this.importoMinimo = null;
		this.importoMassimo = null;
		this.codiceTariffa = null;
		this.valoreLogin = null;
		this.premioMinimo = null;
		this.fasciaEta = null;
	}
	itemID: number;
	prodottoID: number | null;
	codice: string | null;
	startDate: string | null;
	endDate: string | null;
	nazionalitaID: number | null;
	destinazioneID: number | null;
	destinazioneA3: string | null;
	destinazioneStatoID: number | null;
	destinazioneStato: string | null;
	tipoDestinazione: number | null;
	tipoPremioID: number | null;
	premio: number | null;
	durataMinima: number | null;
	durataMassima: number | null;
	tipoApplicazioneID: number | null;
	applicazione: number | null;
	postiMinimi: number | null;
	postiMassimi: number | null;
	imposta: number | null;
	limiteAssicurato: number | null;
	cancellato: boolean | null;
	limiteMultiploGiorni: number | null;
	limiteMultiploImporto: number | null;
	limiteMultiploPasseggeri: number | null;
	tipoImpostaID: number | null;
	importoMinimo: number | null;
	importoMassimo: number | null;
	codiceTariffa: string;
	valoreLogin: string;
	premioMinimo: number | null;
	fasciaEta: number | null;
}