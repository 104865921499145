export class ProdottiPolize {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.nomeProdotto = null;
		this.prodottiPacchettiID = null;
		this.pacchetto = null;
		this.tipologiaID = null;
		this.fornitoreID = null;
		this.categoriaID = null;
		this.logoImage = null;
		this.logoImageThumb = null;
		this.contrattoNumero = null;
		this.codiceAcquistoAutomatico = null;
		this.caricaImmagine = null;
		this.ordine = null;
		this.immagineRicerca = null;
		this.immagineComplementare = null;
		this.categoria = null;
		this.logoImageThumb_file = null;
		this.logoImage_file = null;
		this.immagineRicerca_file = null;
		this.immagineComplementare_file = null;
	}
	itemID: number;
	prodottoID: number | null;
	nomeProdotto: string;
	prodottiPacchettiID: number | null;
	pacchetto: string;
	tipologiaID: number | null;
	fornitoreID: number | null;
	categoriaID: number | null;
	logoImage: string;
	logoImageThumb: string;
	contrattoNumero: string;
	codiceAcquistoAutomatico: string;
	caricaImmagine: boolean | null;
	ordine: number | null;
	immagineRicerca: string;
	immagineComplementare: string;
	categoria: string;
	logoImageThumb_file: File;
	logoImage_file: File;
	immagineRicerca_file: File;
	immagineComplementare_file: File;
	tipoMarkupID: number;
	markup: number;
}