const appColors: string[] = [
	"white-lighten",
	"white-darken",
	"white",
	"black",
	"black-darken",
	"black-lighten",
	"blue",
	"blue-darken",
	"blue-lighten",
	"indigo",
	"indigo-darken",
	"indigo-lighten",
	"purple",
	"purple-darken",
	"purple-lighten",
	"pink",
	"pink-darken",
	"pink-lighten",
	"red",
	"red-darken",
	"red-lighten",
	"orange",
	"orange-darken",
	"orange-lighten",
	"yellow",
	"yellow-darken",
	"yellow-lighten",
	"green",
	"green-darken",
	"green-lighten",
	"teal",
	"teal-darken",
	"teal-lighten",
	"cyan",
	"cyan-darken",
	"cyan-lighten",
	"gray",
	"gray-darken",
	"gray-lighten",
	"theme"
];

export default appColors;