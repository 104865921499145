"use strict";

var __assign = undefined && undefined.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = undefined && undefined.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridColumnMenuFilter = exports.filterGroupByField = exports.rootFilterOrDefault = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");

var kendo_data_query_1 = require("@progress/kendo-data-query");

var GridColumnMenuItem_1 = require("./GridColumnMenuItem");

var GridColumnMenuItemGroup_1 = require("./GridColumnMenuItemGroup");

var GridColumnMenuItemContent_1 = require("./GridColumnMenuItemContent");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");

var filterCommon_1 = require("../filterCommon");

var messages_1 = require("../messages");

var GridColumnMenuFilterUI_1 = require("./GridColumnMenuFilterUI");
/**
 * @hidden
 */


exports.rootFilterOrDefault = function (rootFilter) {
  return rootFilter || {
    filters: [],
    logic: 'and'
  };
}; // tslint:disable:max-line-length

/**
 * Can be used to check if filtering is applied to a specific field ([see example]({% slug column_menu_grid_native %}#toc-styling-the-column-menu-icon)). Useful for creating active filter indicators.
 */
// tslint:enable:max-line-length


exports.filterGroupByField = function (field, filter) {
  var rootFilter = exports.rootFilterOrDefault(filter);
  var compositeFilters = rootFilter.filters.filter(function (f) {
    if (kendo_data_query_1.isCompositeFilterDescriptor(f)) {
      return f.filters && f.filters.length && f.filters.length <= 2 && !f.filters.find(function (nf) {
        return kendo_data_query_1.isCompositeFilterDescriptor(nf) || nf.field !== field;
      });
    }

    return false;
  });
  return compositeFilters[0] || null;
};
/**
 * Represents the default `GridColumnMenuFilter` component.
 */


var GridColumnMenuFilter = {
  props: {
    column: Object,
    filter: Object,
    filterable: Boolean,
    hideSecondFilter: {
      type: [Boolean, Object],
      default: function _default() {
        return undefined;
      }
    },
    filterUI: [String, Function, Object]
  },
  inject: {
    kendoLocalizationService: {
      default: null
    }
  },
  data: function data() {
    return {
      expanded: false,
      filterGroup: null
    };
  },
  created: function created() {
    var _this = this;

    var filterGroup;

    if (this.$props.column && this.$props.column.field) {
      var filterType = this.$props.column.filter || 'text';
      var defaultOperator = filterCommon_1.defaultOperators[filterType];
      filterGroup = exports.filterGroupByField(this.$props.column.field, this.$props.filter);
      filterGroup = filterGroup ? __assign(__assign({}, filterGroup), {
        filters: filterGroup.filters.map(function (f) {
          return __assign({}, f);
        })
      }) : {
        logic: 'and',
        filters: [{
          field: this.$props.column.field,
          operator: defaultOperator
        }, {
          field: this.$props.column.field,
          operator: defaultOperator
        }]
      };

      if (filterGroup.filters.filter(function (x) {
        return x.field === _this.$props.column.field;
      }).length === 1) {
        filterGroup.filters.splice(1, 0, {
          field: this.$props.column.field,
          operator: defaultOperator
        });
      }
    }

    this.expanded = this.$props.expanded || false;
    this.filterGroup = filterGroup;
  },
  methods: {
    removeGroup: function removeGroup(group, rootFilter) {
      var filters = __spreadArrays(rootFilter.filters);

      var groupIndex = filters.findIndex(function (f) {
        return f === group;
      });

      if (groupIndex > -1) {
        filters.splice(groupIndex, 1);
      }

      return __assign(__assign({}, rootFilter), {
        filters: filters
      });
    },
    insertGroup: function insertGroup(group, rootFilter) {
      return __assign(__assign({}, rootFilter), {
        filters: __spreadArrays([group], rootFilter.filters)
      });
    },
    isControlled: function isControlled() {
      return this.$props.expanded !== undefined;
    },
    onFilterExpand: function onFilterExpand() {
      var isControlled = this.isControlled();
      var nextValue = !(isControlled ? this.$props.expanded : this.expanded);
      this.$emit('expandchange', nextValue);

      if (!isControlled) {
        this.expanded = nextValue;
      }
    },
    filterChangeHandler: function filterChangeHandler(e, filterIndex) {
      this.filterChange(filterIndex || 0, e);
    },
    firstFilterChange: function firstFilterChange(e) {
      this.filterChange(0, e);
    },
    secondFilterChange: function secondFilterChange(e) {
      this.filterChange(1, e);
    },
    filterChange: function filterChange(filterIndex, e) {
      var filters = this.filterGroup.filters.map(function (f, i) {
        if (i === filterIndex) {
          return __assign(__assign({}, f), {
            value: e.value,
            operator: e.operator
          });
        }

        return f;
      });
      this.filterGroup = __assign(__assign({}, this.filterGroup), {
        filters: filters
      });
    },
    logicChange: function logicChange(e) {
      this.filterGroup = __assign(__assign({}, this.filterGroup), {
        logic: e.target.value.operator
      });
    },
    clear: function clear(e) {
      e.preventDefault();

      if (!kendo_vue_common_1.hasListener.call(this, 'filterchange')) {
        return;
      }

      var field = this.$props.column.field;
      var rootFilter = exports.rootFilterOrDefault(this.$props.filter);
      var filterGroup = exports.filterGroupByField(field, this.$props.filter);
      var rootFilters = rootFilter.filters.filter(function (f) {
        return f !== filterGroup;
      });

      if (!rootFilters.length) {
        this.$emit('filterchange', null, {
          event: e,
          field: this.$props.column.field
        });
      } else {
        this.$emit('filterchange', __assign(__assign({}, rootFilter), {
          filters: rootFilters
        }), {
          event: e,
          field: this.$props.column.field
        });
      }

      this.$emit('closemenu');
    },
    currentFilterGroup: function currentFilterGroup() {
      return __assign(__assign({}, this.filterGroup), {
        filters: this.filterGroup.filters.filter(function (nf) {
          return nf.value !== undefined && nf.value !== null && nf.value !== '' || nf.value === null && nf.operator;
        })
      });
    },
    submit: function submit(e) {
      e.preventDefault();

      if (!kendo_vue_common_1.hasListener.call(this, 'filterchange')) {
        return;
      }

      var field = this.$props.column.field;
      var rootFilter = exports.rootFilterOrDefault(this.$props.filter);
      var filterGroup = exports.filterGroupByField(field, this.$props.filter);
      var currentFilterGroup = this.currentFilterGroup();
      var updatedFilter = null;

      if (filterGroup && currentFilterGroup.filters.length > 0) {
        var rootFilters = rootFilter.filters.map(function (f) {
          if (f === filterGroup) {
            return currentFilterGroup;
          }

          return f;
        });
        updatedFilter = __assign(__assign({}, rootFilter), {
          filters: rootFilters
        });
      } else if (currentFilterGroup.filters.length === 0) {
        var rootFilters = rootFilter.filters.filter(function (f) {
          return f !== filterGroup;
        });

        if (rootFilters.length) {
          updatedFilter = __assign(__assign({}, rootFilter), {
            filters: rootFilters
          });
        }
      } else {
        updatedFilter = __assign(__assign({}, rootFilter), {
          filters: __spreadArrays(rootFilter.filters, [currentFilterGroup])
        });
      }

      this.$emit('filterchange', updatedFilter, {
        event: e,
        field: this.$props.column.field
      });
      this.$emit('closemenu');
    },
    handleFocus: function handleFocus(e) {
      this.$emit('filterfocus', e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoLocalizationService = inject('kendoLocalizationService', {});
    return {
      v3: v3,
      kendoLocalizationService: kendoLocalizationService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this2 = this;

    var h = gh || createElement;
    var _a = this.$props,
        column = _a.column,
        filterUI = _a.filterUI,
        hideSecondFilter = _a.hideSecondFilter;

    if (!column || !column.field) {
      return h("div");
    }

    var filterType = column.filter || 'text';
    var currentHideSecondFilter = hideSecondFilter !== undefined ? hideSecondFilter : filterCommon_1.defaultHideSecondFilter[filterType];
    var localizationService = kendo_vue_intl_1.provideLocalizationService(this);
    var filters = this.filterGroup.filters;
    var currentOperators = filterCommon_1.operatorMap(filterCommon_1.operators[filterType], localizationService);
    var firstFilterCellProps = {
      field: column.field,
      value: filters[0].value,
      operator: filters[0].operator,
      filterType: filterType
    };
    var secondFilterCellProps = {
      field: column.field,
      value: filters[1].value,
      operator: filters[1].operator,
      filterType: filterType
    };
    var filterLogic = this.filterGroup.logic;
    var logicData = filterCommon_1.operatorMap(filterCommon_1.filterLogicList, localizationService);
    var logicProps = {
      value: logicData.find(function (item) {
        return item.operator === (filterLogic === null ? '' : filterLogic);
      }),
      data: logicData
    };
    var isFilterValid = this.currentFilterGroup().filters.length !== 0;
    var expandState = this.isControlled() ? this.$props.expanded : this.expanded;
    return (// @ts-ignore function children
      h(GridColumnMenuItemGroup_1.GridColumnMenuItemGroup, this.v3 ? function () {
        return [// @ts-ignore
        h(GridColumnMenuItem_1.GridColumnMenuItem, {
          title: localizationService.toLanguageString(messages_1.filterTitle, messages_1.messages[messages_1.filterTitle]),
          attrs: _this2.v3 ? undefined : {
            title: localizationService.toLanguageString(messages_1.filterTitle, messages_1.messages[messages_1.filterTitle]),
            iconClass: 'k-i-filter'
          },
          iconClass: 'k-i-filter',
          onMenuitemclick: _this2.onFilterExpand,
          on: _this2.v3 ? undefined : {
            "menuitemclick": _this2.onFilterExpand
          }
        }), // @ts-ignore function children
        h(GridColumnMenuItemContent_1.GridColumnMenuItemContent, {
          show: !!expandState,
          attrs: _this2.v3 ? undefined : {
            show: !!expandState
          }
        }, _this2.v3 ? function () {
          return [h("div", {
            "class": 'kendo-grid-filter-menu-container'
          }, [h("form", {
            "class": 'k-filter-menu k-group k-reset k-state-border-up',
            onSubmit: _this2.submit,
            on: _this2.v3 ? undefined : {
              "submit": _this2.submit,
              "reset": _this2.clear
            },
            onReset: _this2.clear
          }, [h("div", {
            "class": 'k-filter-menu-container'
          }, [// @ts-ignore
          h(GridColumnMenuFilterUI_1.GridColumnMenuFilterUI, {
            firstFilterProps: firstFilterCellProps,
            attrs: _this2.v3 ? undefined : {
              firstFilterProps: firstFilterCellProps,
              secondFilterProps: secondFilterCellProps,
              logicData: logicProps.data,
              logicValue: logicProps.value,
              hideSecondFilter: currentHideSecondFilter,
              operators: currentOperators,
              render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
            },
            secondFilterProps: secondFilterCellProps,
            logicData: logicProps.data,
            logicValue: logicProps.value,
            onFilteruifocus: _this2.handleFocus,
            on: _this2.v3 ? undefined : {
              "filteruifocus": _this2.handleFocus,
              "logicChange": _this2.logicChange,
              "change": _this2.filterChangeHandler
            },
            onLogicChange: _this2.logicChange,
            onChange: _this2.filterChangeHandler,
            hideSecondFilter: currentHideSecondFilter,
            operators: currentOperators,
            render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
          }), h("div", {
            "class": 'k-columnmenu-actions'
          }, [h("button", {
            "class": 'k-button',
            type: 'reset',
            attrs: _this2.v3 ? undefined : {
              type: 'reset'
            }
          }, [localizationService.toLanguageString(messages_1.filterClearButton, messages_1.messages[messages_1.filterClearButton])]), h("button", {
            "class": 'k-button k-primary',
            disabled: !isFilterValid,
            attrs: _this2.v3 ? undefined : {
              disabled: !isFilterValid
            }
          }, [localizationService.toLanguageString(messages_1.filterSubmitButton, messages_1.messages[messages_1.filterSubmitButton])])])])])])];
        } : [h("div", {
          "class": 'kendo-grid-filter-menu-container'
        }, [h("form", {
          "class": 'k-filter-menu k-group k-reset k-state-border-up',
          onSubmit: _this2.submit,
          on: _this2.v3 ? undefined : {
            "submit": _this2.submit,
            "reset": _this2.clear
          },
          onReset: _this2.clear
        }, [h("div", {
          "class": 'k-filter-menu-container'
        }, [h(GridColumnMenuFilterUI_1.GridColumnMenuFilterUI, {
          firstFilterProps: firstFilterCellProps,
          attrs: _this2.v3 ? undefined : {
            firstFilterProps: firstFilterCellProps,
            secondFilterProps: secondFilterCellProps,
            logicData: logicProps.data,
            logicValue: logicProps.value,
            hideSecondFilter: currentHideSecondFilter,
            operators: currentOperators,
            render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
          },
          secondFilterProps: secondFilterCellProps,
          logicData: logicProps.data,
          logicValue: logicProps.value,
          onFilteruifocus: _this2.handleFocus,
          on: _this2.v3 ? undefined : {
            "filteruifocus": _this2.handleFocus,
            "logicChange": _this2.logicChange,
            "change": _this2.filterChangeHandler
          },
          onLogicChange: _this2.logicChange,
          onChange: _this2.filterChangeHandler,
          hideSecondFilter: currentHideSecondFilter,
          operators: currentOperators,
          render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
        }), h("div", {
          "class": 'k-columnmenu-actions'
        }, [h("button", {
          "class": 'k-button',
          type: 'reset',
          attrs: _this2.v3 ? undefined : {
            type: 'reset'
          }
        }, [localizationService.toLanguageString(messages_1.filterClearButton, messages_1.messages[messages_1.filterClearButton])]), h("button", {
          "class": 'k-button k-primary',
          disabled: !isFilterValid,
          attrs: _this2.v3 ? undefined : {
            disabled: !isFilterValid
          }
        }, [localizationService.toLanguageString(messages_1.filterSubmitButton, messages_1.messages[messages_1.filterSubmitButton])])])])])])])];
      } : [h(GridColumnMenuItem_1.GridColumnMenuItem, {
        title: localizationService.toLanguageString(messages_1.filterTitle, messages_1.messages[messages_1.filterTitle]),
        attrs: _this2.v3 ? undefined : {
          title: localizationService.toLanguageString(messages_1.filterTitle, messages_1.messages[messages_1.filterTitle]),
          iconClass: 'k-i-filter'
        },
        iconClass: 'k-i-filter',
        onMenuitemclick: _this2.onFilterExpand,
        on: _this2.v3 ? undefined : {
          "menuitemclick": _this2.onFilterExpand
        }
      }), h(GridColumnMenuItemContent_1.GridColumnMenuItemContent, {
        show: !!expandState,
        attrs: _this2.v3 ? undefined : {
          show: !!expandState
        }
      }, _this2.v3 ? function () {
        return [h("div", {
          "class": 'kendo-grid-filter-menu-container'
        }, [h("form", {
          "class": 'k-filter-menu k-group k-reset k-state-border-up',
          onSubmit: _this2.submit,
          on: _this2.v3 ? undefined : {
            "submit": _this2.submit,
            "reset": _this2.clear
          },
          onReset: _this2.clear
        }, [h("div", {
          "class": 'k-filter-menu-container'
        }, [h(GridColumnMenuFilterUI_1.GridColumnMenuFilterUI, {
          firstFilterProps: firstFilterCellProps,
          attrs: _this2.v3 ? undefined : {
            firstFilterProps: firstFilterCellProps,
            secondFilterProps: secondFilterCellProps,
            logicData: logicProps.data,
            logicValue: logicProps.value,
            hideSecondFilter: currentHideSecondFilter,
            operators: currentOperators,
            render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
          },
          secondFilterProps: secondFilterCellProps,
          logicData: logicProps.data,
          logicValue: logicProps.value,
          onFilteruifocus: _this2.handleFocus,
          on: _this2.v3 ? undefined : {
            "filteruifocus": _this2.handleFocus,
            "logicChange": _this2.logicChange,
            "change": _this2.filterChangeHandler
          },
          onLogicChange: _this2.logicChange,
          onChange: _this2.filterChangeHandler,
          hideSecondFilter: currentHideSecondFilter,
          operators: currentOperators,
          render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
        }), h("div", {
          "class": 'k-columnmenu-actions'
        }, [h("button", {
          "class": 'k-button',
          type: 'reset',
          attrs: _this2.v3 ? undefined : {
            type: 'reset'
          }
        }, [localizationService.toLanguageString(messages_1.filterClearButton, messages_1.messages[messages_1.filterClearButton])]), h("button", {
          "class": 'k-button k-primary',
          disabled: !isFilterValid,
          attrs: _this2.v3 ? undefined : {
            disabled: !isFilterValid
          }
        }, [localizationService.toLanguageString(messages_1.filterSubmitButton, messages_1.messages[messages_1.filterSubmitButton])])])])])])];
      } : [h("div", {
        "class": 'kendo-grid-filter-menu-container'
      }, [h("form", {
        "class": 'k-filter-menu k-group k-reset k-state-border-up',
        onSubmit: _this2.submit,
        on: _this2.v3 ? undefined : {
          "submit": _this2.submit,
          "reset": _this2.clear
        },
        onReset: _this2.clear
      }, [h("div", {
        "class": 'k-filter-menu-container'
      }, [h(GridColumnMenuFilterUI_1.GridColumnMenuFilterUI, {
        firstFilterProps: firstFilterCellProps,
        attrs: _this2.v3 ? undefined : {
          firstFilterProps: firstFilterCellProps,
          secondFilterProps: secondFilterCellProps,
          logicData: logicProps.data,
          logicValue: logicProps.value,
          hideSecondFilter: currentHideSecondFilter,
          operators: currentOperators,
          render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
        },
        secondFilterProps: secondFilterCellProps,
        logicData: logicProps.data,
        logicValue: logicProps.value,
        onFilteruifocus: _this2.handleFocus,
        on: _this2.v3 ? undefined : {
          "filteruifocus": _this2.handleFocus,
          "logicChange": _this2.logicChange,
          "change": _this2.filterChangeHandler
        },
        onLogicChange: _this2.logicChange,
        onChange: _this2.filterChangeHandler,
        hideSecondFilter: currentHideSecondFilter,
        operators: currentOperators,
        render: filterUI && kendo_vue_common_1.templateRendering.call(_this2, filterUI, kendo_vue_common_1.getListeners.call(_this2))
      }), h("div", {
        "class": 'k-columnmenu-actions'
      }, [h("button", {
        "class": 'k-button',
        type: 'reset',
        attrs: _this2.v3 ? undefined : {
          type: 'reset'
        }
      }, [localizationService.toLanguageString(messages_1.filterClearButton, messages_1.messages[messages_1.filterClearButton])]), h("button", {
        "class": 'k-button k-primary',
        disabled: !isFilterValid,
        attrs: _this2.v3 ? undefined : {
          disabled: !isFilterValid
        }
      }, [localizationService.toLanguageString(messages_1.filterSubmitButton, messages_1.messages[messages_1.filterSubmitButton])])])])])])])])
    );
  }
};
exports.GridColumnMenuFilter = GridColumnMenuFilter;