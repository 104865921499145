import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { TipologieDistributore } from "@/models/anafrafica/tipologieDistributore";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.TIPOLOGIE_DISTRIBUTORE;
@Component({})
export default class iTipologieDistributore extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.TIPOLOGIE_DISTRIBUTORE];
	}
	editItem: TipologieDistributore = new TipologieDistributore();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 200, filterable: false,  headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "tipologia",
				title: this.$i18n.t("generico.tipologiaDistributore").toString(),
				headerCell: "myHeaderCellTemplate"
			},
			

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;

		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
		.then(responses => {
			self.getData();
		})
		.catch(err => {
			console.log(err);
		});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.tipologiaDistributore")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]).then(res => {
			if( res && res.status == "ok")
				self.$emit("updated");
		});
	}

	addNewRow() {
		let newItem = new TipologieDistributore();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new TipologieDistributore(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.tipologiaDistributore") }).toString();
		self.setGridRow<TipologieDistributore>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
			self.$emit("updated");
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}