export class ExtraInfoItem {
	constructor() {
		this.itemID = null;
		this.labelCarrello = null;
		this.tipoCampo = null;
		this.suggerimento = null;
	}
	itemID: number;
	labelCarrello: string | null;
	tipoCampo: string | null;
	suggerimento: string | null;
}