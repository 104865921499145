import { Component, Watch } from "vue-property-decorator";
import api from "@/services/api";
import baseUserContextComponent from "../baseUserContextComponent";
import { process, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import Vue from "vue";
import { Filter, Filters } from "@/models/grid/filter";
import headerCellTemplate from "./cellTemplate/headerTemplate.vue";
import helpers from "@/helpers";
import { VNode } from "vue/types/umd";
import { operationResult } from "@/models/operationResult";
import { TextValueItem } from "@/models/TextValueItem";
import moment from "moment";
import { filterBy } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import { Azioni } from "@/models/ruolo";
import { bus } from "@/main";

@Component({})
export default class gridComponentClientBase extends baseUserContextComponent {
	[x: string]: any;
	expandedField: string = "_expanded";
	customSelectedField: string = "_customSelected";
	selectedRowField: string = "_selectedRow";
	maxPageSizeValue: number = 2147483647;

	//columns: any = [];
	gridData: any = [];

	//#region -- paging --
	pageable: any = {
		pageSize: 20,
		pageSizes: [20, 40, 60, 100, "tutti"],
		messages: {
			display: "{0}-{1} di {2} elementi",
			empty: "Nessun dato",
			first: "Prima",
			last: "Ultima",
			next: "Successiva",
			previous: "Precedente",
			itemsPerPage: "elementi per pagina"
		}
	};
	pageSize: number = 20;
	pageIndex: number = 0;
	take: number = 20;
	skip: number = 0;
	//#endregion

	//#region -- sort --
	sort: any = [];
	//#endregion

	//#region grouping
	headerCellGroupTemplate: string = "myHeaderCellGroupTemplate";
	group: any = [];
	groupForSort: any = [];
	expandedItems: any = [];
	groupable: any = {
		messages: {
			empty: "&nbsp;Trasporta una colonna per Raggruppare"
		}
	};
	//#endregion
	filter: CompositeFilterDescriptor = {
		logic: "and",
		filters: []
	};

	editMode: boolean = false;

	filterable: any = {
		messages: {
			info: "Filtra per:",
			and: "e",
			or: "oppure",
			filter: "Applica",
			clear: "Rimuovi"
		},
		operators: {
			isTrue: "Vero",
			isFalse: "Falso",
			selectValue: "Scegli",
			operator: "Operatore",
			value: "Valore",
			string: {
				contains: "Contiene",
				eq: "Uguale a",
				neq: "Diverso da",
				startswith: "Comincia per",
				doesnotcontain: "Non contiene",
				endswith: "Finisce per"
			},
			number: {
				eq: "Uguale a",
				neq: "Diverso da",
				gte: "Maggiore o uguale a",
				gt: "Maggiore di",
				lte: "Minore o uguale a",
				lt: "Minore di"
			},
			date: {
				gte: "Dal",
				lte: "Al"
			},
			enums: {
				eq: "Uguale a",
				neq: "Diverso da"
			}
		}
	};

	created() {
		bus.$on("onClickRowGroupChange", this.expandChangeGroupRow.bind(self));
	}

	destroyed() {
		bus.$off("onClickRowGroupChange");
	}

	onGroupChanged(newValue: []) {
		this.groupForSort = [];
		if (newValue && newValue.length > 0 && this.columns) {
			for (let i = 0; i < newValue.length; i++) {
				let element = newValue[i] as any;
				if (element && element.field) {
					var col = this.columns.find(function (item) {
						return item.field == element.field;
					});

					if (col && col.sortBy) {
						this.groupForSort.push({ field: col.sortBy, dir: element.dir });
					} else {
						this.groupForSort.push({ field: element.field, dir: element.dir });
					}
				}
			}
		}
	}

	setDefaultPageSize(): void {
		this.pageIndex = 0;
		this.skip = 0;
	}

	pageChangeHandler(event) {
		this.skip = event.page.skip;
		this.take = event.page.take;

		this.pageSize = !isNaN(event.page.take) ? event.page.take : 10000;

		if (this.skip === 0) {
			this.pageIndex = 0;
		} else {
			this.pageIndex = this.skip / this.take;
		}
	}

	clearSortAndFilter() {
		this.sort = [];
		this.filter = {
			logic: "and",
			filters: []
		};
		this.group = [];

		this.setDefaultPageSize();
	}

	onFilterRemove(e: headerCellTemplate) {
		if (e) {
			this.filter.filters = this.filter.filters.filter(function (item: FilterDescriptor) {
				return item.field !== e.field;
			});

			// this.filter.filters.splice(findFilterIndex, 1);
		}
	}

	onFilterApply(e: headerCellTemplate) {

		if (e) {
			var findFilterIndex = this.filter.filters.findIndex(function (item: FilterDescriptor) {
				return item.field === e.field;
			});

			if (findFilterIndex >= 0) {
				this.filter.filters = this.filter.filters.filter(function (item: FilterDescriptor) {
					return item.field !== e.field;
				});
			}

			if (e.filterTypeHeader == "multiselect" && e.filterItemsSelected.length > 0) {
				for (var i = 0; i < e.filterItemsSelected.length; i++) {
					var f = e.filterItemsSelected[i];
					this.filter.filters.push({
						field: e.field,
						operator: "contains",
						value: f
					});
				}
			} else if (e.filterTypeHeader == "select") {
				let filtersToAdd: Filters = null;

				if (e.filterItemSelected) {

					this.filter.filters.push({
						field: e.field,
						operator: e.filterCompareSelected,
						value: e.filterItemSelected[e.filterSourceValue]
					});
				}
			} else if (e.filterTypeHeader == "numeric") {
				if (e.filterCompareSelected && e.filterText) {
					this.filter.filters.push({
						field: e.field,
						operator: e.filterCompareSelected,
						value: parseFloat(e.filterText)
					});
				}
			} else if (e.filterTypeHeader == "numeric-int") {
				if (e.filterCompareSelected && e.filterText) {
					this.filter.filters.push({
						field: e.field,
						operator: e.filterCompareSelected,
						value: parseInt(e.filterText)
					});
				}
			} else if (e.filterTypeHeader == "datetimerange" || e.filterTypeHeader == "daterange") {
				/*
			if (e.filterType == "multiselect" && e.filterItemsSelected.length > 0) {
				var filtersToAdd = {
					field: e.field,
					type: "multiselect",
					filter: {
						logic: "or",
						filters: []
					}
				};

				for (var i = 0; i < e.filterItemsSelected.length; i++) {
					var f = e.filterItemsSelected[i];
					filtersToAdd.filter.filters.push({
						field: e.field,
						operator: "contains",
						value: f
					});
				}

				this.filter.push(filtersToAdd);
			} 
			*/
				if (e.startDateHeader) {
					this.filter.filters.push({
						field: e.field,
						operator: e.filterCompareSelected,
						value: new Date(e.startDateHeader as any)
					});
				}
				if (e.endDateHeader) {
					this.filter.filters.push({
						field: e.field,
						operator: e.filterCompareValue2Selected,
						value: new Date(e.endDateHeader as any)
					});
				}
			} else if (e.filterType == "boolean") {
				if (e.filterRadioButtonSelected) {
					this.filter.filters.push({
						field: e.field,
						operator: "eq",
						value: e.filterRadioButtonSelected == "true"
					});
				}
			} else if (e.filterTypeHeader) {
				this.filter.filters.push({
					field: e.field,
					operator: e.filterCompareSelected,
					value: e.filterText
				});
			} else {
				this.onFilterRemove(e);
			}

			this.$emit("grid-header-filter", this.filter);
		}
	}

	sortChangeHandler(e: any) { }

	dataStateChange(e) {
		//this.group = e.groups;
		this.createAppState(e.data);
	}

	createAppState(dataState) {
		this.group = dataState.group;
		this.take = dataState.take;
		this.skip = dataState.skip;
		if (dataState.group) {
			dataState.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = dataState.group;
	}
	clickHandler(dataItem) {
		//Vue.set(dataItem, this.expandedField, dataItem[this.expandedField] === undefined ? false : !dataItem[this.expandedField]);
		var self = this;
		let selectedField = this.selectedRowField;
		this.itemsGrid.forEach(d => {
			if (d[self.selectedRowField]) {
				Vue.set(d, selectedField, undefined);
			}
		});

		Vue.set(dataItem, selectedField, dataItem[selectedField] === undefined ? true : !dataItem[selectedField]);
	}
	expandChangeGroupRow(dataItem) {
		Vue.set(dataItem, this.expandedField, dataItem[this.expandedField] === undefined ? false : !dataItem[this.expandedField]);
	}
	expandChange(dataItem) {
		Vue.set(dataItem, this.expandedField, dataItem[this.expandedField] === undefined ? true : !dataItem[this.expandedField]);
	}
	customSelectionChange(dataItem) {
		Vue.set(dataItem, this.customSelectedField, dataItem[this.customSelectedField] === undefined ? true : !dataItem[this.customSelectedField]);
	}

	//#region ** Delete Grid Item
	deleteGridRow(endpoint: string, itemID: number | string, title: string, message: string | VNode[]) {
		var self = this;
		self.$bvModal
			.msgBoxConfirm(message, {
				title: title,
				okVariant: "warning",
				okTitle: self.$i18n
					.t("btn.si")
					.toString()
					.toUpperCase(),
				cancelTitle: self.$i18n
					.t("btn.no")
					.toString()
					.toUpperCase(),
				cancelVariant: "outline-theme",
				footerClass: "",
				headerBgVariant: "warning"
				// centered: true
			})
			.then(value => {
				if (value) {
					api.deleteRowGriglia(endpoint, itemID)
						.then(res => {
							self.$bvToast.toast(self.$i18n.t("msg.cancellazioneriuscita").toString(), {
								variant: "success",
								title: self.$i18n.t("msg.cancellazioneok").toString(),
								solid: true
							});

							self.getData();
						})
						.catch(err => {
							console.log(err);
						});
				}
			})
			.catch(err => {
				// An error occurred
			});
	}
	//#endregion

	//#region ** Edit Grid Item
	setGridRow<T>(endpoint: string, item: T, title: string | VNode | VNode[], message: string | VNode | VNode[], isNew: boolean = true): Promise<operationResult<T>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			api.setGridRow<T>(endpoint, item, isNew)
				.then(res => {
					self.$bvToast.toast(message, {
						variant: "success",
						title: title,
						solid: true
					});

					self.getData();
					//self.showEditModal = false;
					resolve(res);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion

	//#region ** Get Property columns for Custom template **
	getColumsnDefinition(field: string): any {
		if (this.columns) {
			var c = this.columns.find(function (f) {
				return f.field == field;
			});
			return c || {};
		}
		return {};
	}

	getColumnType(field: string): string {
		var c = this.getColumsnDefinition(field);
		return c.type || "string";
	}

	getPathFallback(field: string): string {
		var c = this.getColumsnDefinition(field);
		return c.pathFallback || "";
	}

	idDisabledCell(field: string): boolean {
		var c = this.getColumsnDefinition(field);
		return c.disabled || false;
	}

	isColumnFilterable(field: string): boolean {
		var c = this.getColumsnDefinition(field);
		return c.filterable || false;
	}

	onSortChanged(e: any) {
		var field = e.sortBy || e.field;
		if (this.sort && this.sort.length > 0) {
			var existFieldSorted = this.sort.find(function (f) {
				return f.field == field;
			});
			if (existFieldSorted) {
				if (existFieldSorted.dir == "asc") {
					existFieldSorted.dir = "desc";
				} else if (existFieldSorted.dir == "desc") {
					var findIndexOfField = this.sort.findIndex(function (f) {
						return f.field == field;
					});
					this.sort.splice(findIndexOfField, 1);
				}
			} else {
				this.sort.push({ field: field, dir: "asc" });
			}
		} else {
			this.sort = [{ field: field, dir: "asc" }];
		}
	}

	onHeaderSwitchChange(newValue) {
		if (this.gridData && this.gridData.data) {
			for (var i = 0; i < this.gridData.data.length; i++) {
				let dataItem = this.gridData.data[i];
				Vue.set(dataItem, "_ck_select", newValue);
			}
		}
	}

	//#endregion

	//#region EXPORT

	exportGridExcel(endpoint: string, data: any = null) {
		//prima request a api e poi esport
		var columnsToExport = this.columns.filter(function (item) {
			return item.export == true;
		});

		if (columnsToExport && columnsToExport.length > 0) {
			var self = this;
			if (data) {
				var exportFileName = "Export";
				var pageName = this.$router.currentRoute.name;

				if (pageName) {
					exportFileName = `Export - ${pageName} - ${moment().format("YYYYMMDD")}`;
				}

				self.exportExcelCreate(data, exportFileName);
			} else {
				api.getDatiGriglia(endpoint, this.filter, this.sort, 0, 999999999, this.groupForSort)
					.then(res => {
						var exportFileName = "Export";
						var pageName = this.$router.currentRoute.name;

						if (pageName) {
							exportFileName = `Export - ${pageName} - ${moment().format("YYYYMMDD")}`;
						}
						self.exportExcelCreate(res.items, exportFileName);
					})
					.catch(err => {
						console.log(err);
						this.$bvToast.toast(this.$i18n.t("msg.exporterror").toString(), {
							variant: "danger",
							title: this.$i18n.t("generico.errore").toString(),
							solid: true
						});
					});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("msg.exportcolumnserror").toString(), {
				variant: "danger",
				title: this.$i18n.t("generico.configurationerror").toString(),
				solid: true
			});
		}
	}

	exportExcelCreate(dataItems: [], fileName?: string) {
		var self = this;
		let groupForSort = self.groupForSort;
		let data: any = dataItems;
		var columnsToExport = this.columns.filter(function (item) {
			return item.export == true;
		});
		var cols = helpers.cloneObject(columnsToExport);
		cols.forEach(item => (item.hidden = false));

		if (!data || data.length <= 0) {
			this.$bvToast.toast(this.$i18n.t("export.nodataexport").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
			return;
		}

		//righe del file da produrre
		let rows = [];

		//colonne del file da produrre
		let columnCells = {
			cells: []
		};
		let colsWidth = [];
		let colName = {};

		//Se esistono colonne da raggruppare, gestisco le stesse aggiungendole all'header del file
		//nel frattempo recupero anche i titoli che serviranno per le righe dedicate al raggruppamento
		if (groupForSort && groupForSort.length > 0) {
			for (var g = 0; g < groupForSort.length; g++) {
				let gfField = groupForSort[g].field;
				columnCells.cells.push({
					value: "",
					background: "#7A7A7A",
					color: "#fff"
				});

				colsWidth.push({ width: 20 });

				let ccO = cols.find(function (item) {
					return item.field == gfField;
				});
				colName[gfField] = ccO ? ccO.title : gfField;
			}
		}

		//Inserimento riga di HEADER
		for (var i = 0; i < cols.length; i++) {
			let col = cols[i];
			columnCells.cells.push({
				value: col.title,
				background: "#7A7A7A",
				color: "#fff"
			});

			colsWidth.push({ autoWidth: true });
		}
		rows.push(columnCells);

		if (groupForSort && groupForSort.length > 0) {
			//caso del group by
			let groupFields: string[] = groupForSort.map(function (item) {
				return item.field;
			});
			let groupFieldCount: number = groupFields.length;
			let gValue = {};

			//Inserimentod delle prima/e righe di raggruppamento
			for (var g = 0; g < groupFieldCount; g++) {
				let row = {
					cells: []
				};
				var gf = groupFields[g];

				var dataGroup = data[0][gf] || self.$i18n.t("gridvalue.nondefinito").toString();
				if (g > 0) {
					for (var x = 0; x < g; x++) {
						row.cells.push({
							value: "",
							background: "#d9d9d9"
						});
					}
				}

				row.cells.push({
					value: `${colName[gf]}: ${dataGroup}`,
					colSpan: cols.length + groupFieldCount - g,
					background: "#d9d9d9"
				});

				gValue[`${gf}`] = dataGroup;

				rows.push(row);
			}

			for (var i = 0; i < data.length; i++) {
				let row = {
					cells: []
				};
				var ccsd = [];

				//Valutazione del raggruppamento; cioè se cambia il valore raggruppato, vado a mettere una riga di raggruppamento
				for (var g = 0; g < groupFieldCount; g++) {
					ccsd.push({
						value: "",
						background: "#d9d9d9"
					});

					let gc = groupFields[g];
					let d = data[i][gc];

					if (gValue[gc] != (d || self.$i18n.t("gridvalue.nondefinito").toString())) {
						gValue[gc] = d;
						let gIndexInside = groupFields.indexOf(gc);

						if (gIndexInside > 0) {
							for (var x = 0; x < gIndexInside; x++) {
								row.cells.push({
									value: "",
									background: "#d9d9d9"
								});
							}
						}

						row.cells.push({
							value: `${colName[gc]}: ${d}`,
							colSpan: cols.length + groupFieldCount - gIndexInside,
							background: "#d9d9d9"
						});

						rows.push(row);

						row = {
							cells: []
						};
					}
				}

				//Gestione delle righe dei dati
				for (var j = 0; j < cols.length; j++) {
					let col = cols[j];
					let d = data[i][col.field];

					if (col.field) {
						let d = data[i][col.field];
						ccsd.push({
							value: d
						});
					}
				}

				row.cells = ccsd;
				rows.push(row);
			}
		} else {
			for (var i = 0; i < data.length; i++) {
				var row = {
					cells: []
				};
				for (var j = 0; j < cols.length; j++) {
					let col = cols[j];
					if (col.field) {
						let d = data[i][col.field];
						row.cells.push({
							value: d
						});
					}
				}
				rows.push(row);
			}
		}

		let workbook = new kendo.ooxml.Workbook({
			sheets: [
				{
					columns: colsWidth,
					// The title of the sheet.
					title: "Distributori",
					// The rows of the sheet.
					rows: rows
				}
			]
		});

		kendo.saveAs({ dataURI: workbook.toDataURL(), fileName: fileName || "Export.xlsx" });
	}

	get total() {
		return this.gridData ? this.gridData.length : 0;
	}

	get gridDataitems() {
		return process(this.gridData, { take: this.take, skip: this.skip, group: this.group, sort: this.sort, filter: (this.filter as any) });
	}

	//#endregion

	// get items() {
	// 	let items = process(this.gridData, { take: this.take, skip: 0, group: this.group });
	// 	return items;
	// 	//return this.gridData;
	// }
}
