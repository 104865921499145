var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('grid',{attrs:{"cell-render":'defaultCellTemplate',"data-items":_vm.gridData,"pageable":_vm.pageable,"filter":_vm.filter,"filterable":false,"resizable":true,"reorderable":true,"sortable":true,"sort":_vm.sort,"skip":_vm.skip,"take":_vm.take,"total":_vm.total,"columns":_vm.columns,"page-size":_vm.pageSize,"selectable":true,"selected-field":_vm.selectedRowField,"groupable":true,"group":_vm.group,"expand-field":_vm.expandedField,"detail":'detailTemplate'},on:{"pagechange":_vm.pageChangeHandler,"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange},scopedSlots:_vm._u([{key:"myHeaderCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('header-cell-template',_vm._b({attrs:{"tableName":'storicoinvii',"sort":_vm.sort},on:{"header-switch-change":_vm.onHeaderSwitchChange,"sort-changed":_vm.onSortChanged,"filter-apply":_vm.onFilterApply,"filter-remove":_vm.onFilterRemove}},'header-cell-template',props,false))]}},{key:"defaultCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('cell-template',_vm._b({on:{"expand":_vm.expandChange,"customSelect":_vm.customSelectionChange,"cell-click":function($event){return _vm.clickHandler(props.dataItem)}}},'cell-template',props,false))]}},{key:"detailTemplate",fn:function(ref){
var props = ref.props;
return [(!_vm.isTypeJSON)?_c('grid-storico-dettaglio',{attrs:{"itemId":props.dataItem.itemID}}):_c('grid-storico-dettaglio-json',{attrs:{"itemId":props.dataItem.itemID}})]}},{key:"downloadFileGeneratoCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',_vm._l((( props.dataItem.fileGenerato ? props.dataItem.fileGenerato.split(',') : [] )),function(fileName,index){return _c('a',{key:index,staticStyle:{"cursor":"pointer","color":"blue"},attrs:{"target":"_blank","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadFileGenerato(props.dataItem, fileName)}}},[(fileName)?[_vm._v(" "+_vm._s(fileName.endsWith("crea.txt") ? "Creazioni -" : fileName.endsWith("modifica.txt") ? "Modifiche -" : fileName.endsWith("annulla.txt") ? "Cancellazioni -" : ( props.dataItem.nomeFileGenerato || props.dataItem.jobId ) + "." + props.dataItem.fileGenerato.split('.').pop())+" ")]:_vm._e()],2)}),0)]}}])},[_c('grid-toolbar',[_c('b-row',{staticClass:"grid-toolbar",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"ml-2",attrs:{"title":_vm.$t('btn.aggiorna'),"variant":"info"},on:{"click":_vm.refreshData}},[_c('b-icon',{attrs:{"icon":"arrow-repeat"}}),_vm._v(" "+_vm._s(_vm.$t("btn.aggiorna"))+" ")],1),_c('b-button',{staticClass:"mx-1",attrs:{"title":_vm.$t('generico.esporta'),"variant":"success"},on:{"click":_vm.exportExcel}},[_c('b-icon',{attrs:{"icon":"file-earmark-excel-fill"}}),_vm._v(" "+_vm._s(_vm.$t("generico.esporta"))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }