export class GruppiStatiItem {
	constructor() {
		this.itemID = null;
		this.gruppoStato = null;
		this.descrizione = null;
		this.isDefault = null;
		this.codiceGruppo = null;
		this.esclusione = null;
		this.raggruppaRiepilogo = null;
		this.deleted = null;
	}
	itemID: number;
	gruppoStato: string;
	descrizione: string;
	isDefault: boolean | null;
	codiceGruppo: string;
	esclusione: boolean | null;
	raggruppaRiepilogo: string;
	deleted: boolean | null;
}