export class CommissioneBeneficiariItem {
	constructor() {
		this.itemID = null;
		this.beneficiario = null;
		this.tipoDistributoreID = null;
		this.tipoDistributore = null;
	}
	itemID: number;
	beneficiario: string;
	tipoDistributoreID: number | null;
	tipoDistributore: string | null;
}