"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridFilterCell = exports.operators = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var messages_1 = require("../messages");

var kendo_vue_dropdowns_1 = require("@progress/kendo-vue-dropdowns");

var kendo_vue_inputs_1 = require("@progress/kendo-vue-inputs");

var kendo_vue_dateinputs_1 = require("@progress/kendo-vue-dateinputs");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");

var filterCommon_1 = require("../filterCommon");

Object.defineProperty(exports, "operators", {
  enumerable: true,
  get: function get() {
    return filterCommon_1.operators;
  }
});

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridFilterCell` component.
 */


var GridFilterCell = {
  props: {
    grid: Object,
    field: String,
    filterType: String,
    colSpan: Number,
    value: [String, Number, Boolean, Date],
    operator: [String, Function],
    onChange: Function,
    render: [String, Function, Object]
  },
  inject: {
    kendoLocalizationService: {
      default: null
    },
    kendoIntlService: {
      default: null
    }
  },
  methods: {
    inputChange: function inputChange(value, e) {
      var filter = filterCommon_1.cellInputChange(value, e, this.$props);
      this.triggerChange(filter);
    },
    operatorChange: function operatorChange(operatorValue, e) {
      var filter = filterCommon_1.cellOperatorChange(operatorValue.value.operator, e, this.$props.value);
      this.triggerChange(filter);
    },
    boolDropdownChange: function boolDropdownChange(value, e) {
      var filter = filterCommon_1.cellBoolDropdownChange(value.value.operator, e);
      this.triggerChange(filter);
    },
    clear: function clear(e) {
      e.preventDefault();
      this.triggerChange({
        value: '',
        operator: '',
        event: e
      });
    },
    triggerChange: function triggerChange(filter) {
      filter.field = this.$props.field;
      this.$emit('change', filter);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoIntlService = inject('kendoIntlService', {});
    var kendoLocalizationService = inject('kendoLocalizationService', {});
    return {
      v3: v3,
      kendoIntlService: kendoIntlService,
      kendoLocalizationService: kendoLocalizationService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var localizationService = kendo_vue_intl_1.provideLocalizationService(this);
    var dataItems = filterCommon_1.operatorMap(filterCommon_1.operators[this.$props.filterType], localizationService);
    var selectedOperator = dataItems.find(function (item) {
      return item.operator === _this.$props.operator;
    }) || null;

    var renderOperatorEditor = function renderOperatorEditor() {
      if (this.$props.filterType === 'boolean') {
        return;
      }

      return (// @ts-ignore
        h(kendo_vue_dropdowns_1.DropDownList, {
          onChange: this.operatorChange,
          on: this.v3 ? undefined : {
            "change": this.operatorChange
          },
          value: selectedOperator,
          attrs: this.v3 ? undefined : {
            value: selectedOperator,
            iconClassName: "k-i-filter k-icon",
            "data-items": dataItems,
            textField: "text",
            popupSettings: {
              width: ''
            }
          },
          "class": "k-dropdown-operator",
          iconClassName: "k-i-filter k-icon",
          "data-items": dataItems,
          textField: "text",
          popupSettings: {
            width: ''
          }
        })
      );
    };

    var filterComponent = function filterComponent(filterType, value) {
      var _this = this;

      switch (filterType) {
        case 'numeric':
          return (// @ts-ignore
            h(kendo_vue_inputs_1.NumericTextBox, {
              value: value,
              attrs: this.v3 ? undefined : {
                value: value
              },
              onChange: function onChange(e) {
                _this.inputChange(e.value, e.event);
              },
              on: this.v3 ? undefined : {
                "change": function onChange(e) {
                  _this.inputChange(e.value, e.event);
                }
              }
            })
          );

        case 'date':
          return (// @ts-ignore
            h(kendo_vue_dateinputs_1.DatePicker, {
              value: value,
              attrs: this.v3 ? undefined : {
                value: value
              },
              onChange: function onChange(e) {
                _this.inputChange(e.value, e);
              },
              on: this.v3 ? undefined : {
                "change": function onChange(e) {
                  _this.inputChange(e.value, e);
                }
              }
            })
          );

        case 'boolean':
          var noFilterSet_1 = function noFilterSet_1(filter) {
            return filter === null || filter === undefined;
          };

          var booleanValues = filterCommon_1.operatorMap(filterCommon_1.operators.boolean, localizationService);
          return (// @ts-ignore
            h(kendo_vue_dropdowns_1.DropDownList, {
              onChange: this.boolDropdownChange,
              on: this.v3 ? undefined : {
                "change": this.boolDropdownChange
              },
              value: booleanValues.find(function (item) {
                return item.operator === (noFilterSet_1(value) ? '' : value);
              }),
              attrs: this.v3 ? undefined : {
                value: booleanValues.find(function (item) {
                  return item.operator === (noFilterSet_1(value) ? '' : value);
                }),
                "data-items": booleanValues,
                textField: "text"
              },
              "data-items": booleanValues,
              textField: "text"
            })
          );

        default:
          return h("input", {
            "class": "k-textbox",
            value: this.v3 ? value || '' : null,
            domProps: this.v3 ? undefined : {
              "value": value || ''
            },
            onInput: function onInput(e) {
              _this.inputChange(e.target.value, e);
            },
            on: this.v3 ? undefined : {
              "input": function onInput(e) {
                _this.inputChange(e.target.value, e);
              }
            }
          });
      }
    };

    var defaultRendering = h("div", {
      "class": "k-filtercell"
    }, [h("div", {
      "class": "k-filtercell-wrapper"
    }, [filterComponent.call(this, this.$props.filterType, this.$props.value), h("div", {
      "class": "k-filtercell-operator"
    }, [renderOperatorEditor.call(this), h("button", {
      "class":
      /* button is always visible if there is either value or operator */
      !(this.$props.value === null || this.$props.value === '') || this.$props.operator ? 'k-button k-button-icon k-clear-button-visible' : 'k-button k-button-icon',
      title: localizationService.toLanguageString(messages_1.filterClearButton, messages_1.messages[messages_1.filterClearButton]),
      attrs: this.v3 ? undefined : {
        title: localizationService.toLanguageString(messages_1.filterClearButton, messages_1.messages[messages_1.filterClearButton]),
        type: "button"
      },
      type: "button",
      onClick: this.clear,
      on: this.v3 ? undefined : {
        "click": this.clear
      }
    }, [h("span", {
      "class": "k-icon k-i-filter-clear"
    })])])])]);
    var gridListeners = this.$props.grid ? kendo_vue_common_1.getListeners.call(this.$props.grid) : null;
    var cellRenderFunction = kendo_vue_common_1.templateRendering.call(this.$props.grid, this.$props.render, gridListeners);
    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: cellRenderFunction,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        change: this.triggerChange
      }
    });
  }
};
exports.GridFilterCell = GridFilterCell;