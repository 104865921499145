export default class CommissioneModifica {
		/**
		 *
		 */
		constructor() {
			this.itemID = 0;
			this.commercialeID = null;
			this.distributoreID = null;
			this.prodottoID = null;
			this.pacchettoID = null;
			this.tipoCommissioneID = null;
			this.commissione = null;
			this.startDate = null;
			this.endDate = null;
		}

        itemID: number | null;
        commercialeID: number | null;
        distributoreID: number | null;
        prodottoID: number | null;
        pacchettoID: number | null;
        tipoCommissioneID: number | null;
        commissione: number | null;
        startDate: Date | string | null;
        endDate: Date | string | null;
    }