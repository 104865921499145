export class DistributoreAssociatoAnagraficaEdit {
	constructor() {
		this.itemID = 0;
		this.ragioneSociale = "";
		this.cancellato = false;
		this.commercialeID = 0;
		this.distributoreID = 0;
		this.dataInizioRapporto = null;
		this.dataFineRapporto = null;

		this.tipoDistributore = "";
		this.regione = "";
		this.provincia = "";

		this.commissioneTipoID = null;
		this.commissione = null;
	}

	itemID: number;
	ragioneSociale: string;
	cancellato: boolean | null;
	commercialeID: number;
	distributoreID: number;
	dataInizioRapporto: Date | string | null;
	dataFineRapporto: Date | string | null;

	tipoDistributore: string;
	regione: string;
	provincia: string;

	commissioneTipoID: number | null;
	commissione: number | null;
}