import helpers from "@/helpers";
import { datiViaggio, totaliCarrello } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { ParametriCarrelloItem } from "@/models/carrello/parametriCarrello";
import { parametriPreventivatoreItem } from "@/models/carrello/parametriPreventivatore";
import { TextValueExtended } from "@/models/TextValueItem";
import api from "@/services/api";
import { relativeTimeRounding } from "moment";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import baseUserContextComponent from "../baseUserContextComponent";

const _ENDPONT_TOTALI = api.endpoint.CARRELLO.TOTALI;

@Component({
	computed: {
		...mapState({
			datiViaggio: (state: any) => state.datiViaggio,
			prodottoSelezionato: (state: any) => state.prodottoSelezionato
		})
	},
	methods: {
		...mapMutations(["setDatiViaggio"])
	}
})

export default class complementare extends baseUserContextComponent {
	expand: boolean = false;
	addComplemetare: boolean = false;

	showDestinazione: boolean = false;
	showImporto: boolean = false;
	showNazionalita: boolean = false;

	nazionalita: TextValueExtended[] = [];
	destinazioni: TextValueExtended[] = [];

	nazionalitaID: number = 0;
	destinazioneID: number = 0;
	importoViaggio: number = 0;

	datiViaggio !: datiViaggio;
	prodottoSelezionato !: CarrelloInfoProdotto;

	totali: totaliCarrello | null = null;
	setDatiViaggio!: (setDatiViaggio: datiViaggio) => void;

	@Prop({ default: () => undefined }) complementare: CarrelloInfoProdotto;
	@Prop({ default: () => true }) showImportoViaggio: boolean;
	@Prop({ default: () => true }) showDestinazioneViaggio: boolean;
	@Prop({ default: () => true }) showNazionalitaViaggio: boolean;
	@Prop({ default: () => null }) defaultImportoViaggio: number;
	@Prop({ default: () => null }) defaultDestinazioneViaggio: number;
	@Prop({ default: () => null }) defaultNazionalitaViaggio: number;

	get showInputImportViaggio(): boolean {
		return this.showImporto && this.addComplemetare && this.showImportoViaggio;
	}
	get showInputDestinazione(): boolean {
		return this.showDestinazione && this.addComplemetare && this.showDestinazioneViaggio;
	}
	get showInputNazionalita(): boolean {
		return this.showNazionalita && this.addComplemetare && this.showNazionalitaViaggio;
	}
	get showArrow(): boolean {
		return this.showInputImportViaggio || this.showInputDestinazione || this.showInputNazionalita;
	}
	@Watch("datiViaggio.parametri.importoViaggio", { immediate: true })
	updateImporto() {

		this.importoViaggio = this.datiViaggio.parametri.importoViaggio;
	}
	@Watch("datiViaggio.parametri.destinazioneStato", { immediate: true })
	updateDestinazioneStato() {

		this.destinazioneID = this.datiViaggio.parametri.destinazioneStato;
	}

	isObbligatorio() {
		if (!this.complementare)
			return false;

		if (helpers.getExtraInfoCarrello(this.complementare.extraInfo, helpers.tipiExtraInfo.complementareObbligatorio))
			return true;
		return false;
	}

	beforeDestroy() { }
	beforeMount() { }
	mounted() { }
	beforeCreate() { }
	created() {
		this.initComplementare();
		//(this.$parent as any).calcolaPreventivo();
	}

	initComplementare() {
		if (this.complementare.destinazioni && this.complementare.destinazioni.length) {
			this.destinazioni = this.complementare.destinazioni.map(function (obj) {
				var a = new TextValueExtended();
				a.value = obj.code;
				a.text = obj.nazione;
				return a;
			});
		}

		if (this.complementare.nazionalita && this.complementare.nazionalita.length) {
			this.nazionalita = this.complementare.nazionalita.map(function (obj) {
				var a = new TextValueExtended();
				a.value = obj.codice;
				a.text = obj.nazione;
				return a;
			});
		}

		this.checkVisibilitaParametri();

		this.importoViaggio = this.datiViaggio.parametri.importoViaggio;

		var currPCollegato = this.datiViaggio.prodottiCollegati.find((obj) => obj.pacchettoID == this.complementare.pacchettoID);

		if (currPCollegato) {
			if (currPCollegato.nazionalitaID)
				this.nazionalitaID = this.nazionalita.find(obj => obj.value == (currPCollegato.nazionalitaID as any)) as any;

			if (currPCollegato.destinazioneID)
				this.destinazioneID = this.destinazioni.find(obj => obj.value == (currPCollegato.destinazioneID as any)) as any;

			this.addComplemetare = true;
			this.expand = true
			this.calcolaTotali(false);
		}
		else if (this.isObbligatorio()) {
			this.addComplemetare = false;
			this.onAddComplemetare();
		}

	}

	checkVisibilitaParametri() {
		// mostro destinazione se quella scelta dall'utente non è presente nell'elenco del complementare
		var trovato = false,
			currDestID = this.datiViaggio.parametri.destinazioneStato ? (this.datiViaggio.parametri.destinazioneStato as any).code || "" : "";
		for (var i = 0; i < this.destinazioni.length; i++) {
			if (this.destinazioni[i].value === currDestID) {
				trovato = true;
				break;
			}
		}

		this.showDestinazione = !trovato;

		// mostro nazionalia se quella scelta dall'utente non è presente nell'elenco del complementare
		var trovato = false,
			currNazID = this.datiViaggio.parametri.nazionalita ? (this.datiViaggio.parametri.nazionalita as any).value || 0 : 0;
		for (var i = 0; i < this.nazionalita.length; i++) {
			if (this.nazionalita[i].value === currNazID) {
				trovato = true;
				break;
			}
		}

		this.showNazionalita = !trovato;

		if (helpers.getExtraInfoCarrello(this.complementare.extraInfo, helpers.tipiExtraInfo.importoViaggioObbligatorio)) {
			this.showImporto = true;
			this.importoViaggio = this.datiViaggio.parametri.importoViaggio;
		}
		else {
			this.showImporto = false;
		}
	}

	onAddComplemetare() {
		this.addComplemetare = !this.addComplemetare;

		var currPCollegato = this.datiViaggio.prodottiCollegati.find((obj) => obj.pacchettoID == this.complementare.pacchettoID);

		if (!currPCollegato && this.addComplemetare) {
			this.datiViaggio.prodottiCollegati.push({ pacchettoID: this.complementare.pacchettoID });
		}
		else if (currPCollegato && !this.addComplemetare) {
			var index = this.datiViaggio.prodottiCollegati.findIndex((obj) => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati.splice(index, 1);
			this.destinazioneID = 0;
			this.nazionalitaID = 0;
			this.importoViaggio = 0;
		}

		this.setDatiViaggio(this.datiViaggio);

		this.calcolaTotali(true);
		if (this.addComplemetare)
			this.expand = true;
		else
			this.expand = false;
	}

	async calcolaTotali(validaDati) {
		var self = this;
		return new Promise(async function (resolve, reject) {
			if (!self.addComplemetare) {
				self.totali = null;
				resolve("");
				return;
			}
			var parent = self.$parent as any;

			if (validaDati && !parent.validaDatiPreventivo()) {
				resolve("");
				return null;
			}

			var datiProdotto = parent.getDati();
			datiProdotto.pacchettoID = self.complementare.pacchettoID;

			var datiCompl = self.getDati();
			if (datiCompl.nazionalitaID)
				datiProdotto.nazionalitaID = datiCompl.nazionalitaID;

			if (datiCompl.statoID)
				datiProdotto.statoID = datiCompl.statoID;

			if (!self.validaDati()) {
				resolve("");
				return;
			}

			/*if (!self.getExtraInfo("ApplicazioneAlloggio") && !self.getExtraInfo("ApplicazioneImbarcazione"))
				delete datiProdotto.NumeroAlloggi;*/

			var tot = await api.chiamataPost<totaliCarrello>(_ENDPONT_TOTALI, datiProdotto);

			if (!tot || !tot.data) {
				alert("Dati non congruenti per il prodotto complementare. Contattare Webins");
				resolve("");
				return;
			}

			self.totali = tot.data;
			resolve(tot.data);
			//parent.calcolaPreventivo();
		});

	}

	validaDati() {
		var datiCompl = this.getDati();
		var err = [];

		if (this.showInputDestinazione && !datiCompl.statoID) {
			err.push("Destinazione richiesta per il complementare");
		}
		if (this.showInputNazionalita && !datiCompl.nazionalitaID) {
			err.push("Nazionalità richiesta per il complementare");
		}
		if (this.showInputImportViaggio && !this.importoViaggio) {
			err.push("Importo viaggio richiesto per il complementare");
		}

		for (var i = 0; i < err.length; i++)
			this.$bvToast.toast(err[i], { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });

		return err.length == 0;
	}

	getDati(): ParametriCarrelloItem {
		var dati = {} as ParametriCarrelloItem;
		var currPCollegato = this.datiViaggio.prodottiCollegati.find((obj) => obj.pacchettoID == this.complementare.pacchettoID);
		if (this.showInputDestinazione)
			dati.statoID = currPCollegato.destinazioneID || this.defaultDestinazioneViaggio;
		else
			dati.statoID = this.defaultDestinazioneViaggio;

		if (this.showInputNazionalita)
			dati.nazionalitaID = currPCollegato.nazionalitaID || this.defaultNazionalitaViaggio;
		else
			dati.nazionalitaID = this.defaultNazionalitaViaggio;

		if (this.showInputImportViaggio)
			dati.importoViaggio = this.importoViaggio;
		else
			dati.importoViaggio = this.defaultImportoViaggio;

		return dati;
	}

	changeDestinazione() {
		var currPCollegato = this.datiViaggio.prodottiCollegati.find((obj) => obj.pacchettoID == this.complementare.pacchettoID);
		if (this.addComplemetare) {
			currPCollegato.destinazioneID = (this.destinazioneID as any).value || "";
			var index = this.datiViaggio.prodottiCollegati.findIndex((obj) => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati[index] = currPCollegato;
			this.setDatiViaggio(this.datiViaggio);
			this.calcolaTotali(true);
		}
	}

	changeImportoViaggio() {
		//var currPCollegato = this.datiViaggio.prodottiCollegati.find((obj) => obj.pacchettoID == this.complementare.pacchettoID);
		if (this.addComplemetare) {
			/*currPCollegato.importoViaggio = this.importoViaggio || 0;
			var index = this.datiViaggio.prodottiCollegati.findIndex((obj) => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati[index] = currPCollegato;*/

			this.datiViaggio.parametri.importoViaggio = this.importoViaggio;
			this.setDatiViaggio(this.datiViaggio);
			this.calcolaTotali(true);
		}
	}

	changeNazionalita() {
		var currPCollegato = this.datiViaggio.prodottiCollegati.find((obj) => obj.pacchettoID == this.complementare.pacchettoID);
		if (this.addComplemetare) {
			currPCollegato.nazionalitaID = (this.nazionalita as any).value || "";
			var index = this.datiViaggio.prodottiCollegati.findIndex((obj) => obj.pacchettoID == this.complementare.pacchettoID);
			this.datiViaggio.prodottiCollegati[index] = currPCollegato;
			this.setDatiViaggio(this.datiViaggio);
			this.calcolaTotali(true);
		}
	}

	onLeggiDiPiu() {
		var message = this.$createElement("div", {
			domProps: { innerHTML: this.complementare.descrizioneBreve + "<br>" + "<a target='_blank' href='https://www.webins.it" + this.complementare.pdfTerminiECondizioni + "' >Termini e Condizioni</a>" + "<br>" + this.complementare.descrizioneEstesa }
		});

		var title = this.complementare.pacchetto;

		this.$bvModal.msgBoxOk([message], {
			title: title,
			okVariant: "",
			okTitle: "Prosegui con gli acquisti",
			cancelVariant: "outline-theme",
			footerClass: "",
			headerBgVariant: ""
			// centered: true
		})
	}
}