export class GruppiStatoPerFornitore {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.fornitoreID = 0;
		this.gruppoStatiID = 0;
		this.gruppoStato = "";
		this.gruppoStatoA3 = "";
		this.descrizione = "";
		this.codiceRiepilogo = "";
		this.descrizioneRiepilogo = "";				
	}

	itemID: number;
	fornitoreID: number | null;
	gruppoStatiID: number | null;
	gruppoStato: string;
	gruppoStatoA3: string;
	descrizione: string;
	codiceRiepilogo: string;
	descrizioneRiepilogo: string;
}
