"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Footer = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var ref = allVue.ref;

var browser_support_service_1 = require("../utils/browser-support.service");

var utils_1 = require("../utils");

var Footer = {
  name: 'Footer',
  props: {
    staticHeaders: Boolean,
    row: Object,
    columnResize: Object,
    cols: Array
  },
  data: function data() {
    return {
      scrollbarWidth: 0,
      rtl: false,
      tableWidth: null
    };
  },
  computed: {
    divStyle: {
      get: function get() {
        var padding = this.$data.scrollbarWidth + 'px';
        var right = this.rtl ? 0 : padding;
        var left = this.rtl ? padding : 0;
        return {
          padding: "0 " + right + " 0 " + left
        };
      }
    },
    tableStyle: {
      get: function get() {
        return this.tableWidth ? {
          width: this.tableWidth
        } : null;
      }
    }
  },
  mounted: function mounted() {
    this.$data.scrollbarWidth = new browser_support_service_1.BrowserSupportService().scrollbarWidth;
    this.rtl = utils_1.isRtl(this.$el);
    this.$props.columnResize.colGroupFooter = this.v3 ? this.colGroupHeaderRef : this.$refs.colGroupHeader;
    this._footerWrap = this.v3 ? this.footerWrapRef : this.$refs.footerWrap;
  },
  methods: {
    setScrollLeft: function setScrollLeft(scrollLeft) {
      if (this._footerWrap) {
        this._footerWrap.scrollLeft = scrollLeft;
      }
    },
    setWidth: function setWidth(width) {
      this.$data.tableWidth = width + 'px';
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var footerWrapRef = ref(null);
    var colGroupHeaderRef = ref(null);
    return {
      v3: v3,
      colGroupHeaderRef: colGroupHeaderRef,
      footerWrapRef: footerWrapRef
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;

    if (!this.$props.staticHeaders) {
      return h("tfoot", {
        "class": "k-grid-footer"
      }, [this.$props.row]);
    }

    return h("div", {
      "class": "k-grid-footer",
      style: this.divStyle
    }, [h("div", {
      "class": "k-grid-footer-wrap",
      ref: this.v3 ? function (el) {
        _this.footerWrapRef = el;
      } : 'footerWrap'
    }, [h("table", {
      style: this.tableStyle
    }, [h("colgroup", {
      ref: this.v3 ? function (el) {
        _this.colGroupHeaderRef = el;
      } : 'colGroupHeader'
    }, [this.$props.cols]), h("tfoot", [this.$props.row])])])]);
  }
};
exports.Footer = Footer;