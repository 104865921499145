import helpers from "@/helpers";
import CustomEditAction from "@/models/customEditAction";
import { column } from "@/models/grid/columnModels";
import moment from "moment";
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { filterBy } from '@progress/kendo-data-query';
import editFormGrop from "./editFormGroup.vue";

@Component({})
export default class editForm extends Vue {
	@Prop({ default: true })
	canEdit: boolean;
	@Prop({ default: true })
	modalShown: boolean;
	@Prop({ default: () => 4 })
	labelCols: number;
	// get contentCols(): number {
	// 	return 12 - this.labelCols;
	// }
	@Prop({ default: () => [] })
	columns: column[];
	@Prop({ default: () => { } })
	value: any;

	// @Watch('value', { deep: true })
	// onValueChange(oldValue, newValue) {
	// 	var self = this;
	// 	debugger;
	// 	self.item = Object.assign({}, self.value || {});
	// }
	// @Watch("item", { deep: true })
	// onItemChange(oldValue, newValue) {
	// 	var self = this;
	// 	self.$emit("input", self.item);
	// }
	// item: any = {};

	get item() {
		return this.value || {};
	}
	set item(value) {
		var self = this;
		self.$emit("input", value);
	}
	// set item()
	get definitions(): column[] {
		var self = this;
		if (self.columns == null) return [];

		return self.columns.filter(x => x.editHide != true && x.field != null && x.field != "" && x.field.startsWith("_") == false).sort((a, b) => ((a.editOrder || 0) >= (b.editOrder || 0) ? 1 : -1));
	}

	validate(){
		var errors = [];
		var elements = (this.$refs.forms || []) as editFormGrop[]; 
		for( var i = 0; i < elements.length; i++){
			var v = elements[i].validate();
			if( !v)
				errors.push(elements[i].validation.msg || elements[i].field);
		}
		return errors.length ? errors : null;
	}

}
