import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { DescrizioniProdottiItem } from "@/models/prodotti/descrizioniProdottiItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.PRODOTTI.DESCRIZIONI_PRODOTTI;
@Component({})
export default class DescrizioniProdotti extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Prodotti.PRODOTTI]; }
	@Prop({ default: () => null }) pacchettoID: number;
	editItem: DescrizioniProdottiItem = new DescrizioniProdottiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	modalShown: boolean = false;

	tipoDescrizione: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "pacchettoID", title: "ID", width: 20, filterable: false, hidden: true, values: [this.pacchettoID], headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "tipoDescrizioneID",
				title: this.$i18n.t("generico.tipoDescrizione").toString(),
				values: this.tipoDescrizione,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				sortBy: "tipoDescrizione",
				filterType: "select",
				itemsForFilter: this.tipoDescrizione,
				editType: "select",
				editSource: this.tipoDescrizione
			},
			{ field: "descrizione", encoded: false, title: this.$i18n.t("generico.descrizione").toString(), groupable: true, headerCell: "myHeaderCellTemplate", type: "renderfunction", editType: "editor" },
			{
				field: "_actions",
				title: " ",
				width: (50 + (this.canDelete ? 50 : 0)),
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
			}
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPO_DESCRIZIONI).then(res => { 
			self.tipoDescrizione = (res as TextValueItem[]).map(obj => {
				obj.text = self.$i18n.t("tipodescrizioni." + obj.text.toLowerCase()) as string;
				return obj;
			});
			(self.tipoDescrizione = res)
		});

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.pacchettoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.descrizione")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new DescrizioniProdottiItem();
		newItem.pacchettoID = this.pacchettoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new DescrizioniProdottiItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.descrizione") }).toString();
		self.setGridRow<DescrizioniProdottiItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	onModalShown() {
		this.modalShown = true;
	}

	onModalHidden() {
		this.modalShown = false;
	}
}
