export class TestiCarrelloProdottiItem {
	constructor() {
		this.itemID = null;
		this.pacchettoID = null;
		this.tipoID = null;
		this.tipo = null;
		this.testo = null;
	}
	itemID: number;
	pacchettoID: number | null;
	tipoID: number | null;
	tipo: string;
	testo: string;
}