export class DescrizioniProdottiItem {
	constructor() {
		this.itemID = null;
		this.pacchettoID = null;
		this.descrizione = null;
		this.tipoDescrizioneID = null;
		this.tipoDescrizione = null;
	}
	itemID: number;
	pacchettoID: number;
	descrizione: string;
	tipoDescrizioneID: number | null;
	tipoDescrizione: string;
}