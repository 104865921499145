export class SchedaPolizzaItem {
	constructor() {
		this.prodottoID = null;
		this.schedaParametroID = null;
		this.valore = null;
		this.nomeParametro = null;
		this.tipo = null;
		this.note = null;
		this.nomeXML = null;
		this.descrizione = null;
		this.categoria = null;
		this.attivo = null;
		this.valori = [];
		this.fieldType = 0;
	}
	prodottoID: number;
	schedaParametroID: number;
	valore: any;
	nomeParametro: string;
	tipo: number | null;
	note: string;
	nomeXML: string;
	descrizione: string;
	categoria: string;
	attivo: boolean;
	valori: Array<SchedaPolizzaParametriValori>;
	fieldType: number | null;
}
export class SchedaPolizzaValore {
	constructor() {
		this.prodottoID = null;
		this.schedaParametroID = null;
		this.valore = null;
		this.tipo = null;
		this.attivo = null;
		this.fieldType = 0;
	}
	prodottoID: number;
	schedaParametroID: number;
	valore: any;
	tipo: number | null;
	attivo: boolean;
	fieldType: number | null;
}
export class SchedaPolizzaParametriValori {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.anagraficaProdottoParametriID = null;
		this.anagraficaProdottoParametriCodice = null;
		this.schedaParametroID = null;
		this.valore = null;
		this.attivo = null;
	}
	itemID: number | null;
	prodottoID: number | null;
	anagraficaProdottoParametriID: number;
	anagraficaProdottoParametriCodice: string;
	schedaParametroID: number;
	valore: string;
	attivo: boolean | null;
}