"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridColumnMenuItemGroup = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * The GridColumnMenuItemGroup that will be used inside the Grid ColumnMenu.
 */


var GridColumnMenuItemGroup = {
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
    return h("div", {
      "class": "k-columnmenu-item-wrapper"
    }, [defaultSlot]);
  }
};
exports.GridColumnMenuItemGroup = GridColumnMenuItemGroup;