import { Component, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";
import { CambioPasswordModel, UserLoggedInfoModel } from "@/models/userModels";
import { RouteRecordPublic } from "vue-router";
import * as s from "@/router/router";
import { appRedirectTypes, appRoute } from "@/models/appRoute";
import eventHub from "@/eventHub";
import api from "@/services/api";
import permessi from "@/config/permessi";

@Component({
	methods: {
		...mapGetters(["getToken"]),
		...mapMutations(["setAppCulture"]),
		...mapActions(["logoutUser", "loginUser"])
	}
})
export default class topNavBar extends baseUserContextComponent {
	showCambioPasswordModal: boolean = false;
	showModalAccediCome: boolean = false;
	@Watch("showCambioPasswordModal")
	onShowCambioPasswordModalChange(newValue: boolean, oldValue: boolean) {
		this.cambioPwd = new CambioPasswordModel();
	}
	cambioPwd: CambioPasswordModel = new CambioPasswordModel();
	setAppCulture!: (culture: string | null) => void;
	updateUserInfo!: () => Promise<UserLoggedInfoModel>;
	logoutUser!: () => Promise<boolean>;
	loginUser!: (token: any) => Promise<UserLoggedInfoModel>;
	getToken: () => string;
	doLogout() {
		this.logoutUser().finally(() => this.$router.push({ name: "login" }));
	}
	beforeDestroy() { }
	beforeMount() { }
	mounted() { }
	beforeCreate() { }
	created() {
		var self = this;
		this.getUserSignInAs();
	}
	getUserSignInAs() { }

	get logoPath() {
		return `${this.$appConfig.baseUrl}logo.jpg`;
		// return `${process.env.BASE_URL}logo.jpg`;
		//return   `${process.env.VUE_APP_BASE_URL}/logo.jpg`;
	}
	get items() {
		var self = this;
		return self.routes.filter(x => x.meta != null && x.meta.topNav != null && self.getRouteAllowedChildren(x).length > 0).sort((a, b) => (a.meta.topNav.displayOrder > b.meta.topNav.displayOrder ? 1 : -1));
	}
	get showButtonAccedicome(): boolean {
		var self = this;
		return self.hasPermesso(permessi.BK.Impostazioni.ACCEDICOME);
	}
	getLabel(r: appRoute): string {
		return (r.meta || {}).label;
	}
	getPath(r: appRoute): string {
		let c = this.getPermissionsFirstChild(r);
		return c.path;
	}
	getActiveClass(r: appRoute): string {
		// per ora ci fermiamo a due livelli di navigazione
		var self = this;
		let name = self.$route.name;
		let asctive = self.getRouteAllowedChildren(r).some(x => x.name == name);
		if (asctive)
			return 'router-link-active text-' + this.variant;
		else
			return '';
	}
	getPermissionsFirstChild(r: appRoute): appRoute {
		if (r == null)
			return null;
		var self = this;
		let children = self.getRouteAllowedChildren(r);
		return children != null && children.length > 0 ? children[0] : null;
	}
	onChangeCulture(culture: string) {
		this.setAppCulture(culture);
	}
	onAccediComeModalShow() {
		this.showModalAccediCome = true;
	}
	onCambiaPassword() {
		this.showCambioPasswordModal = true;
	}
	onCambiaPasswordConferma() {
		var self = this;

		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.t("msg.passwordCambiata").toString();
		api.doCambiaPassword(self.cambioPwd).then(token => {
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});
			this.showCambioPasswordModal = false;
		});
	}
	onDisconnectFromUser() {
		var self = this;
		api.disconnectFromSignInAs()
			.then(data => {
				let path = "/";
				self.loginUser(data.data).then(() => {
					// if (self.$route.fullPath != null && self.$route.fullPath != "" && self.$route.fullPath != "/") {
					// 	self.$router.push({ path: path as string });
					// } else {
					// 	self.$emit("user-changed", true);
					// }
					if (self.$route.meta
						&& self.hasPermessi(self.$route.meta.permissions) == false
						&& self.$route.fullPath != null
						&& self.$route.fullPath != ""
						&& self.$route.fullPath != "/") {
						self.$router.push({ path: path as string });
					}
				});
			})
			.catch(err => {
				console.log(err);
			});
	}

	get carrelloUrl() {
		return `${this.$appConfig.carrelloUrl}/login?tk=${this.getToken()}&returnUrl=carrello`;
	}

	carrelloUrlClick() {
		window.location.href = `${this.$appConfig.carrelloUrl}/login?tk=${this.getToken()}&returnUrl=carrello`;
		return;
	}
	onAccediComeChanged() {
		var self = this;
		self.showModalAccediCome = false

		// dato che si possono impersonare solo utenti del carrelo forse ha più senso madare direttamente al carrello
		window.location.href = self.carrelloUrl;


		// let path = "/";
		// // per ora non facciamo contgrolli su padre... perchè non è previsto che ne abbiano
		// if (self.$route.meta
		// 	&& self.hasPermessi(self.$route.meta.permissions) == false
		// 	&& self.$route.fullPath != null
		// 	&& self.$route.fullPath != ""
		// 	&& self.$route.fullPath != "/") {
		// 	self.$router.push({ path: path as string });
		// }
	}
}
