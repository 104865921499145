"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PagerNumericButtons = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
/**
 * Represents the default `PagerNumericButtons` component.
 */

var PagerNumericButtons = {
  props: {
    buttonCount: Number,
    totalPages: Number,
    currentPage: Number,
    pageChange: Function
  },
  computed: {
    start: {
      get: function get() {
        var page = this.$props.currentPage;
        var buttonCount = this.$props.buttonCount;

        if (page > buttonCount) {
          var reminder = page % buttonCount;
          return reminder === 0 ? page - buttonCount + 1 : page - reminder + 1;
        }

        return 1;
      }
    },
    end: {
      get: function get() {
        return Math.min(this.start + this.$props.buttonCount - 1, this.$props.totalPages);
      }
    }
  },
  methods: {
    click: function click(e, page) {
      e.preventDefault();
      this.$emit('pageChange', page, e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var prevDots = this.start > 1 ? h("li", [h("a", {
      "class": "k-link",
      onClick: function onClick(e) {
        return _this.click(e, _this.start - 1);
      },
      on: this.v3 ? undefined : {
        "click": function onClick(e) {
          return _this.click(e, _this.start - 1);
        }
      },
      href: "#",
      attrs: this.v3 ? undefined : {
        href: "#"
      }
    }, ["..."])]) : '';
    var postDots = this.end < this.$props.totalPages ? h("li", [h("a", {
      "class": "k-link",
      onClick: function onClick(e) {
        return _this.click(e, _this.end + 1);
      },
      on: this.v3 ? undefined : {
        "click": function onClick(e) {
          return _this.click(e, _this.end + 1);
        }
      },
      href: "#",
      attrs: this.v3 ? undefined : {
        href: "#"
      }
    }, ["..."])]) : '';
    var buttons = [];

    for (var idx = this.start; idx <= this.end; idx++) {
      buttons.push(idx);
    }

    var numerics = buttons.map(function (page) {
      var _this = this;

      return h("li", {
        key: page
      }, [h("a", {
        href: "#",
        attrs: this.v3 ? undefined : {
          href: "#"
        },
        onClick: function onClick(e) {
          return _this.click(e, page);
        },
        on: this.v3 ? undefined : {
          "click": function onClick(e) {
            return _this.click(e, page);
          }
        },
        "class": this.$props.currentPage === page ? 'k-link k-state-selected' : 'k-link'
      }, [page])]);
    }, this);
    return h("ul", {
      "class": "k-pager-numbers k-reset"
    }, [prevDots, numerics, postDots]);
  }
};
exports.PagerNumericButtons = PagerNumericButtons;