export class SchedaParametriItem {
	constructor() {
		this.schedaParametroID = 0;
		this.nomeParametro = null;
		this.tipo = null;
		this.note = null;
		this.nomeXML = null;
		this.descrizione = null;
		this.categoria = null;
	}
	schedaParametroID: number;
	nomeParametro: string;
	tipo: number | null;
	note: string | null;
	nomeXML: string | null;
	descrizione: string | null;
	categoria: string | null;
}
