export class CommissioniProdottiItem {
	constructor() {
		this.itemID = null;
		this.pacchettoID = null;
		this.beneficiarioID = null;
		this.tipoValoreID = null;
		this.valore = null;
		this.startDate = null;
		this.endDate = null;
	}
	itemID: number;
	pacchettoID: number;
	beneficiarioID: number | null;
	tipoValoreID: number | null;
	valore: number | null;
	startDate: string | null;
	endDate: string | null;
}