"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.footerColumns = exports.autoGenerateColumns = exports.readColumns = exports.mapColumns = exports.checkPropCompatibility = exports.getIndex = exports.isRtl = exports.flatData = exports.getNestedValue = void 0;
/**
 * @hidden
 */
function getNestedValue(fieldName, dataItem) {
    var path = fieldName.split('.');
    var data = dataItem;
    path.forEach(function (p) {
        data = data ? data[p] : undefined;
    });
    return data;
}
exports.getNestedValue = getNestedValue;
/**
 * @hidden
 */
function flatData(output, input, footer, dataIndex, groupDefined, field, level) {
    if (level === void 0) { level = 0; }
    var maxLevel = level;
    for (var i = 0; i < input.length; i++) {
        if (!groupDefined || input[i].value === undefined || input[i].items === undefined) {
            output[output.length] = {
                dataIndex: ++dataIndex.index,
                dataItem: input[i],
                rowType: 'data',
                level: level,
                expanded: field === undefined || getNestedValue(field, input[i])
            };
            continue;
        }
        maxLevel = Math.max(maxLevel, level + 1);
        var expanded = field === undefined ||
            getNestedValue(field, input[i]) === undefined ||
            getNestedValue(field, input[i]);
        // header
        output[output.length] = {
            dataIndex: -1,
            dataItem: input[i],
            level: level,
            rowType: 'groupHeader',
            expanded: expanded
        };
        // children
        if (expanded) {
            maxLevel = Math.max(flatData(output, input[i].items, footer, dataIndex, groupDefined, field, level + 1), maxLevel);
        }
        // footer
        if (footer === 'always' || (expanded && footer === 'visible')) {
            output[output.length] = {
                dataIndex: -1,
                dataItem: input[i],
                rowType: 'groupFooter',
                level: level,
                expanded: expanded
            };
        }
    }
    return maxLevel;
}
exports.flatData = flatData;
/**
 * @hidden
 */
function isRtl(element) {
    return element && (getComputedStyle(element).direction === 'rtl') || false;
}
exports.isRtl = isRtl;
/**
 * @hidden
 */
function getIndex(event, parent) {
    if (!parent || !event || !event.originalEvent) {
        return -1;
    }
    var target = document.elementFromPoint(event.clientX, event.originalEvent.clientY);
    while (target && target.parentElement !== parent) {
        target = target.parentElement;
    }
    var children = parent.children;
    for (var i = 0; i < children.length; i++) {
        if (children[i] === target) {
            return i;
        }
    }
    return -1;
}
exports.getIndex = getIndex;
var eventKeys = [
    'sortChange',
    'filterChange',
    'groupChange',
    'pageChange',
    'expandChange',
    'selectionChange',
    'headerSelectionChange',
    'rowClick',
    'itemChange',
    'dataStateChange',
    'columnResize',
    'columnReorder'
];
var GridColumnDefaults = {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true
};
var deprecatedHandlerMessage = function (oldKey, newKey) { return ("The " + oldKey + " event handler property is deprecated, " +
    ("use https://www.telerik.com/kendo-vue-ui/components/grid/api/GridProps/#toc-" + newKey + " instead")); };
/**
 * @hidden
 */
function checkPropCompatibility(props) {
    for (var index = 0; index < eventKeys.length; index++) {
        var eventKey = eventKeys[index];
        if (props[eventKey] !== undefined) {
            var newKey = 'on' + eventKey.charAt(0).toUpperCase() + eventKey.slice(1);
            console.warn(deprecatedHandlerMessage(eventKey, newKey));
        }
    }
}
exports.checkPropCompatibility = checkPropCompatibility;
/**
 * @hidden
 */
function mapColumns(columns) {
    var columnsMap = [[]];
    var stickyLeftWidth = 0;
    for (var q = columns.length - 1; q >= 0; q--) {
        columns[q].colSpan = columns[q].colSpan || 1;
        if (columns[q].parentIndex !== -1) {
            columns[columns[q].parentIndex].colSpan =
                (columns[columns[q].parentIndex].colSpan || 0) + columns[q].colSpan;
        }
    }
    var maxDepth = 0;
    columns.forEach(function (d) { maxDepth = Math.max(maxDepth, d.depth); });
    var rowSpan = 1;
    columns.forEach(function (column, i) {
        columnsMap[column.depth] = columnsMap[column.depth] || [];
        var needKFirst = false;
        if (columnsMap[column.depth].length === 0) {
            if (rowSpan <= 1) {
                rowSpan = 1 + (column.children.length > 0 ? 0 : maxDepth - column.depth);
            }
            else {
                rowSpan--;
                needKFirst = true;
            }
        }
        column.rowSpan = 1 + (column.children.length > 0 ? 0 : maxDepth - column.depth);
        column.kFirst = needKFirst;
        column.index = columnsMap[column.depth].length;
        columnsMap[column.depth].push(i);
        if (column.locked && column.children.length === 0) {
            column.left = stickyLeftWidth;
            stickyLeftWidth += column.width ? parseFloat(column.width.toString()) : 0;
        }
    });
    var nextIsLocked = false;
    var stickyRightWidth = 0;
    for (var i = columns.length - 1; i >= 0; i--) {
        var column = columns[i];
        if (column.locked && column.children.length === 0) {
            column.right = stickyRightWidth;
            stickyRightWidth += column.width ? parseFloat(column.width.toString()) : 0;
            column.rightBorder = !nextIsLocked;
        }
        nextIsLocked = column.locked || false;
    }
    return columnsMap;
}
exports.mapColumns = mapColumns;
/**
 * @hidden
 */
function readColumns(newColumns, oldColumns, depth) {
    if (depth === void 0) { depth = 0; }
    var columns = [];
    var sameLength = (newColumns && newColumns.length) ? newColumns.length === oldColumns.length : false;
    if (!newColumns) {
        return [];
    }
    if (newColumns && newColumns.length === undefined) {
        newColumns = [newColumns];
    }
    // @ts-ignore
    newColumns.forEach(function (columnProps, index) {
        columnProps = columnProps;
        var oldColumn = sameLength ? oldColumns[index] || null : null;
        var notHiddenChildren = columnProps.children ?
            columnProps.children.filter(function (column) { return !column.hidden; }) : columnProps.children;
        var c = readColumns(notHiddenChildren, oldColumn && oldColumn.children || [], depth + 1);
        columns.push(Object.assign({ depth: depth }, GridColumnDefaults, (c.length) ? { cell: function () { return null; }, filterCell: function () { return null; } } : {}, oldColumn ? { width: oldColumn.width, orderIndex: oldColumn.orderIndex } : {}, columnProps, {
            declarationIndex: columns.length,
            children: c,
            rowSpan: 0,
            colSpan: 0
        }));
    });
    var comparator = function (a, b) {
        return a.orderIndex === b.orderIndex ?
            a.declarationIndex - b.declarationIndex :
            ((a.orderIndex || 0) - (b.orderIndex || 0));
    };
    columns.sort(comparator);
    if (depth === 0) {
        var ret_1 = [];
        var flat_1 = function (cols, pIndex) {
            return cols.forEach(function (c) {
                c.parentIndex = pIndex;
                flat_1(c.children, ret_1.push(c) - 1);
            });
        };
        flat_1(columns, -1);
        return ret_1;
    }
    return columns;
}
exports.readColumns = readColumns;
/**
 * @hidden
 */
function autoGenerateColumns(data, group, expandField) {
    var propData = [];
    if (Array.isArray(data)) {
        propData = data;
    }
    else if (data) {
        propData = data.data;
    }
    if (!propData.length) {
        console.warn('Kendo Grid autogeneration of columns is only possible if some items are defined when the component is created.');
    }
    var columns = [];
    if (propData.length > 0) {
        var itemForColumnsGen = propData[0];
        if (group) {
            for (var i = 0; i < group.length; i++) {
                itemForColumnsGen = itemForColumnsGen.items && itemForColumnsGen.items[0];
            }
        }
        var fields = Object.getOwnPropertyNames(itemForColumnsGen);
        fields.forEach(function (field) {
            if (field !== expandField && field !== '__ob__') {
                columns.push(Object.assign({
                    declarationIndex: -1,
                    parentIndex: -1,
                    depth: 0,
                    colSpan: 0,
                    rowSpan: 0,
                    index: 0,
                    left: 0,
                    right: 0,
                    children: [],
                    rightBorder: false
                }, GridColumnDefaults, { field: field }));
            }
        });
    }
    return columns;
}
exports.autoGenerateColumns = autoGenerateColumns;
var hasParentFooterCell = function (columns, column) {
    var parent = columns[column.parentIndex];
    while (parent) {
        if (parent.footerCell) {
            return true;
        }
        parent = columns[parent.parentIndex];
    }
    return false;
};
/**
 * @hidden
 */
exports.footerColumns = function (columns) {
    return columns.filter(function (column) {
        if (hasParentFooterCell(columns, column)) {
            return false;
        }
        return Boolean(column.footerCell) || !(column.children && column.children.length > 0);
    });
};
