import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import axios from "axios";
import api from "@/services/api";
import { AnagraficaTipiPremio } from "../../../models/anafrafica/anagraficaTipiPremio";
import { Commerciale } from "../../../models/anafrafica/commerciale";
import { process } from "@progress/kendo-data-query";
import { AnagraficaDistributoreEdit } from "@/models/anafrafica/AnagraficaDistributore";
import { CommercialeDistributore } from "../../../models/distributori/CommercialeDistributore";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.DISTRIBUTORI_COMMERCIALI;
@Component({})
export default class distributoriCommerciali extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.DISTRIBUTORI];
	}
	@Prop({ default: () => null }) itemID: number;
	commerciali: TextValueItem[] = [];
	commercialiFull: Commerciale[] = [];
	tipiPremio: TextValueItem[] = [];

	editItem: CommercialeDistributore = new CommercialeDistributore();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },
			{
				field: "commercialeID",
				sortBy: "ragioneSociale",
				title: this.$i18n.t("generico.commerciale").toString(),
				values: this.commerciali,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				itemsForFilter: this.commerciali,
				filterType: "select",
				editType: "select",
				editSource: this.commerciali,
				editSourceText: "text",
				editSourceValue: "value",
				editSelectionCallBack: this.onChangeCommerciale
			},
			{
				field: "commissioneTipoID",
				title: this.$i18n.t("generico.tipoCommissione").toString(),
				width: 200,
				values: this.tipiPremio,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				itemsForFilter: this.tipiPremio,
				filterType: "select",
				editType: "select",
				editSource: this.tipiPremio,
				editSourceText: "text",
				editSourceValue: "value"
			},

			{ field: "commissione", title: this.$i18n.t("generico.commissione").toString(), width: 200, headerCell: "myHeaderCellTemplate", 
			type: "renderfunction", 
			renderFunction: this.renderCommissione, filterable: true, headerType: "numeric" },

			{
				field: "startDate",
				title: this.$i18n.t("generico.inizioRapporto").toString(),
				type: "date",
				format: "{0:dd/MM/yyyy}",
				width: 200,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				headerType: "daterange"
			},
			{
				field: "endDate",
				title: this.$i18n.t("generico.fineRapporto").toString(),
				type: "date",
				format: "{0:dd/MM/yyyy}",
				width: 200,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				headerType: "daterange"
			},
			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;

		Promise.all([
			api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => (self.tipiPremio = res)),
			api.getDatiGriglia(api.endpoint.COMMERCIALI, {"filters":[{"field":"cancellato","operator":"eq","value":"!!false!!"}]}, null, 0, 1000000, null).then(res => {
				let commerciali = res;
				self.commercialiFull = res && res.items || [];
				if (commerciali && commerciali.items && commerciali.items.length > 0) {
					var commItems = commerciali.items as Commerciale[];
					self.commerciali = commItems
						.filter(function(item) {
							return item.itemID > 0;
						})
						.map(function(item) {
							return {
								text: item.ragioneSociale,
								value: item.itemID
							};
						});
				}
			})
		])
			.then(responses => {
				this.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		var self = this;
		self.getGridData(_ENDPONT.replace("{id}", this.itemID.toString()));
	}

	onDelete(item: any) {
		var self = this;
		var commerciale = this.commerciali.find(function(x) {
			return x.value == item.commercialeID;
		});
		var itemToDelete = commerciale ? commerciale.text : item.commercialeID;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${itemToDelete}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })

		var title = "Elimina relazione Commerciale - Distributore";
		self.deleteGridRow(_ENDPONT.replace("/{id}", ""), item.itemID, title, [message]);
	}

	onChangeCommerciale(val, obj) {
		if( !this.isNewItem )
			return;

		// get commissioni padre
		var c = this.commercialiFull.find(x => x.itemID === obj.value);
		var tipoComm = ( c && c.commissioneTipoID ) || null;
		var comm = c && c.commissione >= 0 ? c.commissione : null;
		this.editItem.commissioneTipoID = tipoComm;
		this.editItem.commissione = comm;
	}

	addNewRow() {
		let newItem = new CommercialeDistributore();
		newItem.distributoreID = this.itemID;
		if (this.commerciali && this.commerciali.length > 0) {
			let firstCommerciale = this.commerciali[0].value as number;
			newItem.commercialeID = firstCommerciale;
		}
		if (this.tipiPremio && this.tipiPremio.length > 0) {
			let firstCommissioneTipo = this.tipiPremio[0].value as number;
			newItem.commissioneTipoID = firstCommissioneTipo;
		}
		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new CommercialeDistributore(), item);
		self.editSubTitle = item.ragioneSociale;
		self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.distributorecommerciale") }).toString();
		self.setGridRow<CommercialeDistributore>(_ENDPONT.replace("/{id}", ""), self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
