import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { ParametriPolizeItem } from "@/models/prodotti/parametriPolizeItem";
import { ParametriProdottiItem } from "@/models/prodotti/parametriProdottiItem";
import permessi from "@/config/permessi";
import { DistributoriAssociatiProdottiItem } from "@/models/prodotti/distributoriAssociatiProdottiItem";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";

const _ENDPONT = api.endpoint.PRODOTTI.DISTRIBUTORI_ASSOCIATI_PRODOTTI;
@Component({})
export default class ParametriPolize extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.PRODOTTI];
	}
	@Prop({ default: () => null }) pacchettoID: number;
	editItem: DistributoriAssociatiProdottiItem = new DistributoriAssociatiProdottiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;

	impostaDataFine: string = null;

	itemsGrid: TextValueItem[] = [];
	tipiDistributore: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "pacchettoID", title: this.$i18n.t("generico.prodotto").toString(), width: 20, filterable: false, hidden: true, values: [this.pacchettoID], headerCell: "myHeaderCellTemplate", editDisabled: true },
			{ field: "distributoreID", title: this.$i18n.t("generico.distributore").toString(), filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true },
			{ field: "distributore", title: this.$i18n.t("generico.nominativo").toString(), groupable: true, headerCell: "myHeaderCellTemplate", editDisabled: true },
			{
				field: "tipoDistributoreID",
				title: this.$i18n.t("generico.tipo").toString(),
				groupable: true,
				values: this.tipiDistributore,
				width: 200,
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				sortBy: "tipoDistributore",
				filterable: true,
				itemsForFilter: this.tipiDistributore,
				filterType: "select",
				editType: "select",
				editSource: this.tipiDistributore
			},
			{ field: "startDate", title: this.$i18n.t("generico.startDate").toString(), format: "{0:dd/MM/yyyy}", type: "date", width: 120, headerCell: "myHeaderCellTemplate" },
			{ field: "endDate", title: this.$i18n.t("generico.endDate").toString(), format: "{0:dd/MM/yyyy}", type: "date", width: 120, headerCell: "myHeaderCellTemplate" },
			{
				field: "forzaPagamento",
				title: this.$i18n.t("generico.forzaPagamento").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				disabled: true
			},
			// {
			// 	field: "cancellato",
			// 	title: "Non Attivo",
			// 	width: 150,
			// 	type: "boolean",
			// 	filterType: "boolean",
			// 	headerCell: "myHeaderCellTemplate",
			// 	disabled: true
			// },
			{
				field: "nonAttivo",
				title: this.$i18n.t("generico.distributoreNonAttivo").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				disabled: true,
				editHide: true
			},
			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" },
			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPO_DISTRIBUTORE).then(res => (self.tipiDistributore = res));

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "nonAttivo";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!false!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.pacchettoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributoreAssociato")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	eseguiSuSelezione() {
		var self = this;
		if (this.impostaDataFine) {
			let cmd = "AggiornaDataFine";
			if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
				let elementToDoAction = this.gridData.data
					.filter(function(item) {
						return item._ck_select == true;
					})
					.map(function(el) {
						return el.itemID;
					});
				var chiamate = [];
				for (let i = 0; i < elementToDoAction.length; i++) {
					let element = elementToDoAction[i];

					let data: EseguiComandoRequest = {
						comando: cmd,
						elenco: [],
						parametri: [element, this.impostaDataFine]
					};
					chiamate.push(
					api.eseguiComando(data)
						.then(res => {
							
						})
						.catch(err => {
							console.log(err);
						}));
				}
				Promise.all(chiamate)
				.then(responses => {
					self.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
						variant: "success",
						title: this.$i18n.t("generico.azioneeseguita").toString(),
						solid: true
					});
					self.getData();
				})
				.catch(err => {
					self.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
						variant: "error",
						title: this.$i18n.t("generico.errore").toString(),
						solid: true
					});
					console.log(err);
				});
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	rimuoviSelezionati() {
		var self = this;
		let cmd = "AssociaDistributori";
		if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
			let elementToDoAction = this.gridData.data
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.itemID;
				});
			if (elementToDoAction.length == 0) {
				return this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}

			let data: EseguiComandoRequest = {
				comando: cmd,
				elenco: elementToDoAction,
				parametri: [this.pacchettoID, false]
			};
			api.eseguiComando(data)
				.then(res => {
					self.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
						variant: "success",
						title: this.$i18n.t("generico.azioneeseguita").toString(),
						solid: true
					});
					self.getData();
				})
				.catch(err => {
					self.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
						variant: "error",
						title: this.$i18n.t("generico.errore").toString(),
						solid: true
					});
				});
		}
	}

	addNewRow() {
		let newItem = new DistributoriAssociatiProdottiItem();
		newItem.pacchettoID = this.pacchettoID;
		//var newItem = this.gridData.data[0];

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new DistributoriAssociatiProdottiItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.distributoreAssociato") }).toString();
		self.setGridRow<DistributoriAssociatiProdottiItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
