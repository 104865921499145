import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { DistributoriAssociatiProdottiItem } from "@/models/prodotti/distributoriAssociatiProdottiItem";

const _ENDPONT = api.endpoint.PRODOTTI_DISTRIBUTORI;
@Component({})
export default class ProdottiAssociatiDistributori extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.DISTRIBUTORI];
	}
	@Prop({ default: () => null }) distributoreID: number;
	editItem: DistributoriAssociatiProdottiItem = new DistributoriAssociatiProdottiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showHidden: boolean = false;
	showHiddenPolizze: boolean = false;

	//showOnlyActive: boolean = true;

	impostaDataFine: string = null;

	tipologieProdotti: TextValueItem[] = [];
	fornitori: TextValueItem[] = [];
	categorie: TextValueItem[] = [];
	tipiPremio: TextValueItem[] = [];
	pacchetti: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "pacchettoID",
				title: this.$i18n.t("generico.prodotto").toString(),
				values: this.pacchetti,
				headerCell: "myHeaderCellTemplate",
				editDisabled: this.isNewItem ? false : true,
				editType: "select",
				editSource: this.pacchetti,
				hidden: true
			},
			{
				field: "pacchetto",
				title: this.$i18n.t("generico.prodotto").toString(),
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{ field: "polizza", title: "Polizza", width: 100, headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "tipologiaID",
				title: this.$i18n.t("generico.tipologia").toString(),
				width: 100,
				values: this.tipologieProdotti,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.tipologieProdotti,
				sortBy: "tipologiaProdotto",
				editHide: true
			},
			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				width: 100,
				values: this.fornitori,
				groupable: true,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{
				field: "categoriaID",
				title: this.$i18n.t("generico.categoria").toString(),
				width: 100,
				values: this.categorie,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.categorie,
				sortBy: "categoria",
				editHide: true
			},
			{
				field: "logoImageThumb",
				title: this.$i18n.t("generico.logoPiccolo").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\Loghi\\Thumb",
				editField: "logoImageThumb_file",
				export: true,
				width: 150,
				editHide: true
			},
			{ field: "startDate", title: this.$i18n.t("generico.startDate").toString(), format: "{0:dd/MM/yyyy}", width: 120, headerCell: "myHeaderCellTemplate", headerType: "daterange", type: "date" },
			{ field: "endDate", title: this.$i18n.t("generico.endDate").toString(), format: "{0:dd/MM/yyyy}", width: 120, headerCell: "myHeaderCellTemplate",  headerType: "daterange", type: "date" },

			{
				field: "tipoCommissioneEredID",
				title: this.$i18n.t("generico.tipoCommissioneEredID").toString(),
				width: 100,
				values: this.tipiPremio,
				headerCell: "myHeaderCellTemplate",
				editHide: true,
				filterType: "select",
				itemsForFilter: this.tipiPremio
			},
			{
				field: "commissioneEred",
				title: this.$i18n.t("generico.commissioneEred").toString() /* "Comm Ered"*/,
				width: 100,
				hidden: false,
				headerType: "numeric",
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{
				field: "tipoCommissioneID",
				title: this.$i18n.t("generico.tipoCommissioneID").toString(),
				width: 120,
				values: this.tipiPremio,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipiPremio,
				filterType: "select",
				itemsForFilter: this.tipiPremio
			},
			{ field: "commissione", title: this.$i18n.t("generico.commissioneDistributore").toString(), width: 100, hidden: false, headerCell: "myHeaderCellTemplate", headerType: "numeric" },
			{
				field: "forzaPagamento",
				title: this.$i18n.t("generico.forzaPagamento").toString(),
				width: 70,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				disabled: true
			},
			// {
			// 	field: "vendibile",
			// 	title: this.$i18n.t("generico.vendibile").toString(),
			// 	width: 70,
			// 	type: "boolean",
			// 	filterType: "boolean",
			// 	headerCell: "myHeaderCellTemplate",
			// 	disabled: true,
			// 	editHide: true
			// },
			{
				field: "nascosto",
				title: this.$i18n.t("generico.nascosto").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true,
				editHide: true
			},
			{
				field: "polizzaNascosta",
				title: this.$i18n.t("generico.nonAttivo").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true,
				editHide: true
			},
			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		// this.take = 60;
		// this.pageable.pageSize = 60;
		// this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.CATEGORIE).then(res => (self.categorie = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPOLOGIE_PRODOTTI).then(res => (self.tipologieProdotti = res));
		var p3 = api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res));
		var p4 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => (self.tipiPremio = res));
		var p5 = api.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI).then(res => (self.pacchetti = res));

		if (!self.showHidden) {
			self.filter.filters.push({
				field: "vendibile",
				operator: "eq",
				value: "!!true!!"
			});
		}

		if (!self.showHiddenPolizze) {
			self.filter.filters.push({
				field: "polizzaNascosta",
				operator: "neq",
				value: "!!true!!"
			});
		}

		Promise.all([p, p2, p3, p4, p5])
			.then(responses => {
				// if (self.showOnlyActive) {
				// 	self.filter.filters.push({
				// 		field: "vendibile",
				// 		operator: "eq",
				// 		value: "!!true!!"
				// 	});
				// }
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	// @Watch("showOnlyActive")
	// onShowOnlyActiveChange(newValue: boolean) {
	// 	const field = "vendibile";
	// 	if (newValue) {
	// 		this.filter.filters.push({
	// 			field: field,
	// 			operator: "eq",
	// 			value: "!!true!!"
	// 		});

	// 		this.setDefaultPageSize();
	// 		this.getData();
	// 	} else {
	// 		var findFilterIndex = this.filter.filters.findIndex(function(item) {
	// 			return item.field === field;
	// 		});

	// 		this.filter.filters.splice(findFilterIndex, 1);

	// 		this.setDefaultPageSize();
	// 		this.getData();
	// 	}
	// }

	@Watch("showHidden")
	onShowHiddenChange(newValue: boolean) {
		const field = "vendibile";
		if (newValue) {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();			
		} else {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!true!!"
			});

			this.setDefaultPageSize();
			this.getData();
		}
	}

	@Watch("showHiddenPolizze")
	onShowHiddenPolizzaChange(newValue: boolean) {
		const field = "polizzaNascosta";
		if (newValue) {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();			
		} else {
			this.filter.filters.push({
				field: field,
				operator: "neq",
				value: "!!true!!"
			});

			this.setDefaultPageSize();
			this.getData();
		}
	}

	getData() {
		this.getGridData(_ENDPONT.replace("{id}", this.distributoreID.toString()));
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributoreAssociato")]).toString();
		self.deleteGridRow(_ENDPONT.replace("/{id}", ""), item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new DistributoriAssociatiProdottiItem();
		newItem.distributoreID = this.distributoreID;
		//var newItem = this.gridData.data[0];

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new DistributoriAssociatiProdottiItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.distributoreAssociato") }).toString();
		self.setGridRow<DistributoriAssociatiProdottiItem>(_ENDPONT.replace("/{id}", ""), self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
