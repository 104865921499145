import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { ExtraInfoPolizeItem } from "@/models/prodotti/extraInfoPolizeItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.POLIZZE.EXTRA_INFO_POLIZZE;
@Component({})
export default class ExtraInfoPolize extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	editItem: ExtraInfoPolizeItem = new ExtraInfoPolizeItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	hint: string = "";
	valoreDisabled: boolean = false;
	tipoValore: string = "string";

	tipiExtraInfo: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "itemID", filterable: false, headerCell: "myHeaderCellTemplate", hidden: true, editHide: true },
			{
				field: "labelID",
				title: this.$i18n.t("generico.label").toString(),
				values: this.tipiExtraInfo,
				headerCell: "myHeaderCellTemplate",
				sortBy: "labelCarrello",
				filterType: "select",
				itemsForFilter: this.tipiExtraInfo,
				editType: "select",
				editSource: this.tipiExtraInfo,
				editSelectionCallBack: this.onChangeLabel
			},
			{
				field: "valore",
				title: this.$i18n.t("generico.valore").toString(),
				headerCell: "myHeaderCellTemplate",
				hint: this.hint,
				editDisabled: this.valoreDisabled,
				format: this.tipoValore === "date" ? "{0:dd/MM/yyyy}" : "",
				type: this.tipoValore,
				editType: this.tipoValore
			},
			/*{
				field: "suggerimento",
				//title: "asdasd",
				headerCell: "myHeaderCellTemplate",
				hidden:true,
				editDisabled: true,
				hint: true
			},*/
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPO_EXTRA_INFO).then(res => (self.tipiExtraInfo = res));

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	onChangeLabel(val, obj) {
		this.hint = obj ? obj.description || "" : "";
		this.valoreDisabled = obj ? obj.code === "bool" : false;
		//this.tipoValore = obj && obj.code === "date" ? "date" : "";
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.extraInfo")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new ExtraInfoPolizeItem();
		newItem.prodottoID = this.prodottoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new ExtraInfoPolizeItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		debugger;
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.extraInfo") }).toString();
		self.setGridRow<ExtraInfoPolizeItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
