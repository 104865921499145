import { KeyVakueItem } from "@/models/KeyVakueItem";

export class InformazioniGenericheItem {
	constructor() {
		this.itemID = null;
		this.codice = "";
		this.descrizione = "";
		this.codiceInformazione = "";
		this.testiInformazioni = [];
	}
	itemID: number;
	codice: string;
	descrizione: string;
	codiceInformazione: string;
	testiInformazioni: KeyVakueItem[];
}