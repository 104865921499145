import permessi from "@/config/permessi";
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
Vue.use(VueRouter);
export const routes = [
	{
		path: "/",
		name: "home",
		component: function () {
			return import("../pages/home.vue");
		}
	},
	{
		path: "/login",
		name: "login",
		component: function () {
			return import("../pages/login.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/password-recover",
		name: "password_recover",
		component: function () {
			return import("../pages/passwordRecover.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{ 
		path: "/change-password",
		name: "change_password",
		component: function () {
			return import("../pages/changePassword.vue");
		},
		meta: { layout: "empty-layout", allowanonymous: true }
	},
	{
		path: "/anagrafica",
		redirect: { name: 'anagrafica_fornitori' },
		name: "anagrafica",
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			layout: "sub-menu-layout",
			label: "navbar.anagrafica",
			color: "app-color1",
			topNav: { label: "navbar.anagrafica", displayOrder: 1 }
		},
		children: [
			{
				path: "fornitori",
				name: "anagrafica_fornitori",
				component: function () {
					return import("../pages/anagrafica/fornitori/fornitori.vue");
				},
				meta: {
					permissions: [permessi.BK.Anagrafica.FORNITORI],
					layout: "sub-menu-layout",
					label: "navbar.fornitori",
					topSubNav: { label: "navbar.fornitori", displayOrder: 1 }
				}
			},
			{
				path: "distributori",
				name: "anagrafica_distributori",
				component: function () {
					return import("../pages/anagrafica/distributori/distributori.vue");
				},
				meta: {
					permissions: [permessi.BK.Anagrafica.DISTRIBUTORI],
					layout: "sub-menu-layout",
					label: "navbar.distributori",
					topSubNav: { label: "navbar.distributori", displayOrder: 2 }
				}
			},
			{
				path: "utenti",
				name: "anagrafica_utenti",
				component: function () {
					return import("../pages/anagrafica/utenti/utenti.vue");
				},
				meta: {
					permissions: [permessi.BK.Anagrafica.UTENTI],
					label: "navbar.utenti",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.utenti", displayOrder: 3 }
				}
			},
			{
				path: "elencoCoupon",
				name: "anagrafica_elencoCoupon",
				component: function () {
					return import("../pages/anagrafica/coupon/elencoCoupon.vue");
				},
				meta: {
					permissions: [permessi.BK.Anagrafica.COUPON],
					label: "navbar.elencoCoupon",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.elencoCoupon", displayOrder: 4 }
				}
			},
			{
				path: "agenzie",
				name: "anagrafica_agenzie",
				component: function () {
					return import("../pages/anagrafica/agenzie/agenzie.vue");
				},
				meta: {
					permissions: [permessi.BK.Anagrafica.AGENZIE],
					label: "navbar.agenzie",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.agenzie", displayOrder: 5 }
				}
			},
			{
				path: "aderenti",
				name: "anagrafica_aderenti",
				component: function () {
					return import("../pages/anagrafica/aderenti/aderenti.vue");
				},
				meta: {
					permissions: [permessi.BK.Anagrafica.DISTRIBUTORI],
					layout: "sub-menu-layout",
					label: "navbar.aderenti",
					topSubNav: { label: "navbar.aderenti", displayOrder: 6 }
				}
			},
		]
	},
	{
		path: "/commerciale",
		name: "commerciale",
		redirect: { name: 'commerciale_commerciali' },
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.commerciale",
			color: "app-color2",
			topNav: { label: "navbar.ommerciale", displayOrder: 2 }
		},
		children: [
			{
				path: "commerciali",
				name: "commerciale_commerciali",
				component: function () {
					return import("../pages/commerciale/anagraficaCommerciale/commerciali.vue");
				},
				meta: {
					permissions: [permessi.BK.Commerciali.COMMERCIALI],
					layout: "sub-menu-layout",
					label: "navbar.commerciali",
					topSubNav: {
						label: "navbar.commerciali",
						displayOrder: 1
					}
				}
			},
			{
				path: "commissioni",
				name: "commerciale_commissioni",
				component: function () {
					return import("../pages/commerciale/commissioni/commissioni.vue");
				},
				meta: {
					permissions: [permessi.BK.Commerciali.COMMISSIONI],
					layout: "sub-menu-layout",
					label: "navbar.commissioni",
					topSubNav: { label: "navbar.commissioni", displayOrder: 2 }
				}
			},
			{
				path: "analisi-dati",
				name: "commerciale_analisiDati",
				component: function () {
					return import("../pages/commerciale/analisiCommissioni/analisiCommissioni.vue");
				},
				meta: {
					permissions: [permessi.BK.Commerciali.ANALISICOMMISSIONI],
					layout: "sub-menu-layout",
					label: "navbar.analisiDati",
					topSubNav: { label: "navbar.analisiDati", displayOrder: 3 }
				}
			}
		]
	},
	{
		path: "/gestione-prodotti",
		name: "gestioneProdotti",
		redirect: { name: 'gestioneProdotti_polizze' },
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.gestioneProdotti",
			color: "app-color3",
			topNav: { label: "navbar.gestioneProdotti", displayOrder: 3 }
		},
		children: [
			{
				path: "polizze",
				name: "gestioneProdotti_polizze",
				component: function () {
					return import("../pages/gestioneProdotti/polize/polize.vue");
				},
				meta: {
					permissions: [permessi.BK.Prodotti.POLIZZE],
					layout: "sub-menu-layout",
					label: "navbar.polizze",
					topSubNav: {
						label: "navbar.polizze",
						displayOrder: 1
					}
				}
			},
			{
				path: "prodotti",
				name: "gestioneProdotti_prodotti",
				component: function () {
					return import("../pages/gestioneProdotti/prodotti/prodotti.vue");
				},
				meta: {
					permissions: [permessi.BK.Prodotti.PRODOTTI],
					layout: "sub-menu-layout",
					label: "navbar.prodotti",
					topSubNav: { label: "navbar.prodotti", displayOrder: 2 }
				}
			},
		]
	},
	{
		path: "/gestione-vendite",
		redirect: { name: 'gestioneVendite_ricercaGaranzie' },
		name: "gestioneVendite",
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			layout: "sub-menu-layout",
			label: "navbar.gestioneVendite",
			color: "app-color4",
			topNav: { label: "navbar.gestioneVendite", displayOrder: 4 }
		},
		children: [
			{
				path: "ricerca-garanzie",
				name: "gestioneVendite_ricercaGaranzie",
				component: function () {
					return import("../pages/gestioneVendite/ricercaGaranzie/ricercaGaranzie.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.GARANZIE],
					layout: "sub-menu-layout",
					label: "navbar.ricercaGaranzie",
					topSubNav: { label: "navbar.ricercaGaranzie", displayOrder: 1 }
				}
			},
			{
				path: "ricerca-garanzie-wondersafe",
				name: "gestioneVendite_ricercaGaranzieWondersafe",
				component: function () {
					return import("../pages/gestioneVendite/ricercaGaranzieWondersafe/ricercaGaranzieWondersafe.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.GARANZIE],
					layout: "sub-menu-layout",
					label: "navbar.ricercaGaranziewondersafe",
					topSubNav: { label: "navbar.ricercaGaranziewondersafe", displayOrder: 2 }
				}
			},
			{
				path: "ricerca-garanzie-ecommerce",
				name: "gestioneVendite_ricercaGaranzieECommerce",
				component: function () {
					return import("../pages/gestioneVendite/ricercaGaranzieECommerce/ricercaGaranzieECommerce.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.GARANZIE],
					layout: "sub-menu-layout",
					label: "navbar.ricercaGaranzieecommerce",
					topSubNav: { label: "navbar.ricercaGaranzieecommerce", displayOrder: 3 }
				}
			},
			{
				path: "analisi-avanzata",
				name: "gestioneVendite_analisiAvanzata",
				component: function () {
					return import("../pages/gestioneVendite/analisiAvanzata/distributori.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.ANALISIAVANZATA],
					layout: "sub-menu-layout",
					label: "navbar.analisiAvanzata",
					topSubNav: { label: "navbar.analisiAvanzata", displayOrder: 4 }
				}
			},
			{
				path: "reportFornitore",
				name: "gestioneVendite_reportFornitore",
				component: function () {
					return import("../pages/gestioneVendite/reportFornitore/reportFornitore.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.REPORTFORNITORE],
					label: "navbar.reportFornitore",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.reportFornitore", displayOrder: 5 }
				}
			},
			
			{
				path: "storicoReportFornitore",
				name: "gestioneVendite_storicoReportFornitore",
				component: function () {
					return import("../pages/gestioneVendite/storicoInviiReportFornitore/storicoInviiReportFornitore.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.REPORTFORNITORE],
					label: "navbar.storicoReportFornitore",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.storicoReportFornitore", displayOrder: 6 }
				}
			},
			
			{
				path: "report-vendite",
				name: "gestioneVendite_reportVendite",
				component: function () {
					return import("../pages/gestioneVendite/reportVendite/reportVendite.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.REPORTVENDITE],
					label: "navbar.reportVendite",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.reportVendite", displayOrder: 7 }
				}
			},
			{
				path: "modifica-pratica-avanzata",
				name: "modifica_pratica_avanzata",
				component: function () {
					return import("../pages/gestioneVendite/modificaPraticaAvanzata/modificaPraticaAvanzata.vue");
				},
				meta: {
					permissions: [permessi.BK.Vendite.MODIFICA_PRATICA_AVANZATA],
					label: "navbar.modificaPraticheAvanzata",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.modificaPraticheAvanzata", displayOrder: 8 }
				}
			},
			{
				path: "modifica-pratica/:id",
				name: "modifica_pratica",
				component: function () {
					return import("../pages/gestioneVendite/modificaPratica.vue");
				},
				meta: {
					permissions: [],
					layout: "sub-menu-layout",
					label: "navbar.modificaPratica"
					//topSubNav: { label: "navbar.fornitori", displayOrder: 1 }
				}
			},
			{
				path: "modifica-pratica-ecommerce/:id",
				name: "modifica_pratica-ecommerce",
				component: function () {
					return import("../pages/gestioneVendite/modificaPraticaECommerce.vue");
				},
				meta: {
					permissions: [],
					layout: "sub-menu-layout",
					label: "navbar.modificaPratica"
					//topSubNav: { label: "navbar.fornitori", displayOrder: 1 }
				}
			},
			{
				path: "testwidget",
				name: "test_widget",
				component: function () {
					return import("../pages/gestioneVendite/testWidget/testWidget.vue");
				},
				meta: {
					permissions: [],
					label: "navbar.testwidget",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.testwidget", displayOrder: 8 }
				}
			},
			// { 
			// 	path: "agenzie",
			// 	name: "gestioneVendite_agenzie",
			// 	component: function() {
			// 		return import("../pages/gestioneVendite/agenzie.vue");
			// 	},
			// 	meta: {
			// 		label: "navbar.agenzie",
			// 		layout: "sub-menu-layout",
			// 		topSubNav: { label: "navbar.agenzie", displayOrder: 6 }
			// 	}
			// }
		]
	},
	{
		path: "/gestione-pagamenti",
		name: "gestionePagamenti",
		redirect: { name: 'gestionePagamenti_estrattiConto' },
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.gestionePagamenti",
			color: "app-color5",
			topNav: { label: "navbar.gestionePagamenti", displayOrder: 5 }
		},
		children: [
			{
				path: "estratti-conto",
				name: "gestionePagamenti_estrattiConto",
				component: function () {
					return import("../pages/gestionePagamenti/estrattiConto.vue");
				},
				meta: {
					permissions: [permessi.BK.Pagamenti.ESTRATTICONTO],
					layout: "sub-menu-layout",
					label: "navbar.estrattiConto",
					topSubNav: {
						label: "navbar.estrattiConto",
						displayOrder: 1
					}
				}
			},
			{
				path: "registrazione-pagamenti",
				name: "gestionePagamenti_registrazionePagamenti",
				component: function () {
					return import("../pages/gestionePagamenti/registrazionePagamenti.vue");
				},
				meta: {
					permissions: [permessi.BK.Pagamenti.REGISTRAZIONE],
					layout: "sub-menu-layout",
					label: "navbar.registrazionePagamenti",
					topSubNav: { label: "navbar.registrazionePagamenti", displayOrder: 2 }
				}
			},
		]
	},
	{
		path: "/messaggi",
		name: "messaggi",
		redirect: { name: 'messaggi_lista' },
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.messaggi",
			color: "app-color6",
			topNav: { label: "navbar.mesaggi", displayOrder: 6 }
		},
		children: [
			{
				path: "lista-messaggi",
				name: "messaggi_lista",
				component: function () {
					return import("../pages/messaggi/messaggi.vue");
				},
				meta: {
					permissions: [permessi.BK.Messaggi.MESSAGGI],
					layout: "sub-menu-layout",
					label: "navbar.listaMessaggi",
					topSubNav: {
						label: "navbar.listaMessaggi",
						displayOrder: 1
					}
				}
			}
		]
	},
	{
		path: "/amministrazione",
		name: "amministrazione",
		redirect: { name: 'anagrafica_ruoli' },
		component: function () {
			return import("../pages/contenitoreEmpty.vue");
		},
		meta: {
			label: "navbar.amministrazione",
			color: "app-color7",
			topNav: { label: "navbar.amministrazione", displayOrder: 7 }
		},
		children: [
			{
				path: "ruoli",
				name: "anagrafica_ruoli",
				component: function () {
					return import("../pages/anagrafica/ruoli/ruoli.vue");
				},
				meta: {
					permissions: [permessi.BK.Anagrafica.RUOLI],
					label: "navbar.ruoli",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.ruoli", displayOrder: 1 }
				}
			},
			{
				path: "custom-table",
				name: "amministrazione_customTable",
				component: function () {
					return import("../pages/amministrazione/customTable/customTable.vue");
				},
				meta: {
					permissions: [permessi.BK.Amministrazione.CODICI_PAGAMENTO, permessi.BK.Amministrazione.COMMISIONI_BENEFICARI, 
						permessi.BK.Amministrazione.ETA, permessi.BK.Amministrazione.EXTRA_INFO, permessi.BK.Amministrazione.GRUPPISTATI,  permessi.BK.Amministrazione.GRUPPINAZIONALITA,
						permessi.BK.Amministrazione.IMPOSTAZIONI,
						permessi.BK.Amministrazione.NAZIONALITA, permessi.BK.Amministrazione.PACCHETTI_CATEGORIE, permessi.BK.Amministrazione.PARAMETRI,
						permessi.BK.Amministrazione.POPUP_CARRELLO, permessi.BK.Amministrazione.STATI, permessi.BK.Amministrazione.TIPI_PARAMETRI, 
						permessi.BK.Amministrazione.TIPI_DISTRIBUTORE, permessi.BK.Amministrazione.CONTRAENTI, permessi.BK.Amministrazione.TIPOLOGIE_PRODOTTI,
						permessi.BK.Amministrazione.INFORMAZIONI],
					label: "navbar.customTable",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.customTable", displayOrder: 2 }
				}
			},
			{
				path: "settings-pratiche-multiple",
				name: "settings_pratiche_multiple",
				component: function () {
					return import("../pages/amministrazione/praticheMultiple/listaTemplate.vue");
				},
				meta: {
					permissions: [permessi.BK.Amministrazione.PRATICHE_MULTIPLE],
					//permissions: ["PERMSSO_INESISTENTE_PER_NASCONDERE_MOMENTANEAMENTE"],
					label: "navbar.praticheMultiple",
					layout: "sub-menu-layout",
					topSubNav: { label: "navbar.praticheMultiple", displayOrder: 3 }
				}
			},
			// {
			// 	path: "configurazione-modifica-pratiche",
			// 	name: "configurazione_modifica_pratiche",
			// 	component: function () {
			// 		return import("../pages/amministrazione/configurazioneModificaPratiche/configurazioneModificaPratiche.vue");
			// 	},
			// 	meta: {
			// 		// permissions: [permessi.BK.Anagrafica.RUOLI],
			// 		permissions: ["PERMSSO_INESISTENTE_PER_NASCONDERE_MOMENTANEAMENTE"],
			// 		label: "navbar.configurazioneModificaPratiche",
			// 		layout: "sub-menu-layout",
			// 		topSubNav: { label: "navbar.configurazioneModificaPratiche", displayOrder: 4 }
			// 	}
			// }
		]
	}
];
var router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: routes
});
export default router;
