import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import axios from "axios";
import api from "@/services/api";
import { process } from "@progress/kendo-data-query";
import { CommercialeDistributore } from "../../../models/distributori/CommercialeDistributore";
import { CreditiDistributoriItem } from "../../../models/distributori/creditiDistributoriItem";
import moment from "moment";
import helpers from "@/helpers";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.CREDITI_DISTRIBUTORI,
	_ENDPONT_TOTALI = api.endpoint.CREDITI_DISTRIBUTORI_TOTALI;

@Component({})
export default class CreditiDistributori extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Anagrafica.DISTRIBUTORI]; }
	@Prop({ default: () => null }) itemID: number;
	commerciali: TextValueItem[] = [];
	tipiPremio: TextValueItem[] = [];

	editItem: CreditiDistributoriItem = new CreditiDistributoriItem();
	showEditModal: boolean = false;
	showOnlyActive: boolean = true;
	editSubTitle: string = "";
	editTitle: string = "";
	totaleGenerale: number = 0;
	kendop: any = kendo;

	itemsGrid: CreditiDistributoriItem[] = [];

	aggregates: any[] = [{ field: "importo", aggregate: "sum" }];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },
			{ field: "note", title: this.$i18n.t("generico.note").toString(), headerCell: "myHeaderCellTemplate", filterable: true },
			{
				field: "importo",
				title: this.$i18n.t("generico.importo").toString(),
				width: 180,
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCellTemplate",
				type: "numeric",
				headerType: "numeric",
				filterType: "numeric",
				filterable: true,
				format:"{0:c}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'c')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'c')#",
				//footerCell: "myFooterCommissioniCommercialiCellTemplate",
				//aggregates: ["sum"],
				//footerTemplate: "Tot: #=kendo.toString(sum)#",
				//groupFooterTemplate: "Tot: #=kendo.toString(sum)#"
			},
			{
				field: "istanteInserimento",
				title: this.$i18n.t("generico.istanteInserimento").toString(),
				type: "datetime",
				filterable: true,
				width: 200,
				format: "{0:dd/MM/yyyy HH:mm}",
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				filterType: "datetimerange"
			},
			{
				field: "istanteModifica",
				title: this.$i18n.t("generico.istanteModifica").toString(),
				type: "datetime",
				filterable: true,
				width: 200,
				format: "{0:dd/MM/yyyy HH:mm}",
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				filterType: "datetimerange"
			},
			{ field: "utenteCancellazione", title: 'Cancellato Da', headerCell: "myHeaderCellTemplate", width: 200,  editHide: true, filterable: true },
			{
				field: "deleted",
				title: this.$i18n.t("generico.creditoCancellato").toString(),
				width: 120,
				disabled: true,
				editHide: true,
				type: "boolean",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterType: "boolean"
			},
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		if (this.showOnlyActive) {
			this.filter.filters.push({
				field: "deleted",
				operator: "neq",
				value: "!!true!!"
			});
		}

		this.getData();
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.itemID}`);
		this.calcolaTotaliGenerali();
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "deleted";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "neq",
				value: "!!true!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	onDelete(item: any) {
		var self = this;
		var commerciale = this.commerciali.find(function (x) {
			return x.value == item.commercialeID;
		});
		var itemToDelete = commerciale ? commerciale.text : item.commercialeID;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${itemToDelete}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })

		var title: "Elimina relazione Commerciale - Distributore";
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);

	}

	addNewRow() {
		let newItem = new CreditiDistributoriItem();
		newItem.distributoreID = this.itemID;
		let now = new Date();
		newItem.istanteInserimento = now;
		newItem.istanteModifica = now;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new CreditiDistributoriItem(), item);
		self.editSubTitle = item.ragioneSociale;
		self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
		let now = new Date();
		//self.editItem.istanteInserimento = now;
		self.editItem.istanteModifica = now;
	}


	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.creditidistributori") }).toString();
		self.setGridRow<CreditiDistributoriItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	get getImportoTotale(): number {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.importo;
			});

			result = this._.sum(values);
		}

		return result;
	}

	calcolaTotaliGenerali(){
		this.totaleGenerale = 0;
		var self = this;
		api.get(`${_ENDPONT_TOTALI}/${this.itemID}`).then(res =>{
			self.totaleGenerale = res && res.totale ? res.totale : 0;
		})
	}

}
