// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import { AnagraficaDistributoreEdit } from "@/models/anafrafica/AnagraficaDistributore";
import { AnagraficaLingue } from "@/models/anafrafica/AnagraficaLingue";
import { AnagraficaProvince } from "@/models/anafrafica/AnagraficaProvince";
import { CodiciPagamento } from "@/models/anafrafica/codiciPagamento";
import { utenteEdit } from "@/models/anafrafica/utenti";
import { column, customButtons, customEventItem } from "@/models/grid/columnModels";
import { SendMessageToUserItem } from "@/models/messaggi/sendMessageToUserItem";
import { Azioni } from "@/models/ruolo";
import { TextValueItem } from "@/models/TextValueItem";
import { CambioPasswordModel, ForzaCambioPasswordModel } from "@/models/userModels";
import api from "@/services/api";
import { Component, Prop, Watch } from "vue-property-decorator";

const _ENDPONT = api.endpoint.UTENTI;
const _SEND_MESSAGE_TO_USER_LIST = api.endpoint.NOTIFICATION.SEND_MESSAGE_TO_USER_LIST;
@Component({})
export default class utentiPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.UTENTI];
	}

	get PERMESSI_MESSAGGI(): string[] {
		return [permessi.BK.Messaggi.MESSAGGI];
	}
	@Prop({ default: () => null })
	distributoreID: number;
	@Prop({ default: () => null })
	fornitoreID: number;
	province: Array<TextValueItem> = [];
	distributori: Array<TextValueItem> = [];
	generi: Array<TextValueItem> = [
		{ text: "Femmina", value: "F" },
		{ text: "Maschio", value: "M" }
	];
	actionsToDo: Array<TextValueItem> = [{ text: "Invia messaggio su selezione", value: 0 }];
	selectedActionToDo: number = null;
	commerciali: Array<TextValueItem> = [];
	fornitori: Array<TextValueItem> = [];
	ruoli: Array<TextValueItem> = [];

	editItem: utenteEdit = new utenteEdit();
	showEditModal: boolean = false;
	loading: boolean = true;
	currentItem: any = {};
	showOnlyActive: boolean = true;
	showRecuperoPasswordModal: boolean = false;
	showPasswordNuova: boolean = false;
	showConfermaPassword: boolean = false;

	showDialogSendMessage: boolean = false;
	userToSendMessage: SendMessageToUserItem = null;

	@Watch("showRecuperoPasswordModal")
	onShowCambioPasswordModalChange(newValue: boolean, oldValue: boolean) {
		this.cambioPwd = new ForzaCambioPasswordModel();
	}
	cambioPwd: ForzaCambioPasswordModel = new ForzaCambioPasswordModel();
	get distributoreFisso(): boolean {
		return this.distributoreID != null;
	}
	get fornitoreFisso(): boolean {
		return this.fornitoreID != null;
	}
	get canSendMessage(): boolean {
		return this.hasPermessi(this.PERMESSI_MESSAGGI, [Azioni.C]);
	}
	get columns(): Array<any> {
		return [
			{
				field: "itemID",
				headerCell: "myHeaderCellTemplate",
				filterType: "numeric-int",
				title: "ID",
				width: 90,
				filterable: true,
				hidden: false,
				editHide: true
			},
			{
				field: "cognome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.cognome").toString(),
				width: 160,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "nome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.nome").toString(),
				width: 120,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "ruoliID",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.tc("generico.ruolo_i", 2).toString(),
				editOrder: -310,
				editType: "multi-select",
				editSource: this.ruoli,
				editSourceText: "text",
				editSourceValue: "value",
				hidden: true
			},
			{
				field: "ruoli",
				editHide: true,
				hidden: false,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.tc("generico.ruolo_i", 2).toString(),
				width: 180,
				groupable: false,
				export: true
			},
			{
				field: "distributoreID",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.distributore").toString(),
				sortBy: "distributore",
				editOrder: -230,
				filterable: true,
				values: this.distributori,
				itemsForFilter: this.distributori,
				filterType: "select",
				editType: "select",
				editSource: this.distributori,
				editSourceText: "text",
				editSourceValue: "value",
				groupable: true,
				hidden: this.distributoreFisso || this.fornitoreFisso,
				editDisabled: this.distributoreFisso,
				editHide: this.fornitoreFisso,
				export: false
			},
			{
				field: "distributore",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.distributore").toString(),

				export: true,
				hidden: true,
				editHide: true
			},
			{
				field: "email",
				editOrder: -320,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.email").toString(),
				width: 170,
				export: true
			},
			{
				field: "emailAzienda",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.emailAziendale").toString(),
				width: 180,
				hidden: true,
				export: true
			},
			{
				field: "telefono",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.telefono").toString(),
				width: 140,
				export: true
			},
			{
				field: "fax",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.fax").toString(),
				width: 120,
				hidden: true,
				export: true
			},
			{
				field: "username",
				editOrder: -330,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.username").toString(),
				width: 150,
				export: true
			},
			// { field: "WI_Password", title: "Password", width: 100, hidden: true },

			//	{ field: "GruppoID", title: "Gruppo", width: 100, values: gruppi , groupable: true , hidden: true },
			//	{ field: "CodicePagamentoID", title: this.$i18n.t("generico.pagamento").toString(), width: 100, values: codiciPagamento , groupable: true , hidden: true },
			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				width: 100,
				editType: "select",
				editSource: this.fornitori,
				editOrder: -220,
				editSourceText: "text",
				editSourceValue: "value",
				hidden:  true, //this.distributoreFisso || this.fornitoreFisso,
				editDisabled: this.fornitoreFisso,
				editHide: this.distributoreFisso,
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "commercialeID",
				title: this.$i18n.t("generico.commerciale").toString(),
				width: 100,
				editType: "select",
				editSource: this.commerciali,
				editOrder: -210,
				editSourceText: "text",
				editSourceValue: "value",
				hidden: true,// this.distributoreFisso || this.fornitoreFisso,
				editHide: this.distributoreFisso || this.fornitoreFisso,
				headerCell: "myHeaderCellTemplate"
			},

			{
				field: "indirizzo",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.indirizzo").toString(),
				width: 300,
				hidden: true,
				export: true
			},
			{
				field: "localita",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.localita").toString(),
				width: 250,
				hidden: true,
				export: true
			},
			{
				field: "codiceProvincia",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.provincia").toString(),
				width: 100,
				values: this.province,
				editType: "select",
				editSource: this.province,
				editSourceText: "text",
				editSourceValue: "code",
				hidden: true,
				export: true
			},
			{
				field: "cAP",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.cap").toString(),
				width: 200,
				hidden: true,
				export: true
			},
			{
				field: "sesso",
				title: this.$i18n.t("generico.sesso").toString(),
				editSource: this.generi,
				editType: "select",
				editSourceText: "text",
				editSourceValue: "value",
				hidden: true,
				export: true
			},
			{
				field: "commissioni",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.commissioni").toString(),
				width: 150,
				hidden: true, // this.distributoreFisso || this.fornitoreFisso,
				editHide: this.distributoreFisso || this.fornitoreFisso,
				editType: "numeric",
				export: true,
				headerType: "numeric"
			},
			/*{
				field: "attivo",
				type: "switch",
				editOrder: -310,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.attivo").toString(),
				width: 120,
				hidden: false
			},*/
			{
				field: "attivo",
				type: "boolean",
				disabled: true,
				editOrder: -310,
				cell: "defaultCellTemplate",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.attivo").toString(),
				width: 120,
				hidden: false,
				export: true
			},
			{
				field: "riceviNewsletter",
				type: "switch",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.ricevinewsletter").toString(),
				width: 120,
				hidden: true,
				export: true
			},
			{
				field: "note",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.note").toString(),
				hidden: false,
				export: true,
				editType: "textarea"
			},
			// {
			// 	field: "usaCarrelloAvanzato", disabled: true, type: "boolean", filterType: "boolean", editType: 'switch',
			// 	headerCell: "myHeaderCellTemplate", title: this.$i18n.t("generico.usaCarrelloAvanzato").toString(), width: 150
			// },
			{
				field: "istanteInserimento",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.istanteInserimento").toString(),
				hidden: true,
				format: "{0: dd/MM/yyyy HH:mm}",
				editDisabled: true,
				type: "datetime",
				export: true
			},
			{
				field: "istanteModifica",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.istanteModifica").toString(),
				hidden: true,
				format: "{0: dd/MM/yyyy HH:mm}",
				editDisabled: true,
				type: "datetime",
				export: true
			},
			{
				field: "_actions",
				title: " ",
				width: 50 + (this.canEdit ? 50 : 0) + (this.canDelete ? 50 : 0) + (this.canSendMessage ? 50 : 0),
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				customButtons: [
					{ command: "recuperoPwd", label: "CP", visible: this.canEdit, tooltip: 'Cambia Password' },
					{ command: "inviaMessaggio", label: "MSG", visible: this.canEdit, tooltip: 'Invia Messaggio Personale'}
				]
			},
			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean", hidden: !this.canSendMessage }
		];
	}
	// testVisible(item: any) {
	// 	return true;
	// }
	// testDisable(item: any) {
	// 	return false;
	// }
	// sort: any = [{ field: "cognome", dir: "asc" }];

	created() {}

	mounted() {
		var self = this;
		var p = [
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.COMMERCIALI).then(res => (self.commerciali = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.PROVINCE).then(res => (self.province = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.RUOLI).then(res => (self.ruoli = res))
		];

		Promise.all(p)
			.then(responses => {
				if (self.showOnlyActive) {
					this.filter.filters.push({
						field: "attivo",
						operator: "eq",
						value: "!!true!!"
					});
				}

				this.getData();
				self.loading = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "attivo";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!true!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	getData() {
		var self = this;
		if (this.fornitoreFisso) {
			var f = this.filter.filters.find(x => x.field == "fornitoreID");
			if (f == null)
				this.filter.filters.push({
					field: "fornitoreID",
					operator: "eq",
					value: `!!n=${this.fornitoreID}`
				});
			else {
				f.value = `!!n=${this.fornitoreID}`;
			}
		}
		if (this.distributoreFisso) {
			var f = this.filter.filters.find(x => x.field == "distributoreID");
			if (f == null)
				this.filter.filters.push({
					field: "distributoreID",
					operator: "eq",
					value: `!!n=${this.distributoreID}`
				});
			else {
				f.value = `!!n=${this.distributoreID}`;
			}
		}
		self.getGridData(_ENDPONT);
	}

	exportExcel() {
		//prima request a api e poi esport
		this.exportGridExcel(_ENDPONT);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", {
			domProps: { innerHTML: `<strong>${item.username}</strong><h5>${item.cognome || ""} ${item.nome || ""}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` }
		}); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.tc("generico.utente_i", 1)]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	addNewRow() {
		var u = new utenteEdit();
		if (this.distributoreID) u.distributoreID = this.distributoreID;
		u.fornitoreID = this.fornitoreID;
		this.onEdit(u);
	}
	onEdit(item: any) {
		var self = this;

		api.getUtentiRuoli(item.itemID).then(res => {
			self.editItem = Object.assign(new utenteEdit(), item);
			self.editItem.ruoliID = res;

			if (item.nome && item.nome != "") {
				self.editSubTitle = item.nome + " " + item.cognome;
			} else self.editSubTitle = item.cognome;
			self.editTitle = item.username;
			self.showEditModal = true;
		});
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;

		if( this.isNewItem && this.fornitoreFisso){
			this.editItem.distributoreID = 1;
		}
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.tc("generico.utente_i", 1) }).toString();
		self.setGridRow<utenteEdit>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => (self.showEditModal = false));
	}
	onCustomButtonClick(eventCode: customEventItem) {
		var self = this;
		var item = eventCode.item;
		if (eventCode.command == "recuperoPwd") {
			self.editItem = Object.assign(new utenteEdit(), item);

			if (item.nome && item.nome != "") {
				self.editSubTitle = item.nome + " " + item.cognome;
			} else self.editSubTitle = item.cognome;
			self.editTitle = item.username;
			self.showRecuperoPasswordModal = true;
		} else if (eventCode.command == "inviaMessaggio") {
			this.userToSendMessage = new SendMessageToUserItem();

			this.userToSendMessage.toUser = item.id;
			this.userToSendMessage.isSingleSend = true;
			this.userToSendMessage.toUserFullName = `${item.cognome} ${item.nome}`;
			this.showDialogSendMessage = true;
		} else this.todo();
	}
	onCambiaPasswordConferma() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.t("msg.passwordCambiata").toString();
		self.cambioPwd.username = self.editItem.username;
		api.doForzaCambiaPassword(self.cambioPwd).then(token => {
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});
			this.showRecuperoPasswordModal = false;
		});
	}

	eseguiSuSelezione() {
		if (this.selectedActionToDo == 0) {
			let userToDoAction = this.gridData.data
				.filter(function(item) {
					return item._ck_select == true;
				})
				.map(function(el) {
					return el.id;
				});

			this.userToSendMessage = new SendMessageToUserItem();

			this.userToSendMessage.toUserIds = userToDoAction as string[];
			this.userToSendMessage.isSingleSend = false;
			//this.userToSendMessage.toUserFullName = `${item.cognome} ${item.nome}`;
			this.showDialogSendMessage = true;
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	sendMessageToUsers() {
		if (this.userToSendMessage) {
			if (this.userToSendMessage.message.length <= 0) {
				this.$bvToast.toast(this.$i18n.t("errore.messageTextNotDefined").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
			if (this.userToSendMessage.toUser.length <= 0 && this.userToSendMessage.toUserIds.length <= 0) {
				this.$bvToast.toast(this.$i18n.t("errore.nessunElementoSuCuiEseguireAzione").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}

			var self = this;
			api.chiamataPut(_SEND_MESSAGE_TO_USER_LIST, this.userToSendMessage)
				.then(res => {
					self.showDialogSendMessage = false;
					self.userToSendMessage = null;

					self.$bvToast.toast(self.$i18n.t("generico.cmdeseguito").toString(), {
						variant: "success",
						title: self.$i18n.t("generico.azioneeseguita").toString(),
						solid: true
					});
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			this.$bvToast.toast(this.$i18n.t("errore.nessunElementoSuCuiEseguireAzione").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}
}
