import permessi from "@/config/permessi";
import CommissioneModifica from "@/models/commerciali/CommissioneModifica";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component } from "vue-property-decorator";
import gridComponentBase from "../../../components/grid/gridComponentBase.vue";

const _ENDPONT = api.endpoint.COMMISSIONI.DISTRIBUTORI;

@Component
export default class CommissioniDistributoriPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Commerciali.COMMISSIONI];
	}
	editItem: CommissioneModifica = new CommissioneModifica();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = false;

	tipiPremio: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "ragioneSociale", title: "RagioneSociale", headerCell: "myHeaderCellTemplate", filterable: true, export: true, editDisabled: true },
			{ field: "commerciale", title: "Commerciale", headerCell: "myHeaderCellTemplate", filterable: true, export: true, editDisabled: true },
			{
				field: "tipoCommissioneID",
				title: this.$i18n.t("generico.tipoCommissione").toString(),
				width: 200,
				values: this.tipiPremio,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				sortBy: "tipoCommissioneID",
				itemsForFilter: this.tipiPremio,
				filterType: "select",
				editType: "select",
				editSource: this.tipiPremio
			},
			{ field: "tipoPremio", title: this.$i18n.t("generico.tipoCommissione").toString(), hidden: true, editHide: true, export: true },
			{
				field: "commissione",
				title: this.$i18n.t("generico.commissione").toString(),
				width: 150,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				headerType: "numeric",
				export: true,
				//type: "numeric",
				type: "renderfunction", 
				renderFunction: this.renderCommissione,
			},
			{
				field: "startDate",
				title: this.$i18n.t("generico.inizioRapporto").toString(),
				width: 200,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "endDate",
				title: this.$i18n.t("generico.fineRapporto").toString(),
				width: 200,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "distributoreCancellato",
				title: "Distributore Non Attivo",
				width: 200,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editHide: true,
				editDisabled: true,
				disabled: true
			},
			{
				field: "cancellato",
				title: this.$i18n.t("generico.nonAttivo").toString(),
				width: 200,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				hidden: true,
				disabled: true
			},

			{ field: "_actions", title: " ", width: 50, hidden: this.canEdit == false, cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "ragioneSociale", dir: "asc" }];

	created() {
		var self = this;

		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => (self.tipiPremio = res))])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() {}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new CommissioneModifica(), item);
		self.editSubTitle = item.ragioneSociale;
		self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.commissioniDistributori") }).toString();
		self.setGridRow<CommissioneModifica>(_ENDPONT, self.editItem, title, message, false).then(res => (self.showEditModal = false));
	}

	get detailTemplate() {
		if (this.showDetail) return "detailTemplate";
		else return "";
	}
}
