class Permessi {

	public readonly BK =
		{
			Anagrafica:
			{
				FORNITORI: "BK_ANGRAFICA_FORNITORI",
				DISTRIBUTORI: "BK_ANGRAFICA_DISTRIBUTORI",
				UTENTI: "BK_ANGRAFICA_UTENTI",
				RUOLI: "BK_ANGRAFICA_RUOLI",
				PERMESSI: "BK_ANGRAFICA_RUOLI_PERMESSI",
				COUPON: "BK_ANGRAFICA_COUPON",
				AGENZIE: "BK_ANGRAFICA_AGENZIE",
			},

			Commerciali:
			{
				COMMERCIALI: "BK_COMMERCIALI_COMMERCIALI",
				COMMISSIONI: "BK_COMMERCIALI_COMMISSIONI",
				ANALISICOMMISSIONI: "BK_COMMERCIALI_ANALISICOMMISSIONI",
			},
			Prodotti:
			{
				PRODOTTI: "BK_PRODOTTI_PRODOTTI",
				POLIZZE: "BK_PRODOTTI_POLIZZE",
			},
			Vendite:
			{
				GARANZIE: "BK_VENDITE_GARANZIE",
				// ANALISIDATI: "BK_VENDITE_ANALISIDATI",
				ANALISIAVANZATA: "BK_VENDITE_ANALISIAVANZATA",
				REPORTFORNITORE: "BK_VENDITE_REPORTFORNITORE",
				REPORTVENDITE: "BK_VENDITE_REPORTVENDITE",
				MODIFICA_PRATICA_AVANZATA: "BK_VENDITE_MODIFICAPRATICAAVANZATA",
			},
			Pagamenti:
			{
				ESTRATTICONTO: "BK_PAGAMENTI_ESTRATTICONTO",
				REGISTRAZIONE: "BK_PAGAMENTI_REGISTRAZIONE",
			},
			Messaggi:
			{
				MESSAGGI: "BK_MESSAGGI"
			},
			Amministrazione:
			{
				GRUPPISTATI: "BK_AMMINISTRAZIONE_GRUPPISTATI",
				GRUPPINAZIONALITA: "BK_AMMINISTRAZIONE_GRUPPINAZIONALITA",
				STATI: "BK_AMMINISTRAZIONE_STATI",
				CODICI_PAGAMENTO: "BK_AMMINISTRAZIONE_CODICI_PAGAMENTO",
				MODIFICHE_ABILITAZIONE: "BK_AMMINISTRAZIONE_MODIFICHE_ABILITAZIONE",
				TIPI_DISTRIBUTORE: "BK_AMMINISTRAZIONE_TIPI_DISTRIBUTORE",
				TIPOLOGIE_DISTRIBUTORE: "BK_AMMINISTRAZIONE_TIPOLOGIE_DISTRIBUTORE",
				EXTRA_INFO: "BK_AMMINISTRAZIONE_EXTRA_INFO",
				NAZIONALITA: "BK_AMMINISTRAZIONE_NAZIONALITA",
				PACCHETTI_CATEGORIE: "BK_AMMINISTRAZIONE_PACCHETTI_CATEGORIE",
				COMMISIONI_BENEFICARI: "BK_AMMINISTRAZIONE_COMMISIONI_BENEFICARI",
				CONTRAENTI: "BK_AMMINISTRAZIONE_CONTRAENTI",
				IMPOSTAZIONI: "BK_AMMINISTRAZIONE_IMPOSTAZIONI",
				POPUP_CARRELLO: "BK_AMMINISTRAZIONE_POPUP_CARRELLO",
				TIPOLOGIE_PRODOTTI: "BK_AMMINISTRAZIONE_TIPOLOGIE_PRODOTTI",
				ETA: "BK_AMMINISTRAZIONE_ETA",
				PARAMETRI: "BK_AMMINISTRAZIONE_PARAMETRI",
				TIPI_PARAMETRI: "BK_AMMINISTRAZIONE_TIPI_PARAMETRI",
				INFORMAZIONI: "BK_AMMINISTRAZIONE_INFORMAZIONI",
				PRATICHE_MULTIPLE: "BK_AMMINISTRAZIONE_PRATICHEMULTIPLE"
			},
			Impostazioni:
			{
				ACCEDICOME: "BK_IMPOSTAZIONI_ACCEDICOME",
			},
		}
}


const permessi = new Permessi();
export default permessi;