
import Qs from "qs";
import axios, { AxiosInstance } from "axios";

// Mentre api.ts scatena eventi per gestire in modo centralizzato response code 401,500 ecc quiesto no,
// chi lo usa lo gestisce (decidiere se cambiare nome, per ora serve solo per scaricare immagini e pdf) 
class ApiFile {

	instance: AxiosInstance = null;
	/**
	 * Ctor
	 */
	constructor() {
		var self = this;
		this.instance = axios.create({
			baseURL: process.env.VUE_APP_BASE_URL
		});

		this.instance.interceptors.request.use(config => {
			config.headers.common["Authorization"] = "bearer " + localStorage.getItem("overins-token");
			config.paramsSerializer = params => {
				// Qs is already included in the Axios package
				return Qs.stringify(params, {
					arrayFormat: "repeat"
				});
			};
			return config;
		});
	}


	//#region FILE DOWNLOAD
	downloadGenericFile(pathAndNameFile: string): Promise<any> {
		var self = this;
		return new Promise<any>(function (resolve, reject) {
			self.instance
				.get(`file/file?name=${pathAndNameFile}`, { responseType: "blob" })
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	downloadFile(url: string, nomeFile: string): Promise<any> {
		var self = this;
		return new Promise<any>(function (resolve, reject) {
			self.instance
				.get(`file/${url}`, { responseType: "blob" })
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	downloadImage(url: string): Promise<any> {
		var self = this;
		return new Promise<any>(function (resolve, reject) {
			self.instance({
				method: 'get',
				url: url,
				responseType: 'arraybuffer'
			})
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	downloadReportFornitore(fileName: string): Promise<any> {
		var self = this;
		return new Promise<any>(function (resolve, reject) {
			self.instance
				.get(`file/reportFornitore?fileName=${fileName}`, { responseType: "blob" })
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	downloadFileModelloPraticheMultiple(type: string, nomeFile: string): Promise<any> {
		var self = this;
		return new Promise<any>(function (resolve, reject) {
			self.instance
				.get(`amministrazione/settingspratichemultiple/filemodel/${type}/${nomeFile}`, { responseType: "blob" })
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	downloadFileSchemaPraticheMultiple(type: string, nomeFile: string): Promise<any> {
		var self = this;
		return new Promise<any>(function (resolve, reject) {
			self.instance
				.get(`amministrazione/settingspratichemultiple/schema/${type}/${nomeFile}`, { responseType: "blob" })
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	downloadFileRegistrazioneAgenzia(nomeFile: string): Promise<any> {
		var self = this;
		return new Promise<any>(function (resolve, reject) {
			self.instance
				.get(`file/agenzie?fileName=${nomeFile}`, { responseType: "blob" })
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

}

const apiFile = new ApiFile();
export default apiFile;
