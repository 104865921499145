export class GruppiStatoPerProdotto {
	constructor() {
		this.itemID = 0;
		this.prodottoID = 0;
		this.gruppoStatiID = 0;
		this.gruppoStato = "";
		this.gruppoStatoA3 = "";
		this.codiceRiepilogo = "";
		this.descrizioneRiepilogo = "";				
		this.deleted = null;				
	}

	itemID: number | null;
	prodottoID: number | null;
	gruppoStatiID: number | null;
	gruppoStato: string;
	gruppoStatoA3: string;
	codiceRiepilogo: string;
	descrizioneRiepilogo: string;
	deleted: boolean | null;
}