export class DocumentiPolizeItem {
	constructor() {
		this.itemID = 0;
		this.prodottoID = null;
		this.tipoFileID = null;
		this.tipoFile = null;
		this.nomeFile = null;
		this.descrizioneFile = null;
		this.startDate = null;
		this.endDate = null;
		this.cancellato = null;
		this.guidFile = null;
		this.itemGUID = null;
		this.deleted = null;
		this.nomeFile_uploadFile = null;
	}

	itemID: number;
	prodottoID: number | null;
	tipoFileID: number | null;
	tipoFile: string;
	nomeFile: string;
	descrizioneFile: string;
	startDate: string | null;
	endDate: string | null;
	cancellato: boolean | null;
	guidFile: string | null;
	itemGUID: string;
	deleted: boolean | null;
	nomeFile_uploadFile: File | null;
}