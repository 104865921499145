export class FattoriPolizzeItem {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.chiave = null;
		this.valore = null;
		this.tipo = null; 
	}

	itemID: number;
	prodottoID: number;
	chiave: string;
	valore: string;
	tipo: number | null;
}