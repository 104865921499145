export class ImpostazioniItem {
	constructor() {
		this.itemID = null;
		this.gruppo = null;
		this.variabile = null;
		this.valore = null;
	}
	itemID: number | null;
	gruppo: string;
	variabile: string;
	valore: string;
}