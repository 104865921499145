import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { GruppiStatiItem } from "@/models/amministrazione/gruppiStati/gruppiStatiItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.GRUPPI_STATI;
@Component({})
export default class GruppiStati extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.GRUPPISTATI];
	}
	editItem: GruppiStatiItem = new GruppiStatiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	keyIndex: number = 0;

	showOnlyActive: boolean = false;

	showResultModal: boolean = false;

	fornitoriAggiornati: TextValueItem[] = [];
	polizzeAggiornate: TextValueItem[] = [];
	gruppoStatoChanged: string = "";

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "codiceGruppo", title: this.$i18n.t("generico.codiceGruppo").toString(), filterable: false, headerCell: "myHeaderCellTemplate", width: 250 },
			{ field: "gruppoStato", title: this.$i18n.t("generico.gruppostato").toString(), groupable: true, headerCell: "myHeaderCellTemplate", export: true, width: 250 },
			{
				field: "descrizione",
				title: this.$i18n.t("generico.descrizioneGruppo").toString(),
				headerCell: "myHeaderCellTemplate",
				export: true
			},
			{
				field: "comprendeTuttiGliStati",
				title: this.$i18n.t("generico.tuttiGliStati").toString(),
				headerCell: "myHeaderCellTemplate",
				export: true,
				type: "boolean",
				filterType: "boolean",
				width: 200,
				disabled: true
			},
			{
				field: "isDefault",
				title: this.$i18n.t("generico.isDefault").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true,
				hidden: true
			},
			{
				field: "esclusione",
				title: this.$i18n.t("generico.esclusione").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true,
				hidden: true
			},
			{
				field: "raggruppaRiepilogo",
				title: this.$i18n.t("generico.raggruppaRiepilogo").toString(),
				width: 200,
				headerCell: "myHeaderCellTemplate",
				export: true,
				hidden: true
			},
			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "gruppoStato", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.gruppistati")]).toString();
		self.deleteGridRowT<any>(_ENDPONT, item.itemID, title, [message]).then(res => {
			if (res.data && ((res.data.polizzeModificate && res.data.polizzeModificate.length) || (res.data.fornitoriModificati && res.data.fornitoriModificati.length))) {
				self.fornitoriAggiornati = res.data.fornitoriModificati;
				self.polizzeAggiornate = res.data.polizzeModificate;
				self.gruppoStatoChanged = res.data.gruppoStatoModificato;

				self.showResultModal = true;
			}
		});
	}

	addNewRow() {
		let newItem = new GruppiStatiItem();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new GruppiStatiItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.gruppistati") }).toString();
		self.setGridRow<any>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;

			if (res.data && ((res.data.polizzeModificate && res.data.polizzeModificate.length) || (res.data.fornitoriModificati && res.data.fornitoriModificati.length))) {
				self.fornitoriAggiornati = res.data.fornitoriModificati;
				self.polizzeAggiornate = res.data.polizzeModificate;
				self.gruppoStatoChanged = res.data.gruppoStatoModificato;

				self.showResultModal = true;
			}
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	onReloadAssociaStati() {
		this.keyIndex++;

		var self = this;

		this.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
			variant: "success",
			title: this.$i18n.t("generico.azioneeseguita").toString(),
			solid: true
		});
	}

	hideResultModal() {
		this.showResultModal = false;
	}

	copyResultOnClipboard() {
		try {
			var tempInput = document.createElement("input") as any;
			tempInput.style = "position: absolute; left: -1000px; top: -1000px";
			tempInput.value = this.textForClipBoard;
			document.body.appendChild(tempInput);
			tempInput.select();
			document.execCommand("copy");
			document.body.removeChild(tempInput);

			this.$bvToast.toast(this.$i18n.t("generico.copiaEseguita").toString(), {
				variant: "success",
				title: this.$i18n.t("generico.cmdeseguito").toString(),
				solid: true
			});
		} catch (ex) {
			this.$bvToast.toast(this.$i18n.t("msg.erroreGenerico").toString(), {
				variant: "error",
				title: this.$i18n.t("generico.error").toString(),
				solid: true
			});
		}
	}

	get textForClipBoard(): string {
		var textForClipBoard = "";
		if (this.fornitoriAggiornati.length) {
			textForClipBoard += `${this.$i18n.t("messaggio.fornitoriAggiornati").toString()} ${this.fornitoriAggiornati
				.map(function(x) {
					return x.value + " - " + x.text;
				})
				.join(", ")}` + "\r\n";
		}

		if (this.polizzeAggiornate.length) {
			textForClipBoard += `${this.$i18n.t("messaggio.polizzeAggiornate").toString()} ${this.polizzeAggiornate
				.map(function(x) {
					return x.value + " - " + x.text;
				})
				.join(", ")}`;
		}

		return textForClipBoard;
	}
}
