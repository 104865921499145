export class PopupCarrelloItem {
	constructor() {
		this.itemID = null;
		this.idPolizza = null;
		this.nomeProdotto = null;
		this.idProdotto = null;
		this.pacchetto = null;
		this.idDistributore = null;
		this.ragioneSociale = null;
		this.pathFile = null;
		this.pathFile_file = null;
		this.dallaData = null;
		this.allaData = null;
		this.attivo = null;
		this.titolo = null;
		this.descrizione = null;
		this.binaryChecksum = null;
		this.link = null;
		this.posizioneApertura = null;
		this.tipoVisualizzazione = null;
		this.modalitaVisualizzazione = null;
		this.versione = null;
	}
	itemID: number | null;
	idPolizza: number | null;
	nomeProdotto: string;
	idProdotto: number | null;
	pacchetto: string;
	idDistributore: number | null;
	ragioneSociale: string;
	pathFile: string;
	pathFile_file: string;
	dallaData: string | null;
	allaData: string | null;
	attivo: boolean | null;
	titolo: string;
	descrizione: string;
	binaryChecksum: number | null;
	posizioneApertura: number | null;
	tipoVisualizzazione: number | null;
	modalitaVisualizzazione: number | null;
	versione: number | null;
	link: string;
}