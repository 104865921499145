"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDraggable = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `ColumnDraggable` component.
 */


var ColumnDraggable = {
  mounted: function mounted() {
    if (this.$el) {
      this.draggable = this.$refs.draggable;
    }
  },
  methods: {
    onPress: function onPress(event) {
      var element = this.draggable && this.draggable.element;

      if (element) {
        this.$emit('pressHandler', event, element);
      }
    },
    onDrag: function onDrag(event) {
      var element = this.draggable && this.draggable.element;

      if (element) {
        this.$emit('dragHandler', event, element);
      }
    },
    onRelease: function onRelease(event) {
      var element = this.draggable && this.draggable.element;

      if (element) {
        this.$emit('releaseHandler', event, element);
      }
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
    return (// @ts-ignore  function children
      h(kendo_vue_common_1.Draggable, {
        onPress: this.onPress,
        on: this.v3 ? undefined : {
          "press": this.onPress,
          "drag": this.onDrag,
          "release": this.onRelease
        },
        onDrag: this.onDrag,
        onRelease: this.onRelease,
        ref: 'draggable'
      }, this.v3 ? function () {
        return [h("tr", [defaultSlot])];
      } : [h("tr", [defaultSlot])])
    );
  }
};
exports.ColumnDraggable = ColumnDraggable;