"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Pager = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var GridPagerSettings_1 = require("./GridPagerSettings");

var PagerNumericButtons_1 = require("./PagerNumericButtons");

var PagerInput_1 = require("./PagerInput");

var PagerPageSizes_1 = require("./PagerPageSizes");

var messages_1 = require("../messages");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");
/**
 * Represents the default `Pager` component.
 */


var Pager = {
  props: {
    total: Number,
    skip: Number,
    pageSize: Number,
    settings: [Object, Boolean]
  },
  inject: {
    kendoLocalizationService: {
      default: null
    },
    kendoIntlService: {
      default: null
    }
  },
  computed: {
    totalPages: {
      get: function get() {
        return Math.ceil((this.$props.total || 0) / this.$props.pageSize);
      }
    },
    currentPage: {
      get: function get() {
        return Math.floor((this.$props.skip || 0) / this.$props.pageSize) + 1;
      }
    }
  },
  methods: {
    changePage: function changePage(page, e) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('pageChange', {
          skip: (page - 1) * this.$props.pageSize,
          take: this.$props.pageSize
        }, e);
      }
    },
    triggerPageChange: function triggerPageChange(e) {
      this.$emit('pageChange', e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoIntlService = inject('kendoIntlService', {});
    var kendoLocalizationService = inject('kendoLocalizationService', {});
    return {
      v3: v3,
      kendoIntlService: kendoIntlService,
      kendoLocalizationService: kendoLocalizationService
    };
  },
  // @ts-ignore 
  render: function render(createElement) {
    var h = gh || createElement;
    var settings = GridPagerSettings_1.normalize(this.$props.settings);
    var localizationService = kendo_vue_intl_1.provideLocalizationService(this);
    var intlService = kendo_vue_intl_1.provideIntlService(this);

    var button = function button(page, className, title, icon) {
      var _this = this;

      return h("a", {
        onClick: function onClick(e) {
          e.preventDefault();

          _this.changePage(page, e);
        },
        on: this.v3 ? undefined : {
          "click": function onClick(e) {
            e.preventDefault();

            _this.changePage(page, e);
          }
        },
        href: "#",
        attrs: this.v3 ? undefined : {
          href: "#",
          title: title
        },
        "class": className,
        title: title
      }, [h("span", {
        "class": icon,
        "aria-label": title,
        attrs: this.v3 ? undefined : {
          "aria-label": title
        }
      })]);
    };

    var changer = settings.type === 'numeric' ? // @ts-ignore
    h(PagerNumericButtons_1.PagerNumericButtons, {
      buttonCount: settings.buttonCount || 0,
      attrs: this.v3 ? undefined : {
        buttonCount: settings.buttonCount || 0,
        totalPages: this.totalPages,
        currentPage: this.currentPage
      },
      totalPages: this.totalPages,
      currentPage: this.currentPage,
      onPageChange: this.changePage,
      on: this.v3 ? undefined : {
        "pageChange": this.changePage
      }
    }) : // @ts-ignore
    h(PagerInput_1.PagerInput, {
      currentPage: this.currentPage,
      attrs: this.v3 ? undefined : {
        currentPage: this.currentPage,
        totalPages: this.totalPages
      },
      totalPages: this.totalPages,
      onPageChange: this.changePage,
      on: this.v3 ? undefined : {
        "pageChange": this.changePage
      }
    });
    var first, prev, next, last;

    if (settings.previousNext) {
      first = button.call(this, 1, 'k-link k-pager-nav k-pager-first' + (this.currentPage === 1 ? ' k-state-disabled' : ''), localizationService.toLanguageString(messages_1.pagerFirstPage, messages_1.messages[messages_1.pagerFirstPage]), 'k-icon k-i-seek-w');
      prev = button.call(this, this.currentPage - 1, 'k-link k-pager-nav' + (this.currentPage === 1 ? ' k-state-disabled' : ''), localizationService.toLanguageString(messages_1.pagerPreviousPage, messages_1.messages[messages_1.pagerPreviousPage]), 'k-icon k-i-arrow-w');
      next = button.call(this, this.currentPage + 1, 'k-link k-pager-nav' + (this.currentPage === this.totalPages ? ' k-state-disabled' : ''), localizationService.toLanguageString(messages_1.pagerNextPage, messages_1.messages[messages_1.pagerNextPage]), 'k-icon k-i-arrow-e');
      last = button.call(this, this.totalPages, 'k-link k-pager-nav k-pager-last' + (this.currentPage === this.totalPages ? ' k-state-disabled' : ''), localizationService.toLanguageString(messages_1.pagerLastPage, messages_1.messages[messages_1.pagerLastPage]), 'k-icon k-i-seek-e');
    }

    var pageSizes = settings.pageSizes !== false && // @ts-ignore
    h(PagerPageSizes_1.PagerPageSizes, {
      onPageChange: this.triggerPageChange,
      on: this.v3 ? undefined : {
        "pageChange": this.triggerPageChange
      },
      pageSize: this.$props.pageSize,
      attrs: this.v3 ? undefined : {
        pageSize: this.$props.pageSize,
        pageSizes: settings.pageSizes
      },
      pageSizes: settings.pageSizes
    });
    var defaultMessage = messages_1.messages[messages_1.pagerInfo];
    var info = !settings.info ? '' : h("div", {
      "class": "k-pager-info k-label"
    }, [intlService.format(localizationService.toLanguageString(messages_1.pagerInfo, defaultMessage), [Math.min(this.$props.skip + 1, this.$props.total), Math.min(this.$props.skip + this.$props.pageSize, this.$props.total), this.$props.total])]);
    return h("div", {
      "class": "k-pager-wrap k-grid-pager k-widget"
    }, [first, prev, changer, next, last, pageSizes, info]);
  }
};
exports.Pager = Pager;