"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridColumnMenuSort = exports.sortGroupByField = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var GridColumnMenuItem_1 = require("./GridColumnMenuItem");

var GridColumnMenuItemGroup_1 = require("./GridColumnMenuItemGroup");

var GridSortSettings_1 = require("../interfaces/GridSortSettings");

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");

var messages_1 = require("../messages");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * @hidden
 */


var ASC_DIR = 'asc';
/**
 * @hidden
 */

var DESC_DIR = 'desc';
/**
 * @hidden
 */

var sortSeqMap = {
  true: {
    'asc': {
      'asc': '',
      'desc': 'desc'
    },
    'desc': {
      'asc': 'asc',
      'desc': ''
    },
    '': {
      'asc': 'asc',
      'desc': 'desc'
    }
  },
  false: {
    'asc': {
      'asc': 'asc',
      'desc': 'desc'
    },
    'desc': {
      'asc': 'asc',
      'desc': 'desc'
    },
    '': {
      'asc': 'asc',
      'desc': 'desc'
    }
  }
};
/**
 * @hidden
 */

var sortIndex = function sortIndex(field, sort) {
  if (!sort) {
    return -1;
  }

  return sort.findIndex(function (s) {
    return s.field === field;
  });
};
/**
 * @hidden
 */


var sortedAsc = function sortedAsc(sortedIndex, sort) {
  return !!(sort && sortedIndex > -1 && sort[sortedIndex].dir === ASC_DIR);
};
/**
 * @hidden
 */


var sortedDesc = function sortedDesc(sortedIndex, sort) {
  return !!(sort && sortedIndex > -1 && sort[sortedIndex].dir === DESC_DIR);
}; // tslint:disable:max-line-length

/**
 * Can be used to check if sorting is applied to a specific field ([see example]({% slug column_menu_grid_native %}#toc-styling-the-column-menu-icon)). Useful for creating active sort indicators.
 */
// tslint:enable:max-line-length


exports.sortGroupByField = function (field, sort) {
  var currentSortIndex = sortIndex(field, sort);
  return sortedDesc(currentSortIndex, sort) || sortedAsc(currentSortIndex, sort);
};
/**
 * Represents the default `GridColumnMenuSort` component.
 */


var GridColumnMenuSort = {
  props: {
    sortable: [Boolean, Object],
    sort: {
      type: Array
    },
    column: Object
  },
  inject: {
    kendoLocalizationService: {
      default: null
    }
  },
  methods: {
    onAscClick: function onAscClick(e) {
      this.onSort(e, ASC_DIR);
      this.$emit('closemenu');
    },
    onDescClick: function onDescClick(e) {
      this.onSort(e, DESC_DIR);
      this.$emit('closemenu');
    },
    onSort: function onSort(e, selectedDir) {
      e.preventDefault();

      if (!kendo_vue_common_1.hasListener.call(this, 'sortchange')) {
        return;
      }

      var _a = this.$props,
          column = _a.column,
          sortable = _a.sortable,
          sort = _a.sort;

      var _b = GridSortSettings_1.normalize(sortable || false, false),
          allowUnsort = _b.allowUnsort,
          mode = _b.mode;

      var oldDescriptor = (sort || []).filter(function (d) {
        return d.field === column.field;
      })[0];
      var dir = sortSeqMap[allowUnsort][oldDescriptor && oldDescriptor.dir || ''][selectedDir];
      var newDescriptor = mode === 'single' ? [] : (this.$props.sort || []).filter(function (d) {
        return d.field !== column.field;
      });

      if (dir !== '' && column.field) {
        newDescriptor.push({
          field: column.field,
          dir: dir
        });
      }

      this.$emit('sortchange', newDescriptor, {
        event: e,
        field: this.$props.column.field
      });
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoLocalizationService = inject('kendoLocalizationService', {});
    return {
      v3: v3,
      kendoLocalizationService: kendoLocalizationService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var _a = this.$props,
        sort = _a.sort,
        column = _a.column;
    var currentSortIndex = sortIndex(column.field, sort);
    var localizationService = kendo_vue_intl_1.provideLocalizationService(this);
    return (// @ts-ignore function children
      h(GridColumnMenuItemGroup_1.GridColumnMenuItemGroup, this.v3 ? function () {
        return [// @ts-ignore
        h(GridColumnMenuItem_1.GridColumnMenuItem, {
          title: localizationService.toLanguageString(messages_1.sortAscending, messages_1.messages[messages_1.sortAscending]),
          attrs: _this.v3 ? undefined : {
            title: localizationService.toLanguageString(messages_1.sortAscending, messages_1.messages[messages_1.sortAscending]),
            iconClass: 'k-i-sort-asc-sm',
            selected: sortedAsc(currentSortIndex, sort)
          },
          iconClass: 'k-i-sort-asc-sm',
          selected: sortedAsc(currentSortIndex, sort),
          onMenuitemclick: _this.onAscClick,
          on: _this.v3 ? undefined : {
            "menuitemclick": _this.onAscClick
          }
        }), // @ts-ignore
        h(GridColumnMenuItem_1.GridColumnMenuItem, {
          title: localizationService.toLanguageString(messages_1.sortDescending, messages_1.messages[messages_1.sortDescending]),
          attrs: _this.v3 ? undefined : {
            title: localizationService.toLanguageString(messages_1.sortDescending, messages_1.messages[messages_1.sortDescending]),
            iconClass: 'k-i-sort-desc-sm',
            selected: sortedDesc(currentSortIndex, sort)
          },
          iconClass: 'k-i-sort-desc-sm',
          selected: sortedDesc(currentSortIndex, sort),
          onMenuitemclick: _this.onDescClick,
          on: _this.v3 ? undefined : {
            "menuitemclick": _this.onDescClick
          }
        })];
      } : [h(GridColumnMenuItem_1.GridColumnMenuItem, {
        title: localizationService.toLanguageString(messages_1.sortAscending, messages_1.messages[messages_1.sortAscending]),
        attrs: _this.v3 ? undefined : {
          title: localizationService.toLanguageString(messages_1.sortAscending, messages_1.messages[messages_1.sortAscending]),
          iconClass: 'k-i-sort-asc-sm',
          selected: sortedAsc(currentSortIndex, sort)
        },
        iconClass: 'k-i-sort-asc-sm',
        selected: sortedAsc(currentSortIndex, sort),
        onMenuitemclick: _this.onAscClick,
        on: _this.v3 ? undefined : {
          "menuitemclick": _this.onAscClick
        }
      }), h(GridColumnMenuItem_1.GridColumnMenuItem, {
        title: localizationService.toLanguageString(messages_1.sortDescending, messages_1.messages[messages_1.sortDescending]),
        attrs: _this.v3 ? undefined : {
          title: localizationService.toLanguageString(messages_1.sortDescending, messages_1.messages[messages_1.sortDescending]),
          iconClass: 'k-i-sort-desc-sm',
          selected: sortedDesc(currentSortIndex, sort)
        },
        iconClass: 'k-i-sort-desc-sm',
        selected: sortedDesc(currentSortIndex, sort),
        onMenuitemclick: _this.onDescClick,
        on: _this.v3 ? undefined : {
          "menuitemclick": _this.onDescClick
        }
      })])
    );
  }
};
exports.GridColumnMenuSort = GridColumnMenuSort;