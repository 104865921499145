"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridRow = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var utils_1 = require("../utils");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridRow` component.
 */


var GridRow = {
  props: {
    rowType: String,
    dataItem: Object,
    isAltRow: Boolean,
    isHidden: Boolean,
    onClick: Function,
    selectedField: String,
    rowHeight: Number,
    render: [String, Function, Object]
  },
  methods: {
    handleClick: function handleClick(e) {
      this.$emit('rowclick', e);
    },
    handleDoubleClick: function handleDoubleClick(e) {
      this.$emit('rowdblclick', e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
    var className = this.$props.rowType === 'groupFooter' || this.$props.rowType === 'groupHeader' ? this.$props.rowType === 'groupFooter' && 'k-group-footer' || 'k-grouping-row' : (this.$props.isAltRow ? 'k-master-row k-alt' : 'k-master-row') + (this.$props.selectedField !== undefined && utils_1.getNestedValue(this.$props.selectedField, this.$props.dataItem) ? ' k-state-selected' : '');
    var defaultRendering = h("tr", {
      onClick: this.handleClick,
      on: this.v3 ? undefined : {
        "click": this.handleClick,
        "dblclick": this.handleDoubleClick
      },
      onDblclick: this.handleDoubleClick,
      "class": className,
      style: {
        height: this.$props.rowHeight ? this.$props.rowHeight + 'px' : '',
        visibility: this.$props.isHidden ? 'hidden' : ''
      }
    }, [defaultSlot]);
    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: this.$props.render,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        click: this.handleClick
      },
      defaultSlots: defaultSlot,
      swapDefaultSlots: true
    });
  }
};
exports.GridRow = GridRow;