export class CodiciPagamento {
	constructor() {
		this.itemID = null;
		this.codicePagamento = null;
		this.descrizione = null;
		this.ultimaModifica = null;
		this.verificaLimite = null;
		this.immediato = null;
		this.mesiLimite = null;

	}
	itemID: number;
	codicePagamento: string;
	descrizione: string;
	ultimaModifica: Date | string | null;
	verificaLimite: boolean | null;
	immediato: boolean | null;
	mesiLimite: number | null;
}