"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilterRow = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var filterCommon_1 = require("../filterCommon");

var GridFilterCell_1 = require("../cells/GridFilterCell");
/**
 * Represents the default `FilterRow` component.
 */


var FilterRow = {
  props: {
    grid: Object,
    columns: Array,
    filter: Object,
    sort: [Object, Array],
    cellRender: [String, Function, Object]
  },
  methods: {
    headerCellClassName: function headerCellClassName(field) {
      if (this.$props.sort && this.$props.sort.filter(function (descriptor) {
        return descriptor.field === field;
      }).length > 0) {
        return 'k-sorted';
      }

      return '';
    },
    setFilter: function setFilter(value, operator, field, e) {
      var filters = [];

      if (value !== '' && value !== null || operator !== '' && filterCommon_1.IsUnaryFilter(operator)) {
        filters.push({
          field: field,
          operator: operator,
          value: value
        });
      }

      if (this.$props.filter && this.$props.filter.filters) {
        var oldFilters = this.$props.filter.filters || [];
        oldFilters.forEach(function (filter) {
          var descriptor = filter;

          if (descriptor && descriptor.field !== field) {
            filters.push(descriptor);
          }
        });
      }

      var filtersResult = filters.length > 0 ? {
        logic: 'and',
        filters: filters
      } : null;
      this.$emit('filterchange', filtersResult, e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var that = this;
    var oldFilters = this.$props.filter && this.$props.filter.filters || [];

    var activeFilterValueFor = function activeFilterValueFor(field, defaultValue) {
      if (defaultValue === void 0) {
        defaultValue = null;
      }

      var found = oldFilters.filter(function (filter) {
        return filter.field === field;
      })[0];
      return found ? found.value : defaultValue;
    };

    var activeOperatorFor = function activeOperatorFor(field) {
      var found = oldFilters.filter(function (filter) {
        return filter.field === field;
      })[0];
      return found ? found.operator : null;
    };

    var filterCellProps;
    var serviceIndex = 0,
        index = -1;
    var filterCells = this.$props.columns.filter(function (c) {
      return c.children.length === 0;
    }).map(function (column) {
      var _this = this;

      var onChangeFn = function onChangeFn(e) {
        _this.setFilter(e.value, e.operator, column.field, e);
      };

      var filterType = column.filter === undefined ? 'text' : column.filter; // @ts-ignore

      var filterRender = h(GridFilterCell_1.GridFilterCell, {
        grid: this.$props.grid,
        attrs: this.v3 ? undefined : {
          grid: this.$props.grid,
          field: column.field,
          value: activeFilterValueFor(column.field, filterType === 'text' ? '' : null),
          operator: activeOperatorFor(column.field),
          filterType: column.filter === undefined ? 'text' : column.filter,
          render: column.filterCell || this.$props.cellRender
        },
        field: column.field,
        value: activeFilterValueFor(column.field, filterType === 'text' ? '' : null),
        operator: activeOperatorFor(column.field),
        filterType: column.filter === undefined ? 'text' : column.filter,
        onChange: onChangeFn,
        on: this.v3 ? undefined : {
          "change": onChangeFn
        },
        render: column.filterCell || this.$props.cellRender
      });
      var key = column.declarationIndex >= 0 ? ++index : --serviceIndex;
      var filterCell = h("th", {
        key: key,
        style: column.left !== undefined && {
          position: 'sticky',
          left: column.left + 'px',
          right: column.right + 'px',
          zIndex: 1,
          background: '#f6f6f6',
          borderRightWidth: column.rightBorder ? '1px' : ''
        } || {},
        "class": this.headerCellClassName(column.field)
      }, [filterRender]);
      return column.filterable && filterCell || h("th");
    }, this);
    return h("tr", {
      "class": "k-filter-row"
    }, [filterCells]);
  }
};
exports.FilterRow = FilterRow;