import { render, staticRenderFns } from "./utenti.vue?vue&type=template&id=1bbb0d26&"
import script from "./utenti.vue.ts?vue&type=script&lang=ts&"
export * from "./utenti.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports