import moment from 'moment';
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

@Component({})
export default class AppDatePicker extends Vue {
	@Prop({ default: () => null })
	value: Date;
	@Prop({ default: () => false })
	disabled: boolean;

	onInputDateChange(e: string) {
		var date = moment(e, [moment.RFC_2822, moment.ISO_8601, "DD/MM/YYYY"]);
		if (date.isValid()) {
			this.$emit("input", date.toDate())
		}
		else
			this.$emit("input", null)
	}

	onDatePickerChange(date: Date) {
		this.$emit("input", date);
	}

	get date() {
		return this.$options.filters.dateToString(this.value);
	}
	get dateForPicker() {
		var date = moment(this.value, [moment.RFC_2822, moment.ISO_8601, "DD/MM/YYYY"])
		if (date.isValid()) {
			return date.toDate();
		}

		return null;
	}
}