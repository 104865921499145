import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Prop } from "vue-property-decorator";
import { AssStatoGruppoStatoItem } from "../../../models/anafrafica/AssStatoGruppoStatoItem";

const _ENDPONT = api.endpoint.FORNITORI_ASS_STATO_GRUPPO_STATO;
@Component
export default class AssStatoGruppoStato extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.FORNITORI];
	}
	@Prop({ default: () => null }) fornitoreID: number;

	gruppiStati: TextValueItem[] = [];
	fornitori: TextValueItem[] = [];
	stati: TextValueItem[] = [];

	editItem: AssStatoGruppoStatoItem = new AssStatoGruppoStatoItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true },

			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				values: this.fornitori,
				sortBy: "ragioneSociale",
				hidden: true,
				itemsForFilter: this.fornitori,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editDisabled: true,
				editSource: this.fornitori
			},
			{
				field: "nazione",
				title: this.$i18n.t("generico.stato").toString(),
				sortBy: "nazione",
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{
				field: "gruppoStato",
				title: this.$i18n.t("generico.gruppostato").toString(),
				sortBy: "gruppoStato",
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{
				field: "statoID",
				title: this.$i18n.t("generico.stato").toString(),
				sortBy: "nazione",
				headerCell: "myHeaderCellTemplate",
				hidden: true,
				values: this.stati,
				editSourceValue: "value",
				filterable: true,
				itemsForFilter: this.stati,
				filterType: "select",
				editType: "select",
				editSource: this.stati
			},
			{
				field: "gruppoStatoID",
				title: this.$i18n.t("generico.gruppostato").toString(),
				sortBy: "gruppoStato",
				headerCell: "myHeaderCellTemplate",
				hidden: true,
				values: this.gruppiStati,
				editSourceValue: "value",
				filterable: true,
				itemsForFilter: this.gruppiStati,
				filterType: "select",
				editType: "select",
				editSource: this.gruppiStati
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.GRUPPISTATI).then(res => (self.gruppiStati = res));
		var p1 = api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.STATI).then(res => (self.stati = res));
		Promise.all([p, p1, p2])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		var self = this;
		self.getGridData(`${_ENDPONT}/${this.fornitoreID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.gruppoStato}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.associazione")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new AssStatoGruppoStatoItem();
		newItem.fornitoreID = this.fornitoreID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;

		debugger;
		self.editItem = Object.assign(new AssStatoGruppoStatoItem(), item);
		self.editSubTitle = item.descrizione;
		self.editTitle = item.nomeProdotto;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.gruppostato") }).toString();
		self.setGridRow<AssStatoGruppoStatoItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
