export class ContraentiItem {
	constructor() {
		this.itemID = null;
		this.contraente = null;
		this.templateEmailCopertura = null;
		this.includiSuEC = null;
		this.raggruppamentoRiepilogoFatturati = null;
		this.ragioneSociale = null;
		this.partitaIva = null;
		this.indirizzo = null;
		this.citta = null;
		this.cap = null;
		this.provincia = null;
	}
	
	itemID: number | null;
	contraente: string;
	templateEmailCopertura: string;
	includiSuEC: boolean | null;
	raggruppamentoRiepilogoFatturati: number | null;
	ragioneSociale: string;
	partitaIva: string;
	indirizzo: string;
	citta: string;
	cap: string;
	provincia: string;
}