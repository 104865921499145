import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { GaranziePolizzeItem } from "@/models/prodotti/garanziePolizzeItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.POLIZZE.GARANZIE_POLIZZE;
@Component({})
export default class GaranziePolizze extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	editItem: GaranziePolizzeItem = new GaranziePolizzeItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	hint: string = "";
	valoreDisabled: boolean = false;
	tipoValore: string = "string";


	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "itemID", filterable: false, headerCell: "myHeaderCellTemplate", hidden: true, editHide: true },
			{ field: "nome", title: "Nome", headerCell: "myHeaderCellTemplate" },
			{ field: "testo", title: "Testo", headerCell: "myHeaderCellTemplate" },
			{ field: "codiceRamo", title: "Codice Ramo", headerCell: "myHeaderCellTemplate" },
			
			{ field: "imposte", title: "Imposte", headerCell: "myHeaderCellTemplate", editType: "numeric", headerType: "numeric",
				type: "renderfunction",
				renderFunction: this.cellRenderPercent
			},
			{ field: "pesoAssicurativo", title: "Peso Assicurativo", headerCell: "myHeaderCellTemplate", editType: "numeric", headerType: "numeric",
				type: "renderfunction",
				renderFunction: this.cellRenderPercent
			},


			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;
		self.getData();
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.extraInfo")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new GaranziePolizzeItem();
		newItem.prodottoID = this.prodottoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new GaranziePolizzeItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		debugger;
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.garanzia") }).toString();
		self.setGridRow<GaranziePolizzeItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
