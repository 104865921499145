import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { ParametriPolizeItem } from "@/models/prodotti/parametriPolizeItem";
import permessi from "@/config/permessi";
import editForm from "@/components/grid/edit/editForm.vue";

const _ENDPONT = api.endpoint.POLIZZE.PARAMETRI_POLIZRE;
@Component({})
export default class ParametriPolize extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	@Prop({ default: () => null }) prodottoID: number;
	@Prop({ default: () => null }) pacchettoID: number;
	@Prop({ default: () => false }) mostraNazionalita: boolean;
	editItem: ParametriPolizeItem = new ParametriPolizeItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	itemsGrid: TextValueItem[] = [];
	destinazioni: TextValueItem[] = [];
	stati: TextValueItem[] = [];
	nazionalita: TextValueItem[] = [];
	tipiPremio: TextValueItem[] = [];
	fasceEta: TextValueItem[] = [];
	tipiApllicazione: TextValueItem[] = [];
	tipiDestinazione: TextValueItem[] = [{ value: 1, text: "Gruppo Stato" }, { value: 2, text: "Stato" }];

	hideDestinazioneGruppoStato: boolean = false;
	hideDestinazioneStato: boolean = true;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 80, filterable: false, hidden: false, editHide: true, headerCell: "myHeaderCellTemplate" },
			{ field: "prodottoID", title: "ID", width: 20, filterable: false, hidden: true, editHide: true, headerCell: "myHeaderCellTemplate" },
			{ field: "codice", title: this.$i18n.t("generico.codice").toString(), filterable: true, headerCell: "myHeaderCellTemplate" },
			/*{
				field: "destinazioneA3",
				title: this.$i18n.t("generico.destinazione").toString(),
				width: 100,
				values: this.destinazioni,
				groupable: true,
				editSourceValue: "code",
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.destinazioni,
				editType: "select",
				editSource: this.destinazioni,
				sortBy: "destinazioneGruppoStato",
				hidden: true
			},*/
			// {
			// 	field: "tipoDestinazione",
			// 	title: "Tipo Destinazione",
			// 	width: 100,
			// 	values: this.tipiDestinazione,
			// 	groupable: true,
			// 	editSourceValue: "value",
			// 	headerCell: "myHeaderCellTemplate",
			// 	filterType: "select",
			// 	itemsForFilter: this.tipiDestinazione,
			// 	editType: "select",
			// 	editSource: this.tipiDestinazione,
			// 	hidden: true,
			// 	editSelectionCallBack: this.onChangeTipoDestinazione
			// },
			{
				field: "destinazioneID",
				title: this.$i18n.t("generico.destinazione").toString(),
				width: 100,
				values: this.destinazioni,
				groupable: true,
				editSourceValue: "value",
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.destinazioni,
				editType: "select",
				editSource: this.destinazioni,
				sortBy: "destinazioneGruppoStato",
				hidden: true,
				editHide: this.hideDestinazioneGruppoStato,
				editSelectionCallBack: this.onChangeDestinazioneID
			},
			// {
			// 	field: "destinazioneStatoID",
			// 	title: "Dest Stato",
			// 	width: 100,
			// 	values: this.stati,
			// 	groupable: true,
			// 	editSourceValue: "value",
			// 	headerCell: "myHeaderCellTemplate",
			// 	filterType: "select",
			// 	itemsForFilter: this.stati,
			// 	editType: "select",
			// 	editSource: this.stati,
			// 	sortBy: "destinazioneStato",
			// 	hidden: true,
			// 	editHide: this.hideDestinazioneStato,
			// 	editSelectionCallBack: this.onChangeDestinazioneStatoID
			// },
			{
				field: "nazionalitaID",
				title: this.$i18n.t("generico.nazionalita").toString(),
				width: 100,
				values: this.nazionalita,
				groupable: true,
				hidden: !this.mostraNazionalita,
				editType: "select",
				editSource: this.nazionalita,
				filterType: "select",
				itemsForFilter: this.nazionalita,
				editHide: !this.mostraNazionalita,
				headerCell: "myHeaderCellTemplate",
				sortBy: "nazionalitaDescrizione",
				// validation: { required: true, msg: this.$i18n.t("generico.campoRichiesto").toString() + ": " + this.$i18n.t("generico.nazionalita").toString() }
			},
			{ field: "destinazioneGruppoStato", title: this.$i18n.t("generico.destinazione").toString(), editHide: true, headerCell: "myHeaderCellTemplate", filterable: true },
			{ field: "destinazioneStato", title: "Dest Stato", editHide: true, headerCell: "myHeaderCellTemplate", filterable: true },

			{
				field: "durataMinima",
				title: this.$i18n.t("generico.durataMinima").toString(),
				format: "{0:n0}",
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
				filterType: "numeric-int",
				editChangeCallback: this.onChangeDurataMimina
			},
			{
				field: "durataMassima",
				title: this.$i18n.t("generico.durataMassima").toString(),
				format: "{0:n0}",
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
				filterType: "numeric-int",
				editChangeCallback: this.onChangeDurataMassima
			},
			{
				field: "tipoPremioID",
				title: this.$i18n.t("generico.tipoPremio").toString(),
				width: 100,
				values: this.tipiPremio,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipiPremio
			},
			{ field: "premio", title: this.$i18n.t("generico.premio").toString(), headerCell: "myHeaderCellTemplate", editType: "numeric", filterType: "numeric", type: "renderfunction", renderFunction: this.renderPremio },
			{ field: "premioMinimo", title: this.$i18n.t("generico.premioMinimo").toString(), headerCell: "myHeaderCellTemplate", editType: "numeric", filterType: "numeric" },
			{
				field: "fasciaEta",
				title: this.$i18n.t("generico.fasciaEta").toString(),
				width: 100,
				values: this.fasceEta,
				hidden: false,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.fasceEta
			},
			{
				field: "tipoImpostaID",
				title: this.$i18n.t("generico.tipoImposta").toString(),
				width: 100,
				values: this.tipiPremio,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipiPremio
			},
			{ field: "imposta", title: this.$i18n.t("generico.imposta").toString(), headerCell: "myHeaderCellTemplate", editType: "numeric", headerType: "numeric",
				type: "renderfunction",
				renderFunction: this.renderImposta
			},
			{ field: "startDate", title: this.$i18n.t("generico.startDate").toString(), format: "{0:dd/MM/yyyy}", headerCell: "myHeaderCellTemplate", type: "date" },
			{ field: "endDate", title: this.$i18n.t("generico.endDate").toString(), format: "{0:dd/MM/yyyy}", headerCell: "myHeaderCellTemplate", type: "date" },
			{ field: "postiMinimi", title: this.$i18n.t("generico.postiMinimi").toString(), hidden: true, format: "{0:n0}", headerCell: "myHeaderCellTemplate", editType: "numeric-int" },
			{ field: "postiMassimi", title: this.$i18n.t("generico.postiMassimi").toString(), hidden: true, format: "{0:n0}", headerCell: "myHeaderCellTemplate", editType: "numeric-int" },
			{
				field: "importoMinimo",
				title: this.$i18n.t("generico.importoMinimo").toString(),
				hidden: true,
				format: "{0:n0}",
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
				filterType: "numeric-int"
			},
			{
				field: "importoMassimo",
				title: this.$i18n.t("generico.importoMassimo").toString(),
				hidden: false,
				format: "{0:n0}",
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
				filterType: "numeric-int"
			},
			{ field: "limiteAssicurato", title: this.$i18n.t("generico.limiteAssicurato").toString(), hidden: true, headerCell: "myHeaderCellTemplate", editType: "numeric-int" },
			{
				field: "limiteMultiploGiorni",
				title: this.$i18n.t("generico.limiteMultiploGiorni").toString(),
				hidden: true,
				format: "{0:n0}",
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
				headerType: "numeric-int",
			},
			{ field: "limiteMultiploImporto", title: this.$i18n.t("generico.limiteMultiploImporto").toString(), hidden: true, headerCell: "myHeaderCellTemplate", editType: "numeric" },
			{
				field: "limiteMultiploPasseggeri",
				title: this.$i18n.t("generico.limiteMultiploPasseggeri").toString(),
				hidden: true,
				format: "{0:n0}",
				headerCell: "myHeaderCellTemplate",
				editType: "numeric-int",
			},
			{
				field: "tipoApplicazioneID",
				title: this.$i18n.t("generico.tipoApplicazione").toString(),
				width: 100,
				values: this.tipiApplicazione,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipiApllicazione
			},
			{ field: "applicazione", title: this.$i18n.t("generico.applicazione").toString(), hidden: true, headerCell: "myHeaderCellTemplate", editType: "numeric-int" },
			{ field: "cancellato", title: this.$i18n.t("generico.nonAttivo").toString(), hidden: true, headerCell: "myHeaderCellTemplate", type: "boolean" },
			{ field: "codiceTariffa", title: this.$i18n.t("generico.codiceTariffa").toString(), width: 90, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "istanteModifica", title: "Ultima Modifica", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true, type: "datetime"},
			{ field: "utenteModifica", title: "Utente", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true},
			{ field: "note", title: "Note", type:"textarea", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true},
			{
				field: "_actions",
				title: " ",
				width: (this.canEdit ? 100 : 0) + (this.canDelete ? 50 : 0),
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				hidden: (this.pacchettoID ? true : false) || (this.canEdit == false && this.canDelete == false)
			}
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(`${api.endpoint.DROPDOWN.GRUPPISTATI_PRODOTTO}/${this.prodottoID}`).then(res => (self.destinazioni = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.NAZIONALITA).then(res => (self.nazionalita = res));
		var p3 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => (self.tipiPremio = res));
		var p4 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPIAPPLICAZIONE).then(res => (self.tipiApllicazione = res));
		var p5 = api.getDatiDropDown(`${api.endpoint.DROPDOWN.STATI_PRODOTTO}/${this.prodottoID}`).then(res => (self.stati = res));
		var p6 = api.getDatiDropDown(`${api.endpoint.DROPDOWN.FASCE_ETA}`).then(res => (self.fasceEta = res));

		Promise.all([p, p2, p3, p4, p5, p6])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	onChangeTipoDestinazione(val, obj) {
		if (!val) {
			if (this.editItem.destinazioneID || !this.editItem.destinazioneStatoID) {
				val = 1;
			}
			else
				val = 2;
			this.editItem.tipoDestinazione = val;
		}
		if (val === 1) {
			this.hideDestinazioneGruppoStato = false;
			this.hideDestinazioneStato = true;
			this.editItem.destinazioneStatoID = null;
		}
		else if (val === 2) {
			this.hideDestinazioneGruppoStato = true;
			this.hideDestinazioneStato = false;
			this.editItem.destinazioneID = null;
		}
	}

	onChangeDestinazioneID() {
		this.updateCodiceEditItem();
	}

	onChangeDestinazioneStatoID () {
		this.updateCodiceEditItem();
	}

	onChangeDurataMassima() {
		this.updateCodiceEditItem();
	}	

	onChangeDurataMimina(){
		this.updateCodiceEditItem();
	}

	updateCodiceEditItem(){
		if(this.editItem.itemID) // creo il codice solo per i nuovi parametri
			return;
		
		var dest = "",
			durataMin = this.editItem.durataMinima || '',
			durataMassima = this.editItem.durataMassima || '';

		if(this.editItem.destinazioneStatoID){
			var stato = this.stati.find(obj => obj.value === this.editItem.destinazioneStatoID)
			dest = stato ? stato.text : '';
		}
		else if(this.editItem.destinazioneID){
			var destinaz = this.destinazioni.find(obj => obj.value === this.editItem.destinazioneID)
			dest = destinaz ? destinaz.text : '';
		}

		this.editItem.codice = dest + " " + durataMin + " " + durataMassima;
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.parametri")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new ParametriPolizeItem();
		newItem.prodottoID = this.prodottoID;
		//var newItem = this.gridData.data[0];

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new ParametriPolizeItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;

		var err = (this.$refs.editForm as editForm).validate();

		if (err) {
			var msgs = [];
			for (var i = 0; i < err.length; i++) msgs.push(this.$createElement("div", null, err[i]));

			self.$bvToast.toast(msgs, {
				variant: "danger",
				title: self.$i18n.t("msg.erroreGenerico").toString(),
				solid: true
			});
			return;
		}

		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.parametri") }).toString();
		self.setGridRow<ParametriPolizeItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;

			if (res && res.status == "ok" && res.data) {
				var keys = Object.keys(res.data);

				for (var i = 0; i < keys.length; i++) {
					//var msg = `Il parametro con ID ${keys[i]} ha nel gruppo stato a lui associato i seguenti stati che si sovrappongono con il parametro appena salvato: ${res.data[keys[i]]}`;
					var msg = this.$i18n.t("warning.parametriStessoStato").toString();
					msg = msg.replace("{0}", keys[i]);
					msg = msg.replace("{1}", res.data[keys[i]]);
					self.$bvToast.toast(msg, {
						variant: "warning",
						title: self.$i18n.t('generico.warning').toString(),
						autoHideDelay: 15000,
						solid: true,
						toaster: "b-toaster-bottom-full"
					});
				}
			}
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	async duplicaParametro(item: any){
		var self = this;
		var parametroID = item.itemID;
		var res = await api.chiamataPost(`${_ENDPONT}/${parametroID}/duplica`, {}).then(res => (self.getData()));
	}
	
	renderPremio(data, dataItem){
		var simbolo = dataItem.tipoPremioID === 1 ? '€' : dataItem.tipoPremioID === 2 ? '%' : '';

		return data ? data + " " + simbolo : "";
	}

	renderImposta(data, dataItem){
		var simbolo = dataItem.tipoImpostaID === 1 ? '€' : dataItem.tipoImpostaID === 2 ? '%' : '';

		return data ? data + " " + simbolo : "";
	}
}
