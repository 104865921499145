import permessi from '@/config/permessi';
import api from '@/services/api';
import { Component, Prop } from 'vue-property-decorator';
import gridComponentBase from '../../../components/grid/gridComponentBase.vue';
import { TextValueItem } from '@/models/TextValueItem';

const _ENDPONT = api.endpoint.REPORTFORNITORE;

@Component
export default class GridStoricoDettaglioJSONPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Vendite.REPORTFORNITORE]; }
	@Prop({ default: () => 0 })
	itemId: number;

	azioni: TextValueItem[] = [{value: 1, text:"Creazione"}, {value: 2, text:"Modifica"}, {value: 3, text:"Cancellazione"}];
	esiti: TextValueItem[] = [{value: 0, text:"Nessuna risposta"}, {value: 1, text:"OK"}, {value: 2, text:"ERRORE"}];

	
	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "adesioneID", title: "Adesione", width: "15%", filterType: "numeric-int", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true, },

			{
				field: "istanteInserimento",
				title: "Data Creazione",
				width: "15%",
				format: "{0:dd/MM/yyyy HH:mm:ss}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "datetime",
				headerType: "datetimerange",
				exportRenderFunction: this.exportRenderDateTime
			},

			{
				field: "esito",
				title: "Esito",
				width: "15%",
				values: this.esiti,
				editSourceValue: "value",
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.esiti,
				export: true
			},

			{
				field: "azione",
				title: "Azione",
				width: "15%",
				values: this.azioni,
				editSourceValue: "value",
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.azioni,
				export: true
			},

			{ field: "risposta", title: "Risposta", headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			
		];
	}

	sort: any = [{ field: "adesioneID", dir: "asc" }];

	created() {
		this.getData();
	}

	exportExcel() {
		this.exportGridExcel(`${_ENDPONT}/storicodettagliojson/${this.itemId}`);
	}

	getData() {
		var self = this;
		this.getGridData(`${_ENDPONT}/storicodettagliojson/${this.itemId}`);
	}
}