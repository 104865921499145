import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { SchedaParametriItem } from "@/models/amministrazione/parametri/SchedaParametriItem";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.PARAMETRI;
@Component({})
export default class Parametri extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Amministrazione.PARAMETRI]; }
	tabPemessi(tab: string): boolean {
		if (tab == "tipologieParametri") return this.hasPermesso(permessi.BK.Amministrazione.TIPI_PARAMETRI);

		else return false;
	}
	editItem: SchedaParametriItem = new SchedaParametriItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	tipiParametri: TextValueItem[] = [];

	showOnlyActive: boolean = false;


	get columns(): Array<any> {
		return [
			{ field: "schedaParametroID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "nomeParametro", title: this.$i18n.t("generico.nomeParametro").toString(),  headerCell: "myHeaderCellTemplate"},
			// { field: "tipo", title: this.$i18n.t("generico.tipo").toString(),  headerCell: "myHeaderCellTemplate"},
			{
				field: "tipo",
				title: this.$i18n.t("generico.tipo").toString(),
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.tipiParametri,
				filterable: true,
				itemsForFilter: this.tipiParametri,
				filterType: "select",
				editType: "select",
				editSource: this.tipiParametri,
				editSourceText: "text",
				editSourceValue: "value",
				editSelectionCallBack: this.onChangePagamento
			},
			{ field: "note", title: this.$i18n.t("generico.note").toString(),  headerCell: "myHeaderCellTemplate", editType: "textarea"},
			{ field: "nomeXML", title: this.$i18n.t("generico.nomeXML").toString(),  headerCell: "myHeaderCellTemplate"},
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(),  headerCell: "myHeaderCellTemplate"},
			{ field: "categoria", title: this.$i18n.t("generico.categoria").toString(),  headerCell: "myHeaderCellTemplate"},
			{
				field: "ordine",
				title: this.$i18n.t("generico.ordine").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "numeric-int",
				filterType: "numeric-int",
				width: 120
			},
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "tipo", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([
			api.getDatiDropDown(api.endpoint.DROPDOWN.TIPOLOGIE_PARAMETRI).then(res => {
				if (res && res.length) {
					for (var i = 0; i < res.length; i++) {
						var item = res[i];
						var code = item.value;
						var text = item.text;

						self.tipiParametri.push({ value: code, text: text });
					}
				}
			})
		])
		.then(responses => {
			self.getData();
		})
		.catch(err => {
			console.log(err);
		});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.schedaParametroID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.parametro")]).toString();
		self.deleteGridRow(_ENDPONT, item.schedaParametroID, title, [message]);
	}

	addNewRow() {
		let newItem = new SchedaParametriItem();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new SchedaParametriItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.schedaParametroID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.parametro") }).toString();
		self.setGridRow<SchedaParametriItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).schedaParametroID == null || (this.editItem || {}).schedaParametroID <= 0;
	}
}
