
const appConfig = {

	footer: {
		htmlText: '<small>© <b>2014 WebIns</b>. All Rights Reserved.</small>',
		cssClass: 'px-3 py-2 bgg90-black text-muted'
	},
	loginPage:{
		headerText : "WebIns"
	}
}
export default appConfig