import { Component, Prop } from "vue-property-decorator";
import baseUserContextComponent from "./baseUserContextComponent";
import * as s from "@/router/router";
import { appRoute } from "@/models/appRoute";
import { Route } from "vue-router";

@Component({})
export default class subTopNav extends baseUserContextComponent {
	@Prop({ default: () => 'tabs' })
	layout: string;
	beforeDestroy() { }
	beforeMount() { }
	mounted() { }
	beforeCreate() { }
	created() {
		var self = this;
	}
	// get parentname() {
	// 	var self = this;
	// 	return (self.currentRoute.parent || {}).name;
	// }
	get items() {
		var self = this;
		var name = (self.currentRoute.parent || {}).name;
		return self.routes
			.filter(x => x.parent != null && x.parent.name == name && x.meta != null && x.meta.topSubNav != null && self.hasPermessi(x.meta.permissions))
			.sort((a, b) =>
				a.meta.topSubNav.displayOrder > b.meta.topSubNav.displayOrder
					? 1
					: -1
			);
	}

	getLabel(r: appRoute): string {
		return r.meta.label;
	}
	getPath(r: appRoute): string {
		return r.path;
	}
	isPressed(r: appRoute): boolean {
		var self = this;
		return self.$route.name == r.name;
	}

}
