import Vue from "vue";

class eventClass extends Vue {
	public readonly events = {
		AXIOS_RESPONSE_ERROR: "axios-response-error",
		AXIOS_REQUEST_STARTED: "axios-request-started",
		AXIOS_REQUEST_ENDED: "axios-request-ended",
		USER_LOGIN: "user-login",
		USER_LOGOUT: "user-logout",
		CULTURE_CHANGE: "culture-change",
	};
}

const eventHub = new eventClass();
export default eventHub;
