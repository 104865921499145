import { datiViaggio } from "@/models/carrello/datiViaggio";
import { Component, Prop } from "vue-property-decorator";
import baseUserContextComponent from "../baseUserContextComponent";

@Component({

})
export default class infoProdottoPreventivo extends baseUserContextComponent {
	@Prop({ default: () => new datiViaggio() }) datiViaggio: datiViaggio;

	get textTotale() {
		// return this.datiViaggio && this.datiViaggio.parametri && this.datiViaggio.parametri.numeroAlloggi ? "totale per alloggio" :
		// 		"totale per persona";
		return "totale per persona";
	}
}
