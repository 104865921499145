import { Azioni, Permesso, PermessoGruppo, RuoloPermesso } from "@/models/ruolo";
import api from "@/services/api";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import baseUserContextComponent from "./baseUserContextComponent";
import Vue from "vue";
import permessi from "@/config/permessi";



@Component({
})
export default class associaPermessi extends baseUserContextComponent {
	loading: boolean = false;
	get PERMESSI(): string[] { return [permessi.BK.Anagrafica.RUOLI]; }
	@Prop({ default: () => null }) itemID: number;
	@Watch('itemID')
	onItemIDChange() {
		var self = this;
		if (self.itemID) {
			self.loading = true;
			api.getPermessi(self.itemID).then(res => self.permessi = res).finally(() => self.loading = false);
		}
		else
			self.permessi = [];
	}
	@Prop({ default: () => null }) superAdmin: boolean;
	@Prop({ default: () => null }) backOffice: boolean;
	@Watch('backOffice')
	onBackOfficeChange() {
		var self = this;
		self.elencoPermessi = self.builElencoPermessi();
	}
	listaPermessi: Permesso[] = [];
	elencoPermessi: PermessoGruppo[] = [];
	builElencoPermessi(): PermessoGruppo[] {
		var self = this;
		var gruppi = [];
		var filtered = (self.listaPermessi || []).filter(x => (self.backOffice || false) == (x.backOffice || false)).sort((a, b) => a.distplayOrder < b.distplayOrder ? -1 : 1)
		
		filtered.forEach(item => {
			var gruppo = gruppi.find(x => x.gruppo == item.gruppo);
			
			if (gruppo != null) {
				gruppo.permessi.push(item);
			} else {
				gruppo = { gruppo: item.gruppo, permessi: [], visible: true };
				gruppo.permessi.push(item);
				gruppi.push(gruppo);
			}
		});
		return gruppi.sort((a, b) => a.gruppo > b.gruppo ? 1 : -1);
	}
	permessi: RuoloPermesso[] = [];
	created() {
		var self = this;
		api.getPermessiList().then(res => {
			self.listaPermessi = res;
			self.elencoPermessi = self.builElencoPermessi();
			self.onItemIDChange();
		});
	}
	onSuperAdminChange() {
		this.$emit("superAdminToggle", this.itemID);
	}
	onSetPermesso(aggiungi: boolean, codice: string, azioni: string[]) {
		this.$emit("aggiungiRimuoviPermesso", { ruoloID: this.itemID, aggiungi: aggiungi, permesso: codice, azioni: azioni });
	}
	isChecked(p: Permesso, azione: Azioni) {
		return (this.permessi || []).find(x => x.codice == p.codice && (x.azioni || []).find(a => a == azione) != null) != null;
	}
	onClickHeader(g: PermessoGruppo) {

		Vue.set(g, 'visible', !g.visible);
	}
}
