import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import datePicker from "vue-bootstrap-datetimepicker";
import baseUserContextComponent from "@/components/baseUserContextComponent";
import { AnagraficaModelloImportItem, Colonne } from "@/models/amministrazione/praticheMultiple/AnagraficaModelloImportItem";
import { Defaults } from '../../../models/amministrazione/praticheMultiple/AnagraficaModelloImportItem';
import { KeyValuePairItem } from '../../../models/KeyValuePairItem';

// @Component({ components: { datePicker } })
@Component({})
export default class columnsMappingBase extends baseUserContextComponent {
	@Prop({ default: () => null })
	value: AnagraficaModelloImportItem;

	optionsDateTimePicker: any = {
		//format: 'DD/MM/YYYY HH:mm:ss',
		extraFormats: ["YYYY-MM-DDThh:mm:ssZ"],
		useCurrent: false,
		showClear: true,
		showClose: true,
		locale: 'it',
		icons: {
			time: "fa fa-clock",
			date: "fa fa-calendar",
			up: "fa fa-arrow-up",
			down: "fa fa-arrow-down",
			previous: "fa fa-chevron-left",
			next: "fa fa-chevron-right",
			today: "fa fa-clock",
			clear: "fa fa-trash"
		},
		tooltips: {
			today: 'Oggi',
			clear: 'Cancella selezione',
			close: 'Chiudi',
			selectMonth: 'Seleziona mese',
			prevMonth: 'Mese precedente',
			nextMonth: 'Mese successivo',
			selectYear: 'Seleziona anno',
			prevYear: 'Anno precendente',
			nextYear: 'Anno successivo',
			selectDecade: 'Seleziona decade',
			prevDecade: 'Decade precedente',
			nextDecade: 'Decade successiva'
		}
	};

	get list(): string[] {
		if (this.value.schema.generale.partecipantiSuUnicaRiga) {
			// delete this.value.schema.colonne.colonnaCognome;
			// delete this.value.schema.colonne.colonnaCodiceFiscale;
			// delete this.value.schema.colonne.colonnaDataNascita;
			// delete this.value.schema.colonne.colonnaNome;

			var colonne = Object.keys(this.value.schema.colonne).filter(function(x: string) {
				return x != "colonnaCognome" && x != "colonnaCodiceFiscale" && x != "colonnaDataNascita" && x != "colonnaNome";
			});

			return colonne;
		}
		
		var colonne = Object.keys(this.value.schema.colonne).filter(function(x: string) {
			return x != "colonnaPartecipanti";
		});

		return colonne;
	}

	get listDeafult(): Defaults {
		return this.value.schema.defaults;
	}

	columnValue(index: number) {
		if (this.value.campiSorgente && this.value.campiSorgente.length >= index) {
			return this.value.campiSorgente[index];
		}

		return this.value.campiSorgente[0];
	}

	onChangeDropdownValue(obj: any, m: string) {
		var item = ((obj || {}).target || {}).value as KeyValuePairItem;
		this.value.schema.colonne[m] = item.key;
	}

	getComponentName(m: any): string {
		return `input-type-${m.columnDataType}`;
	}
	isComponentDefined(nameOfComponent: string): boolean {
		var self = this;
		var b = self.$options.components[nameOfComponent];
		return b != null;
	}
	onConfigClick(m: any) {
		this.$emit("configField", m);
	}
	openCalendar(columnName) {
		var self = this;
		(self.$refs[columnName] as any)[0].dp.toggle();
	}

	onInputFileValue(value: File) {
		var fileValueField = `schemaFile_uploadFile`;

		Vue.set(this.value, fileValueField, value);
	}
}
