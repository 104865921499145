
import axios from 'axios';


var api = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL
});

api.interceptors.request.use(config => {
	config.headers.common["Authorization"] = "bearer " + localStorage.getItem("overins-token");
	return config;
});


var setImgSrc = (el, binding) => {
	if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
		var imageUrl = binding.value;
		api({
			method: 'get',
			url: imageUrl,
			responseType: 'arraybuffer'
		})
			.then(function (resp) {
				var mimeType = resp.headers['content-type'].toLowerCase();
				// var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
				var imgBase64 = Buffer.from(resp.data).toString('base64');
				el.src = 'data:' + mimeType + ';base64,' + imgBase64;
			}).catch((function () {
				el.src = imageUrl;
			}));
	}
}
export default {

	bind: function (el, binding) {
		var self = this;
		setImgSrc(el, binding);
	},
	componentUpdated: function (el, binding) {
		var self = this;
		setImgSrc(el, binding);
	}
};