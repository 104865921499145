import permessi from "@/config/permessi";
import { ElencoInviiItem } from "@/models/reportFornitori/elencoInviiItem";
import apiFile from "@/services/apiFile";
import { Component, Prop } from "vue-property-decorator";
import gridComponentBase from "../../../components/grid/gridComponentBase.vue";

@Component
export default class StoricoInviiReportFornitorePage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Vendite.REPORTFORNITORE];
	}
	@Prop({ default: () => "" })
	endpoint: string;

	@Prop({ default: () => false })
	isTypeJSON: boolean;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "jobId", title: "Job ID", headerCell: "myHeaderCellTemplate", filterable: true, export: true, editDisabled: true },
			{ field: "fileGenerato", title: "File", headerCell: "myHeaderCellTemplate", filterable: true, export: true, editDisabled: true, cell: "downloadFileGeneratoCellTemplate" },
			{ field: "stato", title: "Stato", headerCell: "myHeaderCellTemplate", filterable: true, export: true, editDisabled: true },
			{
				field: "dataCreazione",
				title: "Data Creazione",
				width: 200,
				format: "{0:dd/MM/yyyy HH:mm:ss}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "dataModifica",
				title: "Data Modifica",
				width: 200,
				format: "{0:dd/MM/yyyy HH:mm:ss}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "isScheduleJob",
				title: "Da Schedulatore",
				width: 200,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				disabled: true
			},
			{
				field: "isInProgress",
				title: "In Esecuzione",
				width: 200,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				disabled: true
			},
			{
				field: "inviato",
				title: "Inviato",
				width: 200,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				disabled: true
			},
			{
				field: "confermaLettura",
				title: "Conferma di lettura",
				width: 200,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				disabled: true
			},
			{ field: "errore", title: "Errore", headerCell: "myHeaderCellTemplate", filterable: true, export: true, editDisabled: true }
		];
	}

	sort: any = [{ field: "dataCreazione", dir: "desc" }];

	created() {
		this.getData();
	}

	exportExcel() {
		this.exportGridExcel(this.endpoint);
	}

	refreshData() {
		this.getData();
	}

	getData() {
		this.getGridData(this.endpoint);
	}

	downloadFileGenerato(item: ElencoInviiItem, fileFullName: string) {
		var self = this;
		//var fileFullName = item.fileGenerato;

		var fileName = item.nomeFileGenerato || item.jobId;		
		
		var ext =  fileFullName.split('.').pop();

		if( ext === "txt" || ext === "json" ){
			if( fileFullName.endsWith("crea.json") || fileFullName.endsWith("crea.txt"))
				fileName = "Creazioni";
			else  if( fileFullName.endsWith("modifica.json")  || fileFullName.endsWith("modifica.txt"))
				fileName = "Modifiche";
			if( fileFullName.endsWith("annulla.json")  || fileFullName.endsWith("annulla.txt"))
				fileName = "Cancellazioni";
		}
		
		apiFile
			.downloadReportFornitore(fileFullName)
			.then(response => {
				console.log(response);

				var fileType = "application/" + ext;
				if( ext === "txt" )
					fileType = "text/plain";


				const blob = new Blob([response.data], { type: fileType });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = `${fileName}.${ext}`;
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}
}
