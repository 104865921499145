"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropClue = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
/**
 * Represents the default `DropClue` component.
 */

var DropClue = {
  data: function data() {
    return {
      height: 0,
      visible: false,
      left: 0,
      top: 0
    };
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    return this.visible && h("div", {
      "class": "k-grouping-dropclue",
      style: {
        zIndex: 10000,
        display: 'block',
        top: this.top + 'px',
        left: this.left + 'px',
        height: this.height + 'px'
      }
    });
  }
};
exports.DropClue = DropClue;