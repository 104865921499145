import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";

@Component({
})
export default class actionCellTemplate extends Vue {
	@Prop({ default: () => '' })
	field: string;
	@Prop({ default: () => { } })
	dataItem: any;
	@Prop({ default: '' })
	format: string;
	@Prop({ default: () => '' }) className: string;
	@Prop({ default: () => '' }) rowType: string;
	@Prop({ default: () => null }) columnIndex: number;
	@Prop({ default: () => null }) level: number;
	@Prop({ default: () => null }) columnsCount: number;
	@Prop({ default: () => null }) expanded: boolean;
	@Prop({ default: () => '' }) editor: string;

	//Custom Prop
	@Prop({ default: () => 'string' }) type: string;
	@Prop({ default: () => false }) disabled: boolean;
	@Prop({ default: () => false }) showAdd: boolean;
	@Prop({ default: () => true }) showEdit: boolean;
	@Prop({ default: () => true }) showDelete: boolean;
}