export class ModificheAbilitazione {
	constructor() {
		this.itemID = null;
	}

	itemID: number | null;
	descrizione: string;
	fornitoreID: number | null;
	categoriePacchetto: string;
	distributoreInclusoID: string;
	distributoreEsclusoID: string;
	prodotti: string;
	pacchetti: string;
	deltaGiorniPartenza: number | null;
	prezzoInvariato: number | null;
	soloRichiestaModifica: boolean | null;
	copiaAdesione: boolean | null;
	chNomi: boolean | null;
	chNomiDeltaGiorni: number | null;
	chNomiMessaggioWarning: string;
	chNomiFlagWarning: boolean | null;
	chNomiMessaggioToDo: string;
	chNomiCausaleObbligatoria: boolean | null;
	chNomiCausali: string;
	chDate: boolean | null;
	chDateDeltaGiorni: number | null;
	chDateMessaggioWarning: string;
	chDateFlagWarning: boolean | null;
	chDateMessaggioToDo: string;
	chDateCausaleObbligatoria: boolean | null;
	chDateCausali: string;
	chImportoViaggio: boolean | null;
	chImportoViaggioDeltaGiorni: number | null;
	chImportoViaggioMessaggioWarning: string;
	chImportoViaggioFlagWarning: boolean | null;
	chImportoViaggioMessaggioToDo: string;
	chImportoViaggioCausaleObbligatoria: boolean | null;
	chImportoViaggioCausali: string;
	chNumeroAssicurati: boolean | null;
	chNumeroAssicuratiDeltaGiorni: number | null;
	chNumeroAssicuratiMessaggioWarning: string;
	chNumeroAssicuratiFlagWarning: boolean | null;
	chNumeroAssicuratiMessaggioToDo: string;
	chNumeroAssicuratiCausaleObbligatoria: boolean | null;
	chNumeroAssicuratiCausali: string;
}