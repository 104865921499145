import permessi from "@/config/permessi";
import CommissioneModifica from "@/models/commerciali/CommissioneModifica";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component } from "vue-property-decorator";
import gridComponentBase from "../../../components/grid/gridComponentBase.vue";

const _ENDPONT = api.endpoint.COMMISSIONI.POLIZZE_COMMERCIALE;

@Component
export default class CommissioniPolizzeCommercialiPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Commerciali.COMMISSIONI]; }
	editItem: CommissioneModifica = new CommissioneModifica();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = false;

	tipiPremio: TextValueItem[] = [];
	commerciali: TextValueItem[] = [];
	prodotti: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "commerciale",
				title: this.$i18n.t("generico.commerciale").toString(),
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				editHide: true
			},
			{
				field: "commercialeID",
				title: this.$i18n.t("generico.commerciale").toString(),
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				hidden: true,
				export: false,
				editType: "select",
				editSource: this.commerciali,
				editSelectionCallBack: this.onEditCommercialeChange
			},
			{
				field: "prodottoID",
				title: "Polizza",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				hidden: true,
				export: false,
				editType: "select",
				editSource: this.prodotti
			},
			{
				field: "prodotto",
				title: "Polizza",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				editHide: true
			},
			{
				field: "tipoCommissioneID",
				title: this.$i18n.t("generico.tipoCommissione").toString(),
				width: 200,
				values: this.tipiPremio,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				sortBy: "tipoPremio",
				itemsForFilter: this.tipiPremio,
				filterType: "select",
				editType: "select",
				editSource: this.tipiPremio
			},
			{ field: "tipoPremio", title: this.$i18n.t("generico.tipoCommissione").toString(), hidden: true, editHide: true, export: true },
			{ field: "commissione", title: this.$i18n.t("generico.commissione").toString(), width: 150, groupable: true, headerCell: "myHeaderCellTemplate", filterable: true, filterType: "numeric", export: true, 
			type: "renderfunction", 
			renderFunction: this.renderCommissione,
		 },
			{
				field: "startDate",
				title: this.$i18n.t("generico.inizioRapporto").toString(),
				width: 200,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "endDate",
				title: this.$i18n.t("generico.fineRapporto").toString(),
				width: 200,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},

			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "commerciale", dir: "asc" }];

	created() {
		var self = this;
		let commerciali = this.getCommercialiDropDown();

		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => self.tipiPremio = res),
			commerciali])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() { }

	getCommercialiDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.COMMERCIALI)
			.then(res => {
				self.commerciali = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getProdottiDropDown(value: number) {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.COMMISSIONI.DROPDOWN.POLIZZE_COMMERCIALI.replace("{id}", value.toString()))
			.then(res => {
				self.prodotti = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	onEditCommercialeChange(value: number) {
		if (!isNaN(value) && value > 0) this.getProdottiDropDown(value);
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}

	addNewRow() {
		this.onEdit(new CommissioneModifica());
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<strong>${item.commerciale}</strong><h5>${item.prodotto}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.commissioniPolizzeCommerciale")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new CommissioneModifica(), item);
		self.editSubTitle = item.ragioneSociale;
		self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;

		api.setCommissioniPolizzeCommerciali(self.editItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.commissioniPolizzeCommerciale") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	get detailTemplate() {
		if (this.showDetail) return "detailTemplate";
		else return "";
	}
}
