"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridNoRecords = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
var inject = allVue.inject;

var kendo_vue_intl_1 = require("@progress/kendo-vue-intl");

var messages_1 = require("./messages");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the GridNoRecords component which is rendered
 * when the `data` property of the Grid is empty or `null`.
 *
 * @example
 * ```tsx-no-run
 *   <div id="app">
 *       <Grid
 *           :data-items="products"
 *           :columns="columns">
 *       <grid-norecords>
 *        There is no data available custom
 *        </grid-norecords>
 *       </Grid>
 *   </div>
 * <script>
 *       Vue.component('Grid', Grid);
 *       Vue.component('grid-norecords', GridNoRecords);
 *       new Vue({
 *           el: '#app',
 *           data: function () {
 *               return {
 *                  products: [
 *                    { 'foo': 'A1', 'bar': 'B1' },
 *                       { 'foo': 'A2', 'bar': 'B2' },
 *                       { 'foo': 'A3', 'bar': 'B2' }
 *                   ],
 *                   columns: [
 *                       { field: 'foo'},
 *                       { field: 'bar'}
 *                   ]
 *               };
 *           }
 *       });
 *   </script>
 * ```
 */

/**
 * Represents the default `GridNoRecords` component.
 */


var GridNoRecords = {
  name: 'GridNoRecords',
  inject: {
    kendoLocalizationService: {
      default: null
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    var kendoLocalizationService = inject('kendoLocalizationService', {});
    return {
      v3: v3,
      kendoLocalizationService: kendoLocalizationService
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
    var localizationService = kendo_vue_intl_1.provideLocalizationService(this);
    var noRecordsMessage = localizationService.toLanguageString(messages_1.noRecords, messages_1.messages[messages_1.noRecords]);
    return defaultSlot ? h("div", [defaultSlot]) // @ts-ignore
    : this.v3 ? noRecordsMessage : this._v(noRecordsMessage);
  }
};
exports.GridNoRecords = GridNoRecords;