import eventHub from "@/eventHub";
import Vuex, { ActionContext, Store } from "vuex";
// import store from "../store";
import { operationResult, operationResultStatus } from "@/models/operationResult";
import axios, { AxiosInstance } from "axios";
import Qs from "qs";
import { CambioPasswordModel, ChangePasswordModel, ForzaCambioPasswordModel, RecuperaPasswordModel, UserLoggedInfoModel, UserLoginModel, UserMeInfo } from "@/models/userModels";
import helpers from "@/helpers";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";
import { AnagraficaLingue } from "@/models/anafrafica/AnagraficaLingue";
import { AnagraficaTipiPremio } from "@/models/anafrafica/anagraficaTipiPremio";
import { AnagraficaDistributoreEdit, AnagraficaDistributoreLight } from "@/models/anafrafica/AnagraficaDistributore";
import { CommercialeDistributore } from "@/models/distributori/CommercialeDistributore";
import { CreditiDistributoriItem } from "../models/distributori/creditiDistributoriItem";
import { AnagraficaFornitori } from "../models/anafrafica/AnagraficaFornitori";
import { GruppiStatoPerFornitore } from "@/models/anafrafica/gruppiStatiPerFornitore";
import { GruppiStati } from "@/models/anafrafica/gruppiStati";
import { AnagraficaCommerciali } from "../models/commerciali/anagraficaCommerciali";
import { ElencoAssociazione } from "../models/commerciali/elencoAssociazione";
import { DistributoreAssociatoAnagraficaEdit } from "@/models/commerciali/DistributoreAssociatoAnagraficaEdit";
import CommissioneModifica from "@/models/commerciali/CommissioneModifica";
import { TextValueExtended, TextValueItem } from "../models/TextValueItem";
import { CommercialeCommissioneSimula } from "@/models/commerciali/commercialeCommissioneSimula";
import { CommissioniSimulaResult } from "@/models/commerciali/commissioniSimulaResult";
import { CommercialeCommissioneAnalisi } from "@/models/commerciali/commercialeCommissioneAnalisi";
import { CommercialeCommissioneAnalisiResult } from "@/models/commerciali/commercialeCommissioneAnalisiResult";
import { CommercialeCommissioneAnalisiTotali } from "@/models/commerciali/commercialeCommissioneAnalisiTotali";
import { Permesso, RuoloPermesso } from "@/models/ruolo";
import ReportFornitoreItem from "../models/reportFornitori/ReportFornitoreItem";
import { ExportReportItem } from "@/models/reportFornitori/exportReportItem";
import { ReportFornitoreExportItem } from "../models/reportFornitori/ReportFornitoreExportItem";
import qs from "qs";
import { ProdottiPolize } from "@/models/prodotti/prodottiPolize";
import EstrattiContoEditItem from "../models/estrattiConto/estrattiContoEditItem";
import ElencoAdesioniPerPagamentoEditItem from "../models/registrazionePagamenti/elencoAdesioniPerPagamentoEditItem";
import { LoginUserAsResponse } from "@/models/login/loginUserAsResponse";
import { DocumentiProdottiItem } from "@/models/prodotti/documentiProdottiItem";
import { DocumentiPolizeItem } from "@/models/prodotti/documentiPolizeItem";
import { SchedaPolizzaParametriValori, SchedaPolizzaValore } from "@/models/prodotti/schedaPolizzaItem";
import { method } from "lodash";
import { SchedaProdottoParametriValori } from "@/models/prodotti/schedaProdottoItem";
import { AnagraficaModelloImportEdit } from "@/models/anafrafica/AnagraficaModelloImport";
import { QueryDTO } from "@/models/analisiAvanzata/queryDTO";
import { QueryCategories } from "@/models/analisiAvanzata/queryCategories";
import { StatisticheQueryElenco } from "@/models/analisiAvanzata/statisticheQueryElenco";
import { ListaValoriRequest } from "@/models/analisiAvanzata/listaValoriRequest";
import { QueryValuesResponse } from "@/models/analisiAvanzata/queryValuesResponse";

class Api {
	private permessiList: Permesso[];

	public readonly endpoint = {
		DROPDOWN: {
			PACCHETTI: "dropdown/pacchetti",
			PRODOTTI: "dropdown/prodotti",
			CONTRAENTI: "dropdown/contraenti",
			CONTRATTI: "dropdown/contratti",
			DISTRIBUTORI: "dropdown/distributori",
			DISTRIBUTORI_UTENTI: "dropdown/distributoriutenti",
			ALL_DISTRIBUTORI: "dropdown/distributori/all",
			FORNITORI: "dropdown/fornitori",
			FORNITORIFILEINVIO: "dropdown/fornitorifileinvio",
			PROVINCE: "dropdown/province",
			REGIONI: "dropdown/regioni",
			COMMERCIALI: "dropdown/commerciali",
			RUOLI: "dropdown/ruoli",
			TIPOLOGIE_PRODOTTI: "dropdown/tipologieProdotti",
			CATEGORIE: "dropdown/categorie",
			FILEINVIO: "dropdown/fileinvio",
			DESTINAZIONI: "dropdown/destinazioni",
			NAZIONALITA: "dropdown/nazionalita",
			TIPIAPPLICAZIONE: "dropdown/tipiapplicazione",
			BENEFICIARI: "dropdown/beneficiari",
			PRODOTTI_COMMISSIONI: "dropdown/prodotticommissioni/{id}",
			TIPI_FILE: "dropdown/tipifile",
			TIPI_SCONTO: "dropdown/tipisconto",
			CODICIPAGAMENTO: "dropdown/codicipagamento",
			TIPO_DESCRIZIONI: "dropdown/tipodescrizioni",
			GRUPPISTATI: "dropdown/gruppistati",
			GRUPPISTATI_FORNITORE: "dropdown/gruppistati/fornitore",
			GRUPPISTATI_PRODOTTO: "dropdown/gruppistati/prodotto",
			GRUPPINAZIONALITA: "dropdown/gruppinazionalita",
			GRUPPINAZIONALITA_FORNITORE: "dropdown/gruppinazionalita/fornitore",
			GRUPPINAZIONALITA_PRODOTTO: "dropdown/gruppinazionalita/prodotto",
			TIPO_TESTI: "dropdown/tipotesti",
			TIPO_EXTRA_INFO: "dropdown/tipoextrainfo",
			TIPO_DISTRIBUTORE: "dropdown/tipidistributore",
			TIPOLOGIE_DISTRIBUTORE: "dropdown/tipologieDistributori",
			TIPI_PREMIO: "dropdown/tipipremio",
			LINGUE: "dropdown/lingue",
			PRODOTTI_VENDIBILI: "dropdown/pacchettivendibili",
			STATI: "dropdown/stati",
			STATI_PRODOTTO: "dropdown/prodotto/stati",
			FASCE_ETA: "dropdown/fasceeta",
			POPUP_POSIZIONI_APERTURA: "dropdown/popupcarrello/posizioni",
			POPUP_POSIZIONI_TIPI_VISUALIZZAZIONI: "dropdown/popupcarrello/tipivisualizzazioni",
			POPUP_POSIZIONI_MODALITA_VISUALIZZAZIONE: "dropdown/popupcarrello/modalitavisualizzazione",
			TIPI_PARAMETRI: "dropdown/tipiparametri",
			TIPOLOGIE_PARAMETRI: "dropdown/tipologieparametri",
			TIPOLOGIE_PARAMETRI_VALORI: "dropdown/tipologieparametrivalori",
		},
		ANAGRAFICA: {},
		NOTIFICATION: {
			SEND_MESSAGE_TO_USER_LIST: "notification/user/message/list",
			MESSAGES: "notification/user/message"
		},
		FORNITORI: "/fornitori",
		FORNITORI_GRUPPISTATOFORNITORE: "/fornitori/gruppistatofornitore",
		FORNITORI_ASS_STATO_GRUPPO_STATO: "/fornitori/associazionistatogruppostato",
		FORNITORI_STATI_ASSOCIATI: "fornitori/gruppistati/associati/true",
		FORNITORI_STATI_DA_ASSOCIARE: "fornitori/gruppistati/associati/false",
		
		FORNITORI_GRUPPINAZIONALITAFORNITORE: "/fornitori/gruppinazionalitafornitore",
		FORNITORI_ASS_NAZIONALITA_GRUPPO_NAZIONALITA: "/fornitori/associazioninazionalitagrupponazionalita",
		FORNITORI_NAZIONALITA_ASSOCIATE: "fornitori/gruppinazionalita/associati/true",
		FORNITORI_NAZIONALITA_DA_ASSOCIARE: "fornitori/gruppinazionalita/associati/false",
		DISTRIBUTORI: "/distributori",
		ADERENTI: "/aderenti",
		COMMERCIALI: "/commerciali",
		COMMERCIALI_DISTRIBUTORI_ASSOCIATI: "/commerciali/distributori",
		COMMERCIALI_DISTRIBUTORI_NONASSOCIATI: "/commerciali/distributori/nonassociati",
		DISTRIBUTORI_COMMERCIALI: "distributori/{id}/commerciali",
		CREDITI_DISTRIBUTORI: "distributori/crediti",
		CREDITI_DISTRIBUTORI_TOTALI: "distributori/crediti/totali",
		PRODOTTI_DISTRIBUTORI: "distributori/{id}/prodotti",
		PRODOTTI_WIDGET_DISTRIBUTORI: "distributori/{id}/prodottiwidget",
		UTENTI: "utenti",
		UTENTI_SIGN_IN_AS: "account/asusers",
		COUPON: {
			COUPON: "elencocoupon"
		},
		POLIZZE: {
			POLIZZE: "polizze",
			DESCRIZIONI_POLIZZE: "polizze/descrizioni",
			PARAMETRI_POLIZRE: "polizze/parametri",
			COMMISSIONI_POLIZZE: "polizze/commissioni",
			PRODOTTI_POLIZZE: "prodotti/prodotti",
			DOCUMENTI_POLIZZE: "polizze/documenti",
			TESTI_CARRELLO_POLIZZE: "polizze/testicarrello",
			EXTRA_INFO_POLIZZE: "polizze/extrainfo",
			GARANZIE_POLIZZE: "polizze/garanzie",
			FATTORI_POLIZZE: "polizze/fattori",
			GRUPPI_STATI: "polizze/gruppistato",
			SCHEDA_POLIZZE: "polizze/schedapolizza",
			SCHEDA_POLIZZEVALORE: "polizze/schedapolizzavalore",
			POLIZZE_STATI_ASSOCIATi: "polizze/gruppistati/associati/true",
			POLIZZE_STATI_DA_ASSOCIATi: "polizze/gruppistati/associati/false",
		},
		PRODOTTI: {
			PRODOTTI: "prodotti/prodotti",
			DESCRIZIONI_PRODOTTI: "prodotti/descrizioni",
			PARAMETRI_PRODOTTI: "prodotti/parametri",
			COMMISSIONI_PRODOTTI: "prodotti/commissioni",
			DOCUMENTI_PRODOTTI: "prodotti/documenti",
			DISTRIBUTORI_ASSOCIATI_PRODOTTI: "prodotti/distributoriassociati",
			SELEZIONA_DISTRIBUTORI_PRODOTTI: "prodotti/selezionadistributori",
			COPIA_DISTRIBUTORI_PRODOTTI: "prodotti/copiadistributori",
			PRODOTTI_CORRELATI: "prodotti/prodotticorrelati",
			PRODOTTI_UPGRADE: "prodotti/prodottiupgrade",
			TESTI_CARRELLO_PRODOTTI: "prodotti/testicarrello",
			SCHEDA_PRODOTTI: "prodotti/schedaprodotto"
		},

		COMMISSIONI: {
			COMMERCIALI: "commissioni/commerciali",
			DISTRIBUTORI: "commissioni/distributori",
			PRODOTTI_COMMERCIALE: "commissioni/prodotti/commerciale",
			POLIZZE_COMMERCIALE: "commissioni/polizze/commerciale",
			PRODOTTI_DISTRIBUTORE: "commissioni/prodotti/distributore",
			POLIZZE_DISTRIBUTORI: "commissioni/polizze/distributore",
			SIMULA: "commissioni/simula",

			DROPDOWN: {
				DISTRIBUTORI: "commissioni/distributori/dropdown/{id}",
				PRODOTTI_COMMERCIALI: "commissioni/prodotti/commerciali/dropdown/{id}",
				POLIZZE_COMMERCIALI: "commissioni/polizze/commerciali/dropdown/{id}",
				PRODOTTI_DISTRIBUTORE: "commissioni/prodotti/distributori/dropdown/{commId}/{disId}",
				POLIZZE_DISTRIBUTORE: "commissioni/polizze/distributori/dropdown/{commId}/{disId}"
			}
		},

		AMMINISTRAZIONE: {
			GRUPPI_STATI: "amministrazione/gruppistati",
			GRUPPI_STATI_ASSOCIATI: "amministrazione/gruppistati/associati/true",
			GRUPPI_STATI_DA_ASSOCIARE: "amministrazione/gruppistati/associati/false",
			GRUPPI_NAZIONALITA: "amministrazione/gruppinazionalita",
			GRUPPI_NAZIONALITA_ASSOCIATI: "amministrazione/gruppinazionalita/associati/true",
			GRUPPI_NAZIONALITA_DA_ASSOCIARE: "amministrazione/gruppinazionalita/associati/false",
			STATI: "amministrazione/stati",
			CODICI_PAGAMENTO: "amministrazione/codiciPagamento",
			TIPI_DISTRIBUTORE: "amministrazione/tipiDistributore",
			TIPOLOGIE_DISTRIBUTORE: "amministrazione/tipologieDistributore",
			EXTRA_INFO: "amministrazione/extraInfo",
			NAZIONALITA: "amministrazione/nazionalita",
			PACCHETTI_CATEGORIE: "amministrazione/pacchettiCategorie",
			COMMISSIONI_BENEFICIARI: "amministrazione/commissioniBeneficiari",
			CONTRAENTI: "amministrazione/contraenti",
			AGENZIE_ASSOCIAZIONI: "amministrazione/agenzieassociazioni",
			IMPOSTAZIONI: "amministrazione/impostazioni",
			POPUP_CARRELLO: "amministrazione/popupcarrello",
			TIPOLOGIE_PRODOTTI: "amministrazione/tipologieProdotti",
			ETA: "amministrazione/eta",
			FASCE_ETA: "amministrazione/fasceeta",
			PARAMETRI: "amministrazione/parametri",
			TIPI_PARAMETRI: "amministrazione/tipiparametri",
			TIPI_PARAMETRI_VALORI: "amministrazione/tipiparametri/valori",
			MODIFICHE_ABILITAZIONE: "amministrazione/modifiche/abilitazione",
			//LISTA_TEMPLATE_PRATICHE_MULTIPLE: "amministrazione/settingspratichemultiple",
			PREVIEW_TEMPLATE_PRATICHE_MULTIPLE: "amministrazione/settingspratichemultiple/preview",
			LISTA_TEMPLATE_PRATICHE_MULTIPLE: "amministrazione/settingspratichemultiple",
			TEMPLATE_PRATICHE_MULTIPLE_CREATE: "amministrazione/settingspratichemultiple/mapping/create",
			TEMPLATE_PRATICHE_MULTIPLE_UPDATE: "amministrazione/settingspratichemultiple/mapping/update",
			INFORMAZIONI_GNERICHE: "amministrazione/informazioni"
			/*DROPDOWN: {
				DISTRIBUTORI: "commissioni/distributori/dropdown/{id}",
			}*/
		},

		ANALISI_COMMISSIONI: {
			ANALISI: "analisicommissioni/analisi",
			ANALISI_TOTALI: "analisicommissioni/analisi/totali"
		},
		RUOLI: {
			RUOLI: "ruoli"
			// SUPER_ADMIN_TOGGLE: "ruoli/{id}/super-admin-toggle",
		},

		RICERCA_GARANZIE: "ricercagaranzie",
		RICERCA_GARANZIE_E_COMMERCE: "ricercagaranzie/ecommerce",
		RICERCA_GARANZIE_WONDERSAFE: "ricercagaranzie/wondersafe",
		REPORTFORNITORE: "reportfornitore",
		ESTRATTI_CONTO: "estratticonto",
		REGISTRAZIONE_PAGAMENTI: "registrazionepagamenti",
		REGISTRAZIONE_PAGAMENTI_TOTALI: "registrazionepagamenti/totali",
		AGENZIE: "agenzie",
		REPORT: process.env.VUE_APP_REPORT_URL,//"http://localhost:3115"
		ANALISI_AVANZATA: "query",
		CARRELLO: {
			CARRELLO: "carrello",
			PACCHETTI_CATEGORIE: "carrello/pacchettiCategorie",
			PRODOTTI_CATEGORIA: "carrello/prodottiCategoria",
			CONFRONTA_PRODOTTI: "carrello/confrontaProdotti",
			PREVENTIVATORE: "carrello/preventivatore",
			PARAMETRI: "carrello/parametri",
			PREFERITI: "carrello/preferiti",
			INFO_PRODOTTO: "carrello/prodotti",
			TOTALI: "carrello/totali",
			COMPLEMENTARI: "carrello/prodotti/complementari",
			EXCEL_PARTECIPANTI: "carrello/upload/excelpartecipanti",
			FILE_PARTECIPANTI: "carrello/upload/immaginepartecipanti",
			CREA_ADESIONI: "carrello/adesioni",
			DATI_PRATICA: "carrello/pratiche",
			MODIFICA_PRATICA: "carrello/adesioni/modificapratica",
			MODIFICA_PRATICA_AVANZATA: "carrello/adesioni/modificapraticaavanzata"
		},
		E_COMMERCE: {
			TOTALI: "carrello/totaliecommerce",
			DATI_PRATICA: "carrello/praticheecommerce",
			MODIFICA_PRATICA: "carrello/adesioni/modificapraticaecommerce",
		}
	};

	instance: AxiosInstance = null;
	_store: Store<any> = null;

	/**
	 * Ctor
	 */
	constructor() {
		var self = this;
		this.instance = axios.create({
			baseURL: process.env.VUE_APP_BASE_URL
		});

		this.instance.interceptors.request.use(config => {
			eventHub.$root.$emit(eventHub.events.AXIOS_REQUEST_STARTED, "STARTED");
			config.headers.common["Authorization"] = "bearer " + localStorage.getItem("overins-token");
			config.paramsSerializer = params => {
				// Qs is already included in the Axios package
				return Qs.stringify(params, {
					arrayFormat: "repeat"
				});
			};
			return config;
		});
		this.instance.interceptors.response.use(
			response => {

				eventHub.$emit(eventHub.events.AXIOS_REQUEST_ENDED, "ENDED");
				return response;
			},
			error => {

				eventHub.$emit(eventHub.events.AXIOS_RESPONSE_ERROR, error);
				eventHub.$emit(eventHub.events.AXIOS_REQUEST_ENDED, "ENDED");
				return Promise.reject(error);
			}
		);
	}

	//#region ** AUTHENTICATION **

	getLocalization(lang: string): any {
		var self = this;
		return new Promise(function (resolve, reject) {
			// var r = messages[lang];

			// resolve(r);
			self.instance
				.get(`/localization/labels/${lang}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	getUserInfo(): Promise<UserMeInfo> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<UserMeInfo>("/account/me")
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
			// var test = new UserLoggedInfoModel();
			// test.firstName = "firstName";
			// test.emailAddress = "emailAddress";
			// test.lastName = "lastName";
			// test.userName = "signInName";
			// resolve(test)
		});
	}
	doLogout(): Promise<boolean> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.post("/account/logout")
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doJwtLogin(credencial: UserLoginModel): Promise<string> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<string>>("/account/login", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	doRecuperaPassword(credencial: RecuperaPasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<boolean>>("/account/recupera-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	doForzaCambiaPassword(credencial: ForzaCambioPasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<boolean>>("/account/forza-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doCambiaPassword(credencial: CambioPasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<boolean>>("/account/cambia-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	doChangePassword(credencial: ChangePasswordModel): Promise<boolean> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<boolean>>("/account/change-password", credencial)
				.then(res => {
					resolve(res.data.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	//#endregion

	//#region ** PERMESSI **

	getPermessiList(): Promise<Permesso[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			if (self.permessiList != null) resolve(self.permessiList);
			else
				self.instance
					.get<Permesso[]>("/ruoli/permessi-list")
					.then(res => {
						self.permessiList = res.data;
						resolve(self.permessiList);
					})
					.catch(err => {
						reject(err);
					});
		});
	}
	aggiungiRimuoviPermesso(item: any): Promise<operationResult<any>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(`/ruoli/${item.ruoloID}/add-or-remove-permesso`, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getPermessi(ruoloId: number): Promise<RuoloPermesso[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<RuoloPermesso[]>(`/ruoli/${ruoloId}/permessi`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
	//#endregion ** PERMESSI **

	//#region ANAGRAFICA

	getUtentiRuoli(itemID: number): Promise<Array<number>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<Array<number>>(`utenti/ruoli/${itemID}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiDropDown(endpoint): Promise<TextValueItem[] | TextValueExtended[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<TextValueItem[] | TextValueExtended[]>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiDropDownAny(endpoint): Promise<any[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<any[]>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiDropDownAsString(endpoint): Promise<string[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<string[]>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiGriglia(endpoint, filters, sort, page, pageSize, group): Promise<any> {
		var self = this;

		var options = {
			filter: filters,
			sort: helpers.cloneObject(group),
			page: page,
			pageSize: pageSize
		};

		if (sort && sort.length > 0) {
			for (var i = 0; i < sort.length; i++) {
				let s = sort[i];
				options.sort.push(s);
			}
		}

		var params: string = helpers.buildUrlParams(options).join("&");

		let uri = `${endpoint}`;
		if (uri.indexOf("?") >= 0) {
			uri += `&${params}`;
		} else {
			uri += `?${params}`;
		}

		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(uri)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiGrigliaFullText(endpoint, filters?, sort?, page?, pageSize?, group?, fulltext?): Promise<any> {
		var self = this;

		var options = {
			filter: filters,
			sort: helpers.cloneObject(group),
			page: page,
			pageSize: pageSize,
			fullTextSearch: fulltext
		};

		if (sort && sort.length > 0) {
			for (var i = 0; i < sort.length; i++) {
				let s = sort[i];
				options.sort.push(s);
			}
		}

		var params: string = helpers.buildUrlParams(options).join("&");

		let uri = `${endpoint}`;
		if (uri.indexOf("?") >= 0) {
			uri += `&${params}`;
		} else {
			uri += `?${params}`;
		}

		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(uri)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getDatiGrigliaAnalisiCommissioni(endpoint, data: CommercialeCommissioneAnalisi): Promise<CommercialeCommissioneAnalisiResult[]> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<CommercialeCommissioneAnalisiResult[]>(endpoint, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	getTotaliAnalisiCommissioni(endpoint): Promise<CommercialeCommissioneAnalisiTotali> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<CommercialeCommissioneAnalisiTotali>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	deleteRowGriglia(endpoint: string, itemId: number | string): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.delete<operationResult<boolean>>(`${endpoint}/${itemId}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	deleteRowGrigliaT<T>(endpoint: string, itemId: number | string): Promise<operationResult<T>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.delete<operationResult<T>>(`${endpoint}/${itemId}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	deleteAssociazioniCommercialiDistributori(data: ElencoAssociazione): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.delete<operationResult<boolean>>(`${self.endpoint.COMMERCIALI_DISTRIBUTORI_ASSOCIATI}`, {
					data: data
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setAssociazioniCommercialiDistributori(data: ElencoAssociazione): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<boolean>>(`${self.endpoint.COMMERCIALI_DISTRIBUTORI_NONASSOCIATI}`, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	updateAnagraficaAssociazioneCD(data: DistributoreAssociatoAnagraficaEdit): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<boolean>>(`${self.endpoint.COMMERCIALI_DISTRIBUTORI_ASSOCIATI}`, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setGridRow<T>(endpoint: string, item: T, isNew: boolean): Promise<operationResult<T>> {
		var self = this;
		if (isNew)
			return new Promise(function (resolve, reject) {
				self.instance
					.post<operationResult<T>>(endpoint, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		else
			return new Promise(function (resolve, reject) {
				self.instance
					.put<operationResult<T>>(endpoint, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
	}
	setDistributore(item: AnagraficaDistributoreEdit, isNew: boolean): Promise<operationResult<any>> {
		var self = this;
		var formData = new FormData();
		var ojb = Object.assign({}, item);
		formData.append("file", item.bannerHPInterna_file);
		delete ojb.bannerHPInterna_file;
		let json = JSON.stringify(ojb);
		formData.append("data", json);
		if (isNew)
			return new Promise(function (resolve, reject) {
				self.instance
					.post<operationResult<any>>(api.endpoint.DISTRIBUTORI, formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		else
			return new Promise(function (resolve, reject) {
				self.instance
					.put<operationResult<any>>(api.endpoint.DISTRIBUTORI, formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
	}

	setProdottoPoliza(item: ProdottiPolize): Promise<operationResult<any>> {
		var self = this;
		var formData = new FormData();
		var ojb = Object.assign({}, item);
		formData.append("logoImageThumb", item.logoImageThumb_file);
		delete ojb.logoImageThumb_file;
		formData.append("logoImage", item.logoImage_file);
		delete ojb.logoImage_file;
		formData.append("immagineRicerca", item.immagineRicerca_file);
		delete ojb.immagineRicerca_file;
		formData.append("immagineComplementare", item.immagineComplementare_file);
		delete ojb.immagineComplementare_file;
		let json = JSON.stringify(ojb);
		formData.append("data", json);

		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(api.endpoint.POLIZZE.PRODOTTI_POLIZZE, formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setDocumentiProdotto(pacchettoID: number, item: DocumentiProdottiItem) {
		var self = this;
		var formData = new FormData();
		var ojb = Object.assign({}, item);

		formData.append("fileUpload", item.nomeFile_uploadFile);
		delete ojb.nomeFile_uploadFile;
		let json = JSON.stringify(ojb);
		formData.append("data", json);

		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(`${api.endpoint.PRODOTTI.DOCUMENTI_PRODOTTI}/${pacchettoID}`, formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setDocumentiPolizze(prodottiID: number, item: DocumentiPolizeItem) {
		var self = this;
		var formData = new FormData();
		var ojb = Object.assign({}, item);

		formData.append("fileUpload", item.nomeFile_uploadFile);
		delete ojb.nomeFile_uploadFile;
		let json = JSON.stringify(ojb);
		formData.append("data", json);

		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(`${api.endpoint.POLIZZE.DOCUMENTI_POLIZZE}/${prodottiID}`, formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	postFormData(endpoint: string, formData: FormData, methodType: string = 'post'): Promise<operationResult<any>> {
		var self = this;

		return new Promise(function (resolve, reject) {

			if (methodType === 'put') {
				self.instance
					.put<operationResult<any>>(endpoint, formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			}
			else {
				self.instance
					.post<operationResult<any>>(endpoint, formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			}

		});
	}

	setCommissioniCommerciali(item: CommissioneModifica): Promise<operationResult<any>> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<any>>(api.endpoint.COMMISSIONI.COMMERCIALI, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setCommissioniProdottiCommerciali(item: CommissioneModifica): Promise<operationResult<any>> {
		var self = this;

		if (item.itemID == 0) {
			return new Promise(function (resolve, reject) {
				self.instance
					.post<operationResult<any>>(api.endpoint.COMMISSIONI.PRODOTTI_COMMERCIALE, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		} else {
			return new Promise(function (resolve, reject) {
				self.instance
					.put<operationResult<any>>(api.endpoint.COMMISSIONI.PRODOTTI_COMMERCIALE, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		}
	}

	setCommissioniProdottiDistributori(item: CommissioneModifica): Promise<operationResult<any>> {
		var self = this;

		if (item.itemID == 0) {
			return new Promise(function (resolve, reject) {
				self.instance
					.post<operationResult<any>>(api.endpoint.COMMISSIONI.PRODOTTI_DISTRIBUTORE, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		} else {
			return new Promise(function (resolve, reject) {
				self.instance
					.put<operationResult<any>>(api.endpoint.COMMISSIONI.PRODOTTI_DISTRIBUTORE, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		}
	}

	setCommissioniPolizzeDistributori(item: CommissioneModifica): Promise<operationResult<any>> {
		var self = this;

		if (item.itemID == 0) {
			return new Promise(function (resolve, reject) {
				self.instance
					.post<operationResult<any>>(api.endpoint.COMMISSIONI.POLIZZE_DISTRIBUTORI, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		} else {
			return new Promise(function (resolve, reject) {
				self.instance
					.put<operationResult<any>>(api.endpoint.COMMISSIONI.POLIZZE_DISTRIBUTORI, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		}
	}

	setCommissioniPolizzeCommerciali(item: CommissioneModifica): Promise<operationResult<any>> {
		var self = this;

		if (item.itemID == 0) {
			return new Promise(function (resolve, reject) {
				self.instance
					.post<operationResult<any>>(api.endpoint.COMMISSIONI.POLIZZE_COMMERCIALE, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		} else {
			return new Promise(function (resolve, reject) {
				self.instance
					.put<operationResult<any>>(api.endpoint.COMMISSIONI.POLIZZE_COMMERCIALE, item)
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		}
	}

	setCommissioniDistributori(item: CommissioneModifica): Promise<operationResult<any>> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<any>>(api.endpoint.COMMISSIONI.DISTRIBUTORI, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setGruppoStatoFornitore(item: GruppiStatoPerFornitore): Promise<operationResult<any>> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(api.endpoint.FORNITORI_GRUPPISTATOFORNITORE, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	setAssociazioneDistributoreCommerciale(item: CommercialeDistributore): Promise<operationResult<any>> {
		var self = this;

		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<any>>(api.endpoint.DISTRIBUTORI_COMMERCIALI.replace("/{id}", ""), item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	// setSuperAdminToggle(itemID: number): Promise<operationResult<boolean>> {
	// 	var self = this;
	// 	return new Promise(function (resolve, reject) {
	// 		self.instance
	// 			.post<operationResult<boolean>>(api.endpoint.RUOLI.SUPER_ADMIN_TOGGLE.replace("{id}", itemID.toString()))
	// 			.then(res => {
	// 				resolve(res.data);
	// 			})
	// 			.catch(err => {
	// 				reject(err);
	// 			});
	// 	});
	// 	// return new Promise(function(resolve, reject) {
	// 	// 	self.instance
	// 	// 		.post<operationResult<boolean>>(api.endpoint.RUOLI.SUPER_ADMIN_TOGGLE, {id:itemID})
	// 	// 		.then(res => {
	// 	// 			resolve(res.data);
	// 	// 		})
	// 	// 		.catch(err => {
	// 	// 			reject(err);
	// 	// 		});
	// 	// });
	// }

	setTemplatePraticheMultiple(item: AnagraficaModelloImportEdit, isNew: boolean): Promise<operationResult<AnagraficaModelloImportEdit>> {
		var self = this;
		var formData = new FormData();
		var ojb = Object.assign({}, item);
		formData.append("file", item.schemaFile_uploadFile);
		delete ojb.schemaFile_uploadFile;
		let json = JSON.stringify(ojb);
		formData.append("data", json);
		if (isNew)
			return new Promise(function (resolve, reject) {
				self.instance
					.post<operationResult<AnagraficaModelloImportEdit>>(api.endpoint.AMMINISTRAZIONE.TEMPLATE_PRATICHE_MULTIPLE_CREATE, formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
		else
			return new Promise(function (resolve, reject) {
				self.instance
					.put<operationResult<AnagraficaModelloImportEdit>>(api.endpoint.AMMINISTRAZIONE.TEMPLATE_PRATICHE_MULTIPLE_UPDATE, formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						resolve(res.data);
					})
					.catch(err => {
						reject(err);
					});
			});
	}


	//#endregion

	//#region ESEGUI COMANDO
	eseguiComando(comando: EseguiComandoRequest): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post("command/eseguicmd", comando)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion

	//#region GENERALS
	simulaCommercialeCommissioni(data: CommercialeCommissioneSimula): Promise<CommissioniSimulaResult[]> {
		var self = this;
		return new Promise<CommissioniSimulaResult[]>(function (resolve, reject) {
			self.instance
				.post(self.endpoint.COMMISSIONI.SIMULA, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	searchForStringAutocomplete(endpoint: string, search: string): Promise<string[]> {
		var self = this;
		return new Promise<string[]>(function (resolve, reject) {
			self.instance
				.get(`${endpoint}/${search}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	recuperaPraticaCancellata(endpoint: string, itemId: string): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`${endpoint}/${itemId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	associaDistributoriDaProdotto(endpoint: string, id: number, data: any) : Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`${endpoint}/${id}`, data)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

	//#region REPORT FORNITORI

	getReportFornitori(): Promise<ReportFornitoreItem[]> {
		var self = this;
		return new Promise<ReportFornitoreItem[]>(function (resolve, reject) {
			self.instance
				.get("reportfornitore")
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getReportFornitoriById(itemId: number): Promise<ReportFornitoreItem> {
		var self = this;
		return new Promise<ReportFornitoreItem>(function (resolve, reject) {
			self.instance
				.get(`reportfornitore/${itemId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getDataReportFornitori(endpoint: string, params: any): Promise<ReportFornitoreItem[]> {
		var self = this;
		return new Promise<ReportFornitoreItem[]>(function (resolve, reject) {
			self.instance
				.get(endpoint, {
					params: params,
					paramsSerializer: params => {
						return qs.stringify(params);
					}
				})
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	sendDataReportFornitori(endpoint: string, params: any): Promise<ReportFornitoreItem[]> {
		var self = this;
		return new Promise<ReportFornitoreItem[]>(function (resolve, reject) {
			self.instance
				.get(endpoint, {
					params: params,
					paramsSerializer: params => {
						return qs.stringify(params);
					}
				})
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getReportFornitoriExportModel(): Promise<ReportFornitoreExportItem[]> {
		var self = this;
		return new Promise<ReportFornitoreExportItem[]>(function (resolve, reject) {
			self.instance
				.get("reportfornitore/exportmodel")
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	newReportFornitore(item: ReportFornitoreItem): Promise<operationResult<number>> {
		var self = this;
		return new Promise<operationResult<number>>(function (resolve, reject) {
			self.instance
				.post("reportfornitore", item)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	updateReportFornitore(item: ReportFornitoreItem): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.put("reportfornitore", item)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteReportFornitore(itemId: number): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.delete(`reportfornitore/${itemId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	activateReportFornitore(itemId: number): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise<operationResult<boolean>>(function (resolve, reject) {
			self.instance
				.put(`reportfornitore/enable/${itemId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	executeExportReportFornitore(data: ExportReportItem): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`reportfornitore/export`, data)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion

	//#region ESTRATTI CONTO
	updateEstrattoConto(endpoint: string, data: EstrattiContoEditItem): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.put(endpoint, data)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion

	//#region REGISTRAZIONE PAGAMENTI
	updateregistrazionePagamento(endpoint: string, data: ElencoAdesioniPerPagamentoEditItem): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.put(endpoint, data)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion

	//#region AGENZIE
	inviaDocumentoAgenzia(itemId: number): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`agenzie/richiedifirma/${itemId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	abilitaUtentiAgenzia(itemId: number): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`agenzie/abilitautenti/${itemId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

	//#region ACCEDI COME

	signInAs(userId: string): Promise<operationResult<LoginUserAsResponse>> {
		var self = this;
		return new Promise<operationResult<LoginUserAsResponse>>(function (resolve, reject) {
			self.instance
				.post<operationResult<LoginUserAsResponse>>(`${self.endpoint.UTENTI_SIGN_IN_AS}/${userId}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	disconnectFromSignInAs(): Promise<operationResult<string>> {
		var self = this;
		return new Promise<operationResult<string>>(function (resolve, reject) {
			self.instance
				.put<operationResult<string>>(`${self.endpoint.UTENTI_SIGN_IN_AS}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

	//#region REPORT

	getElencoReport(): Promise<operationResult<[]>> {
		var self = this;
		return new Promise<operationResult<[]>>(function (resolve, reject) {
			self.instance
				.get<operationResult<[]>>(`${self.endpoint.REPORT}/reporting/elenco`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

	//#region PARAMETRI
	getSchedaPolizzaValore(polizzaID: number, schedaParametroID: number): Promise<SchedaPolizzaValore> {
		var self = this;
		return new Promise<SchedaPolizzaValore>(function (resolve, reject) {
			self.instance
				.get<SchedaPolizzaValore>(`${self.endpoint.POLIZZE.SCHEDA_POLIZZEVALORE}/${polizzaID}/${schedaParametroID}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}


	getSchedaPolizzaValori(polizzaID: number, schedaParametroID: number): Promise<SchedaPolizzaParametriValori[]> {
		var self = this;
		return new Promise<SchedaPolizzaParametriValori[]>(function (resolve, reject) {
			self.instance
				.get<SchedaPolizzaParametriValori[]>(`${self.endpoint.POLIZZE.SCHEDA_POLIZZE}/${polizzaID}/${schedaParametroID}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getSchedaPacchettoValori(pacchettoID: number, schedaParametroID: number): Promise<SchedaProdottoParametriValori[]> {
		var self = this;
		return new Promise<SchedaProdottoParametriValori[]>(function (resolve, reject) {
			self.instance
				.get<SchedaProdottoParametriValori[]>(`${self.endpoint.PRODOTTI.SCHEDA_PRODOTTI}/${pacchettoID}/${schedaParametroID}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion
	//#region ASSOCIA STATI - GRUPPI STATO
	associaStati(gruppoStatoId: number, data: any): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post("amministrazione/gruppistati/associati/" + gruppoStatoId, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion
	//#region ASSOCIA STATI - GRUPPI STATO - FORNITORI
	associaFornitoriGruppoStati_Stati(fornitoreId:number, gruppoStatoId: number, data: any): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`fornitori/gruppistati/associati/${fornitoreId}/${gruppoStatoId}`, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	resetFornitoriStatiByGruppoStato(fornitoreId:number, gruppoStatoId: number): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`fornitori/gruppistati/reset/${fornitoreId}/${gruppoStatoId}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion
	//#region ASSOCIA STATI - GRUPPI STATO - POLIZZE
	associaPolizzeGruppoStati_Stati(prodottoId:number, gruppoStatoId: number, data: any): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`polizze/gruppistati/associati/${prodottoId}/${gruppoStatoId}`, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion



	//#region GRUPPI NAZIONALITA
	associaNazionalita(gruppoNazionalitaId: number, data: any): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post("amministrazione/gruppinazionalita/associati/" + gruppoNazionalitaId, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	//#endregion

	//#region ASSOCIA NAZIONALITA - GRUPPI NAZIONALITA - FORNITORI
	associaFornitoriGruppoNazionalita_Nazionalita(fornitoreId:number, gruppoNazionalitaId: number, data: any): Promise<operationResult<any>> {
		var self = this;
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`fornitori/gruppinazionalita/associati/${fornitoreId}/${gruppoNazionalitaId}`, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	resetFornitoriNazionalitaByGruppoNazionalita(fornitoreId:number, gruppoNazionalitaId: number): Promise<operationResult<any>> {
		var self = this;
		debugger
		return new Promise<operationResult<any>>(function (resolve, reject) {
			self.instance
				.post(`fornitori/gruppinazionalita/reset/${fornitoreId}/${gruppoNazionalitaId}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion


	//#region ANALISI AVANZATA

	getQueryGroupCategoryAnalisiAvanzata(): Promise<QueryCategories[]> {
		var self = this;
		return new Promise<QueryCategories[]>(function (resolve, reject) {
			self.instance
				.get<QueryCategories[]>(`${self.endpoint.ANALISI_AVANZATA}/categories`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getQueryAnalisiAvanzata(): Promise<QueryDTO[]> {
		var self = this;
		return new Promise<QueryDTO[]>(function (resolve, reject) {
			self.instance
				.get<QueryDTO[]>(self.endpoint.ANALISI_AVANZATA)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getQueryDetailByItemId(itemID: number): Promise<StatisticheQueryElenco> {
		var self = this;
		return new Promise<StatisticheQueryElenco>(function (resolve, reject) {
			self.instance
				.get<StatisticheQueryElenco>(`${self.endpoint.ANALISI_AVANZATA}/${itemID}`)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getListaValori(data: ListaValoriRequest): Promise<TextValueExtended[]> {
		var self = this;
		return new Promise<TextValueExtended[]>(function (resolve, reject) {
			self.instance
				.post<TextValueExtended[]>(`${self.endpoint.ANALISI_AVANZATA}/listavalori`, data)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getQueryValues(itemId: number, parametri: any[]): Promise<QueryValuesResponse> {
		var self = this;
		return new Promise<QueryValuesResponse>(function (resolve, reject) {
			self.instance
				.post<QueryValuesResponse>(`${self.endpoint.ANALISI_AVANZATA}/${itemId}/values`, parametri)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	updateQuery(data: StatisticheQueryElenco): Promise<StatisticheQueryElenco> {
		var self = this;
		return new Promise<StatisticheQueryElenco>(function (resolve, reject) {
			self.instance
				.post<StatisticheQueryElenco>(`${self.endpoint.ANALISI_AVANZATA}`, data)
				.then(res => resolve(res.data))
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
	//#endregion

	get(endpoint): Promise<any> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.get<any>(endpoint)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	chiamataPost<T>(endpoint: string, item): Promise<operationResult<T>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.post<operationResult<T>>(endpoint, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	chiamataPut<T>(endpoint: string, item): Promise<operationResult<T>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.put<operationResult<T>>(endpoint, item)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	delete(endpoint: string, itemId: number | string): Promise<operationResult<boolean>> {
		var self = this;
		return new Promise(function (resolve, reject) {
			self.instance
				.delete<operationResult<boolean>>(`${endpoint}/${itemId}`)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
}

const api = new Api();
export default api;
