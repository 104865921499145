import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { DocumentiProdottiItem } from "@/models/prodotti/documentiProdottiItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.PRODOTTI.DOCUMENTI_PRODOTTI;
@Component({})
export default class DocumentiPolize extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Prodotti.PRODOTTI]; }
	@Prop({ default: () => null }) pacchettoID: number;
	editItem: DocumentiProdottiItem = new DocumentiProdottiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;

	tipiFile: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "pacchettoID", title: "ID", width: 20, filterable: false, hidden: true, values: [this.ItemID], headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "tipoFileID",
				title: this.$i18n.t("generico.tipoFile").toString(),
				values: this.tipiFile,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				sortBy: "tipoFile",
				filterType: "select",
				itemsForFilter: this.tipiFile,
				editType: "select",
				editSource: this.tipiFile
			},
			{
				field: "nomeFile",
				title: this.$i18n.t("generico.file").toString(),
				width: 400,
				editable: true,
				type: "download-file",
				editType: "upload-file",
				headerCell: "myHeaderCellTemplate"
			},
			{ title: "file", width: 200, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "startDate", title: this.$i18n.t("generico.startDate").toString(), format: "{0:dd/MM/yyyy}", width: 150, headerCell: "myHeaderCellTemplate", type: "date" },
			{ field: "endDate", title: this.$i18n.t("generico.endDate").toString(), format: "{0:dd/MM/yyyy}", width: 100, headerCell: "myHeaderCellTemplate", type: "date" },
			{ field: "cancellato", title: this.$i18n.t("generico.nonAttivo").toString(), hidden: true, headerCell: "myHeaderCellTemplate", type: "boolean", filterType: "boolean" },
			{ field: "istanteModifica", title: "Ultima Modifica", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true, type: "datetime"},
			{ field: "utenteModifica", title: "Utente", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true},
			{ field: "note", title: "Note", type:"textarea", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true},
			
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_FILE).then(res => (self.tipiFile = res));

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "vendibile";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!false!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function (item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.pacchettoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${self.poliza}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.documento")]).toString();

		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new DocumentiProdottiItem();
		newItem.pacchettoID = this.pacchettoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new DocumentiProdottiItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		if (self.warningEditDocumentType) {
			var title = this.$i18n.t("generico.warning").toString();
			var message = this.$i18n.t("msg.confermaSalvataggioStessoTipoDocumento").toString();
			self.$bvModal
				.msgBoxConfirm(message, {
					title: title,
					okVariant: "warning",
					okTitle: self.$i18n
						.t("btn.si")
						.toString()
						.toUpperCase(),
					cancelTitle: self.$i18n
						.t("btn.no")
						.toString()
						.toUpperCase(),
					cancelVariant: "outline-theme",
					footerClass: "",
					headerBgVariant: "warning"
					// centered: true
				})
				.then(value => {
					if (value) {
						self.saveEdit();
					}
				})
				.catch(err => {
					// An error occurred
				});
		}
		else {
			self.saveEdit();
		}
	}

	saveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.documento") }).toString();
		// self.setGridRow<DocumentiProdottiItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
		// 	self.showEditModal = false
		// });
		api.setDocumentiProdotto(self.pacchettoID, self.editItem)
			.then(res => {
				this.$bvToast.toast(message, {
					variant: "success",
					title: title,
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	renderCellFile(data: string, dataItem: DocumentiProdottiItem) {
		var result = `<a href='~/CMSPages/GetFile.aspx?nodeguid=${dataItem.guidFile}' target='_blank'>${data}</a>`;
		return result;
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	get warningEditDocumentType(): boolean {
		var self = this;
		var item = self.editItem || new DocumentiProdottiItem();
		return item.nomeFile_uploadFile && ((self.itemsGrid || []) as Array<DocumentiProdottiItem>).some(x => x.tipoFileID == item.tipoFileID && x.itemID != item.itemID)
	}
}
