export class DistributoriAssociatiProdottiItem {
	constructor() {
		this.itemID = null;
		this.pacchettoID = null;
		this.distributoreID = null;
		this.distributore = null;
		this.email = null;
		this.tipoDistributoreID = null;
		this.tipoDistributore = null;
		this.startDate = null;
		this.endDate = null;
		this.cancellato = null;
		this.pubblicato = null;
		this.tipoCommissioneID = null;
		this.commissione = null;
		this.associazionePadreID = null;
		this.forzaPagamento = null;
		this.deleted = null;
	}
	itemID: number;
	pacchettoID: number | null;
	distributoreID: number | null;
	distributore: string;
	email: string;
	tipoDistributoreID: number | null;
	tipoDistributore: string | null;
	startDate: string | null;
	endDate: string | null;
	cancellato: boolean | null;
	pubblicato: boolean | null;
	tipoCommissioneID: number | null;
	commissione: number | null;
	associazionePadreID: number | null;
	forzaPagamento: boolean | null;
	deleted: boolean;
}