"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GridHeaderCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridHeaderCell` component.
 */


var GridHeaderCell = {
	props: {
		field: String,
		title: String,
		sortable: [Boolean, Object],
		render: [Object, Function, String],
		filterType: String,
		filterable: Boolean,
		sort: Array,
		sortBy: String,
		itemsForFilter: Array,
		filterSourceText: String,
		filterSourceValue: String,
		headerType: String
	},
	methods: {
		clickHandler: function clickHandler(event) {
			this.$emit('headercellclick', event);
		}
	},
	// @ts-ignore
	setup: !gh ? undefined : function () {
		var v3 = !!gh;
		return {
			v3: v3
		};
	},
	// @ts-ignore
	render: function render(createElement) {
		var h = gh || createElement;
		var defaultSlot = kendo_vue_common_1.getDefaultSlots(this);
		var renderTemplate = this.$props.render;
		var textInCell = this.$props.title || this.$props.field || "\xA0";
		var defaultRendering = this.$props.sortable ? h("a", {
				"class": "k-link",
				href: "#",
				attrs: this.v3 ? undefined : {
					href: "#"
				},
				onClick: this.clickHandler,
				on: this.v3 ? undefined : {
					"click": this.clickHandler
				}
			}, [textInCell, defaultSlot]) : defaultSlot ? h("span", [textInCell, defaultSlot]) // @ts-ignore
			:
			this.v3 ? textInCell : this._v(textInCell);
		return kendo_vue_common_1.getTemplate.call(this, {
			h: h,
			template: renderTemplate,
			defaultRendering: defaultRendering,
			additionalProps: this.$props,
			additionalListeners: {
				click: this.clickHandler
			}
		});
	}
};
exports.GridHeaderCell = GridHeaderCell;