"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridColumnMenuItem = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;
/**
 * Represents the default `GridColumnMenuItem` component.
 */

var GridColumnMenuItem = {
  props: {
    title: String,
    iconClass: String,
    selected: Boolean
  },
  methods: {
    onClick: function onClick(e) {
      this.$emit('menuitemclick', e);
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var h = gh || createElement;
    var _a = this.$props,
        title = _a.title,
        iconClass = _a.iconClass,
        selected = _a.selected;
    return h("div", {
      onClick: this.onClick,
      on: this.v3 ? undefined : {
        "click": this.onClick
      },
      "class": "k-columnmenu-item " + (selected ? 'k-state-selected' : '')
    }, [iconClass && h("span", {
      "class": "k-icon " + iconClass
    }), title]);
  }
};
exports.GridColumnMenuItem = GridColumnMenuItem;