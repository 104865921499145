"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridHierarchyCell = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var utils_1 = require("./../utils");

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `GridHierarchyCell` component.
 */


var GridHierarchyCell = {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    colSpan: Number,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    render: [String, Function, Object]
  },
  methods: {
    triggerKeydown: function triggerKeydown(e, expanded) {
      this.$emit('cellkeydown', {
        event: e,
        dataItem: this.$props.dataItem,
        field: this.$props.field,
        expanded: expanded
      });
    },
    clickHandler: function clickHandler(e, dataItem, expanded) {
      e.preventDefault();
      this.$emit('change', {
        dataItem: dataItem,
        event: e,
        field: undefined,
        value: !expanded
      });
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var defaultRendering = null;

    if (this.$props.rowType === 'groupFooter') {
      defaultRendering = h("td", {
        "class": "k-hierarchy-cell"
      });
    } else if (this.$props.rowType !== 'groupHeader') {
      var expanded_1 = utils_1.getNestedValue(this.$props.field, this.$props.dataItem);
      var className = expanded_1 ? 'k-icon k-minus' : 'k-icon k-plus';
      defaultRendering = h("td", {
        onKeydown: function onKeydown(ev) {
          _this.triggerKeydown(ev, expanded_1);
        },
        on: this.v3 ? undefined : {
          "keydown": function onKeydown(ev) {
            _this.triggerKeydown(ev, expanded_1);
          }
        },
        "class": "k-hierarchy-cell"
      }, [h("a", {
        onClick: function onClick(e) {
          _this.clickHandler(e, _this.$props.dataItem, expanded_1);
        },
        on: this.v3 ? undefined : {
          "click": function onClick(e) {
            _this.clickHandler(e, _this.$props.dataItem, expanded_1);
          }
        },
        "class": className,
        href: "#",
        attrs: this.v3 ? undefined : {
          href: "#",
          tabIndex: -1
        },
        tabIndex: -1
      })]);
    }

    return kendo_vue_common_1.getTemplate.call(this, {
      h: h,
      template: this.$props.render,
      defaultRendering: defaultRendering,
      additionalProps: this.$props,
      additionalListeners: {
        keydown: this.triggerKeydown,
        click: this.clickHandler
      }
    });
  }
};
exports.GridHierarchyCell = GridHierarchyCell;