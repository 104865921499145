"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnResizer = void 0; // @ts-ignore

var Vue = require("vue");

var allVue = Vue;
var gh = allVue.h;

var kendo_vue_common_1 = require("@progress/kendo-vue-common");
/**
 * Represents the default `ColumnResizer` component.
 */


var ColumnResizer = {
  mounted: function mounted() {
    if (this.$el) {
      this.draggable = this.$refs.draggable;
    }
  },
  methods: {
    drag: function drag(event) {
      var element = this.draggable && this.draggable.element;

      if (element) {
        this.$emit('resize', event, element, false);
      }
    },
    release: function release(event) {
      var element = this.draggable && this.draggable.element;

      if (element) {
        this.$emit('resize', event, element, true);
      }
    }
  },
  // @ts-ignore
  setup: !gh ? undefined : function () {
    var v3 = !!gh;
    return {
      v3: v3
    };
  },
  // @ts-ignore
  render: function render(createElement) {
    var _this = this;

    var h = gh || createElement;
    var resizeStyle = {
      // TODO: move to theme
      cursor: 'col-resize',
      display: 'block',
      height: '1000%',
      position: 'absolute',
      // [this.props.isRtl ? 'left' : 'right']: 0,
      top: 0,
      width: '.5em'
    };
    return (// @ts-ignore  function children
      h(kendo_vue_common_1.Draggable, {
        onDrag: this.drag,
        on: this.v3 ? undefined : {
          "drag": this.drag,
          "release": this.release
        },
        onRelease: this.release,
        ref: 'draggable'
      }, this.v3 ? function () {
        return [h("span", {
          "class": "k-column-resizer",
          draggable: false,
          attrs: _this.v3 ? undefined : {
            draggable: false
          },
          style: resizeStyle
        })];
      } : [h("span", {
        "class": "k-column-resizer",
        draggable: false,
        attrs: _this.v3 ? undefined : {
          draggable: false
        },
        style: resizeStyle
      })])
    );
  }
};
exports.ColumnResizer = ColumnResizer;