import { column } from "@/models/grid/columnModels";
import moment, { Moment } from "moment";
import { Component, Prop } from "vue-property-decorator";
import helpers from "@/helpers";
import gridComponentClientBase from "@/components/grid/gridComponentClientBase";
import { cellOperatorChange } from "../../../customPlugin/kendo/kendo-vue-grid/src/filterCommon";
import api from "@/services/api";
import permessi from "@/config/permessi";

@Component
export default class GridFornitoriReport extends gridComponentClientBase {
	get PERMESSI(): string[] { return [permessi.BK.Vendite.REPORTFORNITORE]; }
	@Prop({ default: () => "" })
	columns: column[];

	@Prop({ default: () => 0 })
	itemId: number;

	dataA: Date = moment().toDate();
	dataDa: Date = moment()
		.add(-7, "days")
		.toDate();

	endpoint: string = "reportfornitore";

	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";

	destinatari: string = "";
	destinatariCC: string = "";
	oggetto: string = "";
	sender: string = "";

	destinatariState: any = null;
	destinatariCCState: any = null;
	dataDaState: boolean = true;
	dataAState: boolean = true;

	created() {
		this.getData();
	}

	getData() {
		//let endpoint = this.endpoint + "?dataDa=" + encodeURIComponent(moment(this.dataDa).format()) + "&dataA=" + encodeURIComponent(moment(this.dataA).format());
		var self = this;
		var params: any = {
			dataDa: moment(this.dataDa).format(),
			dataA: moment(this.dataA).format()
		};
		api.getDataReportFornitori(`${this.endpoint}/data/${this.itemId}`, params)
			.then(res => (self.gridData = res))
			.catch(err => console.log(err));
	}

	riceviPerEmail() {
		let error = false;
		this.dataDaState = true;
		this.dataAState = true;

		this.destinatariState = null;
		this.destinatariCCState = null;

		if (!this.dataDa) {
			this.dataDaState = false;
			error = true;
		}

		if (!this.dataA) {
			this.dataAState = false;
			error = true;
		}

		if (!this.destinatari) {
			this.destinatariState = false;
			error = true;
		}

		if (error) {
			this.$bvToast.toast(this.$i18n.t("reportfornitori.riempireTuttiCampi").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});

			return;
		}

		var self = this;
		var params: any = {
			dataDa: moment(this.dataDa).format(),
			dataA: moment(this.dataA).format(),
			oggettoEmail: this.oggetto,
			indirizzoDiSpedizione: this.sender,
			destinatari: this.destinatari,
			destinatariCC: this.destinatariCC
		};

		api.sendDataReportFornitori(`${this.endpoint}/datasend/${this.itemId}`, params)
			.then(res => {
				self.$bvToast.toast(this.$i18n.t("generico.creazioneInvioReportOk").toString(), {
					variant: "success",
					title: this.$i18n.t("msg.invioReport").toString(),
					solid: true
				});
			})
			.catch(err => {
				console.log(err);
			});
	}
}
