import permessi from '@/config/permessi';
import api from '@/services/api';
import { Component, Prop } from 'vue-property-decorator';
import gridComponentBase from '../../../components/grid/gridComponentBase.vue';

const _ENDPONT = api.endpoint.REPORTFORNITORE;

@Component
export default class GridStoricoDettaglioPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Vendite.REPORTFORNITORE]; }
	@Prop({ default: () => 0 })
	itemId: number;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "reportID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "email", title: this.$i18n.t("generico.email").toString(), headerCell: "myHeaderCellTemplate", filterable: true, export: true, editDisabled: true },
			{
				field: "inviato",
				title: "Inviato",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				disabled: true,
				export: true,
			},
			{
				field: "timeStampInvio",
				title: "TimeStamp Invio",
				width: 180,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "datetime",
				headerType: "datetimerange"
			},
			{
				field: "consegnato",
				title: "Consegnato",
				width: 130,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				disabled: true,
				export: true,
			},
			{
				field: "timeStampConsegna",
				title: "TimeStamp Consegna",
				width: 200,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "datetime",
				headerType: "datetimerange"
			}, {
				field: "letto",
				title: "Letto",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editDisabled: true,
				disabled: true,
				export: true,
			},
			{
				field: "timeStampLettura",
				title: "TimeStamp Lettura",
				width: 180,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "datetime",
				headerType: "datetimerange"
			},
		];
	}

	sort: any = [{ field: "email", dir: "asc" }];

	created() {
		this.getData();
	}

	exportExcel() {
		this.exportGridExcel(`${_ENDPONT}/storicodettaglio/${this.itemId}`);
	}

	getData() {
		var self = this;
		this.getGridData(`${_ENDPONT}/storicodettaglio/${this.itemId}`);
	}
}