import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import permessi from "@/config/permessi";
import { TipoSchedaParametri } from "@/models/amministrazione/parametri/TipoSchedaParametri";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.TIPI_PARAMETRI;
@Component({})
export default class TipiParametri extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.TIPI_PARAMETRI];
	}
	editItem: TipoSchedaParametri = new TipoSchedaParametri();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;
	editItemIsDecimal: boolean = false;
	editItemIsInt: boolean = false;

	tipiParametri: TextValueItem[] = [];

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		var self = this;
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "name", title: this.$i18n.t("generico.nome").toString(), headerCell: "myHeaderCellTemplate", width: 300 },
			{
				field: "dataTypeId",
				title: this.$i18n.t("generico.tipologiaDato").toString(),
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.tipiParametri,
				sortBy: "dataType",
				filterable: true,
				itemsForFilter: this.tipiParametri,
				filterType: "select",
				editType: "select",
				editSource: this.tipiParametri,
				editSourceText: "text",
				editSourceValue: "value",
				editSelectionCallBack: this.onChangeTipologia
			},
			{
				field: "isCurrency",
				title: this.$i18n.t("generico.isCurrency").toString(),
				groupable: true,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				editHide: this.isNotDecimal,
				type: "boolean",
				filterType: "boolean",
				width: this.setColPercent(15),
				disabled: true,
			},
			{
				field: "isPercentage",
				title: this.$i18n.t("generico.isPercentage").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				hidden: true,
				editHide: this.isNotNumber,
				type: "boolean",
				filterType: "boolean",
				width: this.setColPercent(15),
				disabled: true,
			},
			{
				field: "decimalSlot",
				title: this.$i18n.t("generico.decimalSlot").toString(),
				type:"numeric-int",
				hidden: true,
				editHide: this.isNotDecimal,
				filterType:"numeric-int",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				export: true,
				width: this.setColPercent(15)
			},
			{ field: "note", title: this.$i18n.t("generico.note").toString(), headerCell: "myHeaderCellTemplate", editType: "textarea" },


			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "name", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([
			api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PARAMETRI).then(res => {
				if (res && res.length) {
					for (var i = 0; i < res.length; i++) {
						var item = res[i];
						var code = item.value;
						var text = this.$i18n.t(`generico.${item.text}`).toString();

						self.tipiParametri.push({ value: code, text: text });
					}
				}
			})
		])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.schedaParametroID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.tipoParametro")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new TipoSchedaParametri();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new TipoSchedaParametri(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.tipoParametro") }).toString();
		self.setGridRow<TipoSchedaParametri>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	onDataBound(ev: any) {
		console.log("aaa");
	}

	onChangeTipologia(val, obj){
		this.editItemIsDecimal = obj && obj.value == 2 ? true : false;
		this.editItemIsInt = obj && obj.value == 1 ? true : false;
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	get isNotDecimal(): boolean {
		return !this.editItemIsDecimal;
	}

	get isNotNumber(): boolean {
		return !(this.editItemIsInt || this.editItemIsDecimal);
	}
}
