// import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueItem } from "../../../models/TextValueItem";
import { ElencoAssociazione } from "../../../models/commerciali/elencoAssociazione";
import { DistributoreAssociatoAnagrafica } from "../../../models/commerciali/DistributoreAssociatoAnagrafica";
import { DistributoreAssociatoAnagraficaEdit } from "@/models/commerciali/DistributoreAssociatoAnagraficaEdit";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.COMMERCIALI_DISTRIBUTORI_NONASSOCIATI;

@Component({})
export default class SelezionaDistributori extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Commerciali.COMMERCIALI]; }
	@Prop({ default: () => null }) itemID: number;
	editItem: DistributoreAssociatoAnagraficaEdit = new DistributoreAssociatoAnagraficaEdit();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = false;

	tipiPremio: TextValueItem[] = [];

	actionsToDo: TextValueItem[] = [
		{ value: null, text: "Esegui su selezione" },
		{ value: 1, text: "Associa selezionati" }
	];
	selectedActionToDo: number = null;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "ragioneSociale", title: this.$i18n.t("generico.nominativo").toString(), headerCell: "myHeaderCellTemplate", export: true },
			{ field: "tipoDistributore", title: this.$i18n.t("generico.tipo").toString(), width: 200, groupable: true, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "email", title: this.$i18n.t("generico.email").toString(), width: 150, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "partitaIva", title: this.$i18n.t("generico.partitaIva").toString(), width: 150, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "codiceFiscale", title: this.$i18n.t("generico.codiceFiscale").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "telefono", title: this.$i18n.t("generico.telefono").toString(), width: 100, headerCell: "myHeaderCellTemplate" },
			{ field: "fax", title: this.$i18n.t("generico.fax").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "cancellato", title: this.$i18n.t("generico.nonAttivo").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "indirizzo", title: this.$i18n.t("generico.indirizzo").toString(), width: 300, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "localita", title: this.$i18n.t("generico.localita").toString(), width: 250, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "provincia", title: this.$i18n.t("generico.provincia").toString(), width: 100, groupable: true, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "regione", title: "Regione", width: 150, groupable: true, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "cAP", title: this.$i18n.t("generico.cap").toString(), width: 200, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "commissioni", title: this.$i18n.t("generico.commissioni").toString(), width: 150, hidden: true, headerCell: "myHeaderCellTemplate" },

			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	sort: any = [{ field: "ragioneSociale", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;

		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => self.tipiPremio = res)])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() { }

	exportExcel() {
		this.exportGridExcel(`${_ENDPONT}/${this.itemID}`);
	}

	getData() {
		var self = this;
		this.getGridData(`${_ENDPONT}/${this.itemID}`);
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	eseguiSuSelezione() {
		var self = this;
		if (this.selectedActionToDo) {
			if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
				let elementToDoAction: number[] = (this.gridData.data as DistributoreAssociatoAnagrafica[])
					.filter(function (item) {
						return item._ck_select == true;
					})
					.map(function (el) {
						return el.itemID;
					});

				if (elementToDoAction.length > 0) {
					let message = self.$createElement("div", { domProps: { innerHTML: `${self.$i18n.t("messaggio.confermaAssociazione")}` } });
					let title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.associazioneCommercialiDistributori")]).toString();

					self.$bvModal
						.msgBoxConfirm([message], {
							title: title,
							okVariant: "warning",
							okTitle: self.$i18n
								.t("btn.si")
								.toString()
								.toUpperCase(),
							cancelTitle: self.$i18n
								.t("btn.no")
								.toString()
								.toUpperCase(),
							cancelVariant: "outline-theme",
							footerClass: "",
							headerBgVariant: "warning"
						})
						.then(value => {
							if (value) {
								let data: ElencoAssociazione = {
									commercialeID: self.itemID,
									distributori: elementToDoAction,
									dataFineRapporto: null,
									dataInizioRapporto: null
								};

								api.setAssociazioniCommercialiDistributori(data)
									.then(res => {
										this.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
											variant: "success",
											title: this.$i18n.t("generico.azioneeseguita").toString(),
											solid: true
										});

										self.getData();
									})
									.catch(err => {
										console.log(err);
									});
							}
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
						variant: "warning",
						title: this.$i18n.t("generico.warning").toString(),
						solid: true
					});
				}
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}
}
