import helpers from "@/helpers";
import CustomEditAction from "@/models/customEditAction";
import { column } from "@/models/grid/columnModels";
import moment from "moment";
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { filterBy } from "@progress/kendo-data-query";
import appColors from "@/models/appColors";
import { debug } from "console";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";

@Component({})
export default class editFormGroupParametroValore extends Vue {
	@Prop({ default: () => 4 })
	labelCols: number;
	@Prop({ default: () => "" })
	title: string;
	@Prop({ default: () => null })
	hint: string;
	@Prop({ default: () => 1 })
	fieldType: number;
	@Prop({ default: false })
	disabled: boolean;
	@Prop({ default: false })
	modalShown: boolean;
	@Prop({ default: () => 2 })
	decimalSlot: number;
	@Prop({ default: () => 0 })
	parametroTipo: number;

	sourceTypeItems: TextValueItem[] = [];

	get editDisabled(): boolean {
		var self = this;
		return self.disabled;
	}
	get contentCols(): number {
		return 12 - (this.labelCols == 12 ? 0 : this.labelCols);
	}
	@Prop({ default: () => new column() })
	column: column;
	@Prop({ default: () => null })
	value: any;

	constructor() {
		super();
		var self = this;
		api.getDatiDropDown(`${api.endpoint.DROPDOWN.TIPOLOGIE_PARAMETRI_VALORI}/${this.parametroTipo}`).then(res => {
			if (res && res.length) {
				// self.sourceTypeItems.push({ value: null, text: "" });

				for (var i = 0; i < res.length; i++) {
					var item = res[i];
					var code = item.value;
					var text = item.text;

					self.sourceTypeItems.push({ value: code, text: text });
				}
			}
		});
	}

	get model(): any {
		var self = this;
		return self.value;
	}
	set model(value: any) {
		var self = this;
		self.$emit("input", value);
	}
	get modelDDL(): any {
		var self = this;
		if (this.fieldType) return self.sourceTipo.find(x => x.value == self.model);
		// if (this.fieldType == 5)
		// 	return self.sourceTipo5.find(x => x.value == self.model);
		// if (this.fieldType == 6)
		// 	return self.sourceTipo6.find(x => x.value == self.model);
		return null;
	}
	get sourceTipo() {
		return this.sourceTypeItems;
		//return [{ text: 'Entro 70', value: 1 }, { text: 'Tra 70 e 75', value: 2 }, { text: 'Tra 75 e 90', value: 3 }, { text: 'Oltre 90', value: 4 }];
	}

	get decimalPlaces() {
		var result = "0.";

		if (this.decimalSlot > 0) {
			for (var i = 0; i < this.decimalSlot - 1; i++) {
				result += "0";
			}
			result += "1";
		}

		return result;
	}
	// get sourceTipo5() {
	// 	return this.sourceTypeItems;
	// 	//return [{ text: 'Individuale', value: 1 }, { text: 'Gruppo', value: 2 }];
	// }
	// get sourceTipo6() {
	// 	return this.sourceTypeItems;
	// 	//return [{ text: 'Entro 2gg', value: 1 }, { text: 'Entro 30gg', value: 2 }, { text: 'Entro 60gg', value: 3 }, { text: 'Tra 60 e 365gg', value: 4 }];
	// }

	created() {}

	mounted() {
		var self = this;
	}

	onNumericInputChange(value: any) {
		if (value || (value != "" && value == 0)) this.model = parseFloat(value).toFixed(2);
		else this.model = null;
	}

	onNumericIntInputChange(value: any) {
		if (value || value == 0) this.model = parseInt(value);
		else this.model = null;
	}
	onChangeDropdownValue(obj: any) {
		var value = ((obj || {}).target || {}).value;
		this.model = value == null ? null : value.value;
	}
}
