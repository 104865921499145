import { appRoute } from "@/models/appRoute";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import baseUserContextComponent from "@/components/baseUserContextComponent";
import { Route } from "vue-router";

@Component({})
export default class subMenuLayout extends baseUserContextComponent {
	layout: string = 'tabs';
	created() {
		var self = this;
	}
	get title(): string {
		var self = this;
		return self.$route.meta.label;
	}

	buildParentsTree(r: appRoute): appRoute[] {

		if (r == null)
			return null;
		var res = [];
		res.push(r);
		var route = (this.routes.find(x => x.name == r.name) as appRoute);
		var parent = route == null ? null : route.parent;

		if (parent != null) {
			var p_res = this.buildParentsTree(parent as appRoute);
			if (p_res != null && p_res.length > 0)
				res = p_res.concat(res);
		}

		return res;
	}
	get items(): appRoute[] {
		var list = this.buildParentsTree(this.$route as any);
		return list;
	}
	getLabel(r: appRoute): string {
		if (r != null && r.meta != null)
			return r.meta.label;
		return r.name;
	}
	isCurrent(r: appRoute): boolean {
		var self = this;
		return self.$route.name == r.name;
	}
}
