import eventHub from '@/eventHub';
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import api from '../services/api'

Vue.use(VueI18n)

const loadedLanguages = [];
function setI18nLanguage(lang: string): string {
	i18n.locale = lang;
	// api.instance.defaults.headers.common['Accept-Language'] = lang;
	document.querySelector('html').setAttribute('lang', lang);
	return lang
}
eventHub.$on(eventHub.events.CULTURE_CHANGE, (culture) => {

	loadLanguageAsync(culture);
});
export function loadLanguageAsync(lang: string): Promise<string> {

	// If the same language
	// if (i18n.locale === lang) {
	// 	return Promise.resolve(setI18nLanguage(lang))
	// }

	// If the language was already loaded
	if (loadedLanguages.includes(lang)) {
		return Promise.resolve(setI18nLanguage(lang))
	}

	
	// If the language hasn't been loaded yet
	return api.getLocalization(lang).then(
		messages => {

			i18n.setLocaleMessage(lang, messages)
			loadedLanguages.push(lang)
			return setI18nLanguage(lang)
		}
	).catch(err => {
		i18n.setLocaleMessage('none', {})
		loadedLanguages.push('none')
		return setI18nLanguage('none')
	});
}


export const i18n = new VueI18n({
	locale: 'it-IT', // set locale
	fallbackLocale: 'it-IT',
	messages: {} // set locale messages
})

