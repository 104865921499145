import helpers from "@/helpers";
import CustomEditAction from "@/models/customEditAction";
import { column } from "@/models/grid/columnModels";
import moment from "moment";
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { filterBy } from "@progress/kendo-data-query";
import appColors from "@/models/appColors";
import { debug } from "console";

@Component({})
export default class editFormGrop extends Vue {
	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";
	@Prop({ default: () => 4 })
	labelCols: number;
	@Prop({ default: false })
	disabled: boolean;
	@Prop({ default: false })
	modalShown: boolean;
	get contentCols(): number {
		return 12 - (this.labelCols == 12 ? 0 : this.labelCols);
	}
	@Prop({ default: () => new column() })
	column: column;
	@Prop({ default: () => {} })
	value: any;

	files: any = [];
	filePreview: any = null;
	ddlFilter: any = null;

	showEditor: boolean = false;
	loadingImag: boolean = false;
	initialized: boolean = false;
	validation: any = {};
	isValid: boolean = null;

	created() {
		this.ddlFilter = null;
		this.filePreview = null;
		this.validation = this.column.validation || null;
	}

	mounted() {
		var self = this;
		this.initialized = true;
		setTimeout(() => {
			self.showEditor = true;
		}, 5000);
	}
	@Watch("item", { deep: true })
	onItemChange(oldValue, newValue) {
		var self = this;
		self.$emit("input", self.item);
	}
	// item: any = {};
	get item() {
		return this.value || {};
	}

	get fieldType() {
		var self = this;
		if (self.column == null) return "string";

		return self.column.editType || self.column.type || "string";
	}
	get acceptFile(): string {
		var self = this;
		return (self.column || new column()).acceptFile || ".pdf";
	}
	get field(): string {
		var self = this;
		return (self.column || new column()).field || "";
	}
	get editField(): string {
		var self = this;
		return (self.column || new column()).editField;
	}
	get model(): any {
		var self = this;
		return self.item[self.editField || self.field];
	}
	set model(value: any) {
		var self = this;
		self.item[self.field] = value;
	}
	get hint() {
		var self = this;
		return (self.column || new column()).hint;
	}
	get editDisabled(): boolean {
		var self = this;
		return self.disabled || (self.column || new column()).editDisabled || false;
	}
	get editSource(): any[] {
		var self = this;
		return (self.column || new column()).editSource || [];
	}
	get editSourceValue(): string {
		return (this.column || new column()).editSourceValue || "value";
	}
	get editSourceText(): string {
		return (this.column || new column()).editSourceText || "text";
	}
	get dropdownValue() {
		var self = this;
		var v = (self.editSource || []).find(x => x[self.editSourceValue] == this.item[self.field]);
		if (v != null) {
			if (!this.initialized) this.onChangeDropdownValue({ target: { value: v } });
			return v;
		}
		v = {};
		v[self.editSourceValue] = this.item[self.field];
		if (!this.initialized) this.onChangeDropdownValue({ target: { value: v } });
		return v;
		// return Object.assign({}, v || {});
	}
	get dropdownValueAppColor() {
		var self = this;
		var v = (self.appColorsSource || []).find(x => x["value"] == this.item[self.field]);
		if (v != null) {
			if (!this.initialized) this.onChangeDropdownValue({ target: { value: v } });
			return v;
		}
		v = {} as any;
		v["text"] = this.item[self.field];
		if (!this.initialized) this.onChangeDropdownValue({ target: { value: v } });
		return v;
		// return Object.assign({}, v || {});
	}
	onChangeDropdownValue(obj: any) {
		var self = this;
		var value = ((obj || {}).target || {}).value;
		// debugger;
		if (value) this.item[self.field] = value[self.editSourceValue];
		else this.item[self.field] = null;

		if (self.column.editSelectionCallBack) {
			self.column.editSelectionCallBack(value[self.editSourceValue], value);
		}
	}
	onChangeDropdownValueAppColor(props:any, value: string) {
		var self = this;
		var dropdownColor = this.$refs.ddlAppcolors as any;
		// var value = ((obj || {}).target || {}).value;
		// debugger;
		if (value) this.item[self.field] = value;
		else this.item[self.field] = null;

		if (self.column.editSelectionCallBack) {
			self.column.editSelectionCallBack(value[self.editSourceValue], value);
		}
		dropdownColor.currentOpened = false;
	}
	onChangeDate(obj: any) {}

	get multiSelectValue() {
		var self = this;
		// return this.item[self.editSourceValue] || [];
		var v = null;
		if (self.column.multiselectSplitChar) {
			var currValues = self.model ? self.model.split(self.column.multiselectSplitChar) : [];
			if (self.column.selectValueType === "int") currValues = currValues.map(x => (x ? parseInt(x) : null));
			v = self.editSource.filter(x => currValues.indexOf(x[self.editSourceValue]) != -1);
		} else {
			v = self.editSource.filter(x => self.model.indexOf(x[self.editSourceValue]) != -1);
		}
		return v;

		// if (v != null) return v;
		// v = {};
		// v[field] = this.item[c.field];
		// return v;
		// return Object.assign({}, v || {});
	}
	onChangeMultiSelectValue(obj: any) {
		var self = this;
		var value = ((obj || {}).target || {}).value;

		var v = null;
		if (self.column.multiselectSplitChar) {
			v = (value || []).map(x => x[self.editSourceValue]).join(self.column.multiselectSplitChar);
		} else {
			v = (value || []).map(x => x[self.editSourceValue]);
		}
		this.model = v;

		if (self.column.editSelectionCallBack) {
			self.column.editSelectionCallBack(v);
		}
	}
	get dropdownSource() {
		var self = this;
		const data = self.editSource.slice();
		if (self.ddlFilter == null) return data;
		// debugger;
		var filtered = filterBy(data, this.ddlFilter);
		return filtered;
		// if (c['__ddl_filter'] == null)
		// 	return c.editSource;
		// const data = c.editSource.slice();
		// var filtered = filterBy(data, c['__ddl_filter']);
		// return filtered;
	}
	get appColorsSource() {
		var self = this;
		const data = appColors.map(x => {
			return { text: x, value: x };
		});
		data.sort((a, b) => {
			if(a.text < b.text) { return -1; }
			if(a.text > b.text) { return 1; }
			return 0;
		});
		if (self.ddlFilter == null) return data;
		// debugger;
		var filtered = filterBy(data, this.ddlFilter);
		return filtered;
		// if (c['__ddl_filter'] == null)
		// 	return c.editSource;
		// const data = c.editSource.slice();
		// var filtered = filterBy(data, c['__ddl_filter']);
		// return filtered;
	}
	onFilterSelectChange(event: any) {
		// debugger;
		this.ddlFilter = event.filter;
		// Vue.set(c, '__ddl_filter', event.filter);
	}
	onNumericInputChange(value: any) {
		if (value || (value != "" && value == 0)) this.model = parseFloat(value).toFixed(2);
		else this.model = null;
		if (this.column.editChangeCallback) {
			this.column.editChangeCallback(this.model);
		}
	}
	onNumericIntInputChange(value: any) {
		if (value || value == 0) this.model = parseInt(value);
		else this.model = null;
		if (this.column.editChangeCallback) {
			this.column.editChangeCallback(this.model);
		}
	}
	onEditorInputChange(value: any) {
		var innerHTML = value.sender.body.innerHTML;
		if (innerHTML || innerHTML == "") this.model = innerHTML;
		else this.model = null;
	}
	get imagePathFallback(): string {
		return (this.column || new column()).pathFallback;
	}
	get imageValueField(): string {
		return this.editField || `_${this.field}_imageFile`;
	}

	get fileValueField(): string {
		return `${this.field}_uploadFile`;
	}

	onInputImageValue(value: File) {
		var self = this;

		Vue.set(this.item, this.imageValueField, value);

		// Ensure that you have a file before attempting to read it
		if (value) {
			self.loadingImag = true;
			// create a new FileReader to read this image and convert to base64 format
			var reader = new FileReader();
			reader.readAsDataURL(value);
			reader.addEventListener("load", () => {
				self.filePreview = reader.result;
				self.loadingImag = false;
			});
		}
	}
	// showImagePreview(c: columns): boolean {
	// 	if (this.filesPreview[c.field] != null && this.filesPreview[c.field].length > 0) return true;
	// 	else this.item[c.field] != null && this.item[c.field] != "";
	// }
	get imageIsForApi(): boolean {
		if (this.filePreview != null) return false;
		return true;
	}
	get imageSrc(): string {
		if (this.filePreview != null) return this.filePreview;
		else return this.item[this.field];
	}

	onEditImage(): void {
		(this.$refs["formFileImage"] as any).$el.firstElementChild.click();
	}
	onDeleteImage(): void {
		this.filePreview = null;
		Vue.set(this.item, this.imageValueField, null);
		Vue.set(this.item, this.field, null);
	}

	onInputFileValue(value: File) {
		Vue.set(this.item, this.fileValueField, value);
	}

	validate() {
		if (!this.validation) this.isValid = null;
		else {
			var v = this.validation;
			switch (this.fieldType) {
				case "date":
				case "datetime":
					if (v.required) {
						this.isValid = !this.model ? false : null;
					}
					break;
				case "numeric":
				case "numeric-int":
					if (v.required) {
						this.isValid = parseFloat(this.model || 0) === 0 ? false : null;
					}
					break;
				case "select":
				case "multi-select":
					if (v.required) {
						this.isValid = !this.model ? false : null;
					}
					break;
				default:
					if (v.required) {
						this.isValid = !this.model ? false : null;
					}
					break;
			}
		}
		this.updateValidField();
		return this.isValid === false ? false : true;
	}

	updateValidField() {
		var v = this.validation;
		switch (this.fieldType) {
			case "date":
			case "datetime":
			case "select":
			case "multi-select":
				if (this.isValid == false) this.$el.classList.add("is-invalid");
				else if (this.$el.classList.contains("is-invalid")) this.$el.classList.remove("is-invalid");
				break;
			default:
				break;
		}
	}

	// onAdd (event) {
	// 	debugger;
	// 	Vue.set(this.item, this.fileValueField, event.newState);
	// }

	// onRemove(event) {
	// 	Vue.set(this.item, this.fileValueField, null);
	// }

	// onStatusChange(event) {
	// 	Vue.set(this.item, this.fileValueField, event.newState);
	// }

	// dateFormatter(value) {
	// 	return this.$options.filters.dateToString(value);
	// }

	// onInputDateChange(e: string, c: column) {
	// 	if (moment(e, [moment.RFC_2822, moment.ISO_8601, "DD/MM/YYYY"]).isValid()) {
	// 		this.item[c.field] = e;
	// 	}
	// }

	// onDatePickerChange(e: string, c: column) {
	// 	this.item[c.field] = e;
	// }

	// getDate(c: column) {
	// 	return this.$options.filters.dateToString(this.item[c.field]);
	// }
	// getDateForPicker(c: column) {
	// 	if (moment(this.item[c.field], [moment.RFC_2822, moment.ISO_8601, "DD/MM/YYYY"]).isValid()) {
	// 		return this.item[c.field];
	// 	}
	// 	return null;
	// }

	// openCalendar(d: column) {
	// 	var self = this;
	// 	let refName = `datetimeedit_${d.field}`;
	// 	(self.$refs[refName] as any)[0].dp.show();
	// }

	// get datePickerOptions(): any {
	// 	return helpers.optionsDateTimePicker();
	// }
}
