export class GaranziePolizzeItem {
	constructor() {
		this.itemID = null;
		this.prodottoID = null;
		this.nome = null;
		this.testo = null;
		this.codiceRamo = null;
		this.imposte = null;
		this.pesoAssicurativo = null;
	}

	itemID: number;
	prodottoID: number;
	nome: string;
	testo: string;
	codiceRamo: string;
	imposte: number | null;
	pesoAssicurativo: number | null;
}