import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { StatiItem } from "@/models/amministrazione/stati/statiItem";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.STATI;
@Component({})
export default class iStati extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Amministrazione.STATI]; }
	editItem: StatiItem = new StatiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;


	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "nazione", title: this.$i18n.t("generico.nazione").toString(),  headerCell: "myHeaderCellTemplate"},
			{ field: "alphaCode3", title: this.$i18n.t("generico.alphaCode3").toString(), groupable: true, headerCell: "myHeaderCellTemplate", export: true,width: 200 },
			{ field: "numerico", title: this.$i18n.t("generico.numerico").toString(), filterType: "numeric-int", groupable: true, headerCell: "myHeaderCellTemplate", export: true,width: 200 },
			{ field: "alphaCode2", title: this.$i18n.t("generico.alphaCode2").toString(), groupable: true, headerCell: "myHeaderCellTemplate", export: true,width: 200 },
			{ field: "nomeItaliano", title: this.$i18n.t("generico.nomeItaliano").toString(), groupable: true, headerCell: "myHeaderCellTemplate", export: true,width: 250 },
			{ field: "ordine", title: this.$i18n.t("generico.ordine").toString(), filterType: "numeric-int", groupable: true, headerCell: "myHeaderCellTemplate", export: true,width: 200 },
			{ field: "nascostoSuPreventivatore", title: this.$i18n.t("generico.nascostoSuPreventivatore").toString(), width: 150, type: "boolean", filterType: "boolean", headerCell: "myHeaderCellTemplate", disabled: true },
			{ field: "statoXML", title: "Stato XML", width: 150, type: "boolean", filterType: "boolean", headerCell: "myHeaderCellTemplate", disabled: true },
			{ field: "_actions", title: " ", width: (50 + (this.canDelete ? 50 : 0)), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "nazione", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.stati")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new StatiItem();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new StatiItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.stati") }).toString();
		self.setGridRow<StatiItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
