export class StatiItem {
	constructor() {
		this.itemID = null;
		this.nazione = null;
		this.alphaCode3 = null;
		this.numerico = null;
		this.alphaCode2 = null;
		this.nomeItaliano = null;
		this.ordine = null;
		this.nascostoSuPreventivatore = null;
		this.statoXML = null;
		this.deleted = null;
	}
	itemID: number;
	nazione: string;
	alphaCode3: string;
	numerico: number | null;
	alphaCode2: string;
	nomeItaliano: string;
	ordine: number | null;
	nascostoSuPreventivatore: boolean | null;
	statoXML: boolean | null;
	deleted: boolean | null;
}