export class AssStatoGruppoStatoItem {
	constructor() {
		this.itemID = null;
		this.fornitoreID = null;
		this.ragioneSociale = null;
		this.statoID = null;
		this.gruppoStatoID = null;
		this.codiceStatoA3 = null;
		this.nazione = null;
		this.gruppoStatoA3 = null;
		this.gruppoStato = null;
		this.deleted = null;
	}
	itemID: number;
	fornitoreID: number;
	ragioneSociale: string;
	codiceStatoA3: string;
	statoID: number;
	gruppoStatoID: number;
	nazione: string;
	gruppoStatoA3: string;
	gruppoStato: string;
	deleted: boolean | null;
}